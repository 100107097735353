import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import Tlattregform from '../pages/Tlattregform';

function Tlattregframe() {
    const navigate = useNavigate();

    return (
        <div className="p-5 bg-white">
            {/* <div className='ml-7'> */}
                <div className="flex">
                    <BiArrowBack
                        className="mt-1.5 mr-2.5 cursor-pointer"
                        size="2.5%"
                        style={{ color: '#4A65EE' }}
                        onClick={() => navigate(-1)}
                    />
                    <h2 className="pt-2 mb-4 border-x-zxblue-from text-transparent font-semibold text-xl bg-clip-text bg-gradient-to-t from-zxheader-from to-zxheader-to">
                        <span className="border-l-4 pt-1 border-zxsub-to pl-1">
                            Team Attendance Regularize
                        </span>
                    </h2>
                </div>
                <Tlattregform />
            {/* </div> */}
        </div>
    );
}

export default Tlattregframe;
