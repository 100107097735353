import moment from 'moment';
import { Attendance } from '../../api';

interface Props {
    attData: Attendance[];
}

function EmpAvgHours(props: Props) {
    const totalHrs = props.attData.reduce((acc: any, curr: any) => {
        const startTime = moment(curr.sign_in, 'HH:mm:ss a');
        const endTime = moment(curr.sign_out, 'HH:mm:ss a');
        const duration = moment.duration(endTime.diff(startTime));
        return acc + duration.hours();
    }, 0);

    const totalMins =
        ((totalHrs % props.attData.length) * 60) / props.attData.length;
    const avgHrs = totalHrs / props.attData.length;
    const avgmin =
        ((totalHrs % props.attData.length) * 60) / props.attData.length;

    return (
        <div className="font-medium lg:text-base md:text-sm border border-zxblue-from rounded-lg flex justify-between px-8 py-4 w-[70%] mb-12">
            <div className="flex gap-x-8">
                <p className="text-center">Total Hours</p>
                <p>
                    {Math.floor(totalHrs).toString()} Hrs {Math.floor(totalMins).toString()} Min
                </p>
            </div>
            <div className="flex gap-x-8">
                <p className="text-center">Avg Hours</p>
                <p>
                    {Math.floor(avgHrs).toString()}Hrs {Math.floor(avgmin).toString()} Min
                </p>
            </div>
        </div>
    );
}

export default EmpAvgHours;
