import { TLLeave, useRetrieveEmployee } from '../api';

interface EmponleaverowProps {
    tlleave: TLLeave;
}

function Emponleaverow({ tlleave }: EmponleaverowProps) {
    const tlleave_emp_id = tlleave?.employee_id;
    const { data: employeeData } = useRetrieveEmployee(
        tlleave_emp_id ? tlleave_emp_id.toString() : ''
    );
    const emp_name = employeeData?.data.full_name;

    const date1 = tlleave?.applying_from;
    const date2 = tlleave?.applying_to;
    const Difference_In_Time =
        new Date(date2 as string).getTime() -
        new Date(date1 as string).getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return (
        <tr key={tlleave.id} className="text-center h-14">
            <td className="  ">
                <span className="border border-zxblue-from p-3">
                    {tlleave.id}
                </span>
            </td>
            <td>{emp_name}</td>
            <td>{tlleave.applying_from}</td>
            <td>{tlleave.applying_to}</td>
            <td>{Difference_In_Days+1}</td>
            <td>
                {tlleave.leave_type === 'CompOff' ? (
                    <div className="w-4 h-4  rounded-full flex ml-[44%] bg-gradient-to-r from-compbullet-from to-compbullet-to " />
                ) : (
                    <div />
                )}
                {tlleave.leave_type === 'Earned' ? (
                    <div className="w-4 h-4  rounded-full flex ml-[44%] bg-gradient-to-r from-earnedbullet-from to-earnedbullet-to  " />
                ) : (
                    <div />
                )}
                {tlleave.leave_type === 'Medical' ? (
                    <div className="w-4 h-4  rounded-full flex ml-[44%] bg-gradient-to-r from-medbullet-from to-medbullet-to " />
                ) : (
                    <div />
                )}
                {tlleave.leave_type === 'Unpaid' ? (
                    <div className="w-4 h-4  rounded-full flex ml-[44%] bg-gradient-to-r from-unpaidbullet-from to-unpaidbullet-to " />
                ) : (
                    <div />
                )}
                {tlleave.leave_type === 'Casual' ? (
                    <div className="w-4 h-4  rounded-full flex ml-[44%] bg-gradient-to-r from-casbullet-from to-casbullet-to " />
                ) : (
                    <div />
                )}
                {tlleave.leave_type === 'Optional_Holiday' ? (
                    <div className="w-4 h-4  rounded-full flex ml-[44%] bg-gradient-to-r from-opbullet-from to-opbullet-to " />
                ) : (
                    <div />
                )}
            </td>
        </tr>
    );
}

export default Emponleaverow;
