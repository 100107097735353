import classnames from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Designation, useCreateDesignation } from '../../api';
import { getHtmlFor } from '../../utils/formUtils';
import ButtonField from '../FormFields/ButtonField';

export default function CreateDesignation(props: {
    desgnData: Designation[] | undefined;
    refetch: any;
}) {
    const { mutateAsync, isSuccess } = useCreateDesignation();

    const initialDesignationData = {
        name: '',
    };

    const designationValidationSchema = Yup.object({
        name: Yup.string().max(25, 'Too Long').required('Required'),
    });

    const handleDesgnSubmit = async (values: Designation) => {
        if (
            props.desgnData?.some(
                (desgnDetails) => desgnDetails.name === values.name
            )
        ) {
            toast.warn(`${values.name} is present in Database`);
        } else {
            const createDesgn: Designation = {
                ...values,
            };
            await mutateAsync({ data: createDesgn });
            toast.success(`${values.name} Created`);
        }
    };

    useEffect(() => {
        if (isSuccess) props.refetch();
    }, [isSuccess, props]);

    return (
        <div className="flex flex-col px-10">
            <h2 className="py-4 border-x-zxblue-from text-transparent font-semibold md:text-xl lg:text-2xl bg-clip-text bg-gradient-to-t from-zxheader-from to-zxheader-to">
                <span className="border-l-2 border-zxsub-to pl-1">
                    Create Designation
                </span>
            </h2>
            <Formik
                initialValues={initialDesignationData}
                validationSchema={designationValidationSchema}
                onSubmit={handleDesgnSubmit}
            >
                {({ touched, errors, submitForm, resetForm }) => (
                    <Form className="flex flex-row space-x-14">
                        <div className="flex flex-col">
                            <label
                                htmlFor={getHtmlFor('manager_id')}
                                className={classnames(
                                    'pl-0.5',
                                    touched.name && errors.name
                                        ? 'text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                                        : 'border-zxblue-to'
                                )}
                            >
                                Designation Name
                            </label>
                            <Field
                                name="name"
                                type="text"
                                placeholder="New designation name"
                                className={classnames(
                                    'w-full h-8 border rounded-md focus:outline-none mr-3 placeholder: pl-2 text-sm',
                                    touched.name && errors.name
                                        ? 'border-zxerror-to'
                                        : 'border-zxblue-to'
                                )}
                            />
                            <ErrorMessage
                                name="name"
                                component="span"
                                className="text-xs text-zxerror-from top-0"
                            />
                        </div>
                        <ButtonField
                            name="Submit"
                            className="mt-[2%]"
                            onClick={() => {
                                submitForm().then(() => {
                                    resetForm();
                                });
                            }}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
}
