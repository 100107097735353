import RegularizationFrame from '../components/Regularization/RegularizationFrame';
import ZXHeader from '../components/ZXHeader';

export default function Regularization() {
    return (
        <div className="w-screen h-screen box-border p-6 overflow-auto bg-zxattendancebg">
            <div className="pl-12">
                <ZXHeader />
            </div>
            <div className="m-10 box-border shadow-md">
                <RegularizationFrame />
            </div>
        </div>
    );
}
