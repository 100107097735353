import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { MSViewer, useListTodaysAttnds, useRetrieveShift } from '../../api';

import { useCreateAttendance } from '../../api/endpoints/signin/signin';
import { useUpdateAttendance } from '../../api/endpoints/signout/signout';
import Button from '../FormFields/Button';

export default function AttendanceDBD(props: {
    viewerData: AxiosResponse<MSViewer[]> | undefined;
    isViewerAdmin: boolean;
}) {
    const empDetails = props.viewerData?.data[0].MSemployee_details;
    const { data: shiftData } = useRetrieveShift(
        empDetails?.shift_id ? empDetails.shift_id.toString() : ''
    );
    const shiftName = shiftData?.data.shift_name;
    const { mutateAsync: createAsync, isSuccess: createdAtt } =
        useCreateAttendance();
    const { mutateAsync: updateAsync, isSuccess: updatedAtt } =
        useUpdateAttendance();
    const { data: attendanceData, refetch } = useListTodaysAttnds();
    const attendanceDetails = attendanceData?.data;
    const [clockState, setClockState] = useState('');
    const [date, setDateState] = useState('');
    const [weekDay, setWeekDayState] = useState('');

    useEffect(() => {
        setInterval(() => {
            const dt = new Date();
            setClockState(
                dt.toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                })
            );
            setDateState(
                dt.toLocaleDateString('en-IN', {
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit',
                })
            );
            setWeekDayState(dt.toLocaleString('default', { weekday: 'long' }));
        }, 1000);
    }, []);

    const handleSignIn = async () => {
        const element = document.getElementById('signInBtn');
        const btnName = element?.getAttribute('name');
        if (btnName === 'Sign In') {
            const it = new Date().toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
            });
            await createAsync({ data: { sign_in: '', sign_out: '' } });
            toast.success(`Signed in at: ${it}`);
        } else {
            const ot = new Date().toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
            });
            await updateAsync({ data: { sign_in: '', sign_out: '' } });
            toast.success(`Signed out at: ${ot}`);
        }
    };
    useEffect(() => {
        if (createdAtt) {
            refetch();
        }
        if (updatedAtt) {
            refetch();
        }
    }, [refetch, createdAtt, updatedAtt]);

    return (
        <div
            className={classNames(
                'flex flex-col px-7 py-6 box-border',
                props.isViewerAdmin ? 'space-y-5' : 'gap-3'
            )}
        >
            <div className=" text-2xl font-bold text-transparent bg-gradient-to-r from-zxheader-from to-zxheader-to opacity-700 bg-clip-text">
                {clockState}
            </div>
            <div className="text-gray-800 text-base font-medium">{date}</div>
            <div className="font-normal text-xs">
                {weekDay} | {shiftName}
            </div>
            {attendanceDetails?.length === 0 ||
            attendanceDetails === undefined ? (
                <span>Sign In: --:--</span>
            ) : attendanceDetails[attendanceDetails.length - 1].sign_out ===
              null ? (
                <span>
                    Signed In at:{' '}
                    {attendanceDetails[attendanceDetails.length - 1].sign_in}
                </span>
            ) : attendanceDetails[attendanceDetails.length - 1].sign_out ? (
                <span>
                    Signed Out at:{' '}
                    {attendanceDetails[attendanceDetails.length - 1].sign_out}
                </span>
            ) : (
                <span>Sign In: --:--</span>
            )}
            {!props.isViewerAdmin ? (
                <Button
                    value={
                        attendanceDetails?.length === 0 ||
                        attendanceDetails === undefined
                            ? 'Sign In'
                            : attendanceDetails[attendanceDetails.length - 1]
                                  .sign_out === null
                            ? 'Sign Out'
                            : 'Sign In'
                    }
                    name={
                        attendanceDetails?.length === 0 ||
                        attendanceDetails === undefined
                            ? 'Sign In'
                            : attendanceDetails[attendanceDetails.length - 1]
                                  .sign_out === null
                            ? 'Sign Out'
                            : 'Sign In'
                    }
                    id="signInBtn"
                    onClick={handleSignIn}
                    className="w-fit mx-auto"
                />
            ) : null}
        </div>
    );
}
