import * as Yup from 'yup';

import { Holiday, HolidayType, useCreateHoliday } from '../api';

import { useFormik } from 'formik';
import moment from 'moment';
import { toast } from 'react-toastify';
import { weekday_FullNames } from '../constants/functions';
import Button from './FormFields/Button';

function DateField(props: {
    label: string;
    placeholder: string;
    id: string;
    value: any;
    name: string;
    formikChange: any;
    touched: any;
    formik: any;
}) {
    return (
        <div className="flex flex-col">
            <label
                className={
                    props.touched && props.formik
                        ? 'pl-0.5 text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to to-zxerror-to'
                        : 'pl-0.5'
                }
            >
                {props.label}
            </label>
            <input
                id={props.id}
                name={props.name}
                className={
                    props.touched && props.formik
                        ? ' lg:w-[80%] mb-4 border border-zxerrordisplay-to rounded-md focus:outline-none placeholder: pl-2 py-0.5 md:w-48'
                        : 'lg:w-[80%] mb-4 mt-3 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5 md:w-48'
                }
                type="date"
                placeholder={props.placeholder}
                onChange={props.formikChange}
                value={props.value}
            />
        </div>
    );
}

function HolCrudForm() {


    const { mutateAsync } = useCreateHoliday();
    const formik = useFormik({
        initialValues: {
            name: '',
            date: '',
            // day:'',
            type: HolidayType.COMP,
        },

        validationSchema: Yup.object({
            name: Yup.string().max(30).required('Required'),
        }),
        onSubmit: async (values) => {
            const updatedVal: Holiday = {
                name: values.name,
                type: values.type,
                date: values.date,
                day: weekday_FullNames[moment(values.date).day()],
            };
            await mutateAsync({ data: updatedVal });
            toast.success('Added Holiday');
        },
    });
    return (
        <form
            className="grid md:grid-cols-3 lg:grid-cols-3 pl-28 pt-6 md:pl-24"
            onSubmit={formik.handleSubmit}
        >
            <div>
                <label>Holiday Name</label>
                <br />
                <input
                    type="text"
                    placeholder="Republic Day"
                    className={
                        formik.touched.name && formik.errors.name
                            ? 'lg:[90%] mb-4 mt-3 border border-zxerrordisplay-to rounded-md focus:outline-none placeholder: pl-2 py-1 md:w-48 '
                            : 'lg:[90%] mb-4 mt-3 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-1 md:w-48 '
                    }
                    name="name"
                    id="name"
                    onChange={formik.handleChange}
                    value={formik.values.name}
                />
            </div>
            <DateField
                label="Holiday Date"
                name="date"
                id="date"
                formikChange={formik.handleChange}
                value={formik.values.date}
                touched={formik.touched.date}
                formik={formik.errors.date}
                placeholder="DD MM YYYY"
            />

            <div>
                <label className="">Holiday Type</label>
                <br />
                <select
                    className={
                        formik.touched.type && formik.errors.type
                            ? 'lg:[90%] mb-4 mt-3 border border-zxerrordisplay-to rounded-md focus:outline-none placeholder: pl-2 py-1 md:w-48 '
                            : 'lg:[90%] mb-4 mt-3 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-1 md:w-48 '
                    }
                    name="type"
                    id="type"
                    placeholder="----"
                    onChange={formik.handleChange}
                    value={formik.values.type}
                >
                    <option defaultValue="">CHOOSE A TYPE</option>
                    <option value="COMP">Compulsory</option>
                    <option value="OP">Optional</option>
                </select>
            </div>
            
            <div className="mt-10 ml-[105%]">
                <Button
                    value="Submit"
                    className="lg:text-base place-self-center md:text-sm ml-4"
                />
            </div>
        </form>
    );
}
export default HolCrudForm;
