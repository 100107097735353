import { useLocation } from 'react-router-dom';
import ProfileUpdateForm from './ProfileUpdateForm';
import ProfileUpdateHeader from './ProfileUpdateHeader';

export default function BackgroundProfUpdate() {
    const location = useLocation();
    const id = location.pathname.substring(
        location.pathname.lastIndexOf('/') + 1
    );

    return (
        <div className="flex flex-col py-5 w-full">
            <ProfileUpdateHeader id={id} />
            <ProfileUpdateForm id={id} />
        </div>
    );
}
