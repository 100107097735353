import moment from 'moment';
import { ProfileNotifications } from '../api';

function Notif(props: {
    className: string | undefined;
    notification: ProfileNotifications;
    empName: string | undefined;
}) {
    return (
        <>
            <div className="w-[1px] h-9 mt-3 mr-1 bg-zxline" />
            <div className={props.className}>
                {props.empName ? (
                    <h2 className="text-base font-medium mb-2">
                        <strong>{props.empName}</strong>
                    </h2>
                ) : null}

                <div className=" flex flex-row justify-between space-x-0.5 text-xs font-light">
                    {props.notification.created_at ===
                    props.notification.updated_at ? (
                        <span>Hi! I have filled my profile information</span>
                    ) : (
                        <span>Hi! I have updated my profile information</span>
                    )}
                    <span className="mr-4">
                        {moment
                            .utc(props.notification.updated_at)
                            .local()
                            .startOf('seconds')
                            .fromNow()}
                    </span>
                </div>
            </div>
        </>
    );
}

export default Notif;
