/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  Department
} from '../../schema'


/**
 * List all departments
 */
export const listDepartments = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Department[]>> => {
    return axios.get(
      `/departments/`,options
    );
  }


export const getListDepartmentsQueryKey = () => [`/departments/`];

    
export type ListDepartmentsQueryResult = NonNullable<Awaited<ReturnType<typeof listDepartments>>>
export type ListDepartmentsQueryError = AxiosError<unknown>

export const useListDepartments = <TData = Awaited<ReturnType<typeof listDepartments>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listDepartments>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListDepartmentsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listDepartments>>> = ({ signal }) => listDepartments({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof listDepartments>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * Create department
 */
export const createDepartment = (
    department: Department, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Department>> => {
    return axios.post(
      `/departments/`,
      department,options
    );
  }



    export type CreateDepartmentMutationResult = NonNullable<Awaited<ReturnType<typeof createDepartment>>>
    export type CreateDepartmentMutationBody = Department
    export type CreateDepartmentMutationError = AxiosError<unknown>

    export const useCreateDepartment = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createDepartment>>, TError,{data: Department}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createDepartment>>, {data: Department}> = (props) => {
          const {data} = props ?? {};

          return  createDepartment(data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof createDepartment>>, TError, {data: Department}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * Get department details
 */
export const retrieveDepartment = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Department>> => {
    return axios.get(
      `/departments/${id}/`,options
    );
  }


export const getRetrieveDepartmentQueryKey = (id: string,) => [`/departments/${id}/`];

    
export type RetrieveDepartmentQueryResult = NonNullable<Awaited<ReturnType<typeof retrieveDepartment>>>
export type RetrieveDepartmentQueryError = AxiosError<unknown>

export const useRetrieveDepartment = <TData = Awaited<ReturnType<typeof retrieveDepartment>>, TError = AxiosError<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof retrieveDepartment>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveDepartmentQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveDepartment>>> = ({ signal }) => retrieveDepartment(id, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof retrieveDepartment>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * Update department details
 */
export const updateDepartment = (
    id: string,
    department: Department, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Department>> => {
    return axios.put(
      `/departments/${id}/`,
      department,options
    );
  }



    export type UpdateDepartmentMutationResult = NonNullable<Awaited<ReturnType<typeof updateDepartment>>>
    export type UpdateDepartmentMutationBody = Department
    export type UpdateDepartmentMutationError = AxiosError<unknown>

    export const useUpdateDepartment = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDepartment>>, TError,{id: string;data: Department}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateDepartment>>, {id: string;data: Department}> = (props) => {
          const {id,data} = props ?? {};

          return  updateDepartment(id,data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof updateDepartment>>, TError, {id: string;data: Department}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * Delete a department
 */
export const destroyDepartment = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    return axios.delete(
      `/departments/${id}/`,options
    );
  }



    export type DestroyDepartmentMutationResult = NonNullable<Awaited<ReturnType<typeof destroyDepartment>>>
    
    export type DestroyDepartmentMutationError = AxiosError<unknown>

    export const useDestroyDepartment = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof destroyDepartment>>, TError,{id: string}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof destroyDepartment>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  destroyDepartment(id,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof destroyDepartment>>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    