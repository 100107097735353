import { useEffect } from 'react';
import { useListAccessories } from '../../api';
import AccessoryFilter from './AccessoryFilter';
import AccessoryRow from './AccessoryRow';

interface AccessoryTableProps {
    filters: {
        type: string;
        assigned_to: string;
    };
    setFilters: React.Dispatch<
        React.SetStateAction<{
            type: string;
            assigned_to: string;
        }>
    >;
}

export default function AccessoryTable(props: AccessoryTableProps) {
    const {
        data: accessoryData,
        isSuccess,
        isLoading,
        isFetchedAfterMount,
        refetch,
    } = useListAccessories({
        axios: {
            params: {
                type: props.filters.type === '' ? null : props.filters.type,
                assigned_to:
                    props.filters.assigned_to === ''
                        ? null
                        : props.filters.assigned_to,
            },
        },
    });

    useEffect(() => {
        refetch();
    }, [refetch, props.filters.assigned_to, props.filters.type]);

    return (
        <div className="flex flex-col px-10">
            <h2 className="py-4 border-x-zxblue-from text-transparent font-semibold md:text-xl lg:text-2xl bg-clip-text bg-gradient-to-t from-zxheader-from to-zxheader-to">
                <span className="border-l-2 border-zxsub-to pl-1">
                    Accessory Directory
                </span>
            </h2>
            <AccessoryFilter setFilters={props.setFilters} />
            <table className="text-zxtext-1 md:text-sm lg:text-lg px-2 w-full table-auto">
                <thead className="lg:h-11 md:h-10">
                    <tr id="bord-row">
                        <th>Sr No</th>
                        <th>Type</th>
                        <th>Serial No</th>
                        <th>Assigned To</th>
                    </tr>
                </thead>
                <tbody className="lg:text-sm md:text-xs text-left">
                    {isLoading ? (
                        <tr>
                            <td>Loading.......</td>
                        </tr>
                    ) : isFetchedAfterMount && isSuccess ? (
                        <AccessoryRow<
                            Parameters<typeof refetch>[0],
                            ReturnType<typeof refetch>
                        >
                            accessoryData={accessoryData}
                        />
                    ) : (
                        <tr>
                            <td>Not Success</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}
