import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import './Calendaratt.css';

// import Calendar from 'react-calendar';
import { Calendar, DateObject } from 'react-multi-date-picker';
import {
    useListMSViewers,
    useListTodaysAttnds,
    useRetrieveShift
} from '../api';
import { useCreateAttendance } from '../api/endpoints/signin/signin';
import { useUpdateAttendance } from '../api/endpoints/signout/signout';
import AttendanceTableDiv from './AttendanceTableDiv';
import Button from './FormFields/Button';

function Tlattform() {
    const { data: usersData } = useListMSViewers();
    const empDetails = usersData?.data[0].MSemployee_details;
    const { data: shiftData } = useRetrieveShift(
        empDetails?.shift_id ? empDetails.shift_id.toString() : ''
    );
    const { mutateAsync: createAsync, isSuccess: createdAtt } =
        useCreateAttendance();
    const { mutateAsync: updateAsync, isSuccess: updatedAtt } =
        useUpdateAttendance();
    const { data: attendanceData, refetch: refetchTodaysAttnd } =
        useListTodaysAttnds();
    const attendanceDetails = attendanceData?.data;

    const [dateObj, setDateObj] = useState(new DateObject());
    const [clockState, setClockState] = useState('');
    const [date, setDateState] = useState('');
    const [weekDay, setWeekDayState] = useState('');

    // const {
    //     data: attendancesData,
    //     isSuccess: isMonthlyAttndSuccess,
    //     isLoading: isMonthlyAttndLoading,
    //     isFetchedAfterMount: isMonthlyAttndFetchedAfterMount,
    //     refetch: refetchMonthlyAttnd,
    // } = useRetrieveEmpAttendance<AxiosResponse<EmpAttendance[]>>(
    //     empDetails?.id ? empDetails.id.toString() : '',
    //     {
    //         axios: {
    //             params: {
    //                 month: props.month,
    //                 year: props.year,
    //             },
    //         },
    //     }
    // );
    // const att_data = attendancesData?.data;

    useEffect(() => {
        setInterval(() => {
            const dt = new Date();
            setClockState(
                dt.toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                })
            );
            setDateState(
                dt.toLocaleDateString('en-IN', {
                    year: 'numeric',
                    month: 'long',
                    day: '2-digit',
                })
            );
            setWeekDayState(dt.toLocaleString('default', { weekday: 'long' }));
        }, 1000);
        if (createdAtt) {
            refetchTodaysAttnd();
        }
        if (updatedAtt) {
            refetchTodaysAttnd();
        }
    }, [createdAtt, refetchTodaysAttnd, updatedAtt]);

    const handleSignIn = async () => {
        const element = document.getElementById('signInBtn');
        const btnName = element?.getAttribute('name');
        if (btnName === 'Sign In') {
            const it = new Date().toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
            });
            await createAsync({ data: { sign_in: '', sign_out: '' } });
            toast.success(`Signed in at: ${it}`);
        } else {
            const ot = new Date().toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
            });
            await updateAsync({ data: { sign_in: '', sign_out: '' } });
            toast.success(`Signed out at: ${ot}`);
        }
    };

    return (
        <div className="flex flex-col space-x-10 p-1">
            <div className="flex flex-col mx-auto w-full">
                <div className="flex lg:flex-row md:flex-col shadow-lg box-border py-4">
                    <div className="m-auto text-xl font-bold text-transparent bg-gradient-to-r from-zxheader-from to-zxheader-to opacity-700 bg-clip-text">
                        {clockState}
                        <div className="text-gray-800 text-base font-medium mt-[6%]">
                            {date}
                        </div>
                    </div>
                    <div className="my-auto space-y-2 font-normal text-sm">
                        <div className="">
                            {weekDay} | {shiftData?.data.shift_name}
                        </div>
                        {attendanceDetails?.length === 0 ||
                        attendanceDetails === undefined ? (
                            <span className="">Sign In: --:--</span>
                        ) : attendanceDetails[attendanceDetails.length - 1]
                              .sign_out === null ? (
                            <div className="flex flex-col space-y-2">
                                <span className="">
                                    Sign In:{' '}
                                    {
                                        attendanceDetails[
                                            attendanceDetails.length - 1
                                        ].sign_in
                                    }
                                </span>
                                <span className="">Sign Out: --:--</span>
                            </div>
                        ) : attendanceDetails[attendanceDetails.length - 1]
                              .sign_out ? (
                            <div className="flex flex-col space-y-2">
                                <span className="">
                                    Sign In:{' '}
                                    {
                                        attendanceDetails[
                                            attendanceDetails.length - 1
                                        ].sign_in
                                    }
                                </span>
                                <span className="">
                                    Sign Out:{' '}
                                    {
                                        attendanceDetails[
                                            attendanceDetails.length - 1
                                        ].sign_out
                                    }
                                </span>
                            </div>
                        ) : (
                            <span className="">Sign In: --:--</span>
                        )}
                    </div>
                    <Button
                        value={
                            attendanceDetails?.length === 0 ||
                            attendanceDetails === undefined
                                ? 'Sign In'
                                : attendanceDetails[
                                      attendanceDetails.length - 1
                                  ].sign_out === null
                                ? 'Sign Out'
                                : 'Sign In'
                        }
                        name={
                            attendanceDetails?.length === 0 ||
                            attendanceDetails === undefined
                                ? 'Sign In'
                                : attendanceDetails[
                                      attendanceDetails.length - 1
                                  ].sign_out === null
                                ? 'Sign Out'
                                : 'Sign In'
                        }
                        id="signInBtn"
                        onClick={handleSignIn}
                        className="m-auto"
                    />
                </div>
                <Calendar
                    className="w-[80%] h-min text-sm font-medium mx-auto my-5"
                    onChange={(selectedDate: DateObject) => {
                        setDateObj(selectedDate);
                    }}
                    onMonthChange={(selectedDate) => {
                        setDateObj(selectedDate);
                    }}
                />
                <AttendanceTableDiv
                    month={dateObj.month.number}
                    year={dateObj.year}
                    createdAtt={createdAtt}
                    updatedAtt={updatedAtt}
                />
            </div>
        </div>
    );
}
export default Tlattform;
