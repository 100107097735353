import { toast } from 'react-toastify';
import { useRetrieveRolesAssign } from '../api';

interface Props {
    val: {
        id: string;
        role: string;
    };
}

function Assign(props: Props) {
    const { isSuccess, refetch } = useRetrieveRolesAssign(
        props.val.id ? props.val.id : '',
        {
            axios: {
                params: {
                    role: props.val.role,
                },
            },
        }
    );

    // useEffect(() => {
    if (isSuccess) {
        toast.success('Role Assigned');
        // refetch();
    }
    // }, [isSuccess, refetch]);
    return null;
}

export default Assign;
