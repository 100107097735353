import { AxiosResponse } from 'axios';
import {
    Employee,
    EmployeeRegularization,
    useRetrieveEmployeeRegularization
} from '../api';
import AttRegAppForm from './AttRegAppForm';
import EmpMulReg from './EmpMulReg';

interface Props{
    id: number | undefined;
    emp:Employee | undefined;
    empfetch:any;
}

function EmpReg(props: Props) {
    const {
        data: empregData,
        isSuccess,
        refetch,
    } = useRetrieveEmployeeRegularization<
        AxiosResponse<EmployeeRegularization[]>
    >(props.id ? props.id.toString() : ' ', {
        axios: {
            params: { status: 'Pending' },
        },
    });

    // later
//      useEffect(()=>{
//     if(isSuccess)
//     refetch();
//   },[isSuccess, refetch])

    const empreg = empregData?.data;
    // console.log(empregData?.data);

    return (
        <div>
            {empreg && isSuccess && empreg.length===1
                ? empreg.map((e) =>
                       (
                          <AttRegAppForm
                              key={e?.id}
                              regData={e}
                              regsData={empreg}
                              refetch={refetch}
                          />
                      ) 
                  )
                : empreg && isSuccess && empreg.length>1?
                <EmpMulReg 
                mulregdata={props.emp}
                edata={empreg}
                refetch={refetch}
                empfetch={props.empfetch}
                />:null
            }
        </div>
    );
}

export default EmpReg;
