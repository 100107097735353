import { AxiosResponse } from 'axios';
import { Formik } from 'formik';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import {
    Accessories,
    Accessory,
    Employee,
    useCreateAccessories,
    useListEmployees
} from '../../api';
import ButtonField from '../FormFields/ButtonField';
import FormikField from '../FormFields/FormikField';
import FormikSelect from '../FormFields/FormikSelect';

export default function AssignAccessory(props: {
    accessoryList: AxiosResponse<Accessory[], any> | undefined;
    // refetchAccessorys: any;
    accessoriesData: AxiosResponse<Accessories[], any> | undefined;
    refetchAccessories: any;
}) {
    const {
        data: employeeData,
        isSuccess: isListEmpSuccess,
        isLoading,
    } = useListEmployees();
    const {
        mutateAsync,
        isSuccess: isCreateAccSuccess,
        isLoading: isCreateAccLoading,
    } = useCreateAccessories();

    const initialValues = {
        type: Number(''),
        serial_no: '',
        assigned_to: Number(''),
    };
    const validationSchema = Yup.object({
        type: Yup.string().typeError('Choose a value').required('Required'),
        serial_no: Yup.string().max(50).required('Required'),
        assigned_to: Yup.string()
            .typeError('Choose a Value')
            .required('Required'),
    });
    const handleSubmit = async (values: Accessories) => {
        if (
            !props.accessoriesData?.data.some(
                (acc) => acc.serial_no === values.serial_no
            ) &&
            !props.accessoryList?.data.some(
                (accName) => accName.id === values.type
            )
        ) {
            const createVal = {
                ...values,
            };
            await mutateAsync({ data: createVal });
        } else toast.warn('Accessory with this serial number already exists');
    };
    useEffect(() => {
        if (isCreateAccSuccess) {
            toast.success('Accessory Assigned Successfully');
            props.refetchAccessories();
        }
    }, [isCreateAccSuccess, props.refetchAccessories]);

    return (
        <div className="flex flex-col px-10 py-2">
            <h2 className="py-4 border-x-zxblue-from text-transparent font-semibold md:text-xl lg:text-2xl bg-clip-text bg-gradient-to-t from-zxheader-from to-zxheader-to">
                <span className="border-l-2 border-zxsub-to pl-1">
                    Assign Accessory
                </span>
            </h2>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {({ touched, submitForm, resetForm }) => (
                    <form className="grid lg:grid-cols-4 md:grid-cols-2 gap-x-7">
                        <FormikSelect
                            label="Accessory Type"
                            name="type"
                            touched={touched.type}
                        >
                            {props.accessoryList?.data.map((accessory) => (
                                <option key={accessory.id} value={accessory.id}>
                                    {accessory.name}
                                </option>
                            ))}
                        </FormikSelect>
                        <FormikField
                            label="Serial No"
                            name="serial_no"
                            type="text"
                            touched={touched.serial_no}
                        />
                        <FormikSelect
                            label="Assigned To"
                            name="assigned_to"
                            touched={touched.assigned_to}
                        >
                            {isLoading ? (
                                <div>Loading...</div>
                            ) : isListEmpSuccess &&
                              employeeData.data !== undefined ? (
                                employeeData.data.map((employee: Employee) => (
                                    <option
                                        key={employee.id}
                                        value={employee.id}
                                    >
                                        {employee.full_name}
                                    </option>
                                ))
                            ) : (
                                <div>Not Success</div>
                            )}
                        </FormikSelect>
                        <ButtonField
                            name="Submit"
                            onClick={() => {
                                submitForm().then(() => {
                                    resetForm();
                                });
                            }}
                            className="lg:col-start-4 md:col-start-2 row-start-2"
                            disabled={!!isCreateAccLoading}
                        />
                    </form>
                )}
            </Formik>
        </div>
    );
}
