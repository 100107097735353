import { AxiosResponse } from 'axios';
import { Department } from '../../api';
import GetManagerID from './GetManagerID';
import GetManagerName from './GetManagerName';

interface DeptRowProps<A, R> {
    deptData: AxiosResponse<Department[]>;
    // refetch: (options?: A) => R;
}

export function DepartmentRow<A, R>(props: DeptRowProps<A, R>) {
    const departments = props.deptData.data;
    // let counter = 0;
    return (
        <>
            {departments.map((dept: Department) => (
                <tr key={dept.id} className="h-14 hover:shadow-lg">
                    <td className="text-center">
                        {String(dept.id).padStart(3, '0')}
                    </td>
                    <td className="text-center">{dept.name}</td>
                    <td className="text-center">
                        <GetManagerID manager_id={dept.manager_id} />
                    </td>
                    <td className="text-center">
                        <GetManagerName id={dept.manager_id} />{' '}
                    </td>
                </tr>
            ))}
        </>
    );
}
