/* eslint-disable import/no-unresolved */
import { Leave, useListEmployeeLeaves } from '../api';
import LeaveSidebar from '../components/LeaveSidebar';
import LeaveWithForm from '../components/LeaveWithForm';
import ZXHeader from '../components/ZXHeader';

function LeaveWithdrawal(props: {
    isViewerTL: boolean;
    isViewerAdmin: boolean;
}) {
    const {
        data: leavesData,
        isSuccess,
        isLoading,
        isFetchedAfterMount,
        refetch,
    } = useListEmployeeLeaves();

    const leaves = leavesData?.data;
    return (
        <div className="lg:pl-12 md:pl-6 py-6 lg:w-screen h-screen overflow-auto">
            <ZXHeader />
            <div className="flex flex-row">
                <LeaveSidebar
                    isViewerTL={props.isViewerTL}
                    isViewerAdmin={props.isViewerAdmin}
                />
                <div className=" md:mr-[3%] lg:ml-7 lg:min-h-screen shadow-2xl sm:w-[90%] md:min-h-screen  bg-white bg-opacity-60 backdrop-blur-x ">
                    <h1 className="pt-2 flex-none border-x-zxblue-from text-transparent  lg:mt-7 font-semibold lg:text-2xl md:text-xl bg-clip-text bg-gradient-to-t from-zxheader-from to-zxheader-to md:mt-6">
                        <span className="border-l-4 pt-1 md:ml-8 border-zxsub-to lg:ml-8 pl-2">
                            {' '}
                            Leave Withdrawal{' '}
                        </span>
                    </h1>
                    {leaves && isSuccess
                        ? leaves.map((leave: Leave) =>
                              leave.leave_status === 'PEND' ? (
                                  <LeaveWithForm
                                      key={leave.id}
                                      leavesData={leave}
                                      refetch={refetch}
                                  />
                              ) : null
                          )
                        : null}
                </div>
            </div>
        </div>
    );
}

export default LeaveWithdrawal;
