/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  Address
} from '../../schema'


/**
 * Get address details
 */
export const retrieveAddress = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Address>> => {
    return axios.get(
      `/address/${id}/`,options
    );
  }


export const getRetrieveAddressQueryKey = (id: string,) => [`/address/${id}/`];

    
export type RetrieveAddressQueryResult = NonNullable<Awaited<ReturnType<typeof retrieveAddress>>>
export type RetrieveAddressQueryError = AxiosError<unknown>

export const useRetrieveAddress = <TData = Awaited<ReturnType<typeof retrieveAddress>>, TError = AxiosError<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof retrieveAddress>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveAddressQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveAddress>>> = ({ signal }) => retrieveAddress(id, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof retrieveAddress>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * Update address details
 */
export const updateAddress = (
    id: string,
    address: Address, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Address>> => {
    return axios.put(
      `/address/${id}/`,
      address,options
    );
  }



    export type UpdateAddressMutationResult = NonNullable<Awaited<ReturnType<typeof updateAddress>>>
    export type UpdateAddressMutationBody = Address
    export type UpdateAddressMutationError = AxiosError<unknown>

    export const useUpdateAddress = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAddress>>, TError,{id: string;data: Address}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAddress>>, {id: string;data: Address}> = (props) => {
          const {id,data} = props ?? {};

          return  updateAddress(id,data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof updateAddress>>, TError, {id: string;data: Address}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * Delete a address
 */
export const destroyAddress = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    return axios.delete(
      `/address/${id}/`,options
    );
  }



    export type DestroyAddressMutationResult = NonNullable<Awaited<ReturnType<typeof destroyAddress>>>
    
    export type DestroyAddressMutationError = AxiosError<unknown>

    export const useDestroyAddress = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof destroyAddress>>, TError,{id: string}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof destroyAddress>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  destroyAddress(id,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof destroyAddress>>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    