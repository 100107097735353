/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */

export type EducationClass12Board = typeof EducationClass12Board[keyof typeof EducationClass12Board];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EducationClass12Board = {
  CBSE: 'CBSE',
  ICSE: 'ICSE',
  STATE: 'STATE',
} as const;
