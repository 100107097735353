import { Attendance, Employee } from '../../api';

import moment from 'moment';
import ShiftTime from '../ShiftTime';

interface EmpAttProps {
    attData: Attendance[];
    empData: Employee;
}

function EmpAttTable(props: EmpAttProps) {
    const getHours = (sign_in: string, sign_out: string) => {
        const startTime = moment(sign_in, 'HH:mm:ss a');
        const endTime = moment(sign_out, 'HH:mm:ss a');
        const duration = moment.duration(endTime.diff(startTime));
        return [duration.hours(), duration.minutes()];
    };

    return (
        <div className="lg:text-sm md:text-xs bg-gradient-to-r from-zxsub-from to-zxsub-to p-[1px] mb-8 mx-[12%]">
            <table className="table-auto border-separate border-spacing-x-14 border-spacing-y-4 bg-white text-center">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Shift</th>
                        <th>Sign In</th>
                        <th>Sign Out</th>
                        <th>Total Hours</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {props.attData.map((att) => (
                        <tr key={att.id}>
                            <td
                                className={
                                    att.sign_in &&
                                    att.sign_out &&
                                    getHours(att.sign_in, att.sign_out)[0] <
                                        8 &&
                                    att.attendance_status === 'P' || att.attendance_status === 'A'
                                        ? 'text-transparent bg-clip-text bg-gradient-to-r from-zxerror-to to-zxerror-from'
                                        : ''
                                }
                            >
                                {att.date}
                            </td>
                            <ShiftTime
                                shift={props.empData.shift_id}
                                className={
                                    att.sign_in &&
                                    att.sign_out &&
                                    getHours(att.sign_in, att.sign_out)[0] <
                                        8 &&
                                    att.attendance_status === 'P' || att.attendance_status === 'A'
                                        ? 'text-transparent bg-clip-text bg-gradient-to-r from-zxerror-to to-zxerror-from'
                                        : ''
                                }
                            />
                            <td
                                className={
                                    att.sign_in &&
                                    att.sign_out &&
                                    getHours(att.sign_in, att.sign_out)[0] <
                                        8 &&
                                    att.attendance_status === 'P' || att.attendance_status === 'A'
                                        ? 'text-transparent bg-clip-text bg-gradient-to-r from-zxerror-to to-zxerror-from'
                                        : ''
                                }
                            >
                                {att.sign_in}
                            </td>
                            <td
                                className={
                                    att.sign_in &&
                                    att.sign_out &&
                                    getHours(att.sign_in, att.sign_out)[0] <
                                        8 &&
                                    att.attendance_status === 'P' || att.attendance_status === 'A'
                                        ? 'text-transparent bg-clip-text bg-gradient-to-r from-zxerror-to to-zxerror-from'
                                        : ''
                                }
                            >
                                {att.sign_out}
                            </td>
                            <td
                                className={
                                    att.sign_in &&
                                    att.sign_out &&
                                    getHours(att.sign_in, att.sign_out)[0] <
                                        8 &&
                                    att.attendance_status === 'P' || att.attendance_status === 'A'
                                        ? 'text-transparent bg-clip-text bg-gradient-to-r from-zxerror-to to-zxerror-from'
                                        : ''
                                }
                            >
                                {att.sign_in &&
                                    att.sign_out &&
                                    getHours(att.sign_in, att.sign_out).join(
                                        ' : '
                                    )}{' '}
                                Hrs
                            </td>
                            <td
                                className={
                                    att.sign_in &&
                                    att.sign_out &&
                                    getHours(att.sign_in, att.sign_out)[0] <
                                        8 &&
                                    att.attendance_status === 'P' || att.attendance_status === 'A'
                                        ? 'text-transparent bg-clip-text bg-gradient-to-r from-zxerror-to to-zxerror-from'
                                        : ''
                                }
                            >
                                {att.attendance_status}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default EmpAttTable;
