import { Tab } from '@headlessui/react';
import { useState } from 'react';
import { Leave, useListEmployeeLeaves } from '../api';
import LeaveHistForm from './LeaveHistForm';

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

function LeaveStatusbar() {
    const { data: leavesData, isSuccess, refetch } = useListEmployeeLeaves();
   
    const leaves = leavesData?.data;
    const [currentPage, setCurrentPage] = useState(1);
    const accL:any = leaves?.filter((leave: Leave) => leave.leave_status === 'ACC');
    const postsPerPage = 5;
     const indexOfLastPost = currentPage * postsPerPage;
     const indexOfFirstPost = indexOfLastPost - postsPerPage;
     const leavess = accL?.slice(indexOfFirstPost, indexOfLastPost);const pageNumbers = [];
 
     // eslint-disable-next-line no-unsafe-optional-chaining
     for (let i = 1; i <= Math.ceil(accL?.length / postsPerPage); i++) {
        pageNumbers.push(i);
     }

     const [currentPageRej, setCurrentPageRej] = useState(1);
     const rejL:any = leaves?.filter((leave: Leave) => leave.leave_status === 'REJ');
     const postsPerPageRej = 5;
      const indexOfLastPostRej = currentPageRej * postsPerPageRej;
      const indexOfFirstPostRej = indexOfLastPostRej - postsPerPageRej;
      const leavesrej = rejL?.slice(indexOfFirstPostRej, indexOfLastPostRej);
      const pageNumbersRej = [];
  
      // eslint-disable-next-line no-unsafe-optional-chaining
      for (let i = 1; i <= Math.ceil(rejL?.length / postsPerPageRej); i++) {
         pageNumbersRej.push(i);
      }

      const [currentPageWith, setCurrentPageWith] = useState(1);
     const withL:any = leaves?.filter((leave: Leave) => leave.leave_status === 'WITH');
     const postsPerPageWith = 5;
      const indexOfLastPostWith = currentPageWith * postsPerPageWith;
      const indexOfFirstPostWith = indexOfLastPostWith - postsPerPageWith;
      const leaveswith = withL?.slice(indexOfFirstPostWith, indexOfLastPostWith);
      const pageNumbersWith = [];
  
      // eslint-disable-next-line no-unsafe-optional-chaining
      for (let i = 1; i <= Math.ceil(withL?.length / postsPerPageWith); i++) {
         pageNumbersWith.push(i);
      }
   
    return (
        <Tab.Group>
            <div className=" mx-auto text-center divide-x shadow-md w-[84%] h-[5%] border border-zxblue-to rounded-md focus:outline-none ml-[6%] mt-[2%] md:w-[85%]">
                <Tab.List className="grid grid-cols-5">
                    <Tab className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-1.5 text-sm  leading-5  ml-6',
                  '',
                  selected
                    ? 'lg:text-base font-bold bg-clip-text text-transparent bg-gradient-to-r from-tabtext-from to-tabtext-to outline-none'
                    : 'text-gray-500 font-semibold hover:bg-white/[0.12] hover:text-violet-900'
                )
              }>
                        ACCEPTED
                    </Tab>
                    <span className="pt-2">|</span>
                    <Tab className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-1.5 text-sm  leading-5  ',
                  '',
                  selected
                    ? 'lg:text-base font-bold bg-clip-text text-transparent bg-gradient-to-r from-tabtext-from to-tabtext-to outline-none'
                    : 'text-gray-500 font-semibold hover:bg-white/[0.12] hover:text-violet-900'
                )
              }>
                        REJECTED
                    </Tab>
                    <span className="pt-2">|</span>
                    <Tab className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-1.5 text-sm  leading-5  -ml-[30%] ',
                  '',
                  selected
                    ? 'lg:text-sm font-bold bg-clip-text text-transparent bg-gradient-to-r from-tabtext-from to-tabtext-to outline-none '
                    : 'text-gray-500 font-semibold hover:bg-white/[0.12] hover:text-violet-900'
                )
              }>
                        WITHDRAWN
                    </Tab>
                </Tab.List>
            </div>
            <Tab.Panels>
                {/* <button type='button' onClick={()=>setCurrentPage(currentPage+1)}>
                    Click me
                </button> */}
                <Tab.Panel>
                <div className="pagination-container">
                    <ul className="flex flex-row items-center justify-center mt-4">
                        <li>
                        <button type='button' onClick={()=>setCurrentPage(currentPage-1)} className="page-number px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                            Prev
                        </button>
                        </li>
                        {pageNumbers.map((number) => (
                        <li
                            key={number}
                            className="page-number px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        >
                            <button type='button' 
                            onClick={() => setCurrentPage(number)}>
                                {number}
                            </button>
                        </li>
                        ))}
                        <li className="page-number">
                        <button type='button' onClick={() => setCurrentPage(currentPage+1)} className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                            Next
                        </button>
                        </li>
                    </ul>
                </div>
                <div className="overflow-y-scroll h-[550px] ">
                    {leavess && isSuccess
                        ? leavess.map((leave: Leave) =>
                              leave.leave_status === 'ACC' ? (
                                  <LeaveHistForm
                                      key={leave.id}
                                      leavesData={leave}
                                      refetch={refetch}
                                  />
                              ) : null
                          )
                        : null}{' '}
                        </div>
                </Tab.Panel>
                <Tab.Panel>
                <div className="pagination-container">
                    <ul className="flex flex-row items-center justify-center mt-4">
                        <li>
                        <button type='button' onClick={()=>setCurrentPageRej(currentPageRej-1)} className="page-number px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                            Prev
                        </button>
                        </li>
                        {pageNumbersRej.map((number) => (
                        <li
                            key={number}
                            className="page-number page-number px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        >
                            <button type='button' 
                            onClick={() => setCurrentPageRej(number)}>
                                {number}
                            </button>
                        </li>
                        ))}
                        <li className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                        <button type='button' onClick={() => setCurrentPageRej(currentPageRej+1)} >
                            Next
                        </button>
                        </li>
                    </ul>
                </div>
                <div className="overflow-y-scroll h-[550px]">
                    {leavesrej && isSuccess
                        ? leavesrej.map((leave: Leave) =>
                              leave.leave_status === 'REJ' ? (
                                  <LeaveHistForm
                                      key={leave.id}
                                      leavesData={leave}
                                      refetch={refetch}
                                  />
                              ) : null
                          )
                        : null}
                        </div>
                </Tab.Panel>
                <Tab.Panel  >
                <div className="pagination-container">
                    <ul className="flex flex-row items-center justify-center  mt-4">
                        <li>
                        <button type='button' onClick={()=>setCurrentPageWith(currentPageWith-1)} className="page-number px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                            Prev
                        </button>
                        </li>
                        {pageNumbersWith.map((number) => (
                        <li
                            key={number}
                            className="page-number page-number px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        >
                            <button type='button' 
                            onClick={() => setCurrentPageWith(number)}>
                                {number}
                            </button>
                        </li>
                        ))}
                        <li className="page-number px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                        <button type='button' onClick={() => setCurrentPageWith(currentPageWith+1)} >
                            Next
                        </button>
                        </li>
                    </ul>
                </div>
                    <div className="overflow-y-scroll h-[550px]">
                    {leaveswith && isSuccess
                        ? leaveswith.map((leave: Leave) =>
                              leave.leave_status === 'WITH' ? (
                                  <LeaveHistForm
                                      key={leave.id}
                                      leavesData={leave}
                                      refetch={refetch}
                                  />
                              ) : null
                          )
                        : null}{' '}
                        </div>
                </Tab.Panel>
            </Tab.Panels>
        </Tab.Group>
    );
}
export default LeaveStatusbar;
