import { AxiosResponse } from 'axios';
import { useMemo } from 'react';
import { CgAdd } from 'react-icons/cg';
import { IconContext } from 'react-icons/lib';
import { RiEdit2Fill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    Accessories,
    Department,
    MSUser,
    MSUserMSemployeeDetails,
    MSUserMSemployeeDetailsBankDetails,
    MSUserMSemployeeDetailsEducationQualifications,
    MSUserMSemployeeDetailsProfileDetails,
    MSUserMSemployeeDetailsProfileDetailsAddressDetailsItem
} from '../../api';
import { ACCESSORY } from '../../constants/router';

export default function SectionHeader(props: {
    name: string;
    form: boolean;
    setForm: React.Dispatch<React.SetStateAction<boolean>>;
    isUserPresent?: AxiosResponse<MSUser, any>;
    employeeDetails:
        | MSUserMSemployeeDetails
        | MSUserMSemployeeDetailsProfileDetails
        | MSUserMSemployeeDetailsProfileDetailsAddressDetailsItem[]
        | MSUserMSemployeeDetailsBankDetails
        | AxiosResponse<Department, any>
        | MSUserMSemployeeDetailsEducationQualifications
        | AxiosResponse<Accessories[], any>
        | undefined;
}) {
    const navigate = useNavigate();
    const value = useMemo(
        () => ({
            color: '738DFE',
            size: '5%',
        }),
        []
    );
    return (
        <div className="flex flex-row">
            <h2 className="py-4 border-x-zxblue-from text-transparent font-semibold md:text-lg lg:text-xl">
                <span className="border-l-4 border-zxsub-to pl-2 py-1 bg-clip-text bg-gradient-to-r from-zxheader-from to-zxheader-to opacity-100">
                    {props.name}
                </span>
            </h2>
            {/* {props.employeeDetails !== undefined &&
            props.employeeDetails !== null &&
            props.isUserPresent?.data.MSemployee_details !== null ? (
                <button
                    type="button"
                    onClick={() => props.setForm(!props.form)}
                    className="ml-4 -mt-2"
                >
                    {!props.form ? (
                        <IconContext.Provider value={value}>
                            <RiEdit2Fill className="h-5 w-5" />
                        </IconContext.Provider>
                    ) : null}
                </button>
            ) : null} */}
            <button
                type="button"
                onClick={() => {
                    if (
                        props.employeeDetails !== undefined &&
                        props.employeeDetails !== null &&
                        props.isUserPresent?.data.MSemployee_details !== null
                    ) {
                        props.setForm(!props.form);
                    } else {
                        toast.error('User Not Filled 4 Step Form');
                    }
                }}
                className="ml-4 -mt-2"
            >
                {!props.form ? (
                    <IconContext.Provider value={value}>
                        <RiEdit2Fill className="h-5 w-5" />
                    </IconContext.Provider>
                ) : null}
            </button>
            {props.name === 'Employee Accessories' ? (
                <button
                    type="button"
                    onClick={() => navigate(ACCESSORY)}
                    className="ml-4 -mt-2"
                >
                    <IconContext.Provider value={value}>
                        <CgAdd className="h-5 w-5" />
                    </IconContext.Provider>
                </button>
            ) : null}
        </div>
    );
}
