import { AxiosResponse } from 'axios';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Accessory, useCreateAccessory } from '../../api';
import ButtonField from '../FormFields/ButtonField';
import FormikField from '../FormFields/FormikField';

export default function AddAccessory(props: {
    accessoryList: AxiosResponse<Accessory[], any> | undefined;
    refetchAccessorys: any;
}) {
    const { mutateAsync } = useCreateAccessory();

    const initialAccessoryData = {
        name: '',
    };
    const accessoryValidationSchema = Yup.object({
        name: Yup.string().max(50).required('Required'),
    });

    const handleAccFormSubmit = async (values: Accessory) => {
        if (
            !props.accessoryList?.data.some((acc) => acc.name === values.name)
        ) {
            const createAccessory = {
                ...values,
            };
            const resp = await mutateAsync({ data: createAccessory });
            if (resp.status === 201) {
                toast.success(`Accessory Created`);
                props.refetchAccessorys();
            }
        } else toast.warn(`Accessory: ${values.name} is present in Database`);
    };

    return (
        <div className="flex flex-col px-10 py-2">
            <h2 className="py-4 border-x-zxblue-from text-transparent font-semibold md:text-xl lg:text-2xl bg-clip-text bg-gradient-to-t from-zxheader-from to-zxheader-to">
                <span className="border-l-2 border-zxsub-to pl-1">
                    Add New Accessory
                </span>
            </h2>
            <Formik
                initialValues={initialAccessoryData}
                validationSchema={accessoryValidationSchema}
                onSubmit={handleAccFormSubmit}
            >
                {({ touched, submitForm, resetForm }) => (
                    <form className="flex mb-4">
                        <FormikField
                            label="Accessory Name"
                            name="name"
                            touched={touched.name}
                        />
                        <ButtonField
                            name="Submit"
                            onClick={() => {
                                submitForm().then(() => {
                                    resetForm();
                                });
                            }}
                        />
                    </form>
                )}
            </Formik>
        </div>
    );
}
