import { useListMSViewers } from '../api';
import AttendanceDBD from '../components/Dashboard/AttendanceDBD';
import LeaveBalanceDashboard from '../components/Dashboard/LeaveBalanceDashboard';
import ZXHeader from '../components/ZXHeader';

function Dashboard(props: { isViewerAdmin: boolean }) {
    const { data: viewerData, isLoading } = useListMSViewers();

    return (
        <div className="h-screen box-border bg-zxbg p-6 w-screen">
            <div className="pl-12">
                <ZXHeader />
            </div>
            <div className="m-10 box-border">
                <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-5">
                    <div className="bg-white h-60 shadow-xl rounded-lg box-border">
                        <AttendanceDBD
                            viewerData={viewerData}
                            isViewerAdmin={props.isViewerAdmin}
                        />
                    </div>
                    <div className="bg-white h-60 shadow-xl rounded-lg box-border">
                        <LeaveBalanceDashboard
                            viewerData={viewerData}
                            isLoading={isLoading}
                            isViewerAdmin={props.isViewerAdmin}
                        />
                    </div>
                    <div className="bg-white h-60 shadow-xl rounded-lg box-border">
                        <div className="pt-7 pl-7 font-semibold text-transparent bg-gradient-to-r from-zxheader-from to-zxheader-to opacity-700 bg-clip-text">
                            Policy & Changes
                        </div>
                    </div>
                    <div className="bg-white row-span-auto shadow-xl rounded-lg box-border">
                        <div className="pt-7 pl-7 font-semibold text-transparent bg-gradient-to-r from-zxheader-from to-zxheader-to opacity-700 bg-clip-text">
                            Upcoming Events
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
