import { useEffect } from 'react';
import { useListHolidays } from '../api';
import HolidayRow from './HolidayRow';

interface HolidayTableProps {
    filters: {
        name: string;
        month: number;
    }
};

function HolidayTable(props: HolidayTableProps) {
    const {
        data: holidaysData,
        isSuccess,
        isLoading,
        isFetchedAfterMount,
        refetch,
    } = useListHolidays({
        axios: {
            params: {
                name: props.filters.name === '' ? null : props.filters.name,
                month: props.filters.month === 0 ? null : props.filters.month,
            }
        }
    });

    useEffect(() => {
        refetch();
    }, [props.filters, refetch]);
    return (
        <table className="mt-6 text-zxtext-1 md:text-sm lg:text-lg  table-auto">
            <thead className="h-12 border-2 rounded-lg border-indigo-400 ">
                <tr>
                    <th>No</th>
                    <th>Date</th>
                    <th>Day</th>
                    <th>Occasion</th>
                    <th>National Holiday</th>
                    <th>Optional Holiday</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody className="text-sm">
                {isLoading ? (
                    <tr>
                        <td>Loading.......</td>
                    </tr>
                ) : isFetchedAfterMount && isSuccess ? (
                    holidaysData.data.map((holiday) => (
                        <HolidayRow
                            key={holiday.id}
                            holiday={holiday}
                            refetch={refetch}
                        />
                    ))
                ) : (
                    <tr>
                        <td>Not Success</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}

export default HolidayTable;
