import { toast } from 'react-toastify';
import { useRetrieveRolesRemove } from '../api';

interface Props {
    rmRole: {
        id: string;
        role: string;
    };
}

export default function RemoveRole(props: Props) {
    const { isSuccess, refetch } = useRetrieveRolesRemove(
        props.rmRole.id ? props.rmRole.id : '',
        {
            axios: {
                params: {
                    role: props.rmRole.role,
                },
            },
        }
    );

    // useEffect(() => {
    if (isSuccess) {
        toast.success('Role Removed');
        // refetch();
    }
    // }, [isSuccess, refetch]);
    return null;
}
