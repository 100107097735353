import CreateShift from './CreateShift';
import DeleteShift from './DeleteShift';
import ShiftsTable from './ShiftsTable';

export default function Shifts() {
    return (
        <div className="w-screen box-border overflow-auto h-screen bg-zxbg">
            <div className="p-10">
                <div className="shadow-2xl rounded-2xl border-t-2">
                    <CreateShift />
                    <div className="relative mt-3 mx-12 h-0.5 bg-gradient-to-r from-zxheader-to to-zxheader-from opacity-50 rounded-xl" />
                    <DeleteShift />
                    <div className="relative mt-3 mx-12 h-0.5 bg-gradient-to-r from-zxheader-to to-zxheader-from opacity-50 rounded-xl" />
                    <ShiftsTable />
                </div>
            </div>
        </div>
    );
}
