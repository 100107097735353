/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */

export type ProfileBloodGroup = typeof ProfileBloodGroup[keyof typeof ProfileBloodGroup];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProfileBloodGroup = {
  AB: 'AB+',
  'AB-': 'AB-',
  A: 'A+',
  'A-': 'A-',
  B: 'B+',
  'B-': 'B-',
  O: 'O+',
  'O-': 'O-',
} as const;
