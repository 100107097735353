import { ErrorMessage, Field, FormikTouched, useFormikContext } from 'formik';

import classnames from 'classnames';
import { HTMLAttributes } from 'react';
import { getHtmlFor } from '../../utils/formUtils';

export interface FormikFieldProps<T> extends HTMLAttributes<HTMLInputElement> {
    label: string;
    name: keyof T;
    type?: string;
    touched?: boolean | undefined | FormikTouched<Date>;
    disabled?: boolean;
    className?: string;
}

function FormikField<T>({
    label,
    name,
    placeholder,
    type,
    className,
    ...props
}: FormikFieldProps<T>) {
    const { touched, errors } = useFormikContext<T>();
    const checkboxStyle = classnames(
        'flex flex-row items-center gap-[5%] font-medium text-base'
    );
    if (type === 'checkbox') {
        return (
            <div className={checkboxStyle}>
                <Field
                    type={type}
                    className={classnames(
                        'placeholder-text-[80%] rounded-md focus:outline-none border',
                        touched[name] && errors[name]
                            ? 'border-zxerror-to'
                            : 'border-zxblue-to'
                    )}
                    name={name}
                    placeholder={placeholder}
                    {...props}
                />
                <label className="text-md" htmlFor={getHtmlFor(name)}>
                    {label}
                </label>
            </div>
        );
    }

    return (
        <div className={classnames(className, 'flex flex-col')}>
            <label
                className={classnames(
                    'pl-[1%]',
                    touched[name] && errors[name]
                        ? 'text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                        : 'border-zxblue-to'
                )}
                htmlFor={getHtmlFor(name)}
            >
                {label}
                <span className="text-zxerror-from pl-1">*</span>
            </label>
            <Field
                className={classnames(
                    'w-full h-8 mb-[2%] rounded-md focus:outline-none placeholder: pl-[4%] py-[1%] border',
                    touched[name] && errors[name]
                        ? 'border-zxerror-to'
                        : 'border-zxblue-to'
                )}
                name={name}
                placeholder={placeholder}
                type={type}
                {...props}
            />
            <ErrorMessage
                name={name.toString()}
                component="span"
                className="text-[77%] text-zxerror-from"
            />
        </div>
    );
}

export default FormikField;
