/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */

export type EmployeeEmployeeNumberSeries = typeof EmployeeEmployeeNumberSeries[keyof typeof EmployeeEmployeeNumberSeries];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EmployeeEmployeeNumberSeries = {
  ZX: 'ZX',
  IN: 'IN',
  PA: 'PA',
  T: 'T',
} as const;
