import Axios from 'axios';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
    Employee,
    EmployeeEmployeeStatus,
    useListMSViewers,
    useRetrieveMSUser,
    useUpdateEmployee
} from '../../api';

export default function ProfileUpdateHeader(props: { id: string }) {
    const { data: usersData, refetch } = useRetrieveMSUser(props.id);
    const { mutateAsync, isSuccess: statusSuccess } = useUpdateEmployee();
    const [imageUrl, setImageUrl] = useState('');

    const { data: viewersData, isSuccess: userSuccess } = useListMSViewers();

    const userId = usersData?.data.user_id;
    const token = viewersData?.data[0].provider_token;

    useEffect(() => {
        if (userSuccess) {
            Axios.get(
                `https://graph.microsoft.com/v1.0/users/${userId}/photo/$value`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                    responseType: 'blob',
                }
            )
                .then((o) => {
                    const url = window.URL || window.webkitURL;
                    const blobUrl = url.createObjectURL(o.data);
                    setImageUrl(blobUrl);
                })
                .catch(() => {
                    setImageUrl('../img/zedexinfologo.svg');
                });
        }
    }, [token, userId, userSuccess]);

    const updateStatus = async (
        empId: number | undefined,
        values: Employee | undefined,
        stats: EmployeeEmployeeStatus
    ) => {
        if (empId !== undefined && values !== undefined) {
            const updatedVal = {
                ...values,
                employee_status: stats,
            };
            await mutateAsync({
                id: empId.toString(),
                data: updatedVal,
            });
        }
    };
    useEffect(() => {
        if (statusSuccess) {
            refetch();
            toast.success('Status Updated');
        }
    }, [statusSuccess, refetch]);

    const buttonStyle = 'lg:w-20 lg:h-9 md:w-16 md:h-8 mt-2 rounded-lg';

    const activeOn = classnames(
        buttonStyle,
        'mr-2 text-white bg-gradient-to-r from-zxblue-from to-zxblue-to hover:bg-gradient-to-t from-zxblue-from to-zxblue-to'
    );

    const activeOff = classnames(
        buttonStyle,
        'mr-2',
        'text-black bg-gradient-to-l from-white to-white border border-zxblue-to hover:bg-gradient-to-r from-white to-slate-50'
    );

    const deactiveOn = classnames(
        buttonStyle,
        'text-white bg-gradient-to-tl from-zxbutton2-to to-zxbutton2-from border'
    );

    const deactiveOff = classnames(
        buttonStyle,
        'text-black bg-gradient-to-l from-white to-white border border-zxbutton2-to hover:bg-gradient-to-r from-white to-slate-50'
    );

    return (
        <div className="flex flex-row w-full shadow-xl px-[7%] pt-[0.5%] pb-[1.5%]">
            <div className="rounded-full border-blue-700">
                <img
                    src={imageUrl}
                    alt=""
                    className="w-16 h-16 rounded-full ring ring-zxblue-to float-left"
                />
            </div>
            <span className="px-[2%] py-[1%]">
                {usersData?.data.MSemployee_details?.full_name}
                <br />
                <span className="text-sm">
                    ID:
                    <span className="text-blue-700 pl-[4%]">
                        {usersData?.data.MSemployee_details?.employee_number}
                    </span>
                </span>
            </span>
            {usersData?.data.MSemployee_details?.profile_details === null ||
            undefined ? (
                <div className="ml-[60%]">
                    <button type="button" className={deactiveOn}>
                        {' '}
                        Not Filled{' '}
                    </button>
                </div>
            ) : (
                <div className="ml-[60%]">
                    <button
                        type="button"
                        className={
                            usersData?.data.MSemployee_details
                                ?.employee_status === 'Active'
                                ? activeOn
                                : activeOff
                        }
                        onClick={() => {
                            updateStatus(
                                usersData?.data.MSemployee_details?.id,
                                usersData?.data.MSemployee_details,
                                'Active'
                            );
                        }}
                    >
                        Active
                    </button>
                    <button
                        type="button"
                        className={
                            usersData?.data.MSemployee_details
                                ?.employee_status !== 'Active'
                                ? deactiveOn
                                : deactiveOff
                        }
                        onClick={() => {
                            updateStatus(
                                usersData?.data.MSemployee_details?.id,
                                usersData?.data.MSemployee_details,
                                'Deactive'
                            );
                        }}
                    >
                        Deactive
                    </button>
                </div>
            )}
        </div>
    );
}
