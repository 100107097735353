import { useEffect } from 'react';
import { Employee, useListEmployees } from '../api';
import ViewLeaveBalrow from './ViewLeaveBalrow';

interface Props {
    filter: {
        name: string;
    };
}
function ViewLeaveBal(props: Props) {
    const {
        data: emp_data,
        isSuccess,
        isLoading,
        isFetchedAfterMount,
        refetch,
    } = useListEmployees({
        axios: {
            params: {
                name: props.filter.name === '' ? null : props.filter.name,
            },
        },
    });

    useEffect(() => {
        refetch();
    },[props.filter, refetch]);
    return (
        <div className="h-96 overflow-y-scroll sm:overflow-x-scroll xl:overflow-hidden">
            <table className=" text-zxtext-1 md:text-sm lg:text-lg w-full  mt-6 table-auto ">
                <thead className="text-lg ">
                    <tr>
                        <th className="text-sm py-5 px-4">Sr No</th>
                        <th className="text-sm px-6">Employee</th>
                        <th className="text-sm">Earned Leave Balance</th>
                        <th className="text-sm">Comp Off Balance</th>
                        <th className="text-sm">Optional Holiday Balance</th>
                        <th className="text-sm">Medical Leave Balance</th>
                        <th className="text-sm">Casual Leave Balance</th>
                    </tr>
                </thead>
                <tbody className="text-xs font-normal not-italic">
                    {isLoading ? (
                        <tr>
                            <td>Loading.......</td>
                        </tr>
                    ) : emp_data && isFetchedAfterMount && isSuccess ? (
                        emp_data.data.map((emp: Employee) => (
                            <ViewLeaveBalrow key={emp.id} emp={emp} />
                        ))
                    ) : (
                        <tr>
                            <td>Not Success</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}

export default ViewLeaveBal;
