import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { useListMSViewers } from '../api';
import Muster from '../components/Attendance/Muster';
import Header from '../components/Header';

function AttendanceMuster(props: { isViewerAdmin: boolean }) {
    const { data: msViewersData, isSuccess } = useListMSViewers();
    const viewerData = msViewersData?.data[0];
    const navigate = useNavigate();
    return (
        <div className="h-screen w-screen box-border p-10 overflow-auto">
            <div className="flex w-full">
                <BiArrowBack
                    className="mt-2 ml-2.5 cursor-pointer"
                    size="2.5%"
                    style={{ color: '#4A65EE' }}
                    onClick={() => navigate(-1)}
                />
                <Header name="Salary & Attendance" />
            </div>
            {isSuccess && viewerData ? (
                <Muster viewerData={viewerData} isViewerAdmin={props.isViewerAdmin} />
            ) : null}
        </div>
    );
}

export default AttendanceMuster;
