import moment from 'moment';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
    Holiday,
    Leave,
    LeaveLeaveType,
    LeaveSession1,
    LeaveSession2,
    useCreateLeave,
    useListLeaves,
    useListMSViewers
} from '../api';
import Button from './FormFields/Button';

interface OpHolRowProps {
    holiday: Holiday;
    date: string;
    refetch: any;
}

function OpHolRow({ holiday, date, refetch }: OpHolRowProps) {
    // const navigate = useNavigate();
    const {
        data: usersData,
        isSuccess: userSuccess,
        isLoading: userLoading,
    } = useListMSViewers();
    const empDetails = usersData?.data[0].MSemployee_details;
    const { mutateAsync, isSuccess } = useCreateLeave();

    // const hol_date=holiday?.date?moment(holiday.date).format("YYYY-MM-DD"):undefined;
    // const hol_date= new Date(date).toLocaleDateString('en-IN',{
    //     year:'numeric',
    //     month:'2-digit',
    //     day:'2-digit'
    // })
    const hol_date = moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD');

    const { data: userData } = useListMSViewers();
    const emp_id = userData?.data[0].MSemployee_details?.id;

    const { data: applied_hol } = useListLeaves({
        axios: {
            params: {
                employee_id: emp_id?.toString(),
                leave_type: 'Optional_Holiday',
            },
        },
    });

    const hol_data =
        applied_hol?.data?.filter((hol) => hol?.reason === holiday?.name && (hol?.leave_status==='PEND' || hol?.leave_status==='ACC'))
            ?.length === 0;

    

    const handleSubmit = async () => {
        const leave_data: Leave = {
            leave_type: LeaveLeaveType.Optional_Holiday,
            session_1: LeaveSession1.S1,
            session_2: LeaveSession2.S2,
            applying_from: hol_date,
            applying_to: hol_date,
            cc: 'Sushmita',
            reason: holiday?.name,
            employee_id: empDetails?.id,
        };
        await mutateAsync({
            data: leave_data,
        });
    };

    useEffect(() => {
        if (isSuccess) {
            toast.success('Leave Applied');
            setapplyToggle(false);
        }
    }, [isSuccess]);
    const [applyToggle, setapplyToggle] = useState(true);
    return (
        <tr key={holiday.id} className="text-center h-14">
            <td>{holiday.id}</td>
            <td>{holiday.date}</td>
            <td>{holiday.day}</td>
            <td>{holiday.name}</td>
            <td>
                {holiday.type === 'COMP' ? (
                    <div className="w-4 h-4 rounded-full flex bg-gradient-to-r from-zxbullet-from to-zxbullet-to mx-auto" />
                ) : (
                    <div />
                )}
            </td>
            <td>
                {holiday.type === 'OP' ? (
                    <div className="w-4 h-4  rounded-full flex  bg-gradient-to-r from-zxbullet2-from to-zxbullet2-to mx-auto" />
                ) : (
                    <div />
                )}
            </td>

            <td className="flex flex-row gap-3 object-center items-center px-8 py-4">
                {hol_data && holiday?.type === 'OP' && applyToggle  ? (
                    <Button value="Apply" className="" onClick={handleSubmit} />
                ) : null}
            </td>
        </tr>
    );
}

export default OpHolRow;
