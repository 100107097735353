import classnames from 'classnames';

function Field(props: {
    label: string;
    type: string;
    id: string;
    name: string;
    placeholder: string | undefined;
    colspan: number | undefined;
    onChange: any;
    value: string | boolean | number | null | undefined;
    touched: boolean | undefined | string;
    formik: string | undefined;
    tid?: string;
    tname?: string;
    tvalue?: string | null | undefined;
    svalue?: any;
    fvalue?: any;
}) {
    const styles = classnames(
        props.touched && props.formik
            ? ' w-9/12 mb-4 border border-zxerror-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
            : 'w-9/12 mb-4 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5 '
    );
    const style2 = classnames(
        props.touched && props.formik
            ? 'pl-0.5 text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
            : 'pl-0.5'
    );

    const style1 = classnames(
        'flex',
        props.colspan === undefined
            ? props.type === 'checkbox'
                ? 'flex-row gap-5'
                : 'flex-col'
            : 'col-span-2 flex-col'
    );
    if (!(props.type === 'checkbox'))
        return (
            <div className={style1}>
                {props.id === 'spo_father_name' ? (
                    <div className="flex flex-row">
                        <label className={style2}>Spouse</label>
                        <input
                            id="spouse"
                            name="spouse"
                            className="ml-3 mr-3"
                            type="checkbox"
                            placeholder={props.placeholder}
                            onChange={props.onChange}
                            value={props.svalue}
                        />
                        <label className={style2}>Father</label>
                        <input
                            id="father"
                            name="father"
                            className="ml-3"
                            type="checkbox"
                            placeholder={props.placeholder}
                            onChange={props.onChange}
                            value={props.fvalue}
                        />
                    </div>
                ) : (
                    <label className={style2}>{props.label}</label>
                )}
                <input
                    id={props.id}
                    name={props.name}
                    className={styles}
                    type={props.type}
                    placeholder={props.placeholder}
                    onChange={props.onChange}
                    value={
                        typeof props.value === 'boolean'
                            ? ''
                            : props.value
                            ? props.value
                            : ''
                    }
                />
                {props.formik && props.touched ? (
                    <span className="text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to text-xs -mt-4">
                        {props.formik}
                    </span>
                ) : null}
            </div>
        );
    return (
        <div className="pl-0.5 mt-6">
            <label className={style2}>{props.label}</label>
            <input
                id={props.id}
                name={props.name}
                className="ml-3"
                type={props.type}
                placeholder={props.placeholder}
                onChange={props.onChange}
                value={
                    typeof props.value === 'boolean'
                        ? ''
                        : props.value
                        ? props.value
                        : ''
                }
            />
            {props.value ? (
                <div className={style1}>
                    <input
                        id={props.tid}
                        name={props.tname}
                        className={styles}
                        type="text"
                        placeholder="Specify your answer"
                        onChange={props.onChange}
                        value={props.tvalue ? props.tvalue : ''}
                    />
                </div>
            ) : null}
        </div>
    );
}

export default Field;
