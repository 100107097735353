import classnames from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import {
    Department,
    MSUser,
    useCreateDepartment,
    useListMSUsers
} from '../../api';
import { getHtmlFor } from '../../utils/formUtils';
import ButtonField from '../FormFields/ButtonField';

export default function CreateDepartment(props: {
    deptData: Department[] | undefined;
    refetch: any;
}) {
    const { mutateAsync, isSuccess } = useCreateDepartment();
    const { data: usersData } = useListMSUsers();
    const usersId = usersData?.data;
    const children = usersId?.map((userID: MSUser) => (
        <option
            key={userID.MSemployee_details?.id}
            value={userID.MSemployee_details?.id}
        >
            {userID.MSemployee_details?.full_name}
        </option>
    ));

    const initialDepartmentData = {
        name: '',
        manager_id: 0,
    };
    const departmentValidationSchema = Yup.object({
        name: Yup.string().max(50, 'Too Long').required('Required'),
        manager_id: Yup.number()
            .typeError('Choose a value')
            .required('Required'),
    });

    const handleDeptSubmit = async (values: Department) => {
        if (props.deptData?.some((depName) => depName.name === values.name)) {
            toast.warn(`${values.name} is present in Database`);
        } else {
            const createDept: Department = {
                ...values,
            };
            await mutateAsync({ data: createDept });
            toast.success(`${values.name} Created`);
        }
    };

    useEffect(() => {
        if (isSuccess) props.refetch();
    }, [isSuccess, props]);

    return (
        <div className="flex flex-col px-10">
            <h2 className="py-4 border-x-zxblue-from text-transparent font-semibold md:text-xl lg:text-2xl bg-clip-text bg-gradient-to-t from-zxheader-from to-zxheader-to">
                <span className="border-l-2 border-zxsub-to pl-1">
                    Create Department
                </span>
            </h2>
            <Formik
                initialValues={initialDepartmentData}
                validationSchema={departmentValidationSchema}
                onSubmit={handleDeptSubmit}
            >
                {({ touched, errors, submitForm, resetForm }) => (
                    <Form className="flex flex-row space-x-14">
                        <div className="flex flex-col">
                            <label
                                htmlFor={getHtmlFor('manager_id')}
                                className={classnames(
                                    'pl-0.5',
                                    touched.name && errors.name
                                        ? 'text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                                        : 'border-zxblue-to'
                                )}
                            >
                                Department Name
                            </label>
                            <Field
                                name="name"
                                type="text"
                                placeholder="New department name"
                                className={classnames(
                                    'w-full h-8 border rounded-md focus:outline-none placeholder: pl-2 text-sm',
                                    touched.name && errors.name
                                        ? 'border-zxerror-to'
                                        : 'border-zxblue-to'
                                )}
                            />
                            <ErrorMessage
                                name="name"
                                component="span"
                                className="text-xs text-zxerror-from top-0"
                            />
                        </div>
                        <div className="flex flex-col">
                            <label
                                htmlFor={getHtmlFor('manager_id')}
                                className={classnames(
                                    'pl-0.5',
                                    touched.manager_id && errors.manager_id
                                        ? 'text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                                        : 'border-zxblue-to'
                                )}
                            >
                                Select Manager
                            </label>
                            <Field
                                as="select"
                                name="manager_id"
                                className={classnames(
                                    'w-44 h-8 border py-1 rounded-md focus:outline-none placeholder: pl-2 text-sm',
                                    touched.manager_id && errors.manager_id
                                        ? 'border-zxerror-to '
                                        : 'border-zxblue-to'
                                )}
                                // {...props}
                            >
                                <option defaultValue={undefined}>Select</option>
                                {children}
                            </Field>
                            <ErrorMessage
                                name="manager_id"
                                component="span"
                                className="text-xs text-zxerror-from top-0"
                            />
                        </div>
                        <ButtonField
                            name="Submit"
                            className="mt-[2%]"
                            onClick={() => {
                                submitForm().then(() => {
                                    resetForm();
                                });
                            }}
                        />
                    </Form>
                )}
            </Formik>
        </div>
    );
}
