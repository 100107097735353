/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */

export type ProfileNationality = typeof ProfileNationality[keyof typeof ProfileNationality];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProfileNationality = {
  Indian: 'Indian',
  USA: 'USA',
  Other: 'Other',
} as const;
