import { ProfileNotifications, useListProfileNotifications } from '../api';

import ProfileNotifInfo from './ProfileNotifInfo';

function Notification(props: { notif: boolean }) {
    const {
        data: profileNotificationsData,
        isSuccess,
        isLoading,
        isFetchedAfterMount,
    } = useListProfileNotifications();
    if (props.notif)
        return (
            <div
                className="ml-[31%] text-zxtext-1 absolute bg-white shadow-lg z-0 top-14 h-[55%] w-[32%] rounded-lg pl-4 py-3 overflow-y-scroll"
                role="alert"
            >
                <h2 className="pt-2 ml-2 mt-2 flex-none border-x-zxblue-from text-transparent font-semibold lg:text-2xl md:text-xl bg-clip-text bg-gradient-to-t from-zxheader-from to-zxheader-to">
                    <span className="border-l-4 pt-1 border-zxsub-to pl-1">
                        Employee Directory
                    </span>
                </h2>
                {isLoading ? (
                    <div>Loading....</div>
                ) : isFetchedAfterMount && isSuccess ? (
                    profileNotificationsData.data.map(
                        (notification: ProfileNotifications) => (
                            <div
                                className="mt-2 ml-3 flex flex-row"
                                key={notification.emp_id}
                            >
                                {notification.emp_id ? (
                                    <ProfileNotifInfo
                                        notification={notification}
                                        empId={notification.emp_id}
                                    />
                                ) : null}
                            </div>
                        )
                    )
                ) : (
                    <div>Something went wrong</div>
                )}
            </div>
        );
    return null;
}

export default Notification;
