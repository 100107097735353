import 'react-toastify/dist/ReactToastify.css';

import { useEffect, useState } from 'react';
import { useListMSViewers, useRetrieveDepartment } from '../api';

import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AddressForm from './AddressForm';
import BankForm from './BankForm';
import EducationForm from './EducationForm';
import ProfileForm from './ProfileForm';

export default function Frame() {
    const navigate = useNavigate();
    const [imageUrl, setImageUrl] = useState('');
    const [form2, setForm2] = useState(false);
    // console.log(form2);
    const {
        data: usersData,
        isSuccess: userSuccess,
        isLoading: userLoading,
        refetch,
    } = useListMSViewers();

    const deptId = usersData?.data[0].MSemployee_details?.department_id;

    const {
        data: deptData,
        isSuccess: deptSuccess,
        isLoading: deptLoading,
    } = useRetrieveDepartment(deptId ? deptId.toString() : '');
    const token = usersData?.data[0].provider_token;

    useEffect(() => {
        if (userSuccess) {
            Axios.get('https://graph.microsoft.com/v1.0/me/photo/$value', {
                headers: { Authorization: `Bearer ${token}` },
                responseType: 'blob',
            })
                .then((o) => {
                    const url = window.URL || window.webkitURL;
                    const blobUrl = url.createObjectURL(o.data);
                    setImageUrl(blobUrl);
                })
                .catch(() => {
                    setImageUrl('../img/zedexinfologo.svg');
                });
        }
    }, [token, userSuccess]);

    if (usersData?.data[0].MSemployee_details === null) {
        navigate('/login');
        // alert('Please complete your Employee Registration');
    }

    return (
        <div className="relative top-12 bottom-2 z-0 shadow-2xl backdrop-blur-xl md:w-[75%] md:mb-20 md:pb-5 lg:w-5/6 rounded">
            <h2 className="py-8 text-center text-transparent font-semibold text-2xl bg-clip-text bg-gradient-to-r from-zxheader-from to-zxheader-to">
                Profile Registration
            </h2>

            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {userLoading && deptLoading ? (
                <div>Loading...</div>
            ) : userSuccess && deptSuccess ? (
                <div className="ml-24 text-zxtext-1 md:text-sm lg:text-base font-medium">
                    <div className="flex">
                        {imageUrl && (
                            <img
                                src={imageUrl}
                                alt=""
                                className="md:h-16 md:w-16 lg:h-20 lg:w-20 shadow rounded-full ring ring-zxblue-to"
                            />
                        )}

                        <div className="ml-5 py-3">
                            {usersData.data[0].MSemployee_details?.full_name}
                            <br />
                            <p className="space-x-5">
                                <span>
                                    ID:{' '}
                                    <span className="text-zxtext-2">
                                        {
                                            usersData.data[0].MSemployee_details
                                                ?.employee_number
                                        }
                                    </span>
                                </span>
                                <span>
                                    Email ID:{' '}
                                    <span className="text-zxtext-2">
                                        {usersData.data[0].email}
                                    </span>
                                </span>
                                <span>
                                    Department:{' '}
                                    <span className="text-zxtext-2">
                                        {deptData.data.name}
                                    </span>
                                </span>
                            </p>
                        </div>
                    </div>
                    {form2 ? (
                        <>
                            <BankForm userData={usersData.data[0]} />
                            <EducationForm
                                userData={usersData.data[0]}
                                refetch = {refetch}
                                // form2={form2}
                                setForm2={setForm2}
                            />
                        </>
                    ) : (
                        <>
                            <ProfileForm userData={usersData.data[0]} refetch={refetch}/>
                            <AddressForm
                                userData={usersData.data[0]}
                                // form2={form2}
                                setForm2={setForm2}
                            />
                        </>
                    )}
                </div>
            ) : (
                <div>Failed to load</div>
            )}
        </div>
    );
}
