import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from '../components/Header';
import { BasicInformation } from '../components/MultiStepForm/Forms/Step1Form';
import { EmployeePosition } from '../components/MultiStepForm/Forms/Step2Form';
import StepNames from '../components/StepNames';
import Steps from '../components/Steps';
import { BASIC_INFORMATION_PAGE } from '../constants/router';

const initialFormData = {
    step1: {
        email_id: '',
        employee_number_series: '',
        employee_number: '',
        first_name: '',
        last_name: '',
        gender: '',
        employee_status: '',
        joining_date: '',
        probation_period: '',
        confirmation_date: '',
    },
    step2: {
        department: '',
        designation: '',
        grade: '',
        reporting_manager: '',
        shift_id: '',
    },
};

export interface EmployeeCreationOutletContext {
    formData: EmployeeCreationFormData;
    setFormData: React.Dispatch<EmployeeCreationFormData>;
}

export interface EmployeeCreationFormData {
    step1: BasicInformation;
    step2: EmployeePosition;
}

export default function EmployeeCreation() {
    const location = useLocation();

    const [formData, setFormData] = useState(initialFormData);

    return (
        <div className="flex-col px-[3%] py-[2%] h-screen w-screen box-border overflow-auto">
            <Header name="Employee Directory" />
            <div className="content-center py-[1%]">
                <Steps path={location.pathname} />
            </div>
            <div className="flex flex-row justify-center items-center space-x-[13%]">
                <StepNames name="Basic Information" />
                <StepNames name="Employee Position" />
            </div>
            <div className="px-10">
                <div className="header">
                    <h2 className="py-[2%] border-x-zxblue-from text-transparent font-semibold md:text-xl lg:text-2xl">
                        <span className="border-l-4 border-zxsub-to pl-[1%] bg-clip-text bg-gradient-to-r from-zxheader-from to-zxheader-to opacity-100">
                            Step{' '}
                            {location.pathname === BASIC_INFORMATION_PAGE
                                ? 1
                                : 2}
                        </span>
                    </h2>
                </div>
                <Outlet context={{ formData, setFormData }} />
            </div>
        </div>
    );
}
