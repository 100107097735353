import moment from "moment";
import { useEffect } from "react";
import { Employee, EmployeeRegularization, EmployeeRegularizationStatus, MultipleRegularizations, MultipleRegularizationsStatus, useRetrieveEmployee, useRetrieveShift, useUpdateMultiRegularizations, useUpdateRegularization } from "../api";
import { weekday } from "../constants/functions";
import { Minuscircle, Pluscircle } from "./icons";

interface Props{
    mulregdata:Employee | undefined;
    edata:EmployeeRegularization[] | undefined;
    refetch:any;
    empfetch:any;
}

 function EmpMulReg(props:Props)
{const multireg=props.mulregdata
    const empregData=props.edata

 const shift_id=multireg?.shift_id
 const{
    data:shiftDetails
 }=useRetrieveShift(String(shift_id))
 
 const shift_start_time=shiftDetails?.data?.start_time 
 const shift_end_time=shiftDetails?.data?.end_time

 const manager_id=multireg?.reporting_manager
 const{data:managerData}=useRetrieveEmployee(String(manager_id)) 
 


 const { mutateAsync, isSuccess } = useUpdateRegularization();
 const handleAcceptChange = (e: any, reg:EmployeeRegularization) => {
    if (empregData !== undefined) {
        const regData = {
            ...reg,
            status: EmployeeRegularizationStatus?.Approved,
        };

        mutateAsync({
            id: reg.id ? reg.id.toString() : '',
            data: regData
        });

        // if(isSuccess)
        // props.ref();
    }
};
  
const handleRejectChange = (e: any, reg:EmployeeRegularization) => {
    if (empregData !== undefined) {
        const regData = {
            ...reg,
            status: EmployeeRegularizationStatus?.Rejected,
        };

        mutateAsync({
            id: reg.id ? reg.id.toString() : '',
            data: regData
        });
        // if (isSuccess) {
        //     props.ref();
        // }
    }
   
};

const { mutateAsync:multisync, isSuccess: multiSuccess} = useUpdateMultiRegularizations();
const handleAcceptAllChange=(e:any)=>{
    if(empregData !== undefined){
        const multiregsData:MultipleRegularizations[]=
            empregData.map((Regdata:MultipleRegularizations)=>(
                {   ...Regdata,
                    status:MultipleRegularizationsStatus.Approved
                }
                
            ))
            

        
        multisync({
           
            data: multiregsData
        });

        // if (multiSuccess){
        //     props.mulref();
        // }
    }
};


const handleRejectAllChange=(e:any)=>{
    if(empregData !== undefined){
        const multiregsData:MultipleRegularizations[]=
            empregData.map((Regdata:MultipleRegularizations)=>(
                {   ...Regdata,
                    status:MultipleRegularizationsStatus.Rejected
                }
                
            ))
            

        
        multisync({
           
            data: multiregsData
        });

        // if (multiSuccess){
        //     props.mulref();
        // }
    }
};


useEffect(()=>{
    if(isSuccess || multiSuccess)
    props.refetch();
},[isSuccess, multiSuccess, props])
 

    return(
        <div>
        <div className=" border border-zxblue-to rounded-md focus:outline-none lg:ml-[9%] lg:w-[85%] lg:mt-5 md:ml-[2%] md:mt-5 mr-8 md:w-[95%] h-[40%]" >
            <div className="grid grid-cols-5 gap-5">
            <h1 className="col-span-2 pt-6 pl-40 text-sm font-medium non-italic -ml-32">{multireg?.full_name} </h1>
            <h1 className="col-span-3 pl-20 text-sm font-medium mt-6  ">Manager : <span className="font-normal text-xs">{managerData?.data?.full_name}</span></h1>
            <h1 className="col-span-5 ml-8 text-sm font-medium ">Shift Type : <span className="font-normal text-xs">{shiftDetails?.data?.shift_name} Shift {shift_start_time}-{shift_end_time}</span></h1>
            {/* <h1 className="col-span-2 text-sm font-medium -mt-4 -ml-16">CC : </h1> */}
            
            {empregData?.map((e)=>
            
            <>
            {e?.status==='Pending'?(
                <button type="button" onClick={(event)=>{
                    handleAcceptChange(event, e)
                    // props.ref();
                    // event.preventDefault();
                    }} className="pl-8 ">
                <Pluscircle />
                </button>
            ):null
            }
            <h1 key={e.id} className="text-xs font-medium pt-1 pl-5 md:px-0 col-span-2"><span className="border border-zxblue-to py-1 px-1">{e?.date}</span></h1>
            <h1 className="text-sm font-medium col-span-2">Sign In : <span className="font-normal text-xs bg-clip-text text-transparent bg-gradient-to-l from-zxorange-from to-zxorange-to">{new Date(e?.actual_sign_in).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                })}</span></h1>
             
             {e?.status==='Pending'?(
                <button type="button" onClick={(event)=>{
                    handleRejectChange(event, e)
                    // props.ref();
                    // event.preventDefault();
                    }} className="pl-8 -mt-6">
                <Minuscircle />
                </button>
            ):null
            }
             <h1 className="text-center text-sm font-medium  pr-32 col-span-2 -ml-17 -mt-4">{weekday[moment(e?.date.toString()).day()]}</h1>
             <h1 className="col-span-2 text-sm font-medium -mt-4">Sign Out : <span className="font-normal text-xs bg-clip-text text-transparent bg-gradient-to-l from-zxorange-from to-zxorange-to">{new Date(e?.actual_sign_out).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                })}</span></h1>
             <h1 className="col-span-3 text-sm font-medium ml-[35%] -mt-4"><span className="font-normal text-xs">{e?.reason}</span></h1>
             <hr className="col-span-5 border-1 border-black lg:w-[90%] lg:ml-8"/>

            </>
             
            )}
            </div>
                    
        <div className="flex flex-row">
 
 <button
         className="border border-orange-400 lg:w-[30%]  ml-44  md:w-[55%] py-3 mt-6 mb-6 rounded-full  place-self-center "
         aria-label="Reject"
         type="button"
         onClick={(event)=>{
            handleRejectAllChange(event)
            // event.preventDefault();
            }}
     >
         Reject All
     </button>
     <button
         aria-label="Accept"
         className="text-white lg:w-[30%] ml-8 mr-[10%] md:w-[55%]  py-3 mt-6 mb-6 rounded-full bg-gradient-to-r from-zxblue-from to-zxblue-to hover:bg-gradient-to-t"
         onClick={(event)=>{
            handleAcceptAllChange(event)
            // event.preventDefault();
            }}
         type="button"
     >
         Accept All
     </button>

</div>      
</div>

</div>
 

        
    )
}

export default EmpMulReg