/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useMutation
} from '@tanstack/react-query'
import type {
  UseMutationOptions,
  MutationFunction
} from '@tanstack/react-query'


/**
 * Update multiple regularizations
 */
export const updateMultiRegularizations = (
    updateMultiRegularizationsBody: unknown, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.put(
      `/multi-regularization-update/`,
      updateMultiRegularizationsBody,options
    );
  }



    export type UpdateMultiRegularizationsMutationResult = NonNullable<Awaited<ReturnType<typeof updateMultiRegularizations>>>
    export type UpdateMultiRegularizationsMutationBody = unknown
    export type UpdateMultiRegularizationsMutationError = AxiosError<unknown>

    export const useUpdateMultiRegularizations = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateMultiRegularizations>>, TError,{data: unknown}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateMultiRegularizations>>, {data: unknown}> = (props) => {
          const {data} = props ?? {};

          return  updateMultiRegularizations(data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof updateMultiRegularizations>>, TError, {data: unknown}, TContext>(mutationFn, mutationOptions)
    }
    