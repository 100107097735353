/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  Accessory
} from '../../schema'


/**
 * List all departments
 */
export const listAccessorys = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Accessory[]>> => {
    return axios.get(
      `/accessory/`,options
    );
  }


export const getListAccessorysQueryKey = () => [`/accessory/`];

    
export type ListAccessorysQueryResult = NonNullable<Awaited<ReturnType<typeof listAccessorys>>>
export type ListAccessorysQueryError = AxiosError<unknown>

export const useListAccessorys = <TData = Awaited<ReturnType<typeof listAccessorys>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listAccessorys>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListAccessorysQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listAccessorys>>> = ({ signal }) => listAccessorys({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof listAccessorys>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * Create department
 */
export const createAccessory = (
    accessory: Accessory, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Accessory>> => {
    return axios.post(
      `/accessory/`,
      accessory,options
    );
  }



    export type CreateAccessoryMutationResult = NonNullable<Awaited<ReturnType<typeof createAccessory>>>
    export type CreateAccessoryMutationBody = Accessory
    export type CreateAccessoryMutationError = AxiosError<unknown>

    export const useCreateAccessory = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAccessory>>, TError,{data: Accessory}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAccessory>>, {data: Accessory}> = (props) => {
          const {data} = props ?? {};

          return  createAccessory(data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof createAccessory>>, TError, {data: Accessory}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * Get accessory details
 */
export const retrieveAccessory = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Accessory>> => {
    return axios.get(
      `/accessory/${id}`,options
    );
  }


export const getRetrieveAccessoryQueryKey = (id: string,) => [`/accessory/${id}`];

    
export type RetrieveAccessoryQueryResult = NonNullable<Awaited<ReturnType<typeof retrieveAccessory>>>
export type RetrieveAccessoryQueryError = AxiosError<unknown>

export const useRetrieveAccessory = <TData = Awaited<ReturnType<typeof retrieveAccessory>>, TError = AxiosError<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof retrieveAccessory>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveAccessoryQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveAccessory>>> = ({ signal }) => retrieveAccessory(id, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof retrieveAccessory>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * Update accessory details
 */
export const updateAccessory = (
    id: string,
    accessory: Accessory, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Accessory>> => {
    return axios.put(
      `/accessory/${id}`,
      accessory,options
    );
  }



    export type UpdateAccessoryMutationResult = NonNullable<Awaited<ReturnType<typeof updateAccessory>>>
    export type UpdateAccessoryMutationBody = Accessory
    export type UpdateAccessoryMutationError = AxiosError<unknown>

    export const useUpdateAccessory = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAccessory>>, TError,{id: string;data: Accessory}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAccessory>>, {id: string;data: Accessory}> = (props) => {
          const {id,data} = props ?? {};

          return  updateAccessory(id,data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof updateAccessory>>, TError, {id: string;data: Accessory}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * Delete an accessory
 */
export const destroyAccessory = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    return axios.delete(
      `/accessory/${id}`,options
    );
  }



    export type DestroyAccessoryMutationResult = NonNullable<Awaited<ReturnType<typeof destroyAccessory>>>
    
    export type DestroyAccessoryMutationError = AxiosError<unknown>

    export const useDestroyAccessory = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof destroyAccessory>>, TError,{id: string}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof destroyAccessory>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  destroyAccessory(id,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof destroyAccessory>>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    