import Tlattregframe from '../components/Tlattregframe';

import ZXHeader from '../components/ZXHeader';

function TeamAttRegularise() {
    return (
        <div className="w-screen h-screen box-border p-6 overflow-auto bg-zxattendancebg">
            <div className="pl-12">
                <ZXHeader />
            </div>
            <div className="m-10 box-border shadow-md">
                <Tlattregframe />
            </div>
        </div>
    );
}

export default TeamAttRegularise;
