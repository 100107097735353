import Holcrudcircle from './Holcrudcircle';
import HolCrudForm from './HolCrudForm';

function Holcrud() {
    return (
        <div className="w-full h-screen flex justify-center items-center">
            <div className="relative z-10 shadow-2xl backdrop-blur-xl md:w-screen md:pb-8 lg:w-5/6 rounded lg:mt-6 lg:h-[60%] md:ml-20 lg:ml-8">
                <h2 className="py-8 text-center text-transparent font-semibold text-2xl bg-clip-text bg-gradient-to-r from-zxheader-from to-zxheader-to">
                    CREATE HOLIDAY PAGE
                </h2>
                <h2 className="pl-20 border-x-zxblue-from text-transparent font-semibold md:text-xl lg:text-2xl bg-clip-text bg-gradient-to-t from-zxheader-from to-zxheader-to">
                    <span className="border-l-4 border-zxsub-to pl-2">
                        Holiday Information
                    </span>
                </h2>
                <HolCrudForm />
            </div>
            <Holcrudcircle />
        </div>
    );
}

export default Holcrud;
