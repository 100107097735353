import 'react-toastify/dist/ReactToastify.css';

import { toast, ToastContainer } from 'react-toastify';

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInAndUp } from 'supertokens-web-js/recipe/thirdparty';
import { HOME, LOGIN } from '../constants/router';

function MsAuth() {
    const navigate = useNavigate();
    async function handleGoogleCallback() {
        try {
            const response = await signInAndUp();
            if (response.status === 'OK') {
                // console.log(response.user);
                if (response.createdNewUser) {
                    // sign up successful
                } else {
                    // sign in successful
                }
                navigate(HOME);
            } else {
                // SuperTokens requires that the third party provider
                // gives an email for the user. If that's not the case, sign up / in
                // will fail.
                toast.error(
                    'No email provided by social login. Please use another form of login'
                );
                navigate(LOGIN); // redirect back to login page
            }
        } catch (err: any) {
            if (err.isSuperTokensGeneralError === true) {
                // this may be a custom error message sent from the API by you.
                toast.error(err.message);
            }
        }
    }
    useEffect(() => {
        handleGoogleCallback();
    });

    return (
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
    );
}

export default MsAuth;
