import {
    Accessories,
    useListAccessories,
    useRetrieveAccessory,
    useRetrieveMSUser
} from '../../../api';
import ProfileUpdateField from '../ProfileUpdateField';

export default function AcccessoriesInfoSection(props: { id: string }) {
    const { data: employeeData } = useRetrieveMSUser(props.id);
    const { data: accessoriesData } = useListAccessories({
        axios: {
            params: {
                assigned_to: employeeData?.data.MSemployee_details.id,
            },
        },
    });
    return accessoriesData?.data ? (
        <div className="grid grid-flow-row gap-y-4 gap-x-2 md:grid-cols-3 lg:grid-cols-4 px-7">
            {accessoriesData.data.map((accessory) => (
                <AccInfoSection
                    key={accessory.id}
                    accID={accessory.type}
                    accessory={accessory}
                />
            ))}
        </div>
    ) : (
        <div>No Accessory Assigned</div>
    );
}

function AccInfoSection(props: { accID: number; accessory: Accessories }) {
    const { data: accessoryData } = useRetrieveAccessory(
        props.accID.toString()
    );
    return (
        <>
            <ProfileUpdateField
                key={props.accessory.type}
                label="Type"
                value={accessoryData?.data.name}
            />
            <ProfileUpdateField
                label="Serial No"
                value={props.accessory.serial_no}
                className="md:col-span-2 lg:col-span-3"
            />
        </>
    );
}
