/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  EmployeeRegularization
} from '../../schema'


/**
 * List all regularizations of an employee
 */
export const retrieveEmployeeRegularization = (
    employeeId: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<EmployeeRegularization>> => {
    return axios.get(
      `/employee-regularizations/${employeeId}`,options
    );
  }


export const getRetrieveEmployeeRegularizationQueryKey = (employeeId: string,) => [`/employee-regularizations/${employeeId}`];

    
export type RetrieveEmployeeRegularizationQueryResult = NonNullable<Awaited<ReturnType<typeof retrieveEmployeeRegularization>>>
export type RetrieveEmployeeRegularizationQueryError = AxiosError<unknown>

export const useRetrieveEmployeeRegularization = <TData = Awaited<ReturnType<typeof retrieveEmployeeRegularization>>, TError = AxiosError<unknown>>(
 employeeId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof retrieveEmployeeRegularization>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveEmployeeRegularizationQueryKey(employeeId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveEmployeeRegularization>>> = ({ signal }) => retrieveEmployeeRegularization(employeeId, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof retrieveEmployeeRegularization>>, TError, TData>(queryKey, queryFn, {enabled: !!(employeeId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

