import { useState } from 'react';
import { Calendar, DateObject } from 'react-multi-date-picker';
import AttendanceTableDiv from './AttendanceTableDiv';

export default function AttendanceFrame2() {
    const [dateObj, setDateObj] = useState(new DateObject());

    return (
        <div className="flex flex-col w-full">
            <div className="bg-white shadow-2xl bg-opacity-60 backdrop-blur-x p-10">
                <h1 className="border-x-zxblue-from text-transparent font-semibold md:text-lg lg:text-xl">
                    <span className="border-l-4 border-zxsub-to pl-2 py-1 bg-clip-text bg-gradient-to-r from-zxheader-from to-zxheader-to opacity-100">
                        Attendance Calendar
                    </span>
                </h1>
                <div className="flex flex-row space-x-10 p-1">
                    <Calendar
                        className="ml-3 mt-3.5 w-[80%] h-min text-sm font-medium shadow-2xl"
                        onChange={(selectedDate: DateObject) => {
                            setDateObj(selectedDate);
                        }}
                        onMonthChange={(selectedDate) => {
                            setDateObj(selectedDate);
                        }}
                    />
                    <AttendanceTableDiv
                        month={dateObj.month.number}
                        year={dateObj.year}
                    />
                </div>
            </div>
        </div>
    );
}
