import { BellIcon } from '@heroicons/react/24/solid';
import { Link } from 'react-router-dom';

interface Props {
    name?: string;
    setFilter?: React.Dispatch<React.SetStateAction<{
        name: string;
    }>>;
}
function ZXHeader(props: Props) {
    return (
        <div className="flex flex-row lg:gap-x-6 md:gap-x-4 mb-6">
            <form>
                <div className="relative">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="absolute top-3 bottom-0 w-6 h-6 my-auto  text-gray-400 left-3"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                    </svg>
                    <input
                        type="text"
                        placeholder="Search"
                        id='search'
                        value={props.name}
                        onChange={(e) => {
                            if (props.setFilter)
                            props.setFilter((values) => ({
                                ...values,
                                name: e.target.value,
                            }));
                        }}
                        className="w-[200%] py-3 pl-12 lg:h-11 md:h-9 mt-3 text-gray border rounded-full outline-none focus:border-zxblue-to"
                    />
                </div>
            </form>
            <Link to="2">
                <BellIcon className="lg:h-11 lg:w-11 md:h-8 md:w-8 lg:mt-2 md:mt-3 p-2 bg-white rounded-full text-zxicon hover:text-gray-800" />
            </Link>
            <img
                src="../img/zedexinfologo.svg"
                alt=""
                className="mr-12 ml-[15%] lg:w-48 lg:mt-4 lg:h-7 md:w-48 md:h-10 md:mt-2"
            />
        </div>
    );
}

export default ZXHeader;
