import {
    Employee,
    EmployeeEmployeeStatus,
    MSUser,
    useUpdateEmployee
} from '../api';

import { AxiosResponse } from 'axios';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PROFILE_UPDATE } from '../constants/router';

interface EmpRowProps<A, R> {
    usersData: AxiosResponse<MSUser[]>;
    refetch: (options?: A) => R;
}

function EmployeeRow<A, R>(props: EmpRowProps<A, R>) {
    const { mutateAsync } = useUpdateEmployee();

    const navigate = useNavigate();
    const updateStatus = async (
        empId: number | undefined,
        values: Employee | undefined,
        stats: EmployeeEmployeeStatus
    ) => {
        if (empId !== undefined && values !== undefined) {
            const updatedVal = {
                ...values,
                employee_status: stats,
            };
            const resp = await mutateAsync({
                id: empId.toString(),
                data: updatedVal,
            });
            if (resp.status === 200) {
                props.refetch();
                toast.success('Status Updated');
            }
        }
    };

    const getDate = (date: string) => {
        return new Date(date).toLocaleDateString('en-IN', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
        });
    };

    const handleClick = (url: string, user_id: number | undefined) => {
        navigate(`${url}/${user_id}`);
    };

    const buttonStyle = 'lg:w-20 lg:h-9 md:w-16 md:h-8 mt-2 rounded-lg';

    const activeOn = classnames(
        buttonStyle,
        'mr-2 text-white bg-gradient-to-r from-zxblue-from to-zxblue-to hover:bg-gradient-to-t from-zxblue-from to-zxblue-to'
    );

    const activeOff = classnames(
        buttonStyle,
        'mr-2',
        'text-black bg-gradient-to-l from-white to-white border border-zxblue-to hover:bg-gradient-to-r from-white to-slate-50'
    );

    const deactiveOn = classnames(
        buttonStyle,
        'text-white bg-gradient-to-tl from-zxbutton2-to to-zxbutton2-from border'
    );

    const deactiveOff = classnames(
        buttonStyle,
        'text-black bg-gradient-to-l from-white to-white border border-zxbutton2-to hover:bg-gradient-to-r from-white to-slate-50'
    );

    let counter = 0;

    const users = props.usersData?.data;
    return (
        <>
            {users.map((user: MSUser) => (
                <tr key={user.id} className="h-14 hover:shadow-lg">
                    <td className="text-center">
                        <div
                            className="w-full h-full"
                            role="button"
                            tabIndex={Number(0)}
                            onClick={() => handleClick(PROFILE_UPDATE, user.id)}
                            onKeyDown={() =>
                                handleClick(PROFILE_UPDATE, user.id)
                            }
                        >
                            {++counter}
                        </div>
                    </td>
                    <td className="pl-12">
                        <div
                            className="w-full h-full"
                            role="button"
                            tabIndex={Number(0)}
                            onClick={() => handleClick(PROFILE_UPDATE, user.id)}
                            onKeyDown={() =>
                                handleClick(PROFILE_UPDATE, user.id)
                            }
                        >
                            {user.MSemployee_details?.employee_number}
                        </div>
                    </td>
                    <td className="pl-12">
                        <div
                            className="w-full h-full"
                            role="button"
                            tabIndex={Number(0)}
                            onClick={() => handleClick(PROFILE_UPDATE, user.id)}
                            onKeyDown={() =>
                                handleClick(PROFILE_UPDATE, user.id)
                            }
                        >
                            {user.MSemployee_details?.full_name}
                        </div>
                    </td>
                    <td className="pl-12">
                        <div
                            className="w-full h-full"
                            role="button"
                            tabIndex={Number(0)}
                            onClick={() => handleClick(PROFILE_UPDATE, user.id)}
                            onKeyDown={() =>
                                handleClick(PROFILE_UPDATE, user.id)
                            }
                        >
                            {
                                user.MSemployee_details?.profile_details
                                    ?.contact_no
                            }
                        </div>
                    </td>
                    <td className="pl-12">
                        <div
                            className="w-full h-full"
                            role="button"
                            tabIndex={Number(0)}
                            onClick={() => handleClick(PROFILE_UPDATE, user.id)}
                            onKeyDown={() =>
                                handleClick(PROFILE_UPDATE, user.id)
                            }
                        >
                            {user.email}
                        </div>
                    </td>
                    <td className="pl-8">
                        <div
                            className="w-full h-full"
                            role="button"
                            tabIndex={Number(0)}
                            onClick={() => handleClick(PROFILE_UPDATE, user.id)}
                            onKeyDown={() =>
                                handleClick(PROFILE_UPDATE, user.id)
                            }
                        >
                            {getDate(
                                user.MSemployee_details?.joining_date
                                    ? user.MSemployee_details.joining_date
                                    : ''
                            ).toString()}
                        </div>
                    </td>
                    <td className="flex justify-center flex-row gap-2">
                        {user.MSemployee_details?.profile_details === null ||
                        undefined ? (
                            <button type="button" className={deactiveOn}>
                                {' '}
                                Not Filled{' '}
                            </button>
                        ) : (
                            <>
                                <button
                                    type="button"
                                    className={
                                        user.MSemployee_details
                                            ?.employee_status === 'Active'
                                            ? activeOn
                                            : activeOff
                                    }
                                    onClick={() => {
                                        updateStatus(
                                            user.MSemployee_details?.id,
                                            user.MSemployee_details,
                                            'Active'
                                        );
                                    }}
                                >
                                    Active
                                </button>
                                <button
                                    type="button"
                                    className={
                                        user.MSemployee_details
                                            ?.employee_status !== 'Active'
                                            ? deactiveOn
                                            : deactiveOff
                                    }
                                    onClick={() => {
                                        updateStatus(
                                            user.MSemployee_details?.id,
                                            user.MSemployee_details,
                                            'Deactive'
                                        );
                                    }}
                                >
                                    Deactive
                                </button>
                            </>
                        )}
                    </td>
                </tr>
            ))}
        </>
    );
}

export default EmployeeRow;
