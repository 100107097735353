import AccessoriesInformationUpdate from './AccessoriesInformationUpdate';
import AddressInformationUpdate from './AddressInformationUpdate';
import BankInformationUpdate from './BankInformationUpdate';
import DepartmentUpdateForm from './DepartmentUpdateForm';
import EducationInformationUpdate from './EducationInformationUpdate';
import EmployeeInformationUpdate from './EmployeeInformationUpdate';
import LeaveInformationUpdate from './LeaveInformationUpdate';
import ProfileInformationUpdate from './ProfileInformationUpdate';

export default function ProfileUpdateForm(props: { id: string }) {
    return (
        <div className="px-16">
            <EmployeeInformationUpdate id={props.id} />
            <div className="relative mt-5 h-0.5 bg-gradient-to-r from-zxheader-to to-zxheader-from opacity-50 rounded-xl" />
            <ProfileInformationUpdate id={props.id} />
            <div className="relative mt-5 h-0.5 bg-gradient-to-r from-zxheader-to to-zxheader-from opacity-50 rounded-xl" />
            <AddressInformationUpdate id={props.id} />
            <div className="relative mt-5 h-0.5 bg-gradient-to-r from-zxheader-to to-zxheader-from opacity-50 rounded-xl" />
            <BankInformationUpdate id={props.id} />
            <div className="relative mt-5 h-0.5 bg-gradient-to-r from-zxheader-to to-zxheader-from opacity-50 rounded-xl" />
            <LeaveInformationUpdate id={props.id} />
            <div className="relative mt-5 h-0.5 bg-gradient-to-r from-zxheader-to to-zxheader-from opacity-50 rounded-xl" />
            <DepartmentUpdateForm id={props.id} />
            <div className="relative mt-5 h-0.5 bg-gradient-to-r from-zxheader-to to-zxheader-from opacity-50 rounded-xl" />
            <EducationInformationUpdate id={props.id} />
            <div className="relative mt-5 h-0.5 bg-gradient-to-r from-zxheader-to to-zxheader-from opacity-50 rounded-xl" />
            <AccessoriesInformationUpdate id={props.id} />
        </div>
    );
}
