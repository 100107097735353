import moment from 'moment';
import { Link } from 'react-router-dom';
import { useListAttendances, useListMSViewers } from '../api';
import AttendanceFrame from '../components/AttendanceFrame';
import AttendanceSidebar from '../components/AttendanceSidebar';
import ZXHeader from '../components/ZXHeader';
import { REGULARIZATION } from '../constants/router';

function Tlatt(props: { isViewerTL: boolean; isViewerAdmin: boolean }) {
    const { data: currViewerData } = useListMSViewers();
    const currViewerDetails = currViewerData?.data[0].MSemployee_details;
    const { data: attendanceData } = useListAttendances({
        axios: {
            params: {
                emp_id: currViewerDetails?.id
                    ? currViewerDetails.id
                    : Number(''),
                month: moment().month() + 1,
                year: moment().year(),
                status: 'A',
            },
        },
    });

    return (
        <div className="p-6 h-screen w-screen box-border overflow-auto">
            <div className="pl-12">
                <ZXHeader />
            </div>
            {attendanceData && attendanceData?.data.length !== 0 ? (
                <div className="h-8 w-fit px-2.5 pt-2 border border-red-600 text-red-600 bg-zxbg mx-auto rounded-md font-light text-xs">
                    You are having{' '}
                    <Link
                        to={REGULARIZATION}
                        className="underline hover:text-zxtext-1"
                    >
                        {attendanceData.data.length}{' '}
                        {attendanceData.data.length > 1 ? 'gaps' : 'gap'}{' '}
                    </Link>
                    in this month
                </div>
            ) : null}
            <div className="flex m-10 space-x-10">
                <AttendanceSidebar
                    isViewerTL={props.isViewerTL}
                    isViewerAdmin={props.isViewerAdmin}
                />
                <AttendanceFrame />
            </div>
        </div>
    );
}
export default Tlatt;
