import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { Calendar, DateObject } from 'react-multi-date-picker';
import { useNavigate } from 'react-router-dom';
import {
    Attendance,
    EmployeeRegularization,
    useListAttendances,
    useListMSViewers,
    useRetrieveEmployeeRegularization
} from '../../api';
import RegularizationStatusBar from './RegularizationStatusBar';

export default function RegularizationFrame() {
    const [value, setValue] = useState<Array<DateObject>>([]);
    const [isClicked, setIsClicked] = useState(true);
    const { data: viewerData } = useListMSViewers();
    const viewerDetails = viewerData?.data[0].MSemployee_details;
    const {
        data: attendanceData,
        refetch: refetchAbsentAttendance,
        isSuccess: isAbsentAttSuccess,
    } = useListAttendances({
        axios: {
            params: {
                emp_id: viewerDetails?.id ? viewerDetails.id : Number(''),
                month: moment().month() + 1,
                year: moment().year(),
                status: 'A',
            },
        },
    });
    const { data: regData, refetch: refetchEmpRegularization } =
        useRetrieveEmployeeRegularization<
            AxiosResponse<EmployeeRegularization[]>
        >(viewerDetails?.id ? viewerDetails.id.toString() : '');

    let d = attendanceData?.data.map((att: Attendance) =>
        moment(att.date).format('YYYY/MM/DD')
    );
    const pendRegData = regData?.data.map((reg: EmployeeRegularization) =>
        reg.status === 'Pending' ? moment(reg.date).format('YYYY/MM/DD') : ''
    );

    const navigate = useNavigate();

    useEffect(() => {
        if (isAbsentAttSuccess && viewerData) refetchAbsentAttendance();
    }, [isAbsentAttSuccess, refetchAbsentAttendance, viewerData]);

    return (
        <div className="flex flex-col p-5 bg-zxbg">
            <div className="flex">
                <BiArrowBack
                    className="mt-1.5 mr-2.5 cursor-pointer"
                    size="2.5%"
                    style={{ color: '#4A65EE' }}
                    onClick={() => navigate(-1)}
                />
                <h2 className="pt-2 mb-4 border-x-zxblue-from text-transparent font-semibold text-xl bg-clip-text bg-gradient-to-t from-zxheader-from to-zxheader-to">
                    <span className="border-l-4 pt-1 border-zxsub-to pl-1">
                        Attendance Regularization
                    </span>
                </h2>
                <button
                    type="button"
                    className={classNames(
                        'w-20 h-7 p-auto ml-5 my-2 border hover:border-zxblue-to rounded-lg font-normal text-xs',
                        !isClicked
                            ? 'bg-zxblue-from text-zxbg'
                            : 'text-[#4A5660] border-zxblue-from'
                    )}
                    onClick={() => {
                        setIsClicked(!isClicked);
                        if (isClicked && d)
                            setValue(d.map((dt) => new DateObject(dt)));
                        else setValue([]);
                        if (pendRegData && d && isClicked) {
                            d = d.filter((x) => !pendRegData.includes(x));
                            setValue(d.map((ele) => new DateObject(ele)));
                        } else setValue([]);
                    }}
                >
                    {!isClicked ? 'Remove' : 'Select All'}
                </button>
            </div>
            <div className="flex flex-row space-x-8">
                <Calendar
                    className="ml-3 mt-5 w-[73%] h-min text-sm font-medium"
                    multiple
                    onChange={(array: DateObject[]) => {
                        setValue(array);
                    }}
                    mapDays={({ date }) => {
                        const props = { style: {}, disabled: false };
                        const today = moment().format('YYYY/MM/DD');
                        if (d?.includes(date.format('YYYY/MM/DD')))
                            props.style = { color: 'red' };
                        if (
                            value.toString().includes(date.format('YYYY/MM/DD'))
                        )
                            props.style = {
                                color: 'white',
                                backgroundColor: '#FF0000',
                            };
                        if (
                            !d?.includes(date.format('YYYY/MM/DD')) ||
                            pendRegData?.includes(date.format('YYYY/MM/DD'))
                        ) {
                            props.disabled = true;
                            props.style = {
                                color: '#8FA0B2',
                            };
                        }
                        if (date.toString() === today) {
                            props.disabled = true;
                            props.style = {
                                color: 'white',
                                backgroundColor: '#7FDBFF',
                            };
                        }
                        return props;
                    }}
                />
                <div className="flex flex-col w-full">
                    {viewerDetails ? (
                        <RegularizationStatusBar
                            clickedDates={value}
                            viewerDetails={viewerDetails}
                            regData={regData}
                            refetchEmpRegularization={refetchEmpRegularization}
                            setValue={setValue}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
}
