/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  Bank
} from '../../schema'


/**
 * List all Banks
 */
export const listBanks = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Bank[]>> => {
    return axios.get(
      `/banks/`,options
    );
  }


export const getListBanksQueryKey = () => [`/banks/`];

    
export type ListBanksQueryResult = NonNullable<Awaited<ReturnType<typeof listBanks>>>
export type ListBanksQueryError = AxiosError<unknown>

export const useListBanks = <TData = Awaited<ReturnType<typeof listBanks>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listBanks>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListBanksQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listBanks>>> = ({ signal }) => listBanks({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof listBanks>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * Create Bank
 */
export const createBank = (
    bank: Bank, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Bank>> => {
    return axios.post(
      `/banks/`,
      bank,options
    );
  }



    export type CreateBankMutationResult = NonNullable<Awaited<ReturnType<typeof createBank>>>
    export type CreateBankMutationBody = Bank
    export type CreateBankMutationError = AxiosError<unknown>

    export const useCreateBank = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createBank>>, TError,{data: Bank}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createBank>>, {data: Bank}> = (props) => {
          const {data} = props ?? {};

          return  createBank(data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof createBank>>, TError, {data: Bank}, TContext>(mutationFn, mutationOptions)
    }
    