/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  Shift
} from '../../schema'


/**
 * Get shift details
 */
export const retrieveShift = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Shift>> => {
    return axios.get(
      `/shift/${id}`,options
    );
  }


export const getRetrieveShiftQueryKey = (id: string,) => [`/shift/${id}`];

    
export type RetrieveShiftQueryResult = NonNullable<Awaited<ReturnType<typeof retrieveShift>>>
export type RetrieveShiftQueryError = AxiosError<unknown>

export const useRetrieveShift = <TData = Awaited<ReturnType<typeof retrieveShift>>, TError = AxiosError<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof retrieveShift>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveShiftQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveShift>>> = ({ signal }) => retrieveShift(id, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof retrieveShift>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * Update shift details
 */
export const updateShift = (
    id: string,
    shift: Shift, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Shift>> => {
    return axios.put(
      `/shift/${id}`,
      shift,options
    );
  }



    export type UpdateShiftMutationResult = NonNullable<Awaited<ReturnType<typeof updateShift>>>
    export type UpdateShiftMutationBody = Shift
    export type UpdateShiftMutationError = AxiosError<unknown>

    export const useUpdateShift = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateShift>>, TError,{id: string;data: Shift}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateShift>>, {id: string;data: Shift}> = (props) => {
          const {id,data} = props ?? {};

          return  updateShift(id,data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof updateShift>>, TError, {id: string;data: Shift}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * Delete a shift
 */
export const destroyShift = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    return axios.delete(
      `/shift/${id}`,options
    );
  }



    export type DestroyShiftMutationResult = NonNullable<Awaited<ReturnType<typeof destroyShift>>>
    
    export type DestroyShiftMutationError = AxiosError<unknown>

    export const useDestroyShift = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof destroyShift>>, TError,{id: string}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof destroyShift>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  destroyShift(id,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof destroyShift>>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    