/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  Regularization
} from '../../schema'


/**
 * Get regularization details
 */
export const retrieveRegularization = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Regularization>> => {
    return axios.get(
      `/regularization/${id}`,options
    );
  }


export const getRetrieveRegularizationQueryKey = (id: string,) => [`/regularization/${id}`];

    
export type RetrieveRegularizationQueryResult = NonNullable<Awaited<ReturnType<typeof retrieveRegularization>>>
export type RetrieveRegularizationQueryError = AxiosError<unknown>

export const useRetrieveRegularization = <TData = Awaited<ReturnType<typeof retrieveRegularization>>, TError = AxiosError<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof retrieveRegularization>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveRegularizationQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveRegularization>>> = ({ signal }) => retrieveRegularization(id, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof retrieveRegularization>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * Update regularization details
 */
export const updateRegularization = (
    id: string,
    regularization: Regularization, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Regularization>> => {
    return axios.put(
      `/regularization/${id}`,
      regularization,options
    );
  }



    export type UpdateRegularizationMutationResult = NonNullable<Awaited<ReturnType<typeof updateRegularization>>>
    export type UpdateRegularizationMutationBody = Regularization
    export type UpdateRegularizationMutationError = AxiosError<unknown>

    export const useUpdateRegularization = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateRegularization>>, TError,{id: string;data: Regularization}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateRegularization>>, {id: string;data: Regularization}> = (props) => {
          const {id,data} = props ?? {};

          return  updateRegularization(id,data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof updateRegularization>>, TError, {id: string;data: Regularization}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * Delete regularization
 */
export const destroyRegularization = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    return axios.delete(
      `/regularization/${id}`,options
    );
  }



    export type DestroyRegularizationMutationResult = NonNullable<Awaited<ReturnType<typeof destroyRegularization>>>
    
    export type DestroyRegularizationMutationError = AxiosError<unknown>

    export const useDestroyRegularization = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof destroyRegularization>>, TError,{id: string}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof destroyRegularization>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  destroyRegularization(id,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof destroyRegularization>>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    