import LoginCircle from './LoginCircle';
import LoginFrame from './LoginFrame';

function Login() {
    return (
        <div className="w-full h-screen flex justify-center items-center">
            <LoginCircle />
            <LoginFrame />
        </div>
    );
}

export default Login;
