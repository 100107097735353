import { useListEmployees } from '../../api';
import SalaryRows from './SalaryRows';

function SalaryTable() {
    const {
        data: employeesData,
        isSuccess: employeeSuccess,
        isLoading: employeeLoading,
        isFetchedAfterMount: employeeFetchedAfterMount,
    } = useListEmployees();

    return (
        <table className="mt-6 text-zxtext-1 md:text-sm lg:text-lg ml-4 min-w-full">
            <thead className="h-12">
                <tr id="bord-row">
                    <th>No</th>
                    <th>Employee ID</th>
                    <th>Name</th>
                    <th>Department</th>
                    <th>Salary</th>
                </tr>
            </thead>
            <tbody className="lg:text-sm md:text-xs">
                {employeeLoading ? (
                    <tr>
                        <td>Loading.......</td>
                    </tr>
                ) : employeeFetchedAfterMount && employeeSuccess ? (
                    <SalaryRows empData={employeesData} />
                ) : (
                    <tr>
                        <td>Not Success</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}

export default SalaryTable;
