function AttatchmentForm() {
    return (
        <div>
            <h1 className="pt-2 flex-none border-x-zxblue-from text-transparent  lg:mt-7 font-semibold lg:text-2xl md:text-xl bg-clip-text bg-gradient-to-t from-zxheader-from to-zxheader-to md:mt-6">
                        <span className="border-l-4 pt-1 md:ml-8 border-zxsub-to lg:ml-8 pl-2">
                            {' '}
                            Attachment{' '}
                        </span>
                    </h1>
                    {/* <AttatchmentForm /> */}
            <input
                type="file"
                id="myfile"
                name="myfile"
                className="ml-12 mt-8"
            />
        </div>
    );
}

export default AttatchmentForm;
