import { Formik } from 'formik';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import {
    Shift,
    useDestroyShift, useListShifts
} from '../../api';
import ButtonField from '../FormFields/ButtonField';

export default function DeleteShift() {
    const { data: shiftData, refetch } = useListShifts();
    const { mutateAsync, isSuccess, error, failureReason } = useDestroyShift();
    // const { data: empData } = useListEmployees();
    const shiftDetails = shiftData?.data;

    interface DeleteShiftProps {
        id: string;
    }

    const DeleteShiftInitialValue = {
        id: '',
    };

    const handleSubmit = async (values: DeleteShiftProps) => {
        // if (
        //     empData?.data.some((emp) => emp.shift_id?.toString() === values.id)
        // ) {
        //     toast.error('The Shift is assigned to some employee/s');
        // }
        const resp = await mutateAsync({ id: values.id.toString() });
        if(resp.status===204) {
            toast.success(`Shift deleted`);
        }
    };

    useEffect(() => {
        if (isSuccess) 
            refetch();
        else if(error){
            toast.error(`The Shift is assigned to some employee/s`);
        }
    }, [error, failureReason, isSuccess, refetch]);

    return (
        <div className="flex flex-col px-10">
            <h2 className="py-4 border-x-zxblue-from text-transparent font-semibold md:text-xl lg:text-2xl bg-clip-text bg-gradient-to-t from-zxheader-from to-zxheader-to">
                <span className="border-l-2 border-zxsub-to pl-1">
                    Delete Shift
                </span>
            </h2>
            <Formik
                initialValues={DeleteShiftInitialValue}
                onSubmit={handleSubmit}
            >
                {({ handleChange, submitForm, resetForm }) => (
                    <form className="flex space-x-5">
                        <div className="flex flex-col">
                            <label className="pl-0.5 border-zxblue-to">
                                Delete Shift{' '}
                                <span className="text-zxerror-from">*</span>
                            </label>
                            <select
                                name="id"
                                id="id"
                                className="w-56 h-8 mb-4 border pl-2 py-1 rounded-md focus:outline-none border-zxblue-to"
                                onChange={handleChange}
                            >
                                <option defaultValue={undefined}>Select</option>
                                {shiftDetails?.map((shiftID: Shift) => (
                                    <option key={shiftID.id} value={shiftID.id}>
                                        {shiftID.shift_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <ButtonField
                            name="Delete"
                            className="mt-3"
                            onClick={() => {
                                submitForm().then(() => {
                                    resetForm();
                                });
                            }}
                        />
                    </form>
                )}
            </Formik>
        </div>
    );
}
