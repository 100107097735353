/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */

export type ProfileReligion = typeof ProfileReligion[keyof typeof ProfileReligion];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProfileReligion = {
  Hindu: 'Hindu',
  Muslim: 'Muslim',
  Christian: 'Christian',
  Sikh: 'Sikh',
  Jain: 'Jain',
  Other: 'Other',
} as const;
