import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { MSViewer } from '../../api';
import MembersAttendanceList from './MembersAttendanceList';

function TLList(props: { viewerData: MSViewer }) {
    const tlId = props.viewerData.MSemployee_details?.id;
    const navigate = useNavigate();

    return (
        <div className="px-5 pt-5">
            <div className="flex">
                <BiArrowBack
                    className="mt-1.5 mr-2.5 cursor-pointer"
                    size="2.5%"
                    style={{ color: '#4A65EE' }}
                    onClick={() => navigate(-1)}
                />
                <h2 className="pt-2 mb-4 border-x-zxblue-from text-transparent font-semibold text-xl bg-clip-text bg-gradient-to-t from-zxheader-from to-zxheader-to">
                    <span className="border-l-4 pt-1 border-zxsub-to pl-1">
                        Team Members
                    </span>
                </h2>
            </div>
            <div className="border rounded-lg border-zxblue-to mx-[1%]">
                {tlId ? <MembersAttendanceList tlId={tlId} /> : null}
            </div>
        </div>
    );
}

export default TLList;
