/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */

export type LeaveSession1 = typeof LeaveSession1[keyof typeof LeaveSession1];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LeaveSession1 = {
  S1: 'S1',
  S2: 'S2',
} as const;
