import {
    Link,
    LinkProps,
    useLocation,
    useMatch,
    useResolvedPath
} from 'react-router-dom';

import { useMemo } from 'react';
import { IconContext } from 'react-icons';
import {
    ADMINLEAVEAPPLY,
    COMPOFF,
    HISTORY,
    HOLIDAYS,
    LEAVE,
    LEAVEBALANCES,
    LEAVEWITHDRAWAL,
    OPHOL,
    PENDAPP,
    TLLEAVEAPPROVAL,
    TLLEAVEWITHDRAWAL,
    VIEWLEAVEBALANCE
} from '../constants/router';

function LeaveSidebar(props: { isViewerTL: boolean; isViewerAdmin: boolean }) {
    const value = useMemo(() => ({ color: 'gray', size: '1.2em' }), []);
    const location = useLocation();

    return (
        <IconContext.Provider value={value}>
            <div className="flex flex-row gap-x-6">
                <div className="lg:w-[100%] pr-16 lg:h-fit md:w-[90%] md:h-fit shadow-2xl  bg-white bg-opacity-60 backdrop-blur-x">
                    <div className="flex flex-row -ml-8 lg:mt-14 md:mt-14 md:ml-14 w-full">
                        <Link
                            to={props.isViewerAdmin ? ADMINLEAVEAPPLY : LEAVE}
                            className="flex flex-row "
                        >
                            <img
                                className="w-fit -ml-2"
                                alt=""
                                src="../img/leavesymb.svg"
                            />
                            <h1 className="ml-2 mt-2 font-semibold">Leave</h1>
                        </Link>
                        {location.pathname === LEAVE ? (
                            <div className="w-3 h-3 lg:mt-3 md:mt-2 rounded-full flex ml-24 bg-gradient-to-r from-zxbullet2-from to-zxbullet2-to" />
                        ) : null}
                    </div>
                    <div className="flex flex-col">
                        <hr className="lg:w-56 md:w-40 lg:mt-8 md:mt-8 place-self-center" />
                    </div>
                    <h1 className="font-normal text-sm lg:mt-2 lg:ml-14 md:ml-14 md:mt-2">
                        Menu
                    </h1>
                    <ul>
                        {props.isViewerTL || props.isViewerAdmin ? (
                            <>
                                <CustomLink to={TLLEAVEAPPROVAL}>
                                    <img
                                        className="float-left lg:ml-8 md:ml-8 lg:w-8 lg:h-8 lg:pb-2"
                                        alt=""
                                        src="../img/ta (1).svg"
                                    />{' '}
                                    <span className=" text-black md:text-sm">
                                        Teams Approval
                                    </span>
                                </CustomLink>
                                <CustomLink to={TLLEAVEWITHDRAWAL}>
                                    <img
                                        className="float-left lg:ml-8 md:ml-8 lg:w-8 lg:h-8 lg:pb-2"
                                        alt=""
                                        src="../img/tw.svg"
                                    />{' '}
                                    <span className=" text-black md:text-sm">
                                        Teams Withdrawal
                                    </span>
                                </CustomLink>
                            </>
                        ) : null}

                        <CustomLink to={LEAVEWITHDRAWAL}>
                            <img
                                className="float-left lg:ml-8 md:ml-8 lg:w-8 lg:h-8 lg:pb-2"
                                alt=""
                                src="../img/leavewith.svg"
                            />{' '}
                            <span className=" text-black md:text-sm">
                                Leave Withdrawal
                            </span>
                        </CustomLink>
                        {!props.isViewerAdmin ? (
                            <CustomLink to={COMPOFF}>
                                <img
                                    className="float-left lg:ml-8 md:ml-8 lg:w-8 lg:h-8 lg:pb-2"
                                    alt=""
                                    src="../img/compoff.svg"
                                />{' '}
                                <span className=" text-black md:text-sm">
                                    {' '}
                                    Apply Comp Off{' '}
                                </span>{' '}
                            </CustomLink>
                        ) : null}

                        <CustomLink to={OPHOL}>
                            <img
                                className="float-left lg:ml-8 md:ml-7 lg:w-8 lg:h-8 lg:pb-2"
                                alt=""
                                src="../img/OpHol.svg"
                            />{' '}
                            <span className=" text-black md:text-sm">
                                Optional Holidays
                            </span>{' '}
                        </CustomLink>
                        {props.isViewerAdmin ? (
                            <CustomLink to={HOLIDAYS}>
                                <img
                                    className="float-left lg:ml-7 md:ml-7 lg:w-8 lg:h-8 lg:pb-2"
                                    alt=""
                                    src="../img/HolCal.svg"
                                />{' '}
                                <span className=" text-black md:text-sm">
                                    {' '}
                                    Holiday Calendar
                                </span>{' '}
                            </CustomLink>
                        ) : null}

                        {props.isViewerAdmin ? (
                            <CustomLink to={VIEWLEAVEBALANCE}>
                                <img
                                    className="float-left lg:ml-8 md:ml-7 lg:w-8 lg:h-8 lg:pb-2 "
                                    alt=""
                                    src="../img/LeaveBal.svg"
                                />{' '}
                                <span className=" text-black md:text-sm md:ml-1">
                                    {' '}
                                    Leave Balance
                                </span>{' '}
                            </CustomLink>
                        ) : (
                            <CustomLink to={LEAVEBALANCES}>
                                <img
                                    className="float-left lg:ml-8 md:ml-7 lg:w-8 lg:h-8 lg:pb-2 "
                                    alt=""
                                    src="../img/LeaveBal.svg"
                                />{' '}
                                <span className=" text-black md:text-sm md:ml-1">
                                    {' '}
                                    Leave Balance
                                </span>{' '}
                            </CustomLink>
                        )}

                        <CustomLink to={PENDAPP}>
                            <img
                                className="float-left lg:ml-8 md:ml-7 lg:w-8 lg:h-8 lg:pb-2 "
                                alt=""
                                src="../img/PendApp.svg"
                            />{' '}
                            <span className=" text-black md:text-sm md:ml-1">
                                {' '}
                                Pending Application
                            </span>{' '}
                        </CustomLink>
                        <CustomLink to={HISTORY}>
                            <img
                                className="float-left lg:ml-8 md:ml-7 lg:w-8 lg:h-8 lg:pb-2 "
                                alt=""
                                src="../img/History.svg"
                            />{' '}
                            <span className=" text-black md:text-sm md:ml-1">
                                {' '}
                                History
                            </span>{' '}
                        </CustomLink>
                    </ul>
                </div>
            </div>
        </IconContext.Provider>
    );
}
function CustomLink({ to, children, ...props }: LinkProps) {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true });

    return (
        <Link to={to} {...props} className="flex">
            <li
                className={
                    isActive
                        ? '  text-black py-4 w-60 pl-[1.3rem]'
                        : 'py-4 pl-[1.3rem] text-white'
                }
            >
                {children}
            </li>
            {isActive ? (
                <div className="w-3 h-3 lg:mt-5 md:mt-4 rounded-full bg-gradient-to-r from-zxbullet2-from to-zxbullet2-to" />
            ) : null}
        </Link>
    );
}
export default LeaveSidebar;
