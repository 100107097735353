import { useListDesignations } from '../../api';
import CreateDesignation from './CreateDesignation';
import DeleteDesignation from './DeleteDesignation';

export default function Designation() {
    const { data: desgnData, refetch } = useListDesignations();

    return (
        <div className="w-screen h-screen box-border overflow-hidden bg-zxbg">
            <div className="p-[10%] h-full m-auto">
                <div className="shadow-2xl rounded-2xl border-t-2">
                    <CreateDesignation
                        desgnData={desgnData?.data}
                        refetch={refetch}
                    />
                    <div className="relative mt-5 mx-auto w-[90%] border-b border-3 border-gray-400" />
                    <DeleteDesignation
                        desgnData={desgnData?.data}
                        refetch={refetch}
                    />
                </div>
            </div>
        </div>
    );
}
