import PwdForm from './Forms/PwdForm';

function PassResetFrame() {
    return (
        <div className="relative top-8 z-0 h-[40%] shadow-2xl backdrop-blur-xl md:w-[45%] md:mb-20 md:pb-5 lg:w-1/4 rounded">
            <h2 className="py-8 text-center text-transparent font-semibold text-2xl bg-clip-text bg-gradient-to-r from-zxheader-from to-zxheader-to">
                Password Reset
            </h2>

            <h1 className="text-xs text-center md:mb-8 top-8  ">
                Enter your email and reset link will be send to you
            </h1>

            <PwdForm />
        </div>
    );
}

export default PassResetFrame;
