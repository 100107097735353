import { Formik } from 'formik';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Designation, useDestroyDesignation } from '../../api';
import ButtonField from '../FormFields/ButtonField';

export default function DeleteDesignation(props: {
    desgnData: Designation[] | undefined;
    refetch: any;
}) {
    const { mutateAsync, isSuccess } = useDestroyDesignation();
    interface DeleteDesgnProps {
        id: string;
    }

    const DeleteDesgnInitialValues = {
        id: '',
    };

    const handleSubmit = async (values: DeleteDesgnProps) => {
        await mutateAsync({ id: values.id.toString() });
        toast.success(`Designation Deleted`);
    };

    useEffect(() => {
        if (isSuccess) props.refetch();
    }, [isSuccess, props]);

    return (
        <div className="flex flex-col px-10">
            <h2 className="py-4 border-x-zxblue-from text-transparent font-semibold md:text-xl lg:text-2xl bg-clip-text bg-gradient-to-t from-zxheader-from to-zxheader-to">
                <span className="border-l-2 border-zxsub-to pl-1">
                    Delete Designation
                </span>
            </h2>
            <Formik
                initialValues={DeleteDesgnInitialValues}
                onSubmit={handleSubmit}
            >
                {({ handleChange, submitForm, resetForm }) => (
                    <form className="flex flex-row space-x-14">
                        <div className="flex flex-col">
                            <label className="pl-0.5 border-zxblue-to">
                                Delete Designation
                            </label>
                            <select
                                name="id"
                                id="id"
                                className="w-48 h-8 mb-4 border px-1 py-1 rounded-md focus:outline-none border-zxblue-to text-sm"
                                onChange={handleChange}
                            >
                                <option defaultValue={undefined}>Select</option>
                                {props.desgnData?.map(
                                    (desgnDetails: Designation) => (
                                        <option
                                            key={desgnDetails.id}
                                            value={desgnDetails.id}
                                        >
                                            {desgnDetails.name}
                                        </option>
                                    )
                                )}
                            </select>
                        </div>
                        <ButtonField
                            name="Delete"
                            className="mt-[2%]"
                            onClick={() => {
                                submitForm().then(() => {
                                    resetForm();
                                });
                            }}
                        />
                    </form>
                )}
            </Formik>
        </div>
    );
}
