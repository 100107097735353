import { Formik } from 'formik';
import * as Yup from 'yup';
import { Address, useRetrieveMSUser, useUpdateAddress } from '../../api';
import ButtonField from '../FormFields/ButtonField';
import FormikField from '../FormFields/FormikField';

import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import SectionHeader from './SectionHeader';
import AddressInfoSection from './Sections/AddressInfoSection';

export default function AddressInformationUpdate(props: { id: string }) {
    const { mutateAsync, isSuccess } = useUpdateAddress();
    const [form, setForm] = useState(false);
    const { data: usersData, refetch } = useRetrieveMSUser(props.id);
    const addressDetails =
        usersData?.data.MSemployee_details?.profile_details?.address_details;
    const newAdd1 = addressDetails ? addressDetails[0].address_line_1 : '';
    const newAdd2 = addressDetails ? addressDetails[0].address_line_2 : '';
    const newCity = addressDetails ? addressDetails[0].city : '';
    const newState = addressDetails ? addressDetails[0].state : '';
    const newPincode = addressDetails ? addressDetails[0].pincode : Number('');
    const AddressInformationInitialValues = {
        address_line_1: newAdd1,
        address_line_2: newAdd2,
        city: newCity,
        state: newState,
        pincode: newPincode,
    };
    const AddressInformationValidationSchema = Yup.object({
        address_line_1: Yup.string()
            .max(23, 'Continue in line 2')
            .required('Required'),
        address_line_2: Yup.string()
            .max(23, 'Max characters of 23 allowed')
            .required('Required'),
        city: Yup.string().max(20, 'Max 20 characters').required('Required'),
        state: Yup.string().max(20, 'Max 20 characters').required('Required'),
        pincode: Yup.number()
            .typeError('Only numbers are allowed')
            .max(999999, '6 digits')
            .min(100000, '6 digits')
            .required('Required'),
    });

    const handleSubmit = async (values: Address) => {
        if (addressDetails !== undefined || addressDetails !== null) {
            const updatedVal = {
                ...values,
            };
            await mutateAsync({
                data: updatedVal,
                id: String(addressDetails ? addressDetails[0]?.id : undefined),
            });
        }
    };
    useEffect(() => {
        if (isSuccess) {
            refetch();
            toast.success('Address Information Updated');
            setForm(false);
        }
    }, [isSuccess, refetch, setForm]);

    return form ? (
        <>
            <SectionHeader
                name="Address Information"
                form={form}
                setForm={setForm}
                employeeDetails={addressDetails}
            />
            <Formik
                initialValues={AddressInformationInitialValues}
                validationSchema={AddressInformationValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ touched, submitForm }) => (
                    <form className="grid grid-flow-row gap-x-4 gap-y-2 md:grid-cols-3 lg:grid-cols-4 px-7">
                        <FormikField
                            label="Line 1"
                            name="address_line_1"
                            type="text"
                            touched={touched.address_line_1}
                            className="col-span-2"
                        />
                        <FormikField
                            label="Line 2"
                            name="address_line_2"
                            type="text"
                            touched={touched.address_line_2}
                            className="col-span-2"
                        />
                        <FormikField
                            label="City"
                            name="city"
                            type="text"
                            touched={touched.city}
                            className="md:row-start-3"
                        />
                        <FormikField
                            label="State"
                            name="state"
                            type="text"
                            touched={touched.state}
                            className="md:row-start-3"
                        />
                        <FormikField
                            label="Pincode"
                            name="pincode"
                            type="text"
                            touched={touched.pincode}
                            className="md:row-start-3"
                        />
                        <div className="row-start-4 lg:col-start-3 md:col-start-2 col-span-2 mx-auto mr-2">
                            <ButtonField
                                name="Cancel"
                                onClick={() => setForm(false)}
                            />
                            <ButtonField
                                name="Submit"
                                onClick={() => {
                                    submitForm();
                                }}
                            />
                        </div>
                    </form>
                )}
            </Formik>
        </>
    ) : (
        <>
            <SectionHeader
                name="Address Information"
                form={form}
                setForm={setForm}
                employeeDetails={addressDetails}
            />
            <AddressInfoSection id={props.id} />
        </>
    );
}
