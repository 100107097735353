import { useRetrieveDepartment, useRetrieveEmployee, useRetrieveMSUser } from '../../../api';
import ProfileUpdateField from '../ProfileUpdateField';

export default function DeptInfoSection(props: { id: string }) {
    const { data: usersData } = useRetrieveMSUser(props.id);
    const departmentID = usersData?.data.MSemployee_details?.department_id;
    const { data: departmentName } = useRetrieveDepartment(
        departmentID ? departmentID.toString() : ''
    );
    const { data: managerData } = useRetrieveEmployee(
        departmentName?.data.manager_id
            ? departmentName.data.manager_id.toString()
            : ''
    );
    return (
        <div className="grid grid-flow-row gap-y-4 gap-x-2 md:grid-cols-3 lg:grid-cols-4 px-7">
            <ProfileUpdateField
                label="Name"
                value={
                    departmentName?.data.name ? departmentName.data.name : '-'
                }
            />
            <ProfileUpdateField
                label="Manager Name"
                value={
                    managerData?.data.full_name
                        ? managerData?.data.full_name
                        : '-'
                }
            />
            <ProfileUpdateField
                label="Manager ID"
                value={
                    managerData?.data.employee_number
                        ? managerData?.data.employee_number
                        : '-'
                }
            />
        </div>
    );
}
