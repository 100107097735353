import { useListDepartments } from '../../api';
import CreateDepartment from './CreateDepartment';
import DeleteDepartment from './DeleteDepartment';
import DepartmentTable from './DepartmentTable';

export default function Department() {
    const { data: deptData, refetch } = useListDepartments();

    return (
        <div className="w-screen h-screen box-border bg-zxbg overflow-auto">
            <div className="p-10">
                <div className="shadow-2xl rounded-2xl border-t-2">
                    <CreateDepartment
                        deptData={deptData?.data}
                        refetch={refetch}
                    />
                    <div className="relative mt-5 mx-auto w-[90%] border-b border-3 border-gray-400" />
                    <DeleteDepartment
                        deptData={deptData?.data}
                        refetch={refetch}
                    />
                    <div className="relative mt-5 mx-auto w-[90%] border-b border-3 border-gray-400" />
                    <DepartmentTable />
                </div>
            </div>
        </div>
    );
}
