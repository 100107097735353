/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */

export type EmployeeGrade = typeof EmployeeGrade[keyof typeof EmployeeGrade];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EmployeeGrade = {
  Junior: 'Junior',
  Senior: 'Senior',
  Team_Leader: 'Team Leader',
  Manager: 'Manager',
  Director: 'Director',
} as const;
