/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */

export type EmployeeProbationPeriod = typeof EmployeeProbationPeriod[keyof typeof EmployeeProbationPeriod];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EmployeeProbationPeriod = {
  '3': '3',
  '6': '6',
} as const;
