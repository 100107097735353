import LoginForm from './Forms/LoginForm';

function LoginFrame() {
    return (
        <div className="relative top-8 z-0 lg:h-[40%] md:h-[40%] shadow-2xl backdrop-blur-xl md:w-[40%] md:mb-20 md:pb-5 lg:w-1/4 rounded">
            <div className="flex flex-col">
                <img
                    className="pt-4 place-self-center"
                    src="./img/logo.png"
                    alt=""
                />
            </div>
            <LoginForm />
        </div>
    );
}

export default LoginFrame;
