import AddressInformationUpdate from '../ProfileUpdate/AddressInformationUpdate';
import BankInformationUpdate from '../ProfileUpdate/BankInformationUpdate';
import EducationInformationUpdate from '../ProfileUpdate/EducationInformationUpdate';
import ProfileInformationUpdate from '../ProfileUpdate/ProfileInformationUpdate';
import AcccessoriesInfoSection from '../ProfileUpdate/Sections/AccessoriesInfoSection';
import DeptInfoSection from '../ProfileUpdate/Sections/DeptInfoSection';
import EmployeeInfoSection from '../ProfileUpdate/Sections/EmployeeInfoSection';
import LeaveInfoSection from '../ProfileUpdate/Sections/LeaveInfoSection';
import MsViewerSectionHeader from './MsViewerSectionHeader';

export default function MsViewerInfo(props: { id: string }) {
    return (
        <div className="px-16">
            <MsViewerSectionHeader name="Employee Information" />
            <EmployeeInfoSection id={props.id} />
            <div className="relative mt-5 h-0.5 bg-gradient-to-r from-zxheader-to to-zxheader-from opacity-50 rounded-xl" />
            <ProfileInformationUpdate id={props.id} />
            <div className="relative mt-5 h-0.5 bg-gradient-to-r from-zxheader-to to-zxheader-from opacity-50 rounded-xl" />
            {/* <MsViewerSectionHeader name="Address Information" /> */}
            <AddressInformationUpdate id={props.id} />
            <div className="relative mt-5 h-0.5 bg-gradient-to-r from-zxheader-to to-zxheader-from opacity-50 rounded-xl" />
            {/* <MsViewerSectionHeader name="Bank Information" /> */}
            <BankInformationUpdate id={props.id} />
            <div className="relative mt-5 h-0.5 bg-gradient-to-r from-zxheader-to to-zxheader-from opacity-50 rounded-xl" />
            <MsViewerSectionHeader name="Leave Information" />
            <LeaveInfoSection id={props.id} />
            <div className="relative mt-5 h-0.5 bg-gradient-to-r from-zxheader-to to-zxheader-from opacity-50 rounded-xl" />
            <MsViewerSectionHeader name="Department Information" />
            <DeptInfoSection id={props.id} />
            <div className="relative mt-5 h-0.5 bg-gradient-to-r from-zxheader-to to-zxheader-from opacity-50 rounded-xl" />
            {/* <MsViewerSectionHeader name="Education Information" /> */}
            <EducationInformationUpdate id={props.id} />
            <div className="relative mt-5 h-0.5 bg-gradient-to-r from-zxheader-to to-zxheader-from opacity-50 rounded-xl" />
            <MsViewerSectionHeader name="Employee Accessories" />
            <AcccessoriesInfoSection id={props.id} />
        </div>
    );
}
