import { useEffect } from 'react';
import { Leave, useListMSViewers, useListTLLeaves } from '../api';
import LeaveSidebar from '../components/LeaveSidebar';
import TeamLeaveWithForm from '../components/TeamLeaveWithForm';
import ZXHeader from '../components/ZXHeader';

function TlleaveWithdrawal(props: {
    isViewerTL: boolean;
    isViewerAdmin: boolean;
}) {
    const {
        data: usersData,
        isSuccess: userSuccess,
        isLoading: userLoading,
    } = useListMSViewers();

    const {
        data: leavesData,
        isSuccess,
        refetch,
    } = useListTLLeaves({
        axios: {
            params: {
                reporting_to: usersData?.data[0].MSemployee_details?.id,
            },
        },
    });
    useEffect(() => {
        if (usersData !== undefined) refetch();
    }, [usersData]);
    const leaves = leavesData?.data;
    return (
        <div className="lg:pl-12 md:pl-6 py-6 lg:w-screen h-screen overflow-auto">
            <ZXHeader />
            <div className="flex flex-row">
                <LeaveSidebar
                    isViewerTL={props.isViewerTL}
                    isViewerAdmin={props.isViewerAdmin}
                />
                <div className=" md:mr-12 lg:ml-7 lg:min-h-screen shadow-2xl md:w-screen md:min-h-screen  bg-white bg-opacity-60 backdrop-blur-x">
                    <h1 className="pt-2 flex-none border-x-zxblue-from text-transparent  lg:mt-7 font-semibold lg:text-2xl md:text-xl bg-clip-text bg-gradient-to-t from-zxheader-from to-zxheader-to md:mt-6">
                        <span className="border-l-4 pt-1 md:ml-8 border-zxsub-to lg:ml-8 pl-2">
                            {' '}
                            Teams Withdrawal{' '}
                        </span>
                    </h1>
                    {leaves && isSuccess
                        ? leaves.map((leave: Leave) =>
                              leave.leave_status === 'WITH' ? (
                                  <TeamLeaveWithForm
                                      key={leave.id}
                                      leavesData={leave}
                                  />
                              ) : null
                          )
                        : null}
                </div>
            </div>
        </div>
    );
}

export default TlleaveWithdrawal;
