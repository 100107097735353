import { EmpAttendance, useRetrieveEmpAttendance } from '../../api';

import { AxiosResponse } from 'axios';
import moment from 'moment';
import { useEffect } from 'react';
import { getDaysInMonth } from '../../constants/functions';

interface Props {
    empId: number;
    month: number;
    year: number;
}

function EmpMonthlyAtt(props: Props) {
    const { data: attendanceData, refetch, isFetchedAfterMount } = useRetrieveEmpAttendance<
        AxiosResponse<EmpAttendance[]>
    >(props.empId.toString(), {
        axios: {
            params: {
                month: props.month,
                year: props.year,
            },
        },
    });

    const days = getDaysInMonth(props.month - 1, props.year);
    const attData = attendanceData?.data;
    let counter = 0;
    const count = 0;
    
    useEffect(() => {
        refetch();
    }, [props.month, props.year, refetch]);
    
    const disp = days.map((day) =>
        attData &&
        attData[counter] &&
        day.toLocaleDateString("en-US", {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
        }).replaceAll('/', '-') ===
            attData[counter].date ? (
            <td
                key={day.getDate()}
                className={
                    moment(attData[counter].date).format('dddd') === 'Sunday'
                        ? 'font-extralight text-gray-400'
                        : attData[counter].attendance_status === 'A'
                        ? 'font-semibold text-transparent bg-clip-text bg-gradient-to-l from-zxerror-from to-zxerror-to'
                        : ''
                }
            >
                {
                    attData[
                        counter + 1 === attData.length ? counter : counter++
                    ].attendance_status
                }
            </td>
        ) : (
            <td key={day.getDate()} className="px-1">
                --
            </td>
        )
    );

    return (
        <>
            {disp}
            <td />
        </>
    );
}

export default EmpMonthlyAtt;
