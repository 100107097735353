import { BiBook, BiCalendar } from 'react-icons/bi';
import {
    BsFillPersonFill,
    BsGear,
    BsLaptop,
    BsLaptopFill,
    BsPerson
} from 'react-icons/bs';
import {
    ACCESSORY,
    ADMINLEAVEAPPLY,
    ATTENDANCELIST,
    DEPARTMENT,
    DESIGNATION,
    DOCUMENT,
    EMPLOYEES,
    HELPDESK,
    HOME,
    LEAVE,
    MY_PROFILE,
    PROFILE,
    ROLES,
    SALARY_PAGE,
    SETTINGS,
    SHIFTS
} from '../constants/router';

import { useEffect, useMemo, useState } from 'react';
import { AiFillIdcard, AiOutlineIdcard } from 'react-icons/ai';
import { FaCalendarAlt, FaCompass } from 'react-icons/fa';
import {
    IoAccessibilityOutline,
    IoCallOutline,
    IoDocumentTextOutline
} from 'react-icons/io5';
import {
    MdAccountTree,
    MdHomeFilled,
    MdOutlineAccessTime,
    MdOutlineAccessTimeFilled,
    MdOutlineAccountTree
} from 'react-icons/md';
import { RiBook2Fill } from 'react-icons/ri';

import {
    Link,
    LinkProps,
    Outlet,
    useLocation,
    useMatch,
    useNavigate,
    useResolvedPath
} from 'react-router-dom';

import Axios from 'axios';
import classnames from 'classnames';
import { IconContext } from 'react-icons';
import { CgHomeAlt } from 'react-icons/cg';
import { FiCompass } from 'react-icons/fi';
import { VscSignOut } from 'react-icons/vsc';
import { ToastContainer } from 'react-toastify';
import { signOut } from 'supertokens-web-js/recipe/thirdparty';
import { useListMSViewers } from '../api';

export default function Nav(props: { isViewerAdmin: boolean }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [imageUrl, setImageUrl] = useState('');
    const [isPeopleClicked, setIsPeopleClicked] = useState(false);

    const {
        data: usersData,
        isSuccess: userSuccess,
        isLoading: userLoading,
    } = useListMSViewers();

    const userId = usersData?.data[0].user_id;
    const token = usersData?.data[0].provider_token;
    const profileDetails =
        usersData?.data[0].MSemployee_details?.profile_details;

    useEffect(() => {
        if (userSuccess) {
            Axios.get(
                `https://graph.microsoft.com/v1.0/users/${userId}/photo/$value`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                    responseType: 'blob',
                }
            )
                .then((o) => {
                    const url = window.URL || window.webkitURL;
                    const blobUrl = url.createObjectURL(o.data);
                    setImageUrl(blobUrl);
                })
                .catch(() => {
                    setImageUrl('../img/zedexinfologo.svg');
                });
        }
    }, [token, userId, userSuccess]);

    if (
        userSuccess &&
        (profileDetails === null || profileDetails === undefined)
    ) {
        navigate(PROFILE);
    }

    async function onLogout() {
        await signOut();
        window.location.href = '/';
    }

    const value = useMemo(
        () => ({
            color: 'gray',
            size: '10%',
        }),
        []
    );

    useEffect(() => {
        if (
            location.pathname === SHIFTS ||
            location.pathname === ACCESSORY ||
            location.pathname === DEPARTMENT ||
            location.pathname === DESIGNATION
        ) {
            setIsPeopleClicked(true);
        }
    }, [location.pathname]);

    return (
        <IconContext.Provider value={value}>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <aside className="lg:w-[22%] md:w-[20%] lg:rounded-tr-3xl md:rounded-tr-2xl bg-gradient-to-b from-slate-800 to-slate-700 flex flex-col items-start min-h-screen">
                <div className="text-white pl-[16%] pt-[10%] pb-[3%] w-full">
                    <Link
                        to={`${MY_PROFILE}/${usersData?.data[0].id}`}
                        className="flex flex-row gap-4"
                        onClick={() => setIsPeopleClicked(false)}
                    >
                        <img
                            src={imageUrl}
                            alt=""
                            className="lg:w-14 lg:h-14 md:w-12 md:h-12 rounded-full ring ring-zxblue-to"
                        />
                        {userLoading ? (
                            <span className="text-center">Loading.....</span>
                        ) : userSuccess ? (
                            <span className="pt-[2%] w-full">
                                {
                                    usersData.data[0].MSemployee_details
                                        ?.full_name
                                }
                                <br />
                                <span className="text-sm">
                                    ID:
                                    <span className="text-gray-400 ml-[3%]">
                                        {
                                            usersData.data[0].MSemployee_details
                                                ?.employee_number
                                        }
                                    </span>
                                </span>
                            </span>
                        ) : (
                            <span className="text-center">Failed to load!</span>
                        )}
                    </Link>
                </div>
                <div className="pl-[15%] pt-[8%] w-full flex flex-col space-y-[2%] items-start">
                    <CustomLink
                        to={HOME}
                        onClick={() => setIsPeopleClicked(false)}
                    >
                        {location.pathname !== HOME ? (
                            <CgHomeAlt className="float-left" />
                        ) : (
                            <MdHomeFilled className="float-left" />
                        )}
                        <span className="ml-[4%]">Home</span>
                    </CustomLink>
                    <CustomLink
                        to={SALARY_PAGE}
                        onClick={() => setIsPeopleClicked(false)}
                    >
                        {location.pathname !== SALARY_PAGE ? (
                            <FiCompass className="float-left" />
                        ) : (
                            <FaCompass className="float-left" />
                        )}
                        <span className="ml-[4%]">Salary</span>
                    </CustomLink>
                    <CustomLink
                        to={props.isViewerAdmin ? ADMINLEAVEAPPLY : LEAVE}
                        onClick={() => setIsPeopleClicked(false)}
                    >
                        {location.pathname !== LEAVE ? (
                            <BiBook className="float-left" />
                        ) : (
                            <RiBook2Fill className="float-left" />
                        )}
                        <span className="ml-[4%]">Leave</span>
                    </CustomLink>
                    <CustomLink
                        to={ATTENDANCELIST}
                        onClick={() => setIsPeopleClicked(false)}
                    >
                        {location.pathname !== ATTENDANCELIST ? (
                            <BiCalendar className="float-left" />
                        ) : (
                            <FaCalendarAlt className="float-left" />
                        )}
                        <span className="ml-[4%]">Attendence</span>
                    </CustomLink>
                    <CustomLink
                        to="/document"
                        onClick={() => setIsPeopleClicked(false)}
                    >
                        <IoDocumentTextOutline className="float-left" />
                        <span className="ml-[4%]">Document</span>
                    </CustomLink>
                    {props.isViewerAdmin ? (
                        isPeopleClicked ? (
                            <>
                                <CustomLink
                                    to={EMPLOYEES}
                                    onClick={() => {
                                        setIsPeopleClicked((curr) => !curr);
                                    }}
                                >
                                    {location.pathname !== EMPLOYEES ? (
                                        <BsPerson className="float-left" />
                                    ) : (
                                        <BsFillPersonFill className="float-left" />
                                    )}
                                    <span className="ml-[4%]">People</span>
                                </CustomLink>
                                <div className="pl-[14%] w-full flex flex-col space-y-[2%] items-start">
                                    <CustomLink
                                        to={SHIFTS}
                                        className="text-gray-500"
                                    >
                                        {location.pathname !== SHIFTS ? (
                                            <MdOutlineAccessTime
                                                className="float-left mt-0.5"
                                                size="12%"
                                            />
                                        ) : (
                                            <MdOutlineAccessTimeFilled
                                                className="float-left mt-0.5"
                                                size="12%"
                                            />
                                        )}
                                        <span className="ml-[4%]">Shift</span>
                                    </CustomLink>
                                    <CustomLink
                                        to={ACCESSORY}
                                        className="text-gray-500"
                                    >
                                        {location.pathname === ACCESSORY ? (
                                            <BsLaptopFill className="float-left mt-1" />
                                        ) : (
                                            <BsLaptop className="float-left mt-1" />
                                        )}
                                        <span className="ml-[4%]">
                                            Accessories
                                        </span>
                                    </CustomLink>
                                    <CustomLink
                                        to={DEPARTMENT}
                                        className="text-gray-500"
                                    >
                                        {location.pathname === DEPARTMENT ? (
                                            <MdAccountTree
                                                className="float-left mt-0.5"
                                                size="13%"
                                            />
                                        ) : (
                                            <MdOutlineAccountTree
                                                className="float-left mt-0.5"
                                                size="13%"
                                            />
                                        )}
                                        <span className="ml-[4%]">
                                            Department
                                        </span>
                                    </CustomLink>
                                    <CustomLink
                                        to={DESIGNATION}
                                        className="text-gray-500"
                                    >
                                        {location.pathname === DESIGNATION ? (
                                            <AiFillIdcard
                                                className="float-left mt-0.5"
                                                size="13%"
                                            />
                                        ) : (
                                            <AiOutlineIdcard
                                                className="float-left mt-0.5"
                                                size="13%"
                                            />
                                        )}
                                        <span className="ml-[4%]">
                                            Designation
                                        </span>
                                    </CustomLink>
                                    <CustomLink to={ROLES}>
                                        <IoAccessibilityOutline
                                            className="float-left mt-0.5"
                                            size="13%"
                                        />
                                        <span className="ml-[4%]">Roles</span>
                                    </CustomLink>
                                </div>
                            </>
                        ) : (
                            <CustomLink
                                to={EMPLOYEES}
                                onClick={() => {
                                    setIsPeopleClicked((curr) => !curr);
                                }}
                            >
                                {location.pathname !== EMPLOYEES ? (
                                    <BsPerson className="float-left" />
                                ) : (
                                    <BsFillPersonFill className="float-left" />
                                )}
                                <span className="ml-[4%]">People</span>
                            </CustomLink>
                        )
                    ) : null}
                    <CustomLink
                        to="/helpdesk"
                        onClick={() => setIsPeopleClicked(false)}
                    >
                        <IoCallOutline className="float-left mb-2" />
                        <span className="ml-[4%]">Help Desk</span>
                    </CustomLink>
                    <img
                        src="/img/rectangleline.png"
                        alt=""
                        className="w-25% pl-[10%] py-[5%]"
                    />
                    <CustomLink
                        to="/settings"
                        onClick={() => setIsPeopleClicked(false)}
                    >
                        <BsGear className="float-left" />
                        <span className="ml-[4%]">Settings</span>
                    </CustomLink>
                    <CustomLink to="/logout" onClick={() => onLogout()}>
                        <VscSignOut className="float-left" />
                        <span className="ml-[4%]">Logout</span>
                    </CustomLink>
                </div>
            </aside>
            <Outlet />
        </IconContext.Provider>
    );
}

function CustomLink({ to, children, ...props }: LinkProps) {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true });
    const location = useLocation();

    return (
        <div
            className={classnames(
                'pl-[10%] relative py-[7%] w-full h-full',
                isActive ? 'bg-zxbg rounded-l-full' : ''
            )}
        >
            <span
                className={classnames(
                    'absolute -h-[0.5%] bg-zxbg',
                    location.pathname === SETTINGS
                        ? 'before:bg-[#2D3B4E]'
                        : location.pathname === HELPDESK
                        ? 'before:bg-[#2B384C]'
                        : location.pathname === EMPLOYEES ||
                          location.pathname === SHIFTS ||
                          location.pathname === ACCESSORY ||
                          location.pathname === DEPARTMENT ||
                          location.pathname === DESIGNATION ||
                          location.pathname === DOCUMENT
                        ? 'before:bg-[#2A374B]'
                        : location.pathname === ATTENDANCELIST
                        ? 'before:bg-[#263546]'
                        : location.pathname === LEAVE ||
                          location.pathname === SALARY_PAGE
                        ? 'before:bg-[#243145]'
                        : 'before:bg-[#212D3F]',
                    isActive
                        ? 'block left-0 w-full h-[37%] -top-[37%] before:top-0 before:left-0 before:absolute before:w-full before:h-full before:rounded-br-full'
                        : 'hidden'
                )}
            />
            <Link to={to} {...props} className="w-full h-5.5 block">
                <span
                    className={classnames(
                        'font-medium text-[90%]',
                        isActive ? 'text-black' : 'text-white'
                    )}
                >
                    {children}
                </span>
            </Link>
            <span
                className={classnames(
                    'absolute -h-[0.5%] bg-zxbg',
                    location.pathname === SETTINGS
                        ? 'before:bg-[#303D51]'
                        : location.pathname === HELPDESK
                        ? 'before:bg-[#2E3B4F]'
                        : location.pathname === EMPLOYEES ||
                          location.pathname === SHIFTS ||
                          location.pathname === ACCESSORY ||
                          location.pathname === DEPARTMENT ||
                          location.pathname === DESIGNATION ||
                          location.pathname === DOCUMENT
                        ? 'before:bg-[#2A374B]'
                        : location.pathname === ATTENDANCELIST
                        ? 'before:bg-[#263546]'
                        : location.pathname === LEAVE ||
                          location.pathname === SALARY_PAGE
                        ? 'before:bg-[#243145]'
                        : 'before:bg-[#232F41]',
                    isActive
                        ? 'block left-0 w-full h-[37%] -bottom-[37%] before:bottom-0 before:left-0 before:absolute before:w-full before:h-full before:rounded-tr-full'
                        : 'hidden'
                )}
            />
        </div>
    );
}
