import { Employee } from '../../api';
import { getDaysInMonth } from '../../constants/functions';

interface EmpProps {
    empData: Employee;
    dateState: any;
}

function EmployeeInfo(props: EmpProps) {
    const month: number = props.dateState?.month;
    const year: number = props.dateState?.year;
    const days = getDaysInMonth(month - 1, year);
    return (
        <div className="mx-[17%] pt-8 justify-start w-auto">
            <img
                src="../../img/zedexinfologo.svg"
                className="mb-6 pl-8"
                alt="img"
            />
            <div className="flex flex-row">
                <div className="grid grid-cols-2 border-y border-r border-zxsub-to lg:text-sm md:text-xs w-full">
                    <div id="emp-info-grid" className="pt-6 font-medium">
                        Employee Name:
                    </div>
                    <div id="emp-info-grid" className="pt-6 font-normal">
                        {props.empData.full_name}
                    </div>
                    <div id="emp-info-grid" className="font-medium">
                        Joining Date:
                    </div>
                    <div id="emp-info-grid" className="font-normal">
                        {props.empData.joining_date}
                    </div>
                    <div id="emp-info-grid" className="font-medium">
                        Probabation Period:
                    </div>
                    <div id="emp-info-grid" className="font-normal">
                        {props.empData.probation_period} Months
                    </div>
                    <div id="emp-info-grid" className="font-medium">
                        PAN No:
                    </div>
                    <div id="emp-info-grid" className="font-normal">
                        {props.empData.bank_details?.PAN}
                    </div>
                    <div id="emp-info-grid" className="font-medium">
                        Contact No:
                    </div>
                    <div id="emp-info-grid" className="font-normal">
                        {props.empData.profile_details?.contact_no}
                    </div>
                </div>
                <div className="grid grid-cols-2 border-y border-zxsub-to lg:text-sm md:text-xs lg:w-[100%] w-full">
                    <div id="emp-info-grid" className="pt-6 font-medium">
                        Employee ID:
                    </div>
                    <div id="emp-info-grid" className="pt-6 font-normal">
                        {props.empData.id}
                    </div>
                    <div id="emp-info-grid" className="font-medium">
                        Current Month CAL Days:
                    </div>
                    <div id="emp-info-grid" className="font-normal">
                        {days.length}
                    </div>
                    <div id="emp-info-grid" className="font-medium">
                        Current Month Paid Days:
                    </div>
                    <div id="emp-info-grid" className="font-normal">
                        {days.length}
                    </div>
                    <div id="emp-info-grid" className="font-medium">
                        Status:
                    </div>
                    <div id="emp-info-grid" className="font-normal">
                        {props.empData.employee_status}
                    </div>
                    <div id="emp-info-grid" className="font-medium">
                        Location:
                    </div>
                    <div id="emp-info-grid" className="font-normal">
                        {props.empData.profile_details?.address_details
                            ? props.empData.profile_details?.address_details[0]
                                  .city
                            : 'N/A'}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmployeeInfo;
