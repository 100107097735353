/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  Profile
} from '../../schema'


/**
 * Get profile details
 */
export const retrieveProfile = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Profile>> => {
    return axios.get(
      `/profile/${id}/`,options
    );
  }


export const getRetrieveProfileQueryKey = (id: string,) => [`/profile/${id}/`];

    
export type RetrieveProfileQueryResult = NonNullable<Awaited<ReturnType<typeof retrieveProfile>>>
export type RetrieveProfileQueryError = AxiosError<unknown>

export const useRetrieveProfile = <TData = Awaited<ReturnType<typeof retrieveProfile>>, TError = AxiosError<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof retrieveProfile>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveProfileQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveProfile>>> = ({ signal }) => retrieveProfile(id, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof retrieveProfile>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * Update profile details
 */
export const updateProfile = (
    id: string,
    profile: Profile, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Profile>> => {
    return axios.put(
      `/profile/${id}/`,
      profile,options
    );
  }



    export type UpdateProfileMutationResult = NonNullable<Awaited<ReturnType<typeof updateProfile>>>
    export type UpdateProfileMutationBody = Profile
    export type UpdateProfileMutationError = AxiosError<unknown>

    export const useUpdateProfile = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProfile>>, TError,{id: string;data: Profile}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateProfile>>, {id: string;data: Profile}> = (props) => {
          const {id,data} = props ?? {};

          return  updateProfile(id,data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof updateProfile>>, TError, {id: string;data: Profile}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * Delete a profile
 */
export const destroyProfile = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    return axios.delete(
      `/profile/${id}/`,options
    );
  }



    export type DestroyProfileMutationResult = NonNullable<Awaited<ReturnType<typeof destroyProfile>>>
    
    export type DestroyProfileMutationError = AxiosError<unknown>

    export const useDestroyProfile = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof destroyProfile>>, TError,{id: string}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof destroyProfile>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  destroyProfile(id,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof destroyProfile>>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    