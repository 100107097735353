import {
    useRetrieveMSUser
} from '../../../api';
import ProfileUpdateField from '../ProfileUpdateField';

export default function BankInfoSection(props: { id: string }) {
    const { data: usersData } = useRetrieveMSUser(props.id);
    const bankDetails = usersData?.data.MSemployee_details?.bank_details;
    return (
        <div className="grid grid-flow-row gap-y-4 gap-x-2 md:grid-cols-3 lg:grid-cols-4 px-7">
                <ProfileUpdateField
                    label="Account No"
                    value={
                        bankDetails?.account_no ? bankDetails?.account_no : '-'
                    }
                />
                <ProfileUpdateField
                    label="Bank Name"
                    value={
                        bankDetails?.bank_name ? bankDetails?.bank_name : '-'
                    }
                />
                <ProfileUpdateField
                    label="Name (as per bank)"
                    value={
                        bankDetails?.customer_name
                            ? bankDetails?.customer_name
                            : '-'
                    }
                />
                <ProfileUpdateField
                    label="Branch"
                    value={bankDetails?.branch ? bankDetails?.branch : '-'}
                />
                <ProfileUpdateField
                    label="IFSC"
                    value={bankDetails?.IFSC ? bankDetails?.IFSC : '-'}
                />
                <ProfileUpdateField
                    label="PAN No"
                    value={bankDetails?.PAN ? bankDetails?.PAN : '-'}
                />
            </div>
    );
}
