import { TLLeave, useListMSViewers, useListTLLeaves } from '../api';

import { useEffect } from 'react';
import Emponleaverow from './Emponleaverow';

function Emponleavetable() {
    const {
        data: usersData,
        isSuccess: userSuccess,
        isLoading: userLoading,
    } = useListMSViewers();

    const emp_id = usersData?.data[0].MSemployee_details?.id;
    const {
        data: tlleavesData,
        isSuccess,
        isLoading,
        isFetchedAfterMount,
        refetch,
    } = useListTLLeaves({
        axios: {
            params: {
                reporting_to: emp_id,
            },
        },
    });
    const tlleaves_data = tlleavesData?.data;
    useEffect(() => {
        if (emp_id !== undefined) refetch();
    }, [emp_id]);
    return (
        <div className="h-72 overflow-y-scroll sm:overflow-x-scroll xl:overflow-hidden">
            <table className=" text-zxtext-1 md:text-sm lg:text-lg w-full  mt-6 ">
                <thead className="text-lg ">
                    <tr>
                        <th>No</th>
                        <th>Name</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Total Days</th>
                        <th>Type</th>
                    </tr>
                </thead>
                <tbody className="text-xs font-normal not-italic">
                    {isLoading ? (
                        <tr>
                            <td>Loading.......</td>
                        </tr>
                    ) : tlleaves_data && isFetchedAfterMount && isSuccess ? (
                        tlleaves_data.map((tlleave: TLLeave) => (
                            tlleave?.leave_status==='ACC'?
                            (<Emponleaverow key={tlleave.id} tlleave={tlleave} />)
                            :null
                        ))
                    ) : (
                        <tr>
                            <td>Not Success</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}
export default Emponleavetable;
