/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */

export type ProfileMaritalStatus = typeof ProfileMaritalStatus[keyof typeof ProfileMaritalStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProfileMaritalStatus = {
  Single: 'Single',
  Married: 'Married',
} as const;
