/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  TeamRegularization
} from '../../schema'


/**
 * List all regularizations of a team
 */
export const listTeamRegularizations = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TeamRegularization[]>> => {
    return axios.get(
      `/team-regularizations/`,options
    );
  }


export const getListTeamRegularizationsQueryKey = () => [`/team-regularizations/`];

    
export type ListTeamRegularizationsQueryResult = NonNullable<Awaited<ReturnType<typeof listTeamRegularizations>>>
export type ListTeamRegularizationsQueryError = AxiosError<unknown>

export const useListTeamRegularizations = <TData = Awaited<ReturnType<typeof listTeamRegularizations>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTeamRegularizations>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListTeamRegularizationsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listTeamRegularizations>>> = ({ signal }) => listTeamRegularizations({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof listTeamRegularizations>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

