import { useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import HolidaysSubHeader from '../components/HolidaysSubheader';
import HolidayTable from '../components/HolidayTable';

function HolidayList(props: { isViewerAdmin: boolean }) {
    const navigate = useNavigate();

    const [filters, setFilters] = useState({
        name: '',
        month: 0,
    });

    return (
        <div className="h-screen box-border bg-zxbg p-6 w-screen overflow-auto">
            <div className="flex w-full">
                <BiArrowBack
                    className="mt-2 ml-2.5 cursor-pointer"
                    size="2.5%"
                    style={{ color: '#4A65EE' }}
                    onClick={() => navigate(-1)}
                />
                <Header
                    name="Holiday List"
                    hol_name={filters.name}
                    set_hol={setFilters}
                />
            </div>
            <HolidaysSubHeader
                setFilters={setFilters}
                isViewerAdmin={props.isViewerAdmin}
            />
            <HolidayTable filters={filters} />
        </div>
    );
}
export default HolidayList;
