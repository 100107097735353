import 'react-toastify/dist/ReactToastify.css';
import './App.css';

import { Route, Routes, useNavigate } from 'react-router-dom';
import {
    ACCESSORY,
    ADMINLEAVEAPPLY,
    ATTENDANCELIST,
    ATTENDANCEMUSTER,
    BASIC_INFORMATION_PAGE,
    COMPOFF,
    DEPARTMENT,
    DESIGNATION,
    DOCUMENT,
    EMPLOYEEATTENDANCELIST,
    EMPLOYEES,
    EMPLOYEE_CREATION_PAGE,
    EMPLOYEE_POSITION_PAGE,
    HELPDESK,
    HISTORY,
    HOLCRUD,
    HOLIDAYS,
    HOME,
    LEAVE,
    LEAVEBALANCES,
    LEAVEWITHDRAWAL,
    LOADER,
    LOGIN,
    MSAUTH,
    MY_PROFILE,
    OPHOL,
    PASSRESET,
    PENDAPP,
    PROFILE,
    PROFILE_UPDATE,
    REGULARIZATION,
    ROLES,
    SALARYDASHBOARD,
    SALARY_PAGE,
    SETTINGS,
    SHIFTS,
    TEAMATTENDANCE,
    TEAMATTREGULARISE,
    TLLEAVEAPPROVAL,
    TLLEAVEWITHDRAWAL,
    VIEWLEAVEBALANCE
} from './constants/router';

import { useEffect } from 'react';
import SuperTokens from 'supertokens-web-js';
import Session from 'supertokens-web-js/recipe/session';
import ThirdParty from 'supertokens-web-js/recipe/thirdparty';
import Accessory from './components/Accessories/Accessory';
import AdminAssign from './components/AdminAssign';
import Department from './components/Department/Department';
import Designation from './components/Designation/Designation';
import Holcrud from './components/HolCrud';
import Loader from './components/Loader';
import MsAuth from './components/MsAuth';
import MsViewerProfile from './components/MsViewerProfile/MsViewerProfile';
import Step1Form from './components/MultiStepForm/Forms/Step1Form';
import Step2Form from './components/MultiStepForm/Forms/Step2Form';
import Nav from './components/Navigation';
import Profile from './components/Profile';
import Shifts from './components/Shifts/Shifts';
import { REACT_APP_BASE_URL } from './constant/environment';
import useAdmin from './hooks/useAdmin';
import useTL from './hooks/useTL';
import AdminLeave from './pages/AdminLeave';
import AdminLeaveBal from './pages/AdminLeaveBal';
import Attendence from './pages/Attendance';
import AttendanceMuster from './pages/AttendanceMuster';
import CompOff from './pages/CompOff';
import Dashboard from './pages/Dashboard';
import DocumentPage from './pages/Document';
import EmpAttendanceList from './pages/EmpAttendanceList';
import EmployeeCreation from './pages/EmployeeCreation';
import Employees from './pages/Employees';
import Helpdesk from './pages/Helpdesk';
import HolidayList from './pages/HolidayList';
import Leave from './pages/Leave';
import LeaveBalances from './pages/LeaveBalances';
import LeaveHistory from './pages/LeaveHistory';
import LeaveWithdrawal from './pages/LeaveWithdrawal';
import Login from './pages/LoginPage';
import OpHolList from './pages/OpHolList';
import PendingApp from './pages/PendingApp';
import ProfileUpdation from './pages/ProfileUpdate';
import Regularization from './pages/Regularization';
import ResetPass from './pages/ResetPass';
import Salary from './pages/Salary';
import SalaryDashboard from './pages/SalaryDashboard';
import Settings from './pages/Settings';
import TeamAttRegularise from './pages/TeamAttRegularise';
import Tlatt from './pages/Tlatt';
import Tlleaveapproval from './pages/Tlleaveapproval';
import TlleaveWithdrawal from './pages/Tlleavewithdrawal';

// eslint-disable-next-line import/no-unresolved

SuperTokens.init({
    appInfo: {
        appName: 'Sup',
        apiDomain: REACT_APP_BASE_URL || 'http://localhost:8000',
        // websiteDomain: 'http://localhost:3000',
        apiBasePath: '/auth',
        // websiteBasePath: '/auth',
    },
    recipeList: [ThirdParty.init(), Session.init()],
});

function App() {
    const navigate = useNavigate();

    const isViewerAdmin = useAdmin();
    const isViewerTL = useTL();

    useEffect(() => {
        const checkLoggedIn = async () => {
            if (await Session.doesSessionExist()) {
                // navigate(HOME);
            } else {
                navigate(LOGIN);
            }
        };
        checkLoggedIn();
    }, [navigate]);

    return (
        <div className="flex box-border bg-zxbg">
            <Routes>
                <Route
                    path={HOME}
                    element={<Nav isViewerAdmin={isViewerAdmin} />}
                >
                    <Route
                        path={HOME}
                        element={<Dashboard isViewerAdmin={isViewerAdmin} />}
                    />

                    {/* user profile employee side */}
                    <Route
                        path={`${MY_PROFILE}/:id`}
                        element={<MsViewerProfile />}
                    />

                    {/* Leave and sidebar menu */}
                    {isViewerAdmin ? (
                        <Route
                            path={ADMINLEAVEAPPLY}
                            element={
                                <AdminLeave
                                    isViewerTL={isViewerTL}
                                    isViewerAdmin={isViewerAdmin}
                                />
                            }
                        />
                    ) : (
                        <Route
                            path={LEAVE}
                            element={
                                <Leave
                                    isViewerTL={isViewerTL}
                                    isViewerAdmin={isViewerAdmin}
                                />
                            }
                        />
                    )}

                    <Route
                        path={LEAVEWITHDRAWAL}
                        element={
                            <LeaveWithdrawal
                                isViewerTL={isViewerTL}
                                isViewerAdmin={isViewerAdmin}
                            />
                        }
                    />
                    {!isViewerAdmin ? (
                        <Route
                            path={COMPOFF}
                            element={
                                <CompOff
                                    isViewerTL={isViewerTL}
                                    isViewerAdmin={isViewerAdmin}
                                />
                            }
                        />
                    ) : null}
                    <Route
                        path={OPHOL}
                        element={<OpHolList isViewerAdmin={isViewerAdmin} />}
                    />
                    {!isViewerAdmin ? (
                        <Route
                            path={LEAVEBALANCES}
                            element={
                                <LeaveBalances
                                    isViewerTL={isViewerTL}
                                    isViewerAdmin={isViewerAdmin}
                                />
                            }
                        />
                    ) : null}
                    <Route
                        path={PENDAPP}
                        element={
                            <PendingApp
                                isViewerTL={isViewerTL}
                                isViewerAdmin={isViewerAdmin}
                            />
                        }
                    />
                    <Route
                        path={HISTORY}
                        element={
                            <LeaveHistory
                                isViewerTL={isViewerTL}
                                isViewerAdmin={isViewerAdmin}
                            />
                        }
                    />

                    {/* attendance and sidebar menu */}
                    <Route
                        path={ATTENDANCELIST}
                        element={
                            <Tlatt
                                isViewerTL={isViewerTL}
                                isViewerAdmin={isViewerAdmin}
                            />
                        }
                    />
                    <Route path={REGULARIZATION} element={<Regularization />} />
                    <Route path={SALARY_PAGE} element={<Salary />} />
                    <Route
                        path={SALARYDASHBOARD}
                        element={<SalaryDashboard />}
                    />

                    <Route path={DOCUMENT} element={<DocumentPage />} />
                    <Route path={HELPDESK} element={<Helpdesk />} />
                    <Route path={SETTINGS} element={<Settings />} />

                    {/* admin views */}
                    {isViewerAdmin ? (
                        <>
                            {/* employee table */}
                            <Route path={EMPLOYEES} element={<Employees />} />

                            {/* employee creation */}
                            <Route
                                path={EMPLOYEE_CREATION_PAGE}
                                element={<EmployeeCreation />}
                            >
                                <Route
                                    path={BASIC_INFORMATION_PAGE}
                                    element={<Step1Form />}
                                />
                                <Route
                                    path={EMPLOYEE_POSITION_PAGE}
                                    element={<Step2Form />}
                                />
                            </Route>

                            {/* employee profile update admin side */}
                            <Route
                                path={`${PROFILE_UPDATE}/:id`}
                                element={<ProfileUpdation />}
                            />

                            {/* add & assign accessories admin side */}
                            <Route path={ACCESSORY} element={<Accessory />} />

                            {/* add & delete shifts admin side */}
                            <Route path={SHIFTS} element={<Shifts />} />

                            {/* add & delete department admin side */}
                            <Route path={DEPARTMENT} element={<Department />} />

                            {/* add & delete designation admin side */}
                            <Route
                                path={DESIGNATION}
                                element={<Designation />}
                            />

                            {/* activate & deactivate holiday admin side */}
                            {/* <Route path={HOLIDAYS} element={<HolidayList />} /> */}

                            {/* add holidays admin side */}
                            <Route path={HOLCRUD} element={<Holcrud />} />

                            {/* admin leave apply */}
                            <Route
                                path={ADMINLEAVEAPPLY}
                                element={
                                    <AdminLeave
                                        isViewerTL={isViewerTL}
                                        isViewerAdmin={isViewerAdmin}
                                    />
                                }
                            />

                            {/* approve leave */}
                            <Route
                                path={TLLEAVEAPPROVAL}
                                element={
                                    <Tlleaveapproval
                                        isViewerTL={isViewerTL}
                                        isViewerAdmin={isViewerAdmin}
                                    />
                                }
                            />

                            {/* withdraw leave */}
                            <Route
                                path={TLLEAVEWITHDRAWAL}
                                element={
                                    <TlleaveWithdrawal
                                        isViewerAdmin={isViewerAdmin}
                                        isViewerTL={isViewerTL}
                                    />
                                }
                            />

                            {/* holiday list */}
                            <Route
                                path={HOLIDAYS}
                                element={
                                    <HolidayList
                                        isViewerAdmin={isViewerAdmin}
                                    />
                                }
                            />

                            {/* tl attendance regularization */}
                            <Route
                                path={TEAMATTREGULARISE}
                                element={<TeamAttRegularise />}
                            />

                            {/* team member attendance */}
                            <Route
                                path={TEAMATTENDANCE}
                                element={<Attendence />}
                            />

                            {/* attendance muster */}
                            <Route
                                path={ATTENDANCEMUSTER}
                                element={
                                    <AttendanceMuster
                                        isViewerAdmin={isViewerAdmin}
                                    />
                                }
                            />

                            {/* employee attendance muster */}
                            <Route
                                path={EMPLOYEEATTENDANCELIST}
                                element={<EmpAttendanceList />}
                            />
                            <Route
                                path={ADMINLEAVEAPPLY}
                                element={
                                    <AdminLeave
                                        isViewerTL={false}
                                        isViewerAdmin={false}
                                    />
                                }
                            />
                            <Route
                                path={VIEWLEAVEBALANCE}
                                element={
                                    <AdminLeaveBal
                                        isViewerTL={isViewerTL}
                                        isViewerAdmin={isViewerAdmin}
                                    />
                                }
                            />
                        </>
                    ) : null}

                    {/* TL views */}
                    {isViewerTL ? (
                        <>
                            {/* approve leave */}
                            <Route
                                path={TLLEAVEAPPROVAL}
                                element={
                                    <Tlleaveapproval
                                        isViewerTL={isViewerTL}
                                        isViewerAdmin={isViewerAdmin}
                                    />
                                }
                            />

                            <Route
                                path={TLLEAVEWITHDRAWAL}
                                element={
                                    <TlleaveWithdrawal
                                        isViewerAdmin={isViewerAdmin}
                                        isViewerTL={isViewerTL}
                                    />
                                }
                            />

                            {/* tl attendance regularization */}
                            <Route
                                path={TEAMATTREGULARISE}
                                element={<TeamAttRegularise />}
                            />

                            {/* team member attendance */}
                            <Route
                                path={TEAMATTENDANCE}
                                element={<Attendence />}
                            />

                            {/* attendance muster */}
                            <Route
                                path={ATTENDANCEMUSTER}
                                element={
                                    <AttendanceMuster
                                        isViewerAdmin={isViewerAdmin}
                                    />
                                }
                            />

                            {/* employee attendance muster */}
                            <Route
                                path={EMPLOYEEATTENDANCELIST}
                                element={<EmpAttendanceList />}
                            />
                        </>
                    ) : null}
                </Route>

                <Route path={PROFILE} element={<Profile />} />
                <Route path={LOADER} element={<Loader />} />
                <Route path={LOGIN} element={<Login />} />
                <Route path={MSAUTH} element={<MsAuth />} />
                <Route path={PASSRESET} element={<ResetPass />} />

                {/* admin views */}
                {isViewerAdmin ? (
                    <Route path={ROLES} element={<AdminAssign />} />
                ) : null}
            </Routes>
        </div>
    );
}

export default App;
