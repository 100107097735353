import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import {
    Leave, LeaveLeaveType, LeaveSession1, LeaveSession2, MSViewerMSemployeeDetails, useListMSUsers, useListMSViewers, useRetrieveEmployee,
    useUpdateLeave
} from '../api';

interface Props {
    leavesData: Leave | undefined;
    refetch: any;
    empDetails?: MSViewerMSemployeeDetails
}

function TeamLeaveAppForm(props: Props) {
    const {
        data: userData,
        isSuccess: usersSuccess,
        isLoading: usersLoading,
    } = useListMSViewers();

    const{
        data:emp_data
    }=useRetrieveEmployee(String(props.leavesData?.approved_by))

    const emps_name=emp_data?.data.full_name
    

    const empDetails = userData?.data[0].MSemployee_details;
    const formik = useFormik({
        initialValues: {
            leave_type: LeaveLeaveType.CompOff,
            session_1: LeaveSession1.S1,
            session_2: LeaveSession2.S2,
            applying_from: '',
            applying_to: '',
            cc: '',
            reason: '',
            rejection_reason: ''
        },
        validationSchema: Yup.object({
       

            reason: Yup.string().max(1000).required('Reason Required'),
            // manager: Yup.string().max(20).required('Required'),
            cc: Yup.string().max(25).required('CC Required'),
        }),
    //     onSubmit: async (values: Leave) => {
    //         const updatedVal: Leave = {
    //             ...values,
    //             employee_id: empDetails?.id,
    //         };
    //         await mutateAsync({ data: updatedVal });
    //     },
    // });

    onSubmit: () => {
        //
    },
});

    const leaves = props.leavesData;
    const {
        data: usersData,
        isSuccess: userSuccess,
        isLoading: userLoading,
    } = useListMSUsers();

    const employee_name = usersData?.data[0].MSemployee_details?.full_name;
    const leave_emp_id: number | null | undefined =
        props.leavesData?.employee_id;
    const { data: employeeData } = useRetrieveEmployee(String(leave_emp_id));
    const emp_name = employeeData?.data.full_name;
    const manager_id: number | null | undefined =
        employeeData?.data.reporting_manager;

    const {
        data: managerData,
        isSuccess: managerSuccess,
        isLoading: managerLoading,
    } = useRetrieveEmployee(String(manager_id));
    const date1 = leaves?.applying_from;
    const date2 = leaves?.applying_to;
    const Difference_In_Time =
        new Date(date2 as string).getTime() -
        new Date(date1 as string).getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    const { mutateAsync, isSuccess } = useUpdateLeave();

    const handleAcceptChange = (e: any) => {
        if (leaves !== undefined) {
            const leavesData = {
                ...leaves,
                leave_status: 'ACC',
            };

            mutateAsync({
                id: leaves?.id ? leaves.id.toString() : '',
                data: {
                    session_1: leaves.session_1,
                    leave_type: leaves.leave_type,
                    applying_from: leaves.applying_from,
                    applying_to: leaves.applying_to,
                    leave_status: 'ACC',
                    approved_by: props.empDetails?.id,
                },
            });
        }
        toast.success('Leave Accepted')
    };

    useEffect(() => {
        if(isSuccess) 
        props.refetch();
    },[isSuccess, props])
    const handleRejectChange = (e: any) => {
        if (leaves !== undefined) {
           
            
            mutateAsync({
                id: leaves?.id ? leaves.id.toString() : '',
                data: {
                    session_1: leaves.session_1,
                    leave_type: leaves.leave_type,
                    applying_from: leaves.applying_from,
                    applying_to: leaves.applying_to,
                    leave_status: 'REJ',
                    approved_by: props.empDetails?.id,
                    rejection_reason: formik.values.rejection_reason
                },
            });
        }
        toast.success('Leave Rejected')
    };

    //   const Difference_In_Time = date2.getDuration() - date1.getDuration();

    const managerName = managerData?.data.full_name;
    const [reject,setReject]=useState(false)
    return (
       
        <div className="lg:ml-[7%] border border-zxblue-to rounded-md focus:outline-none  lg:w-[85%] lg:mt-5 md:ml-[9%] md:mt-5 mr-8 md:w-[80%] h-auto ">
            <h1 className="mt-6 ml-8 text-black">Team Member : {emp_name}</h1>
            <div className="grid grid-rows-1 grid-cols-2">
                <h1 className="mt-6 ml-8 text-black">
                    Leave Type : {leaves?.leave_type}{' '}
                </h1>
                <h1 className="mt-6 ml-8  text-black">
                    No Of Days : {Difference_In_Days+1}
                </h1>
            </div>
            <br />
            <div className="grid grid-rows-1 xl:grid-cols-4 sm:grid-cols-2 sm:gap-y-6">
                <h1 className="ml-8 text-black">
                    Applied From : {leaves?.applying_from}
                </h1>
                <h1 className="ml-8 text-black">
                    Applied To : {leaves?.applying_to}
                </h1>
                <h1 className="ml-8 text-black">Manager : {managerName}</h1>
                <h1 className="ml-8 text-black">CC : {leaves?.cc}</h1>
            </div>
            <h1 className="mt-6 ml-8 mb-5 text-black ">
                Reason :
                <span className="text-clip overflow-hidden ...">
                    {' '}
                    {leaves?.reason}
                </span>
            </h1>
            {leaves?.leave_status==='REJ'?
            (
                <><h1 className="mt-6 ml-8 mb-5 text-black ">
                        Rejection Reason :
                        <span className="text-clip overflow-hidden ...">
                            {' '}
                            {leaves?.rejection_reason}
                        </span>
                    </h1>
                    <h1 className="mt-6 ml-8 mb-5 text-black ">
                        Approved/Rejected By : {emps_name}</h1></>
            ):null}
            {leaves?.leave_status==='ACC'?
            (
                <h1 className="mt-6 ml-8 mb-5 text-black ">
                        Approved/Rejected By : {emps_name}</h1>
            ):null}
            
            <div className="grid grid-cols-2 ">
                
                {leaves?.leave_status === 'PEND' ? (
                    <>
                        <button
                            className="border border-orange-400 lg:w-[65%] md:mb-[5%] lg:h-[70%] md:w-[55%]  md:h-[73%] rounded-full  place-self-center md:ml-[50%] lg:ml-[40%] lg:mb-[5%] lg:mt-[5%]"
                            aria-label="Reject"
                            type="button"
                            onClick={()=>setReject(!reject)}
                        >
                            Reject
                        </button>
                        <button
                            aria-label="Accept"
                            className="text-white lg:ml-[10%] lg:mb-[10%] md:mb-[10%] lg:w-[65%] lg:h-[70%] md:w-[55%] md:ml-[5%] md:h-[73%] lg:mt-[3%] rounded-full bg-gradient-to-r from-zxblue-from to-zxblue-to hover:bg-gradient-to-t"
                            onClick={handleAcceptChange}
                            type="button"
                        >
                            Accept
                        </button>
                    </>
                ) : null}
            </div>
           
            {reject?
            (<div><textarea 
            className='w-[69%] ml-32  h-[70%] mb-4 mt-2 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
            placeholder="Reason For Rejection.."
            onChange={(event)=>formik.setFieldValue("rejection_reason",event.target.value)}
            />
            <div className=''>
            <button type="button" 
            aria-label="Confirm Reject"
            className=" w-[35%] mb-4 -mt-4 ml-[35%] py-3 rounded-full bg-gradient-to-r from-zxblue-from to-zxblue-to text-white " 
            onClick={handleRejectChange}>
            Confirm Reject</button>
            </div>
        </div>)
        :null}
        </div>
    );
}
export default TeamLeaveAppForm;
