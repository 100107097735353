import { Field, Formik, FormikTouched, FormikValues } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import {
    Department,
    useListDepartments,
    useRetrieveDepartment,
    useRetrieveEmployee,
    useRetrieveMSUser,
    useUpdateEmployee
} from '../../api';
import { DeptInfoUpdate } from '../../constants/interfaceExports';
import FormikField from '../FormFields/FormikField';
import FormikSelect from '../FormFields/FormikSelect';
import SectionHeader from './SectionHeader';

import ButtonField from '../FormFields/ButtonField';
import DeptInfoSection from './Sections/DeptInfoSection';

export default function DepartmentUpdateForm(props: { id: string }) {
    const { mutateAsync, isSuccess } = useUpdateEmployee();
    const [form, setForm] = useState(false);
    const { data: usersData, refetch } = useRetrieveMSUser(props.id);
    const departmentID = usersData?.data.MSemployee_details?.department_id;
    const { data: departmentName } = useRetrieveDepartment(
        departmentID ? departmentID.toString() : ''
    );
    const { data: managerData } = useRetrieveEmployee(
        departmentName?.data.manager_id
            ? departmentName.data.manager_id.toString()
            : ''
    );
    const { data: deptData } = useListDepartments();
    const deptId: Department[] | undefined = deptData?.data;

    const DepartmentInformationInitialValues = {
        department_id: Number(departmentID),
        manager_name: managerData?.data.full_name,
        manager_emp_number: managerData?.data.employee_number,
    };
    const DepartmentInformationValidationSchema = Yup.object({
        department_id: Yup.number()
            .typeError('Choose a value')
            .required('Required'),
    });

    const handleSubmit = async (values: DeptInfoUpdate) => {
        if (departmentID !== undefined || departmentID !== null) {
            const updatedVal = {
                department_id: Number(values.department_id),
                first_name: usersData?.data.MSemployee_details?.first_name
                    ? usersData.data.MSemployee_details.first_name
                    : '',
                last_name: usersData?.data.MSemployee_details?.last_name
                    ? usersData.data.MSemployee_details.last_name
                    : '',
                joining_date: usersData?.data.MSemployee_details?.joining_date
                    ? usersData.data.MSemployee_details.joining_date
                    : '',
                confirmation_date: usersData?.data.MSemployee_details
                    ?.confirmation_date
                    ? usersData.data.MSemployee_details.confirmation_date
                    : '',
                shift_id: usersData?.data.MSemployee_details.shift_id
                    ? usersData.data.MSemployee_details.shift_id
                    : Number(''),
            };
            await mutateAsync({
                data: updatedVal,
                id: String(usersData?.data.MSemployee_details?.id),
            });
        }
    };
    useEffect(() => {
        if (isSuccess) {
            refetch();
            toast.success('Department Information Updated');
            setForm(false);
        }
    }, [isSuccess, refetch, setForm]);

    return form ? (
        <>
            <SectionHeader
                name="Department Information"
                form={form}
                setForm={setForm}
                isUserPresent={usersData}
                employeeDetails={departmentName}
            />
            <Formik
                initialValues={DepartmentInformationInitialValues}
                validationSchema={DepartmentInformationValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ touched, submitForm, values, setFieldValue }) => (
                    <form className="grid grid-flow-row gap-x-4 md:grid-cols-3 lg:grid-cols-4 px-7">
                        <FormikSelect
                            label="Name"
                            name="department_id"
                            touched={touched.department_id}
                        >
                            {deptId?.map((deptID: Department) => (
                                <option key={deptID.id} value={deptID.id}>
                                    {deptID.name}
                                </option>
                            ))}
                        </FormikSelect>
                        <MyField
                            values={values}
                            setFieldValue={setFieldValue}
                            touched={touched}
                        />
                        <FormikField
                            label="Manager ID"
                            name="manager_emp_number"
                            type="text"
                            disabled
                        />
                        <div className="row-start-4 lg:col-start-3 md:col-start-2 col-span-2 mx-auto mr-2">
                            <ButtonField
                                name="Cancel"
                                onClick={() => setForm(false)}
                            />
                            <ButtonField
                                name="Submit"
                                onClick={() => {
                                    submitForm();
                                }}
                            />
                        </div>
                    </form>
                )}
            </Formik>
        </>
    ) : (
        <>
            <SectionHeader
                name="Department Information"
                form={form}
                setForm={setForm}
                isUserPresent={usersData}
                employeeDetails={departmentName}
            />
            <DeptInfoSection id={props.id} />
        </>
    );
}

function MyField(props: {
    values: FormikValues;
    setFieldValue: any;
    touched: FormikTouched<FormikValues>;
}) {
    const { values, touched, setFieldValue } = props;
    const { data: departmentData } = useRetrieveDepartment(
        values.department_id
    );
    const { data: managerData } = useRetrieveEmployee(
        String(departmentData?.data.manager_id)
    );

    const HandleField = () => {
        useEffect(() => {
            if (values.department_id !== '' && touched.department_id) {
                setFieldValue('manager_name', `${managerData?.data.full_name}`);
                setFieldValue(
                    'manager_emp_number',
                    `${managerData?.data.employee_number}`
                );
            }
        }, [touched, setFieldValue, values]);
    };

    return (
        <div>
            <label className="pl-0.5">Manager Name</label>
            <Field
                className="w-full mb-4 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5"
                name="manager_name"
                value={values.manager_name}
                onClick={HandleField()}
                disabled
            />
        </div>
    );
}
