import { useState } from 'react';
import EmployeeTable from '../components/EmployeeTable';
import Header from '../components/Header';
import Notification from '../components/Notification';
import SubHeader from '../components/SubHeader';

function Employees() {
    const [notif, setNotif] = useState(false);

    const [filters, setFilters] = useState({
        dept: '',
        grade: '',
        desg: '',
        status: '',
        past: '',
        name: '',
    });

    return (
        <div className="h-screen w-screen box-border p-10 overflow-auto">
            <Header
                name="Employee Directory"
                setNotif={setNotif}
                notif={notif}
                emp_name={filters.name}
                set_name={setFilters}
            />
            <Notification notif={notif} />
            <SubHeader setFilters={setFilters} />
            <EmployeeTable filters={filters} />
        </div>
    );
}

export default Employees;
