import classnames from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useEffect } from 'react';
import { GiCancel } from 'react-icons/gi';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import {
    Designation,
    useCreateDesignation,
    useListDesignations
} from '../../api';
import { getHtmlFor } from '../../utils/formUtils';
import ButtonField from '../FormFields/ButtonField';

const initialDesignationData = {
    name: '',
};
const designationValidationSchema = Yup.object({
    name: Yup.string().max(25, 'Too Long').required('Required'),
});

export default function AddNewDesignation(props: {
    addNewDesgn: boolean;
    setAddNewDesgn: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const { data: desgnData, refetch } = useListDesignations();
    const desgnNames = desgnData?.data;
    const { mutateAsync, isSuccess } = useCreateDesignation();

    const handleDesgnSubmit = async (values: Designation) => {
        if (desgnNames?.some((desName) => desName.name === values.name)) {
            toast.warn(`${values.name} is present in Database`);
        } else {
            const createDesgn: Designation = {
                ...values,
            };
            await mutateAsync({ data: createDesgn });
            toast.success(`Designation Created`);
            props.setAddNewDesgn(false);
        }
    };

    useEffect(() => {
        if (isSuccess) {
            refetch();
        }
    }, [isSuccess, refetch]);

    if (props.addNewDesgn) {
        return (
            <div
                className="mx-auto w-[25%] z-50 bg-white text-zxtext-1 absolute shadow-lg shadow-slate-400 rounded-lg border-t-2 border-gray-300 box-border"
                role="alert"
            >
                <h2 className="py-6 pl-7 border-x-zxblue-from text-transparent font-semibold md:text-lg lg:text-xl inline-block">
                    <span className="pl-1 border-l-4 border-zxsub-to bg-clip-text bg-gradient-to-r from-zxheader-from to-zxheader-to opacity-100">
                        Add Designation
                    </span>
                </h2>
                <GiCancel
                    className="float-right mr-7 mt-7"
                    size="5.5%"
                    onClick={() => {
                        props.setAddNewDesgn(false);
                    }}
                />
                <Formik
                    initialValues={initialDesignationData}
                    validationSchema={designationValidationSchema}
                    onSubmit={handleDesgnSubmit}
                >
                    {({ touched, errors, submitForm, isSubmitting }) => (
                        <Form className="flex flex-col mx-auto">
                            <div className="flex flex-col px-10">
                                <label
                                    htmlFor={getHtmlFor('manager_id')}
                                    className={classnames(
                                        'pl-0.5',
                                        touched.name && errors.name
                                            ? 'text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                                            : 'border-zxblue-to'
                                    )}
                                >
                                    Designation Name
                                </label>
                                <Field
                                    name="name"
                                    type="text"
                                    placeholder="Enter new designation name"
                                    className={classnames(
                                        'w-full h-8 border rounded-md focus:outline-none mr-3 placeholder: pl-2 text-sm',
                                        touched.name && errors.name
                                            ? 'border-zxerror-to'
                                            : 'border-zxblue-to'
                                    )}
                                />
                                <ErrorMessage
                                    name="name"
                                    component="span"
                                    className="text-xs text-zxerror-from top-0"
                                />
                            </div>
                            <ButtonField
                                name="Submit"
                                className="self-center mb-3"
                                onClick={() => submitForm()}
                                disabled={isSubmitting}
                            />
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
    return null;
}
