/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  Attendance
} from '../../schema'


/**
 * Get attendance details
 */
export const retrieveAttendance = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Attendance>> => {
    return axios.get(
      `/attendance/${id}`,options
    );
  }


export const getRetrieveAttendanceQueryKey = (id: string,) => [`/attendance/${id}`];

    
export type RetrieveAttendanceQueryResult = NonNullable<Awaited<ReturnType<typeof retrieveAttendance>>>
export type RetrieveAttendanceQueryError = AxiosError<unknown>

export const useRetrieveAttendance = <TData = Awaited<ReturnType<typeof retrieveAttendance>>, TError = AxiosError<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof retrieveAttendance>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveAttendanceQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveAttendance>>> = ({ signal }) => retrieveAttendance(id, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof retrieveAttendance>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * Delete a attendance
 */
export const destroyAttendance = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    return axios.delete(
      `/attendance/${id}`,options
    );
  }



    export type DestroyAttendanceMutationResult = NonNullable<Awaited<ReturnType<typeof destroyAttendance>>>
    
    export type DestroyAttendanceMutationError = AxiosError<unknown>

    export const useDestroyAttendance = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof destroyAttendance>>, TError,{id: string}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof destroyAttendance>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  destroyAttendance(id,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof destroyAttendance>>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    