import { Employee, useListMSViewers, useRetrieveMSUser } from '../api';
import { useEffect, useState } from 'react';

import Axios from 'axios';

function ProfileImage(props: {
    className: string | undefined;
    empData: Employee;
}) {
    const userId = props.empData.msuser_id;
    const { data: msUserData, isSuccess: msUserSuccess } = useRetrieveMSUser(
        userId ? userId.toString() : ''
    );

    const { data: viewerData, isSuccess: viewerSuccess } = useListMSViewers();
    const [imageUrl, setImageUrl] = useState('../logo512.png');

    useEffect(() => {
        if (msUserSuccess && viewerSuccess) {
            Axios.get(
                `https://graph.microsoft.com/v1.0/users/${msUserData.data.user_id}/photo/$value`,
                {
                    headers: {
                        Authorization: `Bearer ${viewerData.data[0].provider_token}`,
                    },
                    responseType: 'blob',
                }
            ).then((o) => {
                const url = window.URL || window.webkitURL;
                const blobUrl = url.createObjectURL(o.data);
                setImageUrl(blobUrl);
            });
        }
    }, [
        msUserData?.data.user_id,
        msUserSuccess,
        viewerData?.data,
        viewerSuccess,
    ]);

    return (
        <div className={props.className}>
            <img
                className="h-14 w-14 rounded-full bg-black"
                src={imageUrl}
                alt=""
            />
        </div>
    );
}

export default ProfileImage;
