/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  MSUser
} from '../../schema'


/**
 * List all users
 */
export const listMSUsers = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<MSUser[]>> => {
    return axios.get(
      `/user/`,options
    );
  }


export const getListMSUsersQueryKey = () => [`/user/`];

    
export type ListMSUsersQueryResult = NonNullable<Awaited<ReturnType<typeof listMSUsers>>>
export type ListMSUsersQueryError = AxiosError<unknown>

export const useListMSUsers = <TData = Awaited<ReturnType<typeof listMSUsers>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listMSUsers>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListMSUsersQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listMSUsers>>> = ({ signal }) => listMSUsers({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof listMSUsers>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * Create user
 */
export const createMSUser = (
    mSUser: MSUser, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<MSUser>> => {
    return axios.post(
      `/user/`,
      mSUser,options
    );
  }



    export type CreateMSUserMutationResult = NonNullable<Awaited<ReturnType<typeof createMSUser>>>
    export type CreateMSUserMutationBody = MSUser
    export type CreateMSUserMutationError = AxiosError<unknown>

    export const useCreateMSUser = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createMSUser>>, TError,{data: MSUser}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createMSUser>>, {data: MSUser}> = (props) => {
          const {data} = props ?? {};

          return  createMSUser(data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof createMSUser>>, TError, {data: MSUser}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * Get user details
 */
export const retrieveMSUser = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<MSUser>> => {
    return axios.get(
      `/user/${id}/`,options
    );
  }


export const getRetrieveMSUserQueryKey = (id: string,) => [`/user/${id}/`];

    
export type RetrieveMSUserQueryResult = NonNullable<Awaited<ReturnType<typeof retrieveMSUser>>>
export type RetrieveMSUserQueryError = AxiosError<unknown>

export const useRetrieveMSUser = <TData = Awaited<ReturnType<typeof retrieveMSUser>>, TError = AxiosError<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof retrieveMSUser>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveMSUserQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveMSUser>>> = ({ signal }) => retrieveMSUser(id, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof retrieveMSUser>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * Delete a user
 */
export const destroyMSUser = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    return axios.delete(
      `/user/${id}/`,options
    );
  }



    export type DestroyMSUserMutationResult = NonNullable<Awaited<ReturnType<typeof destroyMSUser>>>
    
    export type DestroyMSUserMutationError = AxiosError<unknown>

    export const useDestroyMSUser = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof destroyMSUser>>, TError,{id: string}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof destroyMSUser>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  destroyMSUser(id,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof destroyMSUser>>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    