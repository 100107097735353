import { AxiosResponse } from 'axios';
import { Employee } from '../../api';
import SalaryRow from './SalaryRow';

function SalaryRows(props: { empData: AxiosResponse<Employee[]> }) {
    const employees: Employee[] = props.empData.data;

    return (
        <>
            {employees.map((employee: Employee, index) => (
                <SalaryRow
                    key={employee.id}
                    counter={index}
                    employee={employee}
                />
            ))}
        </>
    );
}

export default SalaryRows;
