import 'react-toastify/dist/ReactToastify.css';

import * as Yup from 'yup';

import {
    Department,
    Designation,
    EmployeeEmployeeStatus,
    MSUser,
    useListDepartments,
    useListDesignations,
    useListMSUsers,
    useRetrieveMSUser,
    useUpdateEmployee
} from '../../api';

import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { EmployeeStatusOptions } from '../../constants/formSelect';
import ButtonField from '../FormFields/ButtonField';
import FormikField from '../FormFields/FormikField';
import FormikSelect from '../FormFields/FormikSelect';
import SectionHeader from './SectionHeader';
import EmployeeInfo from './Sections/EmployeeInfoSection';

export default function EmployeeInformationUpdate(props: { id: string }) {
    const { mutateAsync, isSuccess } = useUpdateEmployee();
    const [form, setForm] = useState(false);
    const { data: desgnData } = useListDesignations();
    const desgnId: Designation[] | undefined = desgnData?.data;
    const { data: usersData } = useListMSUsers();
    const empData: MSUser[] | undefined = usersData?.data;
    const { data: deptData } = useListDepartments();
    const deptId: Department[] | undefined = deptData?.data;
    const { data: employeeData, refetch } = useRetrieveMSUser(props.id);
    const empDetails = employeeData?.data.MSemployee_details;
    const jd = `${empDetails?.joining_date}`;
    const cd = `${empDetails?.confirmation_date}`;
    const newJoiningDate = new Date(jd).toLocaleDateString('en-CA');
    const newConfirmationDate = new Date(cd).toLocaleDateString('en-CA');

    const EmployeeInformationInitialValues = {
        first_name: empDetails?.first_name ? empDetails.first_name : '',
        last_name: empDetails?.last_name ? empDetails.last_name : '',
        email_id: employeeData?.data.email ? employeeData.data.email : '',
        designation_id: empDetails?.designation_id
            ? empDetails.department_id
            : '',
        joining_date: newJoiningDate || '',
        confirmation_date: newConfirmationDate || '',
        employee_status: empDetails?.employee_status
            ? empDetails.employee_status
            : '',
        reporting_manager: empDetails?.reporting_manager
            ? empDetails.reporting_manager
            : '',
        department_id: empDetails?.department_id
            ? empDetails.department_id
            : '',
        last_working_date: empDetails?.last_working_date
            ? empDetails.last_working_date
            : '',
    };

    const EmployeeInformationValidationSchema = Yup.object({
        first_name: Yup.string()
            .max(20, 'Maximum 20 characters allowed')
            .required('Required'),
        last_name: Yup.string()
            .max(20, 'Maximum 20 characters allowed')
            .required('Required'),
        email_id: Yup.string().email('Invalid Email').required('Required'),
        designation_id: Yup.number()
            .typeError('Choose a value')
            .required('Required'),
        joining_date: Yup.date().required('Required'),
        confirmation_date: Yup.date().required('Required'),
        employee_status: Yup.string()
            .oneOf(EmployeeStatusOptions, 'Choose a value')
            .required('Required'),
        reporting_manager: Yup.number()
            .typeError('Choose a value')
            .required('Required'),
        department_id: Yup.number()
            .typeError('Choose a value')
            .required('Required'),
        last_working_date: Yup.date(),
    });

    const handleSubmit = async (values: any) => {
        if (empDetails !== undefined || empDetails !== null) {
            const updatedVal = {
                ...values,
                shift_id: empDetails?.shift_id ? empDetails.shift_id : '',
                last_working_date:
                    values.last_working_date &&
                    (values.employee_status ===
                        EmployeeEmployeeStatus.Retired ||
                        values.employee_status ===
                            EmployeeEmployeeStatus.Absconded)
                        ? new Date(values.last_working_date).toLocaleDateString(
                              'en-CA',
                              {
                                  year: 'numeric',
                                  month: '2-digit',
                                  day: '2-digit',
                              }
                          )
                        : null,
            };
            await mutateAsync({
                data: updatedVal,
                id: String(empDetails?.id),
            });
        }
    };

    useEffect(() => {
        if (isSuccess) {
            refetch();
            toast.success('Employee Information Updated');
            setForm(false);
        }
    }, [isSuccess, refetch, setForm]);

    return form ? (
        <>
            <SectionHeader
                name="Employee Information"
                form={form}
                setForm={setForm}
                employeeDetails={empDetails}
            />
            <Formik
                initialValues={EmployeeInformationInitialValues}
                validationSchema={EmployeeInformationValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ touched, submitForm, values }) => (
                    <form className="grid grid-flow-row gap-x-4 gap-y-2 md:grid-cols-3 lg:grid-cols-4 px-7">
                        <FormikField
                            label="First Name"
                            name="first_name"
                            type="text"
                            touched={touched.first_name}
                        />
                        <FormikField
                            label="Last Name"
                            name="last_name"
                            type="text"
                            touched={touched.last_name}
                        />
                        <FormikField
                            label="Email ID"
                            name="email_id"
                            placeholder="xxxx@zedexinfo.com"
                            type="text"
                            touched={touched.email_id}
                        />
                        <FormikSelect
                            label="Position"
                            name="designation_id"
                            touched={touched.designation_id}
                        >
                            {desgnId?.map((desgnID: Designation) => (
                                <option key={desgnID.id} value={desgnID.id}>
                                    {desgnID.name}
                                </option>
                            ))}
                        </FormikSelect>
                        <FormikField
                            label="Joining Date"
                            name="joining_date"
                            type="date"
                            touched={touched.joining_date}
                        />
                        <FormikField
                            label="Confirmation Date"
                            name="confirmation_date"
                            type="date"
                            touched={touched.confirmation_date}
                        />
                        <FormikSelect
                            label="Status"
                            name="employee_status"
                            touched={touched.employee_status}
                        >
                            {EmployeeStatusOptions.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </FormikSelect>
                        <FormikSelect
                            label="Reporting To"
                            name="reporting_manager"
                            touched={touched.reporting_manager}
                        >
                            {empData?.map((empID: MSUser) => (
                                <option
                                    key={empID.MSemployee_details?.id}
                                    value={empID.MSemployee_details?.id}
                                >
                                    {empID.MSemployee_details?.full_name}
                                </option>
                            ))}
                        </FormikSelect>
                        <FormikSelect
                            label="Department ID"
                            name="department_id"
                            touched={touched.department_id}
                        >
                            {deptId?.map((deptID: Department) => (
                                <option key={deptID.id} value={deptID.id}>
                                    {deptID.name}
                                </option>
                            ))}
                        </FormikSelect>
                        {values.employee_status ===
                            EmployeeEmployeeStatus.Absconded ||
                        values.employee_status ===
                            EmployeeEmployeeStatus.Retired ? (
                            <FormikField
                                label="Last Working Date"
                                name="last_working_date"
                                placeholder="YYYY-MM-DD"
                                touched={touched.last_working_date}
                                type="date"
                            />
                        ) : null}
                        <div className="row-start-4 lg:col-start-3 md:col-start-2 col-span-2 mx-auto mr-2">
                            <ButtonField
                                name="Cancel"
                                onClick={() => setForm(false)}
                            />
                            <ButtonField
                                name="Submit"
                                onClick={() => {
                                    submitForm();
                                }}
                            />
                        </div>
                    </form>
                )}
            </Formik>
        </>
    ) : (
        <>
            <SectionHeader
                name="Employee Information"
                form={form}
                setForm={setForm}
                employeeDetails={empDetails}
            />
            <EmployeeInfo id={props.id} />
        </>
    );
}
