/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */

export type HolidayType = typeof HolidayType[keyof typeof HolidayType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const HolidayType = {
  COMP: 'COMP',
  OP: 'OP',
} as const;
