/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useMutation
} from '@tanstack/react-query'
import type {
  UseMutationOptions,
  MutationFunction
} from '@tanstack/react-query'
import type {
  Attendance
} from '../../schema'


/**
 * Sign In
 */
export const createAttendance = (
    attendance: Attendance, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Attendance>> => {
    return axios.post(
      `/signin/`,
      attendance,options
    );
  }



    export type CreateAttendanceMutationResult = NonNullable<Awaited<ReturnType<typeof createAttendance>>>
    export type CreateAttendanceMutationBody = Attendance
    export type CreateAttendanceMutationError = AxiosError<unknown>

    export const useCreateAttendance = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAttendance>>, TError,{data: Attendance}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAttendance>>, {data: Attendance}> = (props) => {
          const {data} = props ?? {};

          return  createAttendance(data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof createAttendance>>, TError, {data: Attendance}, TContext>(mutationFn, mutationOptions)
    }
    