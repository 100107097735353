// // import background from "../img/bg.png";
// // import circle1 from "../img/circle1.png";
// // import circle2 from "../img/circle2.png";
// // import pwdreset from "../img/pwdreset.png";

// function PasswordReset() {
//     return (
//         <div className="images">
//             <div className="circle1pwd" style={{backgroundImage:"url('/img/circle1.png')"}}>
//                 <img src="/img/circle1.png" alt="" />
//             </div>
//             <div className="pwdreset text-center font-bold">
//                 <div className='pswd-rst-title text-center text-xl'>
//                     Password Reset
//                 </div>
//                 <div className="txtpwd text-center">
//                     Enter your Email and reset link will be send to you
//                 </div>

//             </div>
//             <div className="bgpwd backdrop-blur-sm" />

//             <div className="circle2pwd">
//             <img src="/img/circle2.png"  alt="" />
//             </div>

//         </div>

//     );
// }
// export default PasswordReset;
import LoginCircle from './LoginCircle';
import PassResetFrame from './PassResetFrame';

function PasswordReset() {
    return (
        <div className="w-full h-screen flex justify-center items-center">
            <LoginCircle />
            <PassResetFrame />
        </div>
    );
}

export default PasswordReset;
