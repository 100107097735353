import classNames from 'classnames';
import {
    Link,
    LinkProps,
    useLocation,
    useMatch,
    useResolvedPath
} from 'react-router-dom';
import {
    ATTENDANCELIST,
    ATTENDANCEMUSTER,
    REGULARIZATION,
    TEAMATTENDANCE,
    TEAMATTREGULARISE
} from '../constants/router';

export default function AttendanceSidebar(props: {
    isViewerTL: boolean;
    isViewerAdmin: boolean;
}) {
    const location = useLocation();

    return (
        <aside className="flex flex-col min-w-fit">
            <div className="w-full h-fit shadow-2xl bg-white bg-opacity-60 backdrop-blur-x px-10">
                <div className="flex flex-row lg:mt-12 md:mt-10 w-full">
                    <Link to={ATTENDANCELIST} className="flex flex-row ">
                        <img
                            className="w-fit"
                            alt=""
                            src="../img/leavesymb.svg"
                        />
                        <h1 className="ml-2 mt-2 font-semibold">Attendance</h1>
                    </Link>
                    {location.pathname === ATTENDANCELIST ? (
                        <div className="w-3 h-3 rounded-full bg-gradient-to-l from-zxbullet2-from to-zxbullet2-to self-center lg:ml-14 md:ml-12" />
                    ) : null}
                </div>{' '}
                <div className="my-6 h-0.5 w-[95%] bg-gradient-to-r from-zxheader-to to-zxheader-from opacity-50 rounded-xl" />
                <h1 className="font-normal text-sm lg:mt-2 md:mt-2">Menu</h1>
                <ul>
                    {props.isViewerTL || props.isViewerAdmin ? (
                        <CustomLink to={TEAMATTENDANCE}>
                            <img
                                className="float-left lg:w-8 lg:h-8 lg:pb-2"
                                alt=""
                                src="../img/ta (1).svg"
                            />{' '}
                            <span className=" text-black md:text-sm">
                                Team Attendance
                            </span>
                        </CustomLink>
                    ) : null}
                    <CustomLink to={REGULARIZATION}>
                        <img
                            className="float-left lg:w-8 lg:h-8 lg:pb-2"
                            alt=""
                            src="../img/ta (1).svg"
                        />{' '}
                        <span className=" text-black md:text-sm">
                            Regularization
                        </span>
                    </CustomLink>
                    {props.isViewerTL || props.isViewerAdmin ? (
                        <>
                            <CustomLink to={TEAMATTREGULARISE}>
                                <img
                                    className="float-left lg:w-8 lg:h-8 lg:pb-2"
                                    alt=""
                                    src="../img/ta (1).svg"
                                />{' '}
                                <span className=" text-black md:text-sm">
                                    Team Regularization
                                </span>
                            </CustomLink>
                            <CustomLink to={ATTENDANCEMUSTER}>
                                <img
                                    className="float-left lg:w-8 lg:h-8 lg:pb-2"
                                    alt=""
                                    src="../img/ta (1).svg"
                                />{' '}
                                <span className=" text-black md:text-sm">
                                    Attendance Muster
                                </span>
                            </CustomLink>
                        </>
                    ) : null}
                </ul>
            </div>
        </aside>
    );
}

function CustomLink({ to, children, ...props }: LinkProps) {
    const resolvedPath = useResolvedPath(to);
    const isActive = useMatch({ path: resolvedPath.pathname, end: true });

    return (
        <Link to={to} {...props} className="flex">
            <li
                className={classNames(
                    'py-4',
                    isActive
                        ? 'bg-white rounded-l-full text-black w-60'
                        : 'text-white'
                )}
            >
                {children}
            </li>
            {isActive ? (
                <div className="w-3 h-3 lg:mt-5 md:mt-4 rounded-full bg-gradient-to-r from-zxbullet2-from to-zxbullet2-to" />
            ) : null}
        </Link>
    );
}
