export const HOME = '/';

export const EMPLOYEES = '/employees';
export const EMPLOYEE_CREATION_PAGE = `${EMPLOYEES}/employee`;
export const BASIC_INFORMATION_PAGE = `${EMPLOYEE_CREATION_PAGE}/basic-information`;
export const EMPLOYEE_POSITION_PAGE = `${EMPLOYEE_CREATION_PAGE}/employee-postion`;
export const PROFILE_UPDATE = `${EMPLOYEES}/update-profile`;
export const SHIFTS = `${EMPLOYEES}/shifts`;
export const ACCESSORY = `${EMPLOYEES}/accessory`;

// create new department or new designation
export const DEPARTMENT = `${EMPLOYEES}/department`;
export const DESIGNATION = `${EMPLOYEES}/designation`;
export const ROLES = '/roles';

export const PROFILE = '/profile';
export const MY_PROFILE = '/myprofile';

export const SALARY_PAGE = '/salary-dashboard';
export const SALARYDASHBOARD = '/salary-dashboard';

export const DOCUMENT = '/document';

export const HELPDESK = '/helpdesk';

export const SETTINGS = '/settings';

// create holiday admin side
export const HOLCRUD = '/holcrud';

export const LOADER = '/loader';

export const LOGIN = '/login';
export const PASSRESET = '/reset-password';
export const MSAUTH = '/auth/callback/MS';

// leave module and sidebar
export const LEAVE = '/leaves';

// TL side
export const TLLEAVEAPPLY = `${LEAVE}/tlleaveapply`;
export const ADMINLEAVEAPPLY = `${LEAVE}/adminleaveapply`;
export const TLLEAVEAPPROVAL = `${LEAVE}/tlleaveapproval`;
export const TLLEAVEWITHDRAWAL = `${LEAVE}/tlleavewithdrawal`;

// employee side
export const LEAVEWITHDRAWAL = `${LEAVE}/leavewithdrawal`;
export const COMPOFF = `${LEAVE}/compoff`;
export const OPHOL = `${LEAVE}/ophols`;
export const HOLIDAYS = '/holidays';
export const LEAVEBALANCES = `${LEAVE}/LeaveBal`;
export const PENDAPP = `${LEAVE}/pendingapp`;
export const HISTORY = `${LEAVE}/leavehistory`;

// employee attendance
export const ATTENDANCELIST = '/attendance';

// team attendance & muster
export const TLATTENDANCE = `${ATTENDANCELIST}/tlatt`;
export const TEAMATTENDANCE = `${ATTENDANCELIST}/teamattendance`;
export const ATTENDANCEMUSTER = `${ATTENDANCELIST}/muster`;
export const EMPLOYEEATTENDANCELIST = `${ATTENDANCELIST}/muster/:id`;

// regularization (apply, approve & reject)
export const REGULARIZATION = `${ATTENDANCELIST}/regularization`;
export const TEAMATTREGULARISE = `${ATTENDANCELIST}/tlattreg`;

// export const LEAVEWITHDRAWAL='/leavewithdrawal';
export const VIEWLEAVEBALANCE='/viewleavebal'