import { AxiosResponse } from 'axios';
import { Accessories } from '../../api';
import GetAccessoryName from './GetAccessoryName';
import GetEmployeeName from './GetEmployeeName';

interface AccessoryRowProps<A, R> {
    accessoryData: AxiosResponse<Accessories[], any> | undefined;
    // refetch: (options?: A) => R;
}

export default function AccessoryRow<A, R>(props: AccessoryRowProps<A, R>) {
    const accessories = props.accessoryData?.data
        ? props.accessoryData.data
        : [];
    let counter = 0;
    return (
        <>
            {accessories.map((accessory: Accessories) => (
                <tr key={accessory.id} className="h-14 hover:shadow-lg">
                    <td className="text-center">{++counter}</td>
                    <td className="text-center">
                        <GetAccessoryName id={accessory.type} />
                    </td>
                    <td className="text-center">{accessory.serial_no}</td>
                    <td className="text-center">
                        <GetEmployeeName id={accessory.assigned_to} />
                    </td>
                </tr>
            ))}
        </>
    );
}
