import { useLocation } from "react-router-dom";
import MsViewerInfo from "./MsViewerInfo";
import MsViewerProfileHeader from "./MsViewerProfileHeader";

export default function MsViewerProfileBg() {
    const location = useLocation();
    const id = location.pathname.substring(
        location.pathname.lastIndexOf('/') + 1
    );

    return (
        <div className="w-screen box-border h-screen overflow-y-scroll">
            <div className="flex flex-col py-5 w-full">
                <MsViewerProfileHeader id={id} />
                <MsViewerInfo id={id} />
            </div>
        </div>
    );
}