import { Field, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import {
    Profile,
    ProfileBloodGroup,
    ProfileMaritalStatus,
    ProfileNationality,
    ProfileReligion,
    useRetrieveMSUser,
    useUpdateProfile
} from '../../api';
import {
    BloodGroup,
    MaritalStatus,
    Nationality,
    Religion
} from '../../constants/formSelect';
import ButtonField from '../FormFields/ButtonField';
import FormikField from '../FormFields/FormikField';
import FormikSelect from '../FormFields/FormikSelect';
import ProfileUpdateField from './ProfileUpdateField';
import SectionHeader from './SectionHeader';

export default function ProfileInformationUpdate(props: { id: string }) {
    const { mutateAsync, isSuccess } = useUpdateProfile();
    const [form, setForm] = useState(false);
    const { data: employeeData, refetch } = useRetrieveMSUser(props.id);
    const profileDetails =
        employeeData?.data.MSemployee_details?.profile_details;
    const bday = `${profileDetails?.dob}`;
    const newDOB = new Date(bday).toLocaleDateString('en-CA');

    const ProfileInformationInitialValues = {
        dob: newDOB,
        contact_no: `${
            profileDetails?.contact_no ? profileDetails.contact_no : ''
        }`,
        emergency_contact: `${
            profileDetails?.emergency_contact
                ? profileDetails.emergency_contact
                : ''
        }`,
        blood_group: profileDetails?.blood_group
            ? profileDetails?.blood_group
            : ProfileBloodGroup.AB,
        marital_status: profileDetails?.marital_status
            ? profileDetails?.marital_status
            : ProfileMaritalStatus.Single,
        nationality: profileDetails?.nationality
            ? profileDetails?.nationality
            : ProfileNationality.Indian,
        spo_father_name: `${
            profileDetails?.spo_father_name
                ? profileDetails?.spo_father_name
                : ''
        }`,
        religion: profileDetails?.religion
            ? profileDetails?.religion
            : ProfileReligion.Christian,
        differently_abled: false,
        differently_abled_text: `${
            profileDetails?.differently_abled_text &&
            profileDetails.differently_abled
                ? profileDetails.differently_abled_text
                : ''
        }`,
        international_employee: false,
        international_employee_text: `${
            profileDetails?.international_employee_text &&
            profileDetails.international_employee
                ? profileDetails.international_employee_text
                : ''
        }`,
    };

    const ProfileInformationValidationSchema = Yup.object({
        dob: Yup.string().required('Required'),
        contact_no: Yup.string()
            .min(10)
            .max(13)
            .required('Required')
            .matches(/^[+\d]\d*$/),
        emergency_contact: Yup.string()
            .min(10)
            .max(13)
            .required('Required')
            .matches(/^[+\d]\d*$/),
        blood_group: Yup.string()
            .oneOf(BloodGroup, 'Choose a value')
            .required('Required'),
        marital_status: Yup.string()
            .oneOf(MaritalStatus, 'Choose a value')
            .required('Required'),
        nationality: Yup.string()
            .oneOf(Nationality, 'Choose a value')
            .required('Required'),
        spo_father_name: Yup.string()
            .max(20, 'Maximum 20 characters allowed')
            .required('Required'),
        religion: Yup.string()
            .oneOf(Religion, 'Choose a value')
            .required('Required'),
    });

    const handleSubmit = async (values: Profile) => {
        if (profileDetails !== undefined || profileDetails !== null) {
            const updatedVal = {
                ...values,
                differently_abled_text: values.differently_abled_text
                    ? values.differently_abled_text
                    : '',
                internation_employee_text: values.international_employee_text
                    ? values.international_employee_text
                    : '',
            };
            await mutateAsync({
                data: updatedVal,
                id: String(profileDetails?.id),
            });
        }
    };
    useEffect(() => {
        if (isSuccess) {
            refetch();
            toast.success('Profile Information Updated');
            setForm(false);
        }
    }, [isSuccess, refetch, setForm]);

    return form ? (
        <>
            <SectionHeader
                name="Profile Information"
                form={form}
                setForm={setForm}
                employeeDetails={profileDetails}
            />
            <Formik
                initialValues={ProfileInformationInitialValues}
                validationSchema={ProfileInformationValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ touched, values, submitForm }) => (
                    <form className="grid grid-flow-row gap-x-4 gap-y-2 md:grid-cols-3 lg:grid-cols-4 px-7">
                        <FormikField
                            label="Date Of Birth"
                            name="dob"
                            type="date"
                            touched={touched.dob}
                        />
                        <FormikSelect
                            label="Blood Group"
                            name="blood_group"
                            touched={touched.blood_group}
                        >
                            {BloodGroup.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </FormikSelect>
                        <FormikSelect
                            label="Marital Status"
                            name="marital_status"
                            touched={touched.marital_status}
                        >
                            {MaritalStatus.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </FormikSelect>
                        <FormikSelect
                            label="Nationality"
                            name="nationality"
                            touched={touched.nationality}
                        >
                            {Nationality.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </FormikSelect>
                        <FormikField
                            label="Contact No"
                            name="contact_no"
                            type="text"
                            touched={touched.contact_no}
                        />
                        <FormikField
                            label="Father's/Spouse's Name"
                            name="spo_father_name"
                            type="text"
                            touched={touched.spo_father_name}
                        />
                        <FormikField
                            label="Emergency Contact No"
                            name="emergency_contact"
                            type="text"
                            touched={touched.emergency_contact}
                        />
                        <FormikSelect
                            label="Religion"
                            name="religion"
                            touched={touched.religion}
                        >
                            {Religion.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </FormikSelect>
                        <div className="flex flex-col">
                            <FormikField
                                label="Physically Challenged"
                                name="differently_abled"
                                type="checkbox"
                            />
                            {values.differently_abled ? (
                                <Field
                                    className="w-full h-8 mb-[2%] rounded-md focus:outline-none placeholder: pl-[4%] py-[1%] border border-zxblue-to"
                                    name="differently_abled_text"
                                    placeholder="Specify your answer"
                                />
                            ) : null}
                        </div>
                        <div className="flex flex-col">
                            <FormikField
                                label="International Employee"
                                name="international_employee"
                                type="checkbox"
                            />
                            {values.international_employee ? (
                                <Field
                                    className="w-full h-8 mb-[2%] rounded-md focus:outline-none placeholder: pl-[4%] py-[1%] border border-zxblue-to"
                                    name="international_employee_text"
                                    placeholder="Specify your answer"
                                />
                            ) : null}
                        </div>
                        <div className="row-start-4 lg:col-start-3 md:col-start-2 col-span-2 mx-auto mr-2">
                            <ButtonField
                                name="Cancel"
                                onClick={() => setForm(false)}
                            />
                            <ButtonField
                                name="Submit"
                                onClick={() => {
                                    submitForm();
                                }}
                            />
                        </div>
                    </form>
                )}
            </Formik>
        </>
    ) : (
        <>
            <SectionHeader
                name="Profile Information"
                form={form}
                setForm={setForm}
                employeeDetails={profileDetails}
            />
            <div className="grid grid-flow-row gap-y-4 gap-x-2 md:grid-cols-3 lg:grid-cols-4 px-7">
                <ProfileUpdateField
                    label="Date Of Birth"
                    value={profileDetails?.dob ? profileDetails.dob : '-'}
                />
                <ProfileUpdateField
                    label="Blood Group"
                    value={
                        profileDetails?.blood_group
                            ? profileDetails.blood_group
                            : '-'
                    }
                />
                <ProfileUpdateField
                    label="Marital Status"
                    value={
                        profileDetails?.marital_status
                            ? profileDetails.marital_status
                            : '-'
                    }
                />
                <ProfileUpdateField
                    label="Nationality"
                    value={
                        profileDetails?.nationality
                            ? profileDetails.nationality
                            : '-'
                    }
                />
                <ProfileUpdateField
                    label="Contace No"
                    value={
                        profileDetails?.contact_no
                            ? profileDetails.contact_no
                            : '-'
                    }
                />
                <ProfileUpdateField
                    label="Father's/Spouse's Name"
                    value={
                        profileDetails?.spo_father_name
                            ? profileDetails.spo_father_name
                            : '-'
                    }
                />
                <ProfileUpdateField
                    label="Emergency Contact No"
                    value={
                        profileDetails?.emergency_contact
                            ? profileDetails.emergency_contact
                            : '-'
                    }
                />
                <ProfileUpdateField
                    label="Religion"
                    value={
                        profileDetails?.religion ? profileDetails.religion : '-'
                    }
                />
                <ProfileUpdateField
                    label="Physically Challengend"
                    value={
                        profileDetails?.differently_abled
                            ? `Yes, ${profileDetails.differently_abled_text}`
                            : 'No'
                    }
                />
                <ProfileUpdateField
                    label="International Employee"
                    value={
                        profileDetails?.international_employee
                            ? `Yes, ${profileDetails.international_employee_text}`
                            : 'No'
                    }
                />
            </div>
        </>
    );
}
