/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useMutation
} from '@tanstack/react-query'
import type {
  UseMutationOptions,
  MutationFunction
} from '@tanstack/react-query'
import type {
  Attendance
} from '../../schema'


/**
 * Update sign in details and add sign out
 */
export const updateAttendance = (
    attendance: Attendance, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Attendance>> => {
    return axios.put(
      `/signout/`,
      attendance,options
    );
  }



    export type UpdateAttendanceMutationResult = NonNullable<Awaited<ReturnType<typeof updateAttendance>>>
    export type UpdateAttendanceMutationBody = Attendance
    export type UpdateAttendanceMutationError = AxiosError<unknown>

    export const useUpdateAttendance = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAttendance>>, TError,{data: Attendance}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAttendance>>, {data: Attendance}> = (props) => {
          const {data} = props ?? {};

          return  updateAttendance(data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof updateAttendance>>, TError, {data: Attendance}, TContext>(mutationFn, mutationOptions)
    }
    