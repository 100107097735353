import { EMPLOYEE_POSITION_PAGE } from '../constants/router';

export default function Steps(props: { path: string }) {
    const step = props.path === EMPLOYEE_POSITION_PAGE ? 1 : 0;

    const reached_circle =
        'rounded-full bg-gradient-to-b from-zxblue-to to-zxblue-from w-6 h-6 transition transition-duration-900ms ease-linear';
    const not_reached_circle =
        'rounded-full border border-zxyellow-from w-6 h-6';
    const reached_line =
        'w-[21%] bg-gradient-to-r h-[0.1em] from-zxblue-to to-zxblue-from flex-none transition transition-duration-900ms ease-linear';
    const not_reached_line =
        'w-[21%] bg-gradient-to-r h-[0.1em] from-zxyellow-from to-zxyellow-to flex-none';
    return (
        <div className="flex flex-row items-center justify-center">
            <div className="rounded-full bg-gradient-to-b from-zxblue-to to-zxblue-from w-6 h-6" />
            <div className={step >= 1 ? reached_line : not_reached_line} />
            <div className={step >= 1 ? reached_circle : not_reached_circle} />
        </div>
    );
}
