import { Employee } from "../api"

function ViewLeaveBalrow(props:{emp:Employee})
{
    return(
        <tr key={props.emp.id} className="text-center h-14">
            <td className="  ">
                <span className="border border-zxblue-from p-3">
                    {props.emp.id}
                </span>
            </td>
            <td>{props.emp.full_name}</td>
            <td>{props.emp.earned_leave_balance}</td>
            <td>{props.emp.comp_off_bal}</td>
            <td>{props.emp.optional_hol_balance}</td>
            <td>{props.emp.sick_leave_balance}</td>
            <td>{props.emp.casual_leave_balance}</td>
            </tr>
    )
}

export default ViewLeaveBalrow