/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */

export type ShiftBufferTime = typeof ShiftBufferTime[keyof typeof ShiftBufferTime];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ShiftBufferTime = {
  '10': '10',
  '15': '15',
  '20': '20',
  '25': '25',
  '30': '30',
  '35': '35',
  '40': '40',
  '45': '45',
  '50': '50',
  '55': '55',
  '60': '60',
} as const;
