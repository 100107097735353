import { Formik } from 'formik';
import * as Yup from 'yup';
import { Bank, useRetrieveMSUser, useUpdateBank } from '../../api';
import ButtonField from '../FormFields/ButtonField';
import FormikField from '../FormFields/FormikField';

import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import SectionHeader from './SectionHeader';
import BankInfoSection from './Sections/BankInfoSection';

export default function BankInformationUpdate(props: { id: string }) {
    const { mutateAsync, isSuccess } = useUpdateBank();
    const [form, setForm] = useState(false);

    const { data: usersData, refetch } = useRetrieveMSUser(props.id);
    const bankDetails = usersData?.data.MSemployee_details?.bank_details;

    const BankInformationInitialValues = {
        account_no: bankDetails?.account_no ? bankDetails?.account_no : '',
        bank_name: bankDetails?.bank_name ? bankDetails?.bank_name : '',
        customer_name: bankDetails?.customer_name
            ? bankDetails?.customer_name
            : '',
        branch: bankDetails?.branch ? bankDetails?.branch : '',
        IFSC: bankDetails?.IFSC ? bankDetails?.IFSC : '',
        PAN: bankDetails?.PAN ? bankDetails?.PAN : '',
    };
    const BankInformationValidationSchema = Yup.object({
        account_no: Yup.string()
            .max(23, 'Max 23 characters allowed')
            .required('Required'),
        bank_name: Yup.string()
            .max(23, 'Max 23 characters allowed')
            .required('Required'),
        customer_name: Yup.string()
            .max(50, 'Max 30 characters allowed')
            .required('Required'),
        branch: Yup.string()
            .max(30, 'Max 30 characters allowed')
            .required('Required'),
        IFSC: Yup.string()
            .max(20, 'Max 20 characters allowed')
            .required('Required'),
        PAN: Yup.string()
            .max(20, 'Max 20 characters allowed')
            .required('Required'),
    });

    const handleSubmit = async (values: Bank) => {
        if (bankDetails !== undefined || bankDetails !== null) {
            const updatedVal = {
                ...values,
            };
            await mutateAsync({
                data: updatedVal,
                id: String(bankDetails?.id),
            });
        }
    };

    useEffect(() => {
        if (isSuccess) {
            refetch();
            toast.success('Bank Information Updated');
            setForm(false);
        }
    }, [isSuccess, refetch, setForm]);

    return form ? (
        <>
            <SectionHeader
                name="Bank Information"
                form={form}
                setForm={setForm}
                employeeDetails={bankDetails}
            />
            <Formik
                initialValues={BankInformationInitialValues}
                validationSchema={BankInformationValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ touched, submitForm }) => (
                    <form className="grid grid-flow-row gap-x-4 md:grid-cols-3 lg:grid-cols-4 px-7">
                        <FormikField
                            label="Account No"
                            name="account_no"
                            type="text"
                            touched={touched.account_no}
                        />
                        <FormikField
                            label="Bank Name"
                            name="bank_name"
                            type="text"
                            touched={touched.bank_name}
                        />
                        <FormikField
                            label="Name (as per bank)"
                            name="customer_name"
                            type="text"
                            touched={touched.customer_name}
                        />
                        <FormikField
                            label="Branch"
                            name="branch"
                            type="text"
                            touched={touched.branch}
                        />
                        <FormikField
                            label="IFSC"
                            name="IFSC"
                            type="text"
                            touched={touched.IFSC}
                        />
                        <FormikField
                            label="PAN No"
                            name="PAN"
                            type="text"
                            touched={touched.PAN}
                        />
                        <div className="row-start-4 lg:col-start-3 md:col-start-2 col-span-2 mx-auto mr-2">
                            <ButtonField
                                name="Cancel"
                                onClick={() => setForm(false)}
                            />
                            <ButtonField
                                name="Submit"
                                onClick={() => {
                                    submitForm();
                                }}
                            />
                        </div>
                    </form>
                )}
            </Formik>
        </>
    ) : (
        <>
            <SectionHeader
                name="Bank Information"
                form={form}
                setForm={setForm}
                employeeDetails={bankDetails}
            />
            <BankInfoSection id={props.id} />
        </>
    );
}
