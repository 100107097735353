import { useEffect } from 'react';
import { toast } from 'react-toastify';
import {
    Leave,
    useListMSUsers,
    useRetrieveEmployee,
    useUpdateLeave
} from '../api';

function LeaveHistForm(props: { leavesData: Leave | undefined; refetch: any }) {
    const leaves = props.leavesData;
    const {
        data: usersData,
        isSuccess: userSuccess,
        isLoading,
    } = useListMSUsers();

    const {
        data:emps_data
    }=useRetrieveEmployee(String(leaves?.approved_by))

    const emps_name=emps_data?.data.full_name

    const managerId: number | null | undefined =
        usersData?.data[0].MSemployee_details?.reporting_manager;

    const {
        data: managerData,
        isSuccess: managerSuccess,
        isLoading: managerLoading,
    } = useRetrieveEmployee(String(managerId));

    const managerName = managerData?.data.full_name;
    const date1 = leaves?.applying_from;
    const date2 = leaves?.applying_to;
    const Difference_In_Time =
        new Date(date2 as string).getTime() -
        new Date(date1 as string).getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    const { mutateAsync, isSuccess } = useUpdateLeave();
    const handleWithdrawChange = (e: any) => {
        if (leaves !== undefined) {
            const leavesData = {
                ...leaves,
                leave_status: 'WITH',
            };

            mutateAsync({
                id: leaves?.id ? leaves.id.toString() : '',
                data: {
                    session_1: leaves.session_1,
                    leave_type: leaves.leave_type,
                    applying_from: leaves.applying_from,
                    applying_to: leaves.applying_to,
                    leave_status: 'WITH',
                },
            });

            
        }
    };
    useEffect(()=>{
        if (isSuccess) {
            props.refetch();
            toast.success('Leave Withdrawn')
        }   
    },[isSuccess, props])
    
    return (
        <div className="lg:ml-[6%] border border-zxblue-to rounded-md focus:outline-none  lg:w-[88%] lg:mt-5 md:ml-14 md:mt-5 mr-8 md:w-[80%] h-auto">
            <div className="grid grid-rows-1 grid-cols-2">
                <h1 className="mt-6 ml-8 text-black">
                    Leave Type : {leaves?.leave_type}{' '}
                </h1>
                <h1 className="mt-6 ml-8 text-black">
                    No Of Days : {Difference_In_Days+1}
                </h1>
            </div>
            <br />
            <div className="grid  xl:grid-cols-4 sm:grid-cols-2 sm:gap-y-6">
                <h1 className="ml-8 text-black">
                    Applied From : {leaves?.applying_from}
                </h1>
                <h1 className="ml-8 text-black">
                    Applied To : {leaves?.applying_to}
                </h1>
                <h1 className="ml-8 text-black">Manager: {managerName}</h1>
                <h1 className="ml-8 text-black">CC: {leaves?.cc}</h1>
                
            </div>
            <h1 className="mt-6 ml-8 mb-5 text-black">
                    Reason : {leaves?.reason}
            </h1>
            {
                leaves?.leave_status==='ACC' || leaves?.leave_status==='REJ'?
                (
                    <h1 className="mt-6 ml-8 mb-5 text-black">
                    Approved/Rejected By : {emps_name}
                    </h1>
                ):null
        
            }
                {leaves?.leave_status==='REJ'?
                (<h1 className="mt-6 ml-8 mb-5 text-black">
                Rejection Reason : {leaves?.rejection_reason}
            </h1>):null}
            <div className="grid grid-cols-2 ">
                {leaves?.leave_status === 'ACC' ? (
                    <>
                        <button
                            className="border border-orange-400 lg:w-[65%] md:mb-[5%] lg:h-[70%] md:w-[55%]  md:h-[73%] rounded-full  place-self-center md:ml-[50%] lg:ml-[40%] lg:mb-[5%]"
                            aria-label="Back"
                            type="button"
                        >
                            Back
                        </button>
                        <button
                            aria-label="Withdraw"
                            className="text-white lg:ml-[10%] lg:mb-[10%] md:mb-[10%] lg:w-[65%] lg:h-[70%] md:w-[55%] md:ml-[5%] md:h-[73%] rounded-full bg-gradient-to-r from-zxblue-from to-zxblue-to hover:bg-gradient-to-t "
                            onClick={handleWithdrawChange}
                            type="button"
                        >
                            Withdraw
                        </button>
                    </>
                ) : null}
            </div>
        </div>
    );
}

export default LeaveHistForm;
