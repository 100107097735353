function Circle() {
    return (
        <div className="flex justify-between gap-x-10">
            <div className="absolute inset-x-4 md:w-52 md:h-52 lg:w-64 lg:h-64 bg-gradient-to-r from-zxblue-from to-zxblue-to rounded-full" />
            <div className="absolute lg:inset-x-[85%] md:inset-x-[80%] inset-y-20 lg:h-44 lg:w-44 md:w-36 md:h-36 bg-gradient-to-r from-zxblue-from to-zxblue-to rounded-full" />
        </div>
    );
}

export default Circle;
