import { useEffect, useState } from "react";
import { useListLeaves, useListMSViewers } from "../api";


function LeaveBalForm()
{
    const
    {data:EmployeeData,
    isSuccess,
    isLoading
    }=useListMSViewers()

    const emp_data=EmployeeData?.data[0]?.MSemployee_details
    const{
        data:empleaves,
        isSuccess:leaveSuccess,
        refetch
    }=useListLeaves({
        axios: {
            params: {
                employee_id: emp_data?.id,
            },
        },
});
    
useEffect(()=>{
    if(emp_data?.id)
    refetch();
    
  },[emp_data?.id, refetch])

const emp_leaves=empleaves?.data?.filter((x)=>x.employee_id)  

const [counter_ear, setCounter_ear] = useState(0)
const [counter_comp, setCounter_comp] = useState(0)
const [counter_cas, setCounter_cas] = useState(0)
const [counter_med, setCounter_med] = useState(0)
const [counter_op, setCounter_op] = useState(0)

// console.log(emp_leaves && emp_leaves?.filter((x)=>(x.leave_type==='Earned' && x.leave_status==='ACC' && emp_data?.id===x.employee_id) ))


useEffect(()=>{

    
let ear=0
let cas=0
let med=0
let comp=0
let op=0

emp_leaves?.forEach((leave)=>{

    const time_applied=
        new Date(leave?.applying_to).getTime()-new Date(leave?.applying_from).getTime()
        const no_of_days=Math.ceil(time_applied/ (1000 * 3600 * 24))+1
        
    if(leave?.leave_type==='Earned' && leave?.leave_status==='ACC' )
    {
        
        ear+=no_of_days
        console.log(no_of_days)
        
    }
    else if(leave?.leave_type==='CompOff' && leave?.leave_status==='ACC' ){
        comp+=no_of_days
        
    }
    else if(leave?.leave_type==='Casual' && leave?.leave_status==='ACC' ){
        cas+=no_of_days
        
    }
    else if(leave?.leave_type==='Medical' && leave?.leave_status==='ACC' ){
        med+=no_of_days
        
    }
    else if(leave?.leave_type==='Optional_Holiday' && leave?.leave_status==='ACC' ){
        op+=no_of_days
        
    }
}
) 
setCounter_ear(ear)
setCounter_comp(comp)
setCounter_cas(cas)
setCounter_med(med)
setCounter_op(op)
},[ emp_leaves])


 
//    useEffect(()=>{if(leaveSuccess){
//     emp_leaves?.map((leave: Leave) =>
//          leave.leave_status==='ACC'  && leave.leave_type==='CompOff' ? (
//             setCounter_comp(counter_comp+1)
//          ) : null
//      )
//    }},[ emp_leaves, leaveSuccess])

//    useEffect(()=>{if(leaveSuccess){
//     emp_leaves?.map((leave: Leave) =>
//          leave.leave_status==='ACC'  && leave.leave_type==='Casual' ? (
//             setCounter_cas(counter_cas+1)
//          ) : null
//      )
//    }},[ emp_leaves, leaveSuccess])

//    useEffect(()=>{if(leaveSuccess){
//     emp_leaves?.map((leave: Leave) =>
//          leave.leave_status==='ACC'  && leave.leave_type==='Medical' ? (
//             setCounter_med(counter_med+1)
//          ) : null
//      )
//    }},[ emp_leaves, leaveSuccess])

//    useEffect(()=>{if(leaveSuccess){
//     emp_leaves?.map((leave: Leave) =>
//          leave.leave_status==='ACC'  && leave.leave_type==='Optional_Holiday' ? (
//             setCounter_op(counter_op+1)
//          ) : null
//      )
//    }},[ emp_leaves, leaveSuccess])
  
  
   
 

    return (
        <div className="grid grid-cols-3 gap-y-4">
            <div className="lg:ml-10 border border-zxblue-to rounded-md focus:outline-none  lg:w-[85%] lg:mt-5 md:ml-10 md:mt-5 mr-8 md:w-[80%] h-[100%]">
                <h1 className="mt-4 ml-4 font-semibold text-sm">Earned Leave : {emp_data?.earned_leave_balance}</h1>
                <meter value={emp_data?.earned_leave_balance} min="0" max="1"  className="w-[80%] ml-4 mt-3"/>
                <h1 className="mt-4 ml-4 text-xs font-normal">Balance : {emp_data?.earned_leave_balance}</h1>
                <h1 className="mt-4 ml-4 text-xs font-normal">Granted : {counter_ear}
                </h1>
                {/* <h1 className="mt-4 ml-4 text-xs font-normal">Used : </h1> */}
                {/* <h1 className="mt-4 ml-4 text-xs font-normal">Lapsed : </h1> */}
            </div>
            <div className="lg:ml-6 border border-zxblue-to rounded-md focus:outline-none  lg:w-[85%] lg:mt-5 md:ml-6 md:mt-5 mr-8 md:w-[80%] h-[100%]">
                <h1 className="mt-4 ml-4 font-semibold text-sm">Comp Off : {emp_data?.comp_off_bal}</h1>
                <meter value={emp_data?.comp_off_bal} min="0" max="1" low={4} high={4} className="w-[80%] ml-4 mt-3"/>
                <h1 className="mt-4 ml-4 text-xs font-normal">Balance : {emp_data?.comp_off_bal}</h1>
                <h1 className="mt-4 ml-4 text-xs font-normal">Granted : {counter_comp}</h1>
                {/* <h1 className="mt-4 ml-4 text-xs font-normal">Used : </h1> */}
                {/* <h1 className="mt-4 ml-4 text-xs font-normal">Lapsed : </h1> */}
            </div>
            <div className="lg:ml-2 border border-zxblue-to rounded-md focus:outline-none  lg:w-[85%] lg:mt-5 md:ml-2 md:mt-5 mr-8 md:w-[80%] h-[100%]">
                <h1 className="mt-4 ml-4 font-semibold text-sm">Casual Leave : {emp_data?.casual_leave_balance}</h1>
                <meter value={emp_data?.casual_leave_balance} min="0" max="0.5" low={4} high={5} className="w-[80%] ml-4 mt-3"/>
                <h1 className="mt-4 ml-4 text-xs font-normal">Balance : {emp_data?.casual_leave_balance}</h1>
                <h1 className="mt-4 ml-4 text-xs font-normal">Granted : {counter_cas}</h1>
                {/* <h1 className="mt-4 ml-4 text-xs font-normal">Used : </h1> */}
                {/* <h1 className="mt-4 ml-4 text-xs font-normal">Lapsed : </h1> */}
            </div>
            <div className="lg:ml-10 border border-zxblue-to rounded-md focus:outline-none  lg:w-[85%] lg:mt-5 md:ml-10 md:mt-5 mr-8 md:w-[80%] h-[100%]">
                <h1 className="mt-4 ml-4 font-semibold text-sm">Sick Leave : {emp_data?.sick_leave_balance}</h1>
                <meter value={emp_data?.sick_leave_balance} min="0" max="0.5"  className="w-[80%] ml-4 mt-3"/>
                <h1 className="mt-4 ml-4 text-xs font-normal">Balance : {emp_data?.sick_leave_balance}</h1>
                <h1 className="mt-4 ml-4 text-xs font-normal">Granted : {counter_med}</h1>
                {/* <h1 className="mt-4 ml-4 text-xs font-normal">Used : </h1> */}
                {/* <h1 className="mt-4 ml-4 text-xs font-normal">Lapsed : </h1> */}
            </div>
            <div className="lg:ml-6 border border-zxblue-to rounded-md focus:outline-none  lg:w-[85%] lg:mt-5 md:ml-6 md:mt-5 mr-8 md:w-[80%] h-[100%]">
                <h1 className="mt-4 ml-4 font-semibold text-sm">Optional Leave : {emp_data?.optional_hol_balance}</h1>
                <meter value={emp_data?.optional_hol_balance} min="0" max="7" low={4} high={5} className="w-[80%] ml-4 mt-3"/>
                <h1 className="mt-4 ml-4 text-xs font-normal">Balance : {emp_data?.optional_hol_balance}</h1>
                <h1 className="mt-4 ml-4 text-xs font-normal">Granted : {counter_op}</h1>
                {/* <h1 className="mt-4 ml-4 text-xs font-normal">Used : </h1> */}
                {/* <h1 className="mt-4 ml-4 text-xs font-normal">Lapsed : </h1> */}
            </div>
        </div>
    )
}

export default LeaveBalForm