/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  Education
} from '../../schema'


/**
 * Get Education details
 */
export const retrieveEducation = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Education>> => {
    return axios.get(
      `/education/${id}/`,options
    );
  }


export const getRetrieveEducationQueryKey = (id: string,) => [`/education/${id}/`];

    
export type RetrieveEducationQueryResult = NonNullable<Awaited<ReturnType<typeof retrieveEducation>>>
export type RetrieveEducationQueryError = AxiosError<unknown>

export const useRetrieveEducation = <TData = Awaited<ReturnType<typeof retrieveEducation>>, TError = AxiosError<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof retrieveEducation>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveEducationQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveEducation>>> = ({ signal }) => retrieveEducation(id, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof retrieveEducation>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * Update Education details
 */
export const updateEducation = (
    id: string,
    education: Education, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Education>> => {
    return axios.put(
      `/education/${id}/`,
      education,options
    );
  }



    export type UpdateEducationMutationResult = NonNullable<Awaited<ReturnType<typeof updateEducation>>>
    export type UpdateEducationMutationBody = Education
    export type UpdateEducationMutationError = AxiosError<unknown>

    export const useUpdateEducation = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateEducation>>, TError,{id: string;data: Education}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateEducation>>, {id: string;data: Education}> = (props) => {
          const {id,data} = props ?? {};

          return  updateEducation(id,data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof updateEducation>>, TError, {id: string;data: Education}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * Delete an Education Detail
 */
export const destroyEducation = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    return axios.delete(
      `/education/${id}/`,options
    );
  }



    export type DestroyEducationMutationResult = NonNullable<Awaited<ReturnType<typeof destroyEducation>>>
    
    export type DestroyEducationMutationError = AxiosError<unknown>

    export const useDestroyEducation = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof destroyEducation>>, TError,{id: string}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof destroyEducation>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  destroyEducation(id,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof destroyEducation>>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    