import classnames from 'classnames';
import moment from 'moment';
import { EmpAttendance } from '../api';

interface AttendanceRowProps {
    attendance: EmpAttendance;
}

function AttendanceRow({ attendance }: AttendanceRowProps) {
    const day = moment(attendance.date);
    const startTime = moment(attendance?.sign_in, 'HH:mm:ss a');
    const endTime = moment(attendance?.sign_out, 'HH:mm:ss a');
    const duration = moment
        .duration(endTime.diff(startTime))
        .asHours()
        .toFixed(2);
    const lateStyle = classnames(
        'font-normal text-sm',
        Number(duration) < 9
            ? 'bg-gradient-to-bl from-zxerror-to to-zxerror-from bg-clip-text text-transparent'
            : ''
    );

    return (
        <tr key={attendance.id} className="text-center h-10">
            <td>
                <span className="border border-zxblue-from p-2">
                    {day.date()}
                </span>
            </td>
            <td className={lateStyle}>
                {attendance.sign_in ? attendance.sign_in : '--:--'}
            </td>
            <td className={lateStyle}>
                {attendance.sign_out ? attendance.sign_out : '--:--'}
            </td>
            <td className={lateStyle}>
                {duration && attendance.sign_out
                    ? `${duration} Hrs`
                    : ' --:-- Hrs'}
            </td>
            <td>
                <div
                    className={classnames(
                        Number(duration) < 9
                            ? 'bg-gradiant-to-tr from-zxerror-to to-zxerror-from rounded-full w-3 p-3'
                            : ''
                    )}
                />
            </td>
        </tr>
    );
}

export default AttendanceRow;
