import { useEffect } from 'react';
import { EmpAttendance } from '../api';

import AttendanceRow from './AttendanceRow';

function AttendanceTable(props: {
    isSignInSucces: boolean | undefined;
    isSignOutSucces: boolean | undefined;
    att_data: EmpAttendance[] | undefined;
    refetchMonthlyAttnd: any;
    isMonthlyAttndSuccess: boolean;
    isMonthlyAttndLoading: boolean;
    isMonthlyAttndFetchedAfterMount: boolean;
}) {
    useEffect(() => {
        if (
            (props.isSignInSucces || props.isSignOutSucces) &&
            props.isMonthlyAttndSuccess
        )
            props.refetchMonthlyAttnd();
    }, [
        props.isSignInSucces,
        props.isSignOutSucces,
        props.refetchMonthlyAttnd,
        props.isMonthlyAttndSuccess,
    ]);

    return (
        <div className="h-72 box-border overflow-y-auto">
            <table className="pt-2 text-zxtext-1 w-full table-auto">
                <thead>
                    <tr className="top-0 sticky bg-white">
                        <th>Date</th>
                        <th>Sign In</th>
                        <th>Sign Out</th>
                        <th>Total Hours</th>
                        <th>Regularize</th>
                    </tr>
                </thead>
                <tbody className="text-sm">
                    {props.isMonthlyAttndLoading ? (
                        <tr>
                            <td>Loading.......</td>
                        </tr>
                    ) : props.isMonthlyAttndFetchedAfterMount &&
                      props.isMonthlyAttndSuccess ? (
                        props.att_data?.map((attendance: EmpAttendance) => (
                            <AttendanceRow
                                key={attendance.id}
                                attendance={attendance}
                            />
                        ))
                    ) : (
                        <tr>
                            <td>Not Success</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}
export default AttendanceTable;
