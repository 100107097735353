/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */

export type EmployeeGender = typeof EmployeeGender[keyof typeof EmployeeGender];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EmployeeGender = {
  Male: 'Male',
  Female: 'Female',
  Other: 'Other',
} as const;
