/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  RolesRemove
} from '../../schema'


/**
 * Remove a role and get user
 */
export const retrieveRolesRemove = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<RolesRemove>> => {
    return axios.get(
      `/roles-remove/${id}`,options
    );
  }


export const getRetrieveRolesRemoveQueryKey = (id: string,) => [`/roles-remove/${id}`];

    
export type RetrieveRolesRemoveQueryResult = NonNullable<Awaited<ReturnType<typeof retrieveRolesRemove>>>
export type RetrieveRolesRemoveQueryError = AxiosError<unknown>

export const useRetrieveRolesRemove = <TData = Awaited<ReturnType<typeof retrieveRolesRemove>>, TError = AxiosError<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof retrieveRolesRemove>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveRolesRemoveQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveRolesRemove>>> = ({ signal }) => retrieveRolesRemove(id, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof retrieveRolesRemove>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

