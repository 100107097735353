/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  Attendance
} from '../../schema'


/**
 * List all attendances
 */
export const listAttendances = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Attendance[]>> => {
    return axios.get(
      `/attendances/`,options
    );
  }


export const getListAttendancesQueryKey = () => [`/attendances/`];

    
export type ListAttendancesQueryResult = NonNullable<Awaited<ReturnType<typeof listAttendances>>>
export type ListAttendancesQueryError = AxiosError<unknown>

export const useListAttendances = <TData = Awaited<ReturnType<typeof listAttendances>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listAttendances>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListAttendancesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listAttendances>>> = ({ signal }) => listAttendances({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof listAttendances>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

