/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */

export type LeaveLeaveType = typeof LeaveLeaveType[keyof typeof LeaveLeaveType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LeaveLeaveType = {
  Earned: 'Earned',
  Medical: 'Medical',
  Optional_Holiday: 'Optional_Holiday',
  CompOff: 'CompOff',
  Unpaid: 'Unpaid',
  Casual: 'Casual',
} as const;
