import { useState } from 'react';
import { useListAccessories, useListAccessorys } from '../../api';
import AccessoryCountTable from './AccessoryCountTable';
import AccessoryTable from './AccessoryTable';
import AddAccessory from './AddAccessory';
import AssignAccessory from './AssignAccessory';
import DeleteAccessory from './DeleteAccessory';

export default function Accessory() {
    const { data: accessoryList, refetch: refetchAccessorys } =
        useListAccessorys();
    const { data: accessoriesData, refetch: refetchAccessories } =
        useListAccessories();

    const [filters, setFilters] = useState({
        type: '',
        assigned_to: '',
    });

    return (
        <div className="w-screen box-border overflow-auto h-screen">
            <div className="p-10">
                <div className="shadow-2xl rounded-2xl border-t-2">
                    <AddAccessory
                        accessoryList={accessoryList}
                        refetchAccessorys={refetchAccessorys}
                    />
                    <div className="relative mt-3 mx-12 h-0.5 bg-gradient-to-r from-zxheader-to to-zxheader-from opacity-50 rounded-xl" />
                    <AssignAccessory
                        accessoryList={accessoryList}
                        // refetchAccessorys={refetchAccessorys}
                        accessoriesData={accessoriesData}
                        refetchAccessories={refetchAccessories}
                    />
                    <div className="relative mt-3 mx-12 h-0.5 bg-gradient-to-r from-zxheader-to to-zxheader-from opacity-50 rounded-xl" />
                    <DeleteAccessory
                        accessoriesData={accessoriesData}
                        refetchAccessories={refetchAccessories}
                    />
                    <div className="relative mt-3 mx-12 h-0.5 bg-gradient-to-r from-zxheader-to to-zxheader-from opacity-50 rounded-xl" />
                    <AccessoryTable filters={filters} setFilters={setFilters} />
                    <div className="relative mt-3 mx-12 h-0.5 bg-gradient-to-r from-zxheader-to to-zxheader-from opacity-50 rounded-xl" />
                    <AccessoryCountTable />
                </div>
            </div>
        </div>
    );
}
