import {
    DocumentPlusIcon,
    InformationCircleIcon,
    PencilSquareIcon
} from '@heroicons/react/24/solid';
import { Employee, Salary, useCreateSalary, useUpdateSalary } from '../../api';

import { useState } from 'react';
import Dept from '../DepartmentCell';

interface SalaryRowProps {
    counter: number;
    employee: Employee;
}

function SalaryRow(props: SalaryRowProps) {
    const [form, setForm] = useState(false);
    const [salary, setSalary] = useState(
        props.employee.salary_details?.new_amount
    );

    const { mutateAsync: salaryUpdateMutate } = useUpdateSalary();
    const { mutateAsync: salaryCreateMutate } = useCreateSalary();

    const submitForm = async () => {
        if (props.employee.salary_details === null) {
            const salaryData: Salary = {
                old_amount: salary,
                new_amount: salary,
                employee_id: props.employee.id,
            };
            await salaryCreateMutate({ data: salaryData });
        } else {
            const updatedVal: Salary = {
                ...props.employee.salary_details,
                old_amount: props.employee.salary_details?.new_amount,
                new_amount: salary,
            };
            const salaryId: number | undefined =
                props.employee.salary_details?.id;
            if (salaryId !== undefined) {
                await salaryUpdateMutate({
                    id: salaryId.toString(),
                    data: updatedVal,
                });
            }
        }
        setForm(false);
    };
    return form ? (
        <tr className="text-center h-14">
            <td>{props.counter}</td>
            <td>{props.employee.id}</td>
            <td>{props.employee.full_name}</td>
            {/* <td>{employee.department_id}</td> */}
            <Dept deptId={props.employee.department_id} />
            <td>
                <input
                    type="number"
                    defaultValue={props.employee.salary_details?.new_amount}
                    className="mb-1 h-6 pl-3 w-24 border border-zxblue-to rounded-full text-center outline-none"
                    onChange={(e) => setSalary(Number(e.target.value))}
                />
            </td>
            <td className="flex justify-center flex-row gap-4">
                <button type="submit" onClick={submitForm}>
                    <DocumentPlusIcon className="h-6 w-6 text-zxicon mt-3" />
                </button>
            </td>
        </tr>
    ) : (
        <tr key={props.counter} className="text-center h-14">
            <td>{props.counter}</td>
            <td>{props.employee.id}</td>
            <td>{props.employee.full_name}</td>
            <Dept deptId={props.employee.department_id} />
            <td>
                {props.employee.salary_details?.new_amount}
                <div className="flex justify-center transition-all duration-300 ease-linear cursor-pointer group">
                    <button type="button">
                        <InformationCircleIcon className="h-4 w-4 text-zxicon" />
                    </button>
                    <span
                        className="absolute z-0 p-2 m-2 rounded-md shadow-md
                    text-white bg-gray-700
                    text-xs font-bold transition-all duration-100 scale-0 origin-left
                    group-hover:scale-100"
                    >
                        Salary changed to INR{' '}
                        {props.employee.salary_details?.new_amount} from INR{' '}
                        {props.employee.salary_details?.old_amount} on{' '}
                        {props.employee.salary_details?.updated_at}
                    </span>
                </div>
            </td>
            <td>
                <button type="button" onClick={() => setForm(true)}>
                    <PencilSquareIcon className="h-6 w-6 text-zxicon mt-3" />
                </button>
            </td>
        </tr>
    );
}

export default SalaryRow;
