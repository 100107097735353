/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  Education
} from '../../schema'


/**
 * List all Education Details
 */
export const listEducations = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Education[]>> => {
    return axios.get(
      `/educations/`,options
    );
  }


export const getListEducationsQueryKey = () => [`/educations/`];

    
export type ListEducationsQueryResult = NonNullable<Awaited<ReturnType<typeof listEducations>>>
export type ListEducationsQueryError = AxiosError<unknown>

export const useListEducations = <TData = Awaited<ReturnType<typeof listEducations>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listEducations>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListEducationsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listEducations>>> = ({ signal }) => listEducations({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof listEducations>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * Create an Education Detail
 */
export const createEducation = (
    education: Education, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Education>> => {
    return axios.post(
      `/educations/`,
      education,options
    );
  }



    export type CreateEducationMutationResult = NonNullable<Awaited<ReturnType<typeof createEducation>>>
    export type CreateEducationMutationBody = Education
    export type CreateEducationMutationError = AxiosError<unknown>

    export const useCreateEducation = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createEducation>>, TError,{data: Education}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createEducation>>, {data: Education}> = (props) => {
          const {data} = props ?? {};

          return  createEducation(data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof createEducation>>, TError, {data: Education}, TContext>(mutationFn, mutationOptions)
    }
    