import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import {
    Education,
    EducationClass10Board,
    EducationClass12Board,
    EducationClass12Stream,
    useRetrieveMSUser,
    useUpdateEducation
} from '../../api';
import {
    _10thBoardOptions,
    _12thBoardOptions,
    _12thStreamOptions
} from '../../constants/formSelect';
import ButtonField from '../FormFields/ButtonField';
import FormikField from '../FormFields/FormikField';
import FormikSelect from '../FormFields/FormikSelect';
import SectionHeader from './SectionHeader';
import EducationInfoSection from './Sections/EducationInfoSection';

export default function EducationInformationUpdate(props: { id: string }) {
    const { mutateAsync, isSuccess } = useUpdateEducation();
    const [form, setForm] = useState(false);
    const { data: employeeData, refetch } = useRetrieveMSUser(props.id);
    const educationDetails =
        employeeData?.data.MSemployee_details?.education_qualifications;

    const EducationInformationInitialValues = {
        class_10_board: educationDetails?.class_10_board
            ? educationDetails.class_10_board
            : EducationClass10Board.STATE,
        class_10_percentage: educationDetails?.class_10_percentage
            ? educationDetails.class_10_percentage
            : '',
        class_12_board: educationDetails?.class_12_board
            ? educationDetails.class_12_board
            : EducationClass12Board.STATE,
        class_12_stream: educationDetails?.class_12_stream
            ? educationDetails.class_12_stream
            : EducationClass12Stream.Science,
        class_12_percentage: educationDetails?.class_12_percentage
            ? educationDetails.class_12_percentage
            : '',
        grad_college_uni: educationDetails?.grad_college_uni
            ? educationDetails.grad_college_uni
            : '',
        grad_degree: educationDetails?.grad_degree
            ? educationDetails.grad_degree
            : '',
        grad_specialization: educationDetails?.grad_specialization
            ? educationDetails.grad_specialization
            : '',
        grad_cgpa_percentage: educationDetails?.grad_cgpa_percentage
            ? educationDetails.grad_cgpa_percentage
            : '',
        master_college_uni: educationDetails?.master_college_uni
            ? educationDetails.master_college_uni
            : undefined,
        master_degree: educationDetails?.master_degree
            ? educationDetails.master_degree
            : undefined,
        master_specialization: educationDetails?.master_specialization
            ? educationDetails.master_specialization
            : undefined,
        master_cgpa_percentage: educationDetails?.master_cgpa_percentage
            ? educationDetails.master_cgpa_percentage
            : undefined,
    };

    const EducationInformationValidationSchema = Yup.object().shape({
        class_10_board: Yup.string()
            .oneOf(_10thBoardOptions, 'Choose a value')
            .required('Required'),
        class_10_percentage: Yup.string()
            .min(1)
            .max(5)
            .required('Required')
            .matches(/^(\d{1,5}|\d{0,5}\.\d{1,2})$/, 'Must be only digits'),
        class_12_board: Yup.string()
            .oneOf(_12thBoardOptions, 'Choose a value')
            .required('Required'),
        class_12_stream: Yup.string()
            .oneOf(_12thStreamOptions, 'Choose a value')
            .required('Required'),
        class_12_percentage: Yup.string()
            .max(5)
            .required('Required')
            .matches(/^(\d{1,5}|\d{0,5}\.\d{1,2})$/, 'Must be only digits'),
        grad_college_uni: Yup.string().max(200).required('Required'),
        grad_degree: Yup.string().max(100).required('Required'),
        grad_specialization: Yup.string().max(50).required('Required'),
        grad_cgpa_percentage: Yup.string()
            .max(4)
            .required('Required')
            .matches(/^(\d{1,3}|\d{0,3}\.\d{1,2})$/, 'Must be only digits'),
        master_college_uni: Yup.string().max(200, 'Too Long'),
        master_degree: Yup.string().max(100, 'Too Long'),
        master_specialization: Yup.string().max(50, 'Too Long'),
        master_cgpa_percentage: Yup.string().min(1).max(4),
    });

    const handleSubmit = async (values: Education) => {
        if (educationDetails !== undefined || educationDetails !== null) {
            const updatedVal = {
                ...values,
                master_college_uni: values.master_college_uni
                    ? values.master_college_uni
                    : null,
                master_degree: values.master_degree
                    ? values.master_degree
                    : null,
                master_specialization: values.master_specialization
                    ? values.master_specialization
                    : null,
                master_cgpa_percentage: values.master_cgpa_percentage
                    ? values.master_cgpa_percentage
                    : null,
            };
            await mutateAsync({
                data: updatedVal,
                id: String(educationDetails?.id),
            });
        }
    };
    useEffect(() => {
        if (isSuccess) {
            refetch();
            toast.success('Education Information Updated');
            setForm(false);
        }
    }, [isSuccess, refetch, setForm]);

    return form ? (
        <>
            <SectionHeader
                name="Education Information"
                form={form}
                setForm={setForm}
                employeeDetails={educationDetails}
            />
            <Formik
                initialValues={EducationInformationInitialValues}
                validationSchema={EducationInformationValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ touched, submitForm }) => (
                    <form className="grid grid-flow-row gap-x-4 gap-y-2 md:grid-cols-3 lg:grid-cols-4 px-7">
                        <FormikSelect
                            label="Class 10 Board"
                            name="class_10_board"
                            touched={touched.class_10_board}
                        >
                            {_10thBoardOptions.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </FormikSelect>
                        <FormikField
                            label="Class 10 Percentage"
                            name="class_10_percentage"
                            type="text"
                            touched={touched.class_10_percentage}
                            placeholder="%"
                        />
                        <FormikSelect
                            label="Class 12 Board"
                            name="class_12_board"
                            touched={touched.class_12_board}
                        >
                            {_12thBoardOptions.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </FormikSelect>
                        <FormikSelect
                            label="Class 12 Stream"
                            name="class_12_stream"
                            touched={touched.class_12_stream}
                        >
                            {_12thStreamOptions.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </FormikSelect>
                        <FormikField
                            label="Class 12 Percentage"
                            name="class_12_percentage"
                            type="text"
                            touched={touched.class_12_percentage}
                            placeholder="%"
                        />
                        <FormikField
                            label="Graduation College"
                            name="grad_college_uni"
                            type="text"
                            touched={touched.grad_college_uni}
                        />
                        <FormikField
                            label="Graduation Degree"
                            name="grad_degree"
                            type="text"
                            touched={touched.grad_degree}
                            placeholder="B.Tech/B.E"
                        />
                        <FormikField
                            label="Graduation Specialization"
                            name="grad_specialization"
                            type="text"
                            touched={touched.grad_specialization}
                            placeholder="Computer Science"
                        />
                        <FormikField
                            label="CGPI/Percentage"
                            name="grad_cgpa_percentage"
                            type="text"
                            touched={touched.grad_cgpa_percentage}
                        />
                        <FormikField
                            label="PostGraduation College"
                            name="master_college_uni"
                            type="text"
                            touched={touched.master_college_uni}
                        />
                        <FormikField
                            label="PostGraduation Degree"
                            name="master_degree"
                            type="text"
                            touched={touched.master_degree}
                            placeholder="M.Tech/M.E"
                        />
                        <FormikField
                            label="PostGraduation Specialization"
                            name="master_specialization"
                            type="text"
                            touched={touched.master_specialization}
                            placeholder="Electronics"
                        />
                        <FormikField
                            label="CGPI/Percentage"
                            name="master_cgpa_percentage"
                            type="text"
                            touched={touched.master_cgpa_percentage}
                        />
                        <div className="lg:row-start-4 md:row-start-5 lg:col-start-3 md:col-start-2 col-span-2 mx-auto mr-2">
                            <ButtonField
                                name="Cancel"
                                onClick={() => setForm(false)}
                            />
                            <ButtonField
                                name="Submit"
                                onClick={() => {
                                    submitForm();
                                }}
                            />
                        </div>
                    </form>
                )}
            </Formik>
        </>
    ) : (
        <>
            <SectionHeader
                name="Education Information"
                form={form}
                setForm={setForm}
                employeeDetails={educationDetails}
            />
            <EducationInfoSection id={props.id} />
        </>
    );
}
