import { useListAccessories, useRetrieveMSUser } from '../../api';

import { useState } from 'react';
import ButtonField from '../FormFields/ButtonField';
import AccessoriesUpdateForm from './AccessoriesUpdateForm';
import SectionHeader from './SectionHeader';
import AccessoriesInfoSection from './Sections/AccessoriesInfoSection';

export default function AccessoriesInformationUpdate(props: { id: string }) {
    const [form, setForm] = useState(false);
    const { data: usersData } = useRetrieveMSUser(props.id);
    const { data: accessoriesData } = useListAccessories({
        axios: {
            params: {
                assigned_to: usersData?.data.MSemployee_details.id,
            },
        },
    });

    return form ? (
        <>
            <SectionHeader
                name="Employee Accessories"
                form={form}
                setForm={setForm}
                employeeDetails={accessoriesData}
            />
            {accessoriesData?.data.map((accessory) => (
                <AccessoriesUpdateForm
                    key={accessory.id}
                    accessory={accessory}
                    setForm={setForm}
                />
            ))}
            {form ? (
                <div className="w-full flex pl-[40%]">
                    <ButtonField
                        name="Cancel"
                        onClick={() => setForm(false)}
                        className=""
                    />
                </div>
            ) : null}
        </>
    ) : (
        <>
            <SectionHeader
                name="Employee Accessories"
                form={form}
                setForm={setForm}
                employeeDetails={accessoriesData}
            />
            <AccessoriesInfoSection id={props.id} />
        </>
    );
}
