import 'react-toastify/dist/ReactToastify.css';

import * as Yup from 'yup';

import { Field, Form, Formik } from 'formik';
import { Bank, MSViewer, useCreateBank, useUpdateBank } from '../api';

import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { BANK_FIELD } from '../constants/FieldNames';
import { BANKINITIAL } from '../constants/initialValues';
import { UPIAPP } from '../constants/options';
import Button from './FormFields/Button';

const BankValidationSchema = Yup.object().shape({
    account_no: Yup.string()
        .max(20)
        .required('Required')
        .matches(/^[0-9]+$/, 'Must be only digits'),
    bank_name: Yup.string().max(100).required('Required'),
    customer_name: Yup.string().max(50).required('Required'),
    branch: Yup.string().max(30).required('Required'),
    IFSC: Yup.string().max(50).required('Required'),
    PAN: Yup.string().max(20).required('Required'),
    upi_id: Yup.string().max(50).required('Required'),
    aadhar_no: Yup.string()
        .max(12)
        .min(12)
        .required('Required')
        .matches(/^[0-9]+$/, 'Must be only digits'),
    upi_mobile: Yup.string()
        .min(10)
        .max(10)
        .required('Required')
        .matches(/^\d*$/),
});

function BankForm(props: { userData: MSViewer }) {
    const { mutateAsync: createMutate, isSuccess: createSuccess } =
        useCreateBank();
    const { mutateAsync: updateMutate, isSuccess: updateSuccess } =
        useUpdateBank();

    const empDetails = props.userData.MSemployee_details;

    const handleSubmit = async (values: Bank) => {
        if (empDetails === undefined || empDetails === null) {
            // console.log('Create a new employee');
        } else {
            const updatedVal: Bank = {
                ...values,
                employee_id: empDetails.id,
            };

            if (
                empDetails.bank_details === null ||
                empDetails.bank_details === undefined
            ) {
                await createMutate({ data: updatedVal });
            } else if (empDetails.bank_details?.id !== undefined) {
                await updateMutate({
                    id: empDetails.bank_details?.id.toString(),
                    data: updatedVal,
                });
            }
        }
    };

    useEffect(() => {
        if (createSuccess) toast('Bank Details Added');
        if(updateSuccess) toast('Bank Details Updated');
    }, [createSuccess, updateSuccess]);
    
    const options = UPIAPP.map((val) => (
        <option key={val} value={val}>
            {val}
        </option>
    ));

    return (
        <div>
            <h2 className="py-4 border-x-zxblue-from text-transparent font-semibold md:text-xl lg:text-2xl bg-clip-text bg-gradient-to-t from-zxheader-from to-zxheader-to">
                <span className="border-l-2 border-zxsub-to pl-1">
                    Bank Information
                </span>
            </h2>
            <Formik
                initialValues={BANKINITIAL}
                validationSchema={BankValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ touched, errors }) => (
                    <Form className="text-sm grid md:grid-cols-3 lg:grid-cols-4 pl-3">
                        <div className="flex flex-col">
                            <label
                                className={
                                    touched.aadhar_no && errors.aadhar_no
                                        ? 'pl-0.5 text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                                        : 'pl-0.5'
                                }
                            >
                                Aadhar Number *
                            </label>
                            <Field
                                id={BANK_FIELD.AADHAR_NO}
                                name={BANK_FIELD.AADHAR_NO}
                                className={
                                    touched.aadhar_no && errors.aadhar_no
                                        ? 'w-9/12 mb-4 border border-zxerror-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                                        : 'w-9/12 mb-4 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5 '
                                }
                            />
                            {errors.aadhar_no && touched.aadhar_no ? (
                                <span className="text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to text-xs -mt-4">
                                    {errors.aadhar_no}
                                </span>
                            ) : null}
                        </div>

                        <div className="flex flex-col">
                            <label
                                className={
                                    touched.PAN && errors.PAN
                                        ? 'pl-0.5 text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                                        : 'pl-0.5'
                                }
                            >
                                PAN Number *
                            </label>
                            <Field
                                id={BANK_FIELD.PAN}
                                name={BANK_FIELD.PAN}
                                className={
                                    touched.PAN && errors.PAN
                                        ? 'w-9/12 mb-4 border border-zxerror-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                                        : 'w-9/12 mb-4 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5 '
                                }
                            />
                            {errors.PAN && touched.PAN ? (
                                <span className="text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to text-xs -mt-4">
                                    {errors.PAN}
                                </span>
                            ) : null}
                        </div>

                        <div className="flex flex-col">
                            <label
                                className={
                                    touched.bank_name && errors.bank_name
                                        ? 'pl-0.5 text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                                        : 'pl-0.5'
                                }
                            >
                                Bank Name *
                            </label>
                            <Field
                                id={BANK_FIELD.BANK_NAME}
                                name={BANK_FIELD.BANK_NAME}
                                className={
                                    touched.bank_name && errors.bank_name
                                        ? 'w-9/12 mb-4 border border-zxerror-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                                        : 'w-9/12 mb-4 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5 '
                                }
                            />
                            {errors.bank_name && touched.bank_name ? (
                                <span className="text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to text-xs -mt-4">
                                    {errors.bank_name}
                                </span>
                            ) : null}
                        </div>

                        <div className="flex flex-col">
                            <label
                                className={
                                    touched.branch && errors.branch
                                        ? 'pl-0.5 text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                                        : 'pl-0.5'
                                }
                            >
                                Branch *
                            </label>
                            <Field
                                id={BANK_FIELD.BRANCH}
                                name={BANK_FIELD.BRANCH}
                                className={
                                    touched.branch && errors.branch
                                        ? 'w-9/12 mb-4 border border-zxerror-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                                        : 'w-9/12 mb-4 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5 '
                                }
                            />
                            {errors.branch && touched.branch ? (
                                <span className="text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to text-xs -mt-4">
                                    {errors.branch}
                                </span>
                            ) : null}
                        </div>

                        <div className="flex flex-col">
                            <label
                                className={
                                    touched.account_no && errors.account_no
                                        ? 'pl-0.5 text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                                        : 'pl-0.5'
                                }
                            >
                                A/C Number *
                            </label>
                            <Field
                                id={BANK_FIELD.ACCOUNT_NO}
                                name={BANK_FIELD.ACCOUNT_NO}
                                className={
                                    touched.account_no && errors.account_no
                                        ? 'w-9/12 mb-4 border border-zxerror-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                                        : 'w-9/12 mb-4 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5 '
                                }
                            />
                            {errors.account_no && touched.account_no ? (
                                <span className="text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to text-xs -mt-4">
                                    {errors.account_no}
                                </span>
                            ) : null}
                        </div>

                        <div className="flex flex-col">
                            <label
                                className={
                                    touched.IFSC && errors.IFSC
                                        ? 'pl-0.5 text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                                        : 'pl-0.5'
                                }
                            >
                                IFSC Code *
                            </label>
                            <Field
                                id={BANK_FIELD.IFSC}
                                name={BANK_FIELD.IFSC}
                                className={
                                    touched.IFSC && errors.IFSC
                                        ? 'w-9/12 mb-4 border border-zxerror-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                                        : 'w-9/12 mb-4 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5 '
                                }
                            />
                            {errors.IFSC && touched.IFSC ? (
                                <span className="text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to text-xs -mt-4">
                                    {errors.IFSC}
                                </span>
                            ) : null}
                        </div>

                        <div className="flex flex-col">
                            <label
                                className={
                                    touched.customer_name &&
                                    errors.customer_name
                                        ? 'pl-0.5 text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                                        : 'pl-0.5'
                                }
                            >
                                Name as per Bank *
                            </label>
                            <Field
                                id={BANK_FIELD.CUSTOMER_NAME}
                                name={BANK_FIELD.CUSTOMER_NAME}
                                className={
                                    touched.customer_name &&
                                    errors.customer_name
                                        ? 'w-9/12 mb-4 border border-zxerror-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                                        : 'w-9/12 mb-4 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5 '
                                }
                            />
                            {errors.customer_name && touched.customer_name ? (
                                <span className="text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to text-xs -mt-4">
                                    {errors.customer_name}
                                </span>
                            ) : null}
                        </div>

                        <div className="flex flex-col">
                            <label
                                className={
                                    touched.upi_id && errors.upi_id
                                        ? 'pl-0.5 text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                                        : 'pl-0.5'
                                }
                            >
                                UPI ID *
                            </label>
                            <Field
                                id={BANK_FIELD.UPI_ID}
                                name={BANK_FIELD.UPI_ID}
                                className={
                                    touched.upi_id && errors.upi_id
                                        ? 'w-9/12 mb-4 border border-zxerror-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                                        : 'w-9/12 mb-4 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5 '
                                }
                            />
                            {errors.upi_id && touched.upi_id ? (
                                <span className="text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to text-xs -mt-4">
                                    {errors.upi_id}
                                </span>
                            ) : null}
                        </div>

                        <div className="flex flex-col">
                            <label
                                className={
                                    touched.upi_mobile && errors.upi_mobile
                                        ? 'pl-0.5 text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                                        : 'pl-0.5'
                                }
                            >
                                UPI Mobile Number *
                            </label>
                            <Field
                                id={BANK_FIELD.UPI_MOBILE}
                                name={BANK_FIELD.UPI_MOBILE}
                                className={
                                    touched.upi_mobile && errors.upi_mobile
                                        ? 'w-9/12 mb-4 border border-zxerror-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                                        : 'w-9/12 mb-4 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5 '
                                }
                            />
                            {errors.upi_mobile && touched.upi_mobile ? (
                                <span className="text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to text-xs -mt-4">
                                    {errors.upi_mobile}
                                </span>
                            ) : null}
                        </div>

                        <div className="flex flex-col">
                            <label
                                className={
                                    touched.upi_app && errors.upi_app
                                        ? 'pl-0.5 text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                                        : 'pl-0.5'
                                }
                            >
                                UPI APP *
                            </label>
                            <Field
                                as="select"
                                id={BANK_FIELD.UPI_APP}
                                name={BANK_FIELD.UPI_APP}
                                className={
                                    touched.upi_app && errors.upi_app
                                        ? 'w-9/12 mb-4 border border-zxerror-to rounded-md focus:outline-none placeholder: pl-2 py-1'
                                        : 'w-9/12 mb-4 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-1'
                                }
                            >
                                {options}
                            </Field>
                            {errors.upi_app && touched.upi_app ? (
                                <span className="text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to text-xs -mt-4">
                                    {errors.upi_app}
                                </span>
                            ) : null}
                        </div>

                        <Button value="Submit" className="mt-3" />
                    </Form>
                )}
            </Formik>
            <div className="relative mt-3 mx-12 right-7 border-b border-3 border-zxheader-from" />
        </div>
    );
}

export default BankForm;
