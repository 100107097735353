import Tlattform from './Tlattform';

function AttendanceFrame() {
    return (
        <div className="flex flex-col w-full">
            <div className="bg-white shadow-2xl bg-opacity-60 backdrop-blur-x p-10">
                <h1 className="border-x-zxblue-from text-transparent font-semibold md:text-lg lg:text-xl">
                    <span className="border-l-4 border-zxsub-to pl-2 py-1 bg-clip-text bg-gradient-to-r from-zxheader-from to-zxheader-to opacity-100">
                        Attendance Calendar
                    </span>
                </h1>
                <Tlattform />
            </div>
        </div>
    );
}
export default AttendanceFrame;
