import { AxiosResponse } from 'axios';
import { Accessories, Accessory, useListAccessorys } from '../../api';

interface AccessoryCountRowProps<A, R> {
    accessoryData: AxiosResponse<Accessories[], any> | undefined;
    // refetch: (options?: A) => R;
}

export default function AccCountTableRow<A, R>(
    props: AccessoryCountRowProps<A, R>
) {
    const { data: accessorysData } = useListAccessorys();
    const accessories = props.accessoryData?.data
        ? props.accessoryData.data
        : [];
    let counter = 0;

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {accessorysData?.data.map((accessory: Accessory) => (
                <tr key={accessory.id} className="h-14 hover:shadow-lg">
                    <td className="text-center">{++counter}</td>
                    <td className="text-center">{accessory.name}</td>
                    <td className="text-center">
                        {
                            accessories.filter((x) => x.type === accessory.id)
                                .length
                        }
                    </td>
                </tr>
            ))}
        </>
    );
}
