import moment from 'moment';
import {
    EmpAttendance
} from '../api';

function AttAvgHrs(props: {
    att_data: EmpAttendance[] | undefined;
    isMonthlyAttndSuccess: boolean;
}) {
    if (props.isMonthlyAttndSuccess && props.att_data) {
        const totalHrs = props.att_data?.reduce((acc, curr) => {
            const startTime = moment(curr.sign_in, 'HH:mm:ss a');
            const endTime = moment(curr.sign_out, 'HH:mm:ss a');
            const duration = moment.duration(endTime.diff(startTime));
            return acc + duration.hours();
        }, 0);
        const avgHrs = totalHrs / props.att_data.length;
        const avgmin =
            ((totalHrs % props.att_data.length) * 60) / props.att_data.length;

        return (
            <h1 className="m-auto pl-7">
                {Math.floor(avgHrs).toString()} Hrs {Math.floor(avgmin).toString()} min
            </h1>
        );
    }
    return null;
}
export default AttAvgHrs;
