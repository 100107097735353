/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  Employee
} from '../../schema'


/**
 * Get employee details
 */
export const retrieveEmployee = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Employee>> => {
    return axios.get(
      `/employee/${id}/`,options
    );
  }


export const getRetrieveEmployeeQueryKey = (id: string,) => [`/employee/${id}/`];

    
export type RetrieveEmployeeQueryResult = NonNullable<Awaited<ReturnType<typeof retrieveEmployee>>>
export type RetrieveEmployeeQueryError = AxiosError<unknown>

export const useRetrieveEmployee = <TData = Awaited<ReturnType<typeof retrieveEmployee>>, TError = AxiosError<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof retrieveEmployee>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveEmployeeQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveEmployee>>> = ({ signal }) => retrieveEmployee(id, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof retrieveEmployee>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * Update employee details
 */
export const updateEmployee = (
    id: string,
    employee: Employee, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Employee>> => {
    return axios.put(
      `/employee/${id}/`,
      employee,options
    );
  }



    export type UpdateEmployeeMutationResult = NonNullable<Awaited<ReturnType<typeof updateEmployee>>>
    export type UpdateEmployeeMutationBody = Employee
    export type UpdateEmployeeMutationError = AxiosError<unknown>

    export const useUpdateEmployee = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateEmployee>>, TError,{id: string;data: Employee}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateEmployee>>, {id: string;data: Employee}> = (props) => {
          const {id,data} = props ?? {};

          return  updateEmployee(id,data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof updateEmployee>>, TError, {id: string;data: Employee}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * Delete a employee
 */
export const destroyEmployee = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    return axios.delete(
      `/employee/${id}/`,options
    );
  }



    export type DestroyEmployeeMutationResult = NonNullable<Awaited<ReturnType<typeof destroyEmployee>>>
    
    export type DestroyEmployeeMutationError = AxiosError<unknown>

    export const useDestroyEmployee = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof destroyEmployee>>, TError,{id: string}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof destroyEmployee>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  destroyEmployee(id,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof destroyEmployee>>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    