/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */

export type BankUpiApp = typeof BankUpiApp[keyof typeof BankUpiApp];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BankUpiApp = {
  Gpay: 'Gpay',
  Phonepe: 'Phonepe',
  BHIM: 'BHIM',
  Amazon_Pay: 'Amazon Pay',
  WhatsApp: 'WhatsApp',
  Other: 'Other',
} as const;
