import { useRetrieveShift } from '../api';

interface ShiftProps {
    shift: number | null | undefined;
    className?: string | undefined;
}

function ShiftTime(props: ShiftProps) {
    const { data: shiftData, isSuccess } = useRetrieveShift(
        props.shift ? props.shift.toString() : ''
    );
    return isSuccess && shiftData ? (
        <td className={props.className}>
            {shiftData.data.start_time} - {shiftData.data.end_time}
        </td>
    ) : (
        <td>--:-- - --:--</td>
    );
}

export default ShiftTime;
