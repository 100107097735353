/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  Salary
} from '../../schema'


/**
 * List all Salarys
 */
export const listSalarys = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Salary[]>> => {
    return axios.get(
      `/salaries/`,options
    );
  }


export const getListSalarysQueryKey = () => [`/salaries/`];

    
export type ListSalarysQueryResult = NonNullable<Awaited<ReturnType<typeof listSalarys>>>
export type ListSalarysQueryError = AxiosError<unknown>

export const useListSalarys = <TData = Awaited<ReturnType<typeof listSalarys>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listSalarys>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListSalarysQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listSalarys>>> = ({ signal }) => listSalarys({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof listSalarys>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * Create Salary
 */
export const createSalary = (
    salary: Salary, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Salary>> => {
    return axios.post(
      `/salaries/`,
      salary,options
    );
  }



    export type CreateSalaryMutationResult = NonNullable<Awaited<ReturnType<typeof createSalary>>>
    export type CreateSalaryMutationBody = Salary
    export type CreateSalaryMutationError = AxiosError<unknown>

    export const useCreateSalary = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createSalary>>, TError,{data: Salary}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createSalary>>, {data: Salary}> = (props) => {
          const {data} = props ?? {};

          return  createSalary(data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof createSalary>>, TError, {data: Salary}, TContext>(mutationFn, mutationOptions)
    }
    