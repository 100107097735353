import { useFormik } from 'formik';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useCreateShift, useListShifts } from '../../api';
import { Shift, ShiftBufferTime } from '../../api/schema';
import { ShiftBufferTimeOptions } from '../../constants/formSelect';
import Button from '../FormFields/Button';
import Field from '../FormFields/Field';
import Select from '../FormFields/Select';

export default function CreateShift() {
    const { data: shiftData, refetch } = useListShifts();
    const { mutateAsync, isSuccess } = useCreateShift();
    const shiftNames = shiftData?.data;

    const formik = useFormik({
        initialValues: {
            shift_name: '',
            start_time: '',
            end_time: '',
            buffer_time: ShiftBufferTime[10],
        },
        validationSchema: Yup.object({
            shift_name: Yup.string().max(20).required('Required'),
            start_time: Yup.string().max(10),
            end_time: Yup.string().max(10),
            buffer_time: Yup.string()
                .oneOf(ShiftBufferTimeOptions, 'Choose a value')
                .required('Required'),
        }),
        onSubmit: async (values: Shift) => {
            if (
                shiftNames?.some(
                    (shift: Shift) => shift.shift_name === values.shift_name
                )
            ) {
                toast.warn(`${values.shift_name}is present in Database`);
            } else {
                const createShift: Shift = {
                    ...values,
                    start_time: values.start_time ? values.start_time : null,
                    end_time: values.end_time ? values.end_time : null,
                };
                await mutateAsync({ data: createShift });
                toast.success(`${values.shift_name} created`);
            }
        },
    });

    useEffect(() => {
        if (isSuccess) {
            refetch();
        }
    }, [isSuccess, refetch]);

    return (
        <div className="flex flex-col px-10">
            <h2 className="py-4 border-x-zxblue-from text-transparent font-semibold md:text-xl lg:text-2xl bg-clip-text bg-gradient-to-t from-zxheader-from to-zxheader-to">
                <span className="border-l-2 border-zxsub-to pl-1">
                    Create Shift
                </span>
            </h2>
            <form className="grid grid-cols-3" onSubmit={formik.handleSubmit}>
                <Field
                    label="Shift Name *"
                    type="text"
                    id="shift_name"
                    name="shift_name"
                    placeholder={undefined}
                    colspan={undefined}
                    onChange={formik.handleChange}
                    value={formik.values.shift_name}
                    touched={formik.touched.shift_name}
                    formik={formik.errors.shift_name}
                />
                <Field
                    label="Start Time *"
                    type="time"
                    id="start_time"
                    name="start_time"
                    placeholder="HH:MM am/pm"
                    colspan={undefined}
                    onChange={formik.handleChange}
                    value={formik.values.start_time}
                    touched={formik.touched.start_time}
                    formik={formik.errors.start_time}
                />
                <Field
                    label="End Time *"
                    type="time"
                    id="end_time"
                    name="end_time"
                    placeholder="HH:MM am/pm"
                    colspan={undefined}
                    onChange={formik.handleChange}
                    value={formik.values.end_time}
                    touched={formik.touched.end_time}
                    formik={formik.errors.end_time}
                />
                <Select
                    label="Buffer Time"
                    options={ShiftBufferTimeOptions}
                    id="buffer_time"
                    name="buffer_time"
                    onChange={formik.handleChange}
                    value={formik.values.buffer_time}
                    touched={formik.touched.buffer_time}
                    formik={formik.errors.buffer_time}
                />
                <Button value="Submit" className="mt-3 col-start-2" />
            </form>
        </div>
    );
}
