import { FormikTouched } from 'formik';
import { useEffect } from 'react';
import { Leave, useRetrieveEmployee } from '../api';

export default function SetContactField(props: {
    name: string;
    employee_id: number;
    setFieldValue: any;
    touched: FormikTouched<Leave>;
}) {
    const { employee_id, touched, name, setFieldValue } = props;
    const { data: empData } = useRetrieveEmployee(
        employee_id ? employee_id.toString() : ''
    );
    const contact_no = empData?.data.profile_details?.contact_no ? empData.data.profile_details.contact_no : '';

    useEffect(() => {
        if (
            (employee_id !== null || employee_id !== undefined) &&
            touched.employee_id
        ) {
            setFieldValue(name, contact_no);
        }
    }, [touched, employee_id]);

    return (
        <div>
            <label>Contact No</label>
            <br />
            <input
                className="w-[85%] mb-4 mt-2 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5"
                name={name}
                id="contact_no"
                value={contact_no}
                disabled
                placeholder='-'
            />
        </div>
    );
}
