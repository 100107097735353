import { Tab } from '@headlessui/react';
import { useEffect } from 'react';
import {
    Regularization,
    useListEmployees,
    useListMSViewers,
    useListTeamRegularizations
} from '../api';
import AttRegAppForm from '../components/AttRegAppForm';
import EmpReg from '../components/EmpReg';

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

function AttStatusBar() {
    const {
        data: usersData,
        isSuccess: userSuccess,
        isLoading: userLoading,
    } = useListMSViewers();

    const {
        data: regData,
        isSuccess,
        refetch,
    } = useListTeamRegularizations({
        axios: {
            params: {
                reporting_to: usersData?.data[0].MSemployee_details?.id,
            },
        },
    });

    const {
        data: employeeData,
        isSuccess: empSuccess,
        refetch: empfetch,
    } = useListEmployees({
        axios: {
            params: {
                reporting_manager: usersData?.data[0].MSemployee_details?.id,
            },
        },
    });

    const emp_data = employeeData?.data;

    // const emp_id=employeeData?.data.map((emp:Employee)=>
    // emp.id)


    // const{
    // data:empregData,
    // isSuccess:empregSuccess
    // }=useRetrieveEmployeeRegularization(String(team_emp_id))

    // later
    useEffect(() => {
        if (usersData?.data[0].MSemployee_details?.id) {
            refetch();
            empfetch();
        }
    }, [refetch, empfetch, usersData?.data]);

    const regs = regData?.data;

    return (
        <div className="flex flex-col w-full">
            <Tab.Group>
                <div className="text-center shadow-md h-[13%] border border-zxblue-to rounded-md focus:outline-none w-[85%] ml-[9%]  px-6 mt-[3%]">
                    <Tab.List className="grid grid-cols-5">
                        <Tab
                            className={({ selected }) =>
                                classNames(
                                    'w-full rounded-lg py-1.5 text-base',
                                    selected
                                        ? 'lg:text-base font-bold bg-clip-text text-transparent bg-gradient-to-r from-tabtext-from to-tabtext-to outline-none'
                                        : 'text-gray-500 font-semibold hover:bg-white/[0.12] hover:text-violet-900'
                                )
                            }
                        >
                            <span className="pl-3">APPLIED</span>
                        </Tab>
                        <span className="py-1.5">|</span>
                        <Tab
                            className={({ selected }) =>
                                classNames(
                                    'w-full rounded-lg py-1.5 text-base ',
                                    selected
                                        ? 'lg:text-base font-bold bg-clip-text text-transparent bg-gradient-to-r from-tabtext-from to-tabtext-to outline-none pr-[7%]'
                                        : 'text-gray-500 font-semibold hover:bg-white/[0.12] hover:text-violet-900'
                                )
                            }
                        >
                            ACCEPTED
                        </Tab>
                        <span className="py-1.5">|</span>
                        <Tab
                            className={({ selected }) =>
                                classNames(
                                    'w-full rounded-lg py-1.5 text-base pr-20',
                                    selected
                                        ? 'lg:text-base font-bold bg-clip-text text-transparent bg-gradient-to-r from-tabtext-from to-tabtext-to outline-none'
                                        : 'text-gray-500 font-semibold hover:bg-white/[0.12] hover:text-violet-900'
                                )
                            }
                        >
                            REJECTED
                        </Tab>
                    </Tab.List>
                </div>
                <Tab.Panels className="pt-4">
                    <Tab.Panel>
                        <div className="overflow-y-auto h-full">
                            {employeeData?.data && empSuccess && emp_data
                                ? emp_data.map((e) => (
                                      <EmpReg
                                          key={e?.id}
                                          id={e?.id}
                                          emp={e}
                                          empfetch={empfetch}
                                      />
                                  ))
                                : null}
                            {/* {regs && isSuccess
                        ? regs.map((reg: Regularization) =>
                              reg.status === 'Pending' ? (
                                  <AttRegAppForm
                                      key={reg.id}
                                      regData={reg}
                                      regsData={regs}
                                      ref={refetch()}
                                  />
                              ) : null
                          )
                        : null}{' '} */}
                        </div>
                    </Tab.Panel>
                    <Tab.Panel>
                        <div className="overflow-y-auto">
                            {regs && isSuccess
                                ? regs.map((reg: Regularization) =>
                                      reg.status === 'Approved' ? (
                                          <AttRegAppForm
                                              key={reg?.id}
                                              regData={reg}
                                              regsData={regs}
                                              refetch={refetch}
                                          />
                                      ) : null
                                  )
                                : null}{' '}
                        </div>
                    </Tab.Panel>
                    <Tab.Panel>
                        <div className="overflow-y-auto">
                            {regs && isSuccess
                                ? regs.map((reg: Regularization) =>
                                      reg.status === 'Rejected' ? (
                                          <AttRegAppForm
                                              key={reg?.id}
                                              regData={reg}
                                              regsData={regs}
                                              refetch={refetch}
                                          />
                                      ) : null
                                  )
                                : null}{' '}
                        </div>
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        </div>
    );
}

export default AttStatusBar;