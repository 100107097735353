import { ErrorMessage, Field, useFormikContext } from 'formik';

import classnames from 'classnames';
import { HTMLAttributes } from 'react';
import { getHtmlFor } from '../../utils/formUtils';

interface FormikSelectProps<T> extends HTMLAttributes<HTMLSelectElement> {
    label: string;
    name: keyof T;
    touched: boolean | undefined;
}

function FormikSelect<T>({
    label,
    children,
    name,
    ...props
}: FormikSelectProps<T>) {
    const { touched, errors } = useFormikContext<T>();

    return (
        <div className="flex flex-col">
            <label
                htmlFor={getHtmlFor(name)}
                className={classnames(
                    'pl-[1%]',
                    touched[name] && errors[name]
                        ? 'text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                        : 'border-zxblue-to'
                )}
            >
                {label}
                <span className="text-zxerror-from pl-1">*</span>
            </label>
            <Field
                as="select"
                name={name}
                className={classnames(
                    'w-full h-8 mb-[2%] rounded-md focus:outline-none placeholder: pl-[4%] py-[1%] border',
                    touched[name] && errors[name]
                        ? 'border-zxerror-to'
                        : 'border-zxblue-to'
                )}
                {...props}
            >
                <option defaultValue={undefined}>Select</option>
                {children}
            </Field>
            <ErrorMessage
                name={name.toString()}
                component="span"
                className="text-[77%] text-zxerror-from"
            />
        </div>
    );
}

export default FormikSelect;
