import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import {
    Department,
    Designation,
    EmployeeGrade,
    MSUser,
    Shift,
    useCreateMSUser,
    useListDepartments,
    useListDesignations,
    useListMSUsers,
    useListShifts
} from '../../../api';
import { EmpGradeOptions } from '../../../constants/formSelect';
import { BASIC_INFORMATION_PAGE, EMPLOYEES } from '../../../constants/router';
import { EmployeeCreationOutletContext } from '../../../pages/EmployeeCreation';
import ButtonField from '../../FormFields/ButtonField';
import FormikSelect from '../../FormFields/FormikSelect';
import AddNewDepartment from '../AddNewDepartment';
import AddNewDesignation from '../AddNewDesingation';

const EmployeePositionValidationSchema = Yup.object().shape({
    department: Yup.number().typeError('Choose a value').required('Required'),
    designation: Yup.number().typeError('Choose a value').required('Required'),
    grade: Yup.string()
        .oneOf(EmpGradeOptions, 'Choose a value')
        .required('Required'),
    reporting_manager: Yup.number()
        .typeError('Choose a value')
        .required('Required'),
    shift_id: Yup.number().typeError('Choose a value').required('Required'),
});

export interface EmployeePosition {
    department: number;
    designation: number;
    grade: EmployeeGrade;
    reporting_manager: number;
    shift_id: number;
}

const EmployeePositionSelect = FormikSelect<EmployeePosition>;

export default function Step2Form() {
    const { mutateAsync, isSuccess: empCreateSuccess } = useCreateMSUser();

    const { setFormData, formData } =
        useOutletContext<EmployeeCreationOutletContext>();

    const navigate = useNavigate();
    const location = useLocation();

    const [addDept, setAddDept] = useState(false);
    const [addDesgn, setAddDesgn] = useState(false);

    const {
        data: desgnData,
        // refetch: fetchDesignation,
        // isSuccess: isSuccessDesgn,
    } = useListDesignations();
    const desgnId = desgnData?.data;
    const {
        data: deptData,
        // refetch: fetchDepartment,
        // isSuccess: isSuccessDept,
    } = useListDepartments();
    const deptId = deptData?.data;
    const { data: shiftData } = useListShifts();
    const shiftIDs = shiftData?.data;
    const { data: usersData } = useListMSUsers();
    const empData = usersData?.data;

    const handleSubmit = async (values: EmployeePosition) => {
        setFormData({ ...formData, step2: values });
        const createEmp: MSUser = {
            email: formData.step1.email_id,
            MSemployee_details: {
                ...formData.step1,
                ...values,
                department_id: values.department,
                designation_id: values.designation,
                joining_date: String(formData.step1.joining_date),
                probation_period: formData.step1.probation_period,
                confirmation_date: new Date(
                    formData.step1.confirmation_date
                ).toLocaleDateString('en-CA'),
                reporting_manager: Number(values.reporting_manager),
                shift_id: values.shift_id,
            },
        };
        await mutateAsync({ data: createEmp });
        // if (isSuccessDept) {
        //     setAddDept(!addDept);
        //     fetchDepartment();
        // }
        // if (isSuccessDesgn) {
        //     setAddDesgn(!addDesgn);
        //     fetchDesignation();
        // }
    };

    useEffect(() => {
        if (empCreateSuccess) {
            toast.success('Employee Created');
            navigate(EMPLOYEES);
        }
    }, [empCreateSuccess, navigate]);

    return (
        <Formik
            initialValues={formData.step2}
            onSubmit={handleSubmit}
            validationSchema={EmployeePositionValidationSchema}
        >
            {({ touched, submitForm }) => (
                <Form className="grid grid-flow-row gap-5 md:grid-cols-3 lg:grid-cols-4 px-[1%]">
                    <div className="flex flex-col">
                        <EmployeePositionSelect
                            label="Department"
                            name="department"
                            touched={touched.department}
                        >
                            {deptId?.map((deptID: Department) => (
                                <option key={deptID.id} value={deptID.id}>
                                    {deptID.name}
                                </option>
                            ))}
                        </EmployeePositionSelect>
                        <button
                            type="button"
                            className="text-sm self-end pr-2"
                            onClick={() => {
                                setAddDept(true);
                            }}
                        >
                            Add New
                        </button>
                        <AddNewDepartment
                            addNewDept={addDept}
                            setAddNewDept={setAddDept}
                        />
                    </div>

                    <div className="flex flex-col">
                        <EmployeePositionSelect
                            label="Designation"
                            name="designation"
                            touched={touched.designation}
                        >
                            {desgnId?.map((desgnID: Designation) => (
                                <option key={desgnID.id} value={desgnID.id}>
                                    {desgnID.name}
                                </option>
                            ))}
                        </EmployeePositionSelect>
                        <button
                            type="button"
                            className="text-sm self-end pr-2"
                            onClick={() => {
                                setAddDesgn(true);
                            }}
                        >
                            Add New
                        </button>
                        <AddNewDesignation
                            addNewDesgn={addDesgn}
                            setAddNewDesgn={setAddDesgn}
                        />
                    </div>
                    <EmployeePositionSelect
                        label="Grade"
                        name="grade"
                        touched={touched.grade}
                    >
                        {EmpGradeOptions.map((item) => (
                            <option key={item} value={item}>
                                {item}
                            </option>
                        ))}
                    </EmployeePositionSelect>
                    <EmployeePositionSelect
                        label="Reporting Manager"
                        name="reporting_manager"
                        touched={touched.reporting_manager}
                    >
                        {empData?.map((empID: MSUser) => (
                            <option
                                key={empID.MSemployee_details?.id}
                                value={empID.MSemployee_details?.id}
                            >
                                {empID.MSemployee_details?.full_name}
                            </option>
                        ))}
                    </EmployeePositionSelect>
                    <EmployeePositionSelect
                        label="Attendance Type"
                        name="shift_id"
                        touched={touched.shift_id}
                    >
                        {shiftIDs?.map((shiftID: Shift) => (
                            <option key={shiftID.id} value={shiftID.id}>
                                {shiftID.shift_name}
                            </option>
                        ))}
                    </EmployeePositionSelect>
                    <div className="lg:pl-[30%] row-start-5 space-x-[5%] col-span-4 md:col-start-1">
                        <div className="space-x-10">
                            <ButtonField
                                name="Previous"
                                location={location.pathname}
                                onClick={() => navigate(BASIC_INFORMATION_PAGE)}
                            />
                            <ButtonField
                                name="Cancel"
                                onClick={() => navigate(EMPLOYEES)}
                            />
                            <ButtonField
                                name="Submit"
                                onClick={() => {
                                    submitForm();
                                }}
                            />
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
}
