import { useNavigate } from 'react-router-dom';
import { Employee } from '../../api';
import { ATTENDANCEMUSTER } from '../../constants/router';
import EmpMonthlyAtt from './EmpMonthlyAtt';

interface Props {
    empData: Employee[];
    month: number;
    year: number;
}

function TeamMemberCol(props: Props) {
    const navigate = useNavigate();
    return (
        <>
            {props.empData.map((emp: Employee) => (
                <tr
                    className="hover:shadow-[0_0_10px_0_rgba(0,0,0,0.25)] rounded-lg"
                    id="muster-row"
                    key={emp.id}
                    onClick={() =>
                        navigate(`${ATTENDANCEMUSTER}/${emp.id}`, {
                            state: { month: props.month, year: props.year },
                        })
                    }
                >
                    <td>{emp.id}</td>
                    <td className="text-left bg-zxbg sticky left-0 top-0">{emp.full_name}</td>
                    <td> </td>
                    {emp.id ? (
                        <EmpMonthlyAtt
                            empId={emp.id}
                            month={props.month}
                            year={props.year}
                        />
                    ) : null}
                </tr>
            ))}
        </>
    );
}

export default TeamMemberCol;
