import { useRetrieveEmployee } from '../../api';

export default function GetEmployeeName(props: {
    id: number | null | undefined;
}) {
    const { data: empData } = useRetrieveEmployee(
        props.id ? props.id.toString() : ''
    );
    return <span>{empData?.data.full_name}</span>;
}
