/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  Address
} from '../../schema'


/**
 * List all addresses
 */
export const listAddress = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Address[]>> => {
    return axios.get(
      `/addresses/`,options
    );
  }


export const getListAddressQueryKey = () => [`/addresses/`];

    
export type ListAddressQueryResult = NonNullable<Awaited<ReturnType<typeof listAddress>>>
export type ListAddressQueryError = AxiosError<unknown>

export const useListAddress = <TData = Awaited<ReturnType<typeof listAddress>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listAddress>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListAddressQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listAddress>>> = ({ signal }) => listAddress({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof listAddress>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * Create a address
 */
export const createAddress = (
    address: Address, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Address>> => {
    return axios.post(
      `/addresses/`,
      address,options
    );
  }



    export type CreateAddressMutationResult = NonNullable<Awaited<ReturnType<typeof createAddress>>>
    export type CreateAddressMutationBody = Address
    export type CreateAddressMutationError = AxiosError<unknown>

    export const useCreateAddress = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAddress>>, TError,{data: Address}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAddress>>, {data: Address}> = (props) => {
          const {data} = props ?? {};

          return  createAddress(data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof createAddress>>, TError, {data: Address}, TContext>(mutationFn, mutationOptions)
    }
    