import Session from 'supertokens-web-js/recipe/session';
import { UserRoleClaim } from 'supertokens-web-js/recipe/userroles';

export const getDaysInMonth = (month: number, year: number) => {
    const date = new Date(year, month, 1);
    const days = [];
    while (date.getMonth() === month) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }
    return days;
};

export const isAdmin = async () => {
    if (await Session.doesSessionExist()) {
        const validationErrors = await Session.validateClaims({
            overrideGlobalClaimValidators: (globalValidators) => [
                ...globalValidators,
                UserRoleClaim.validators.includes('admin'),
                /* PermissionClaim.validators.includes("modify") */
            ],
        });

        if (validationErrors.length === 0) {
            // user is an admin
            return true;
        }
        for (const err of validationErrors) {
            if (err.validatorId === UserRoleClaim.id) {
                // user is not an admin
                return false;
            }
        }
    }
    // either a session does not exist, or the user is not an admin
    return false;
};

export const isTL = async () => {
    if (await Session.doesSessionExist()) {
        const validationErrors = await Session.validateClaims({
            overrideGlobalClaimValidators: (globalValidators) => [
                ...globalValidators,
                UserRoleClaim.validators.includes('team_lead'),
                /* PermissionClaim.validators.includes("modify") */
            ],
        });

        if (validationErrors.length === 0) {
            // user is an admin
            return true;
        }
        for (const err of validationErrors) {
            if (err.validatorId === UserRoleClaim.id) {
                // user is not an admin
                return false;
            }
        }
    }
    // either a session does not exist, or the user is not an admin
    return false;
};

export const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
];

export const weekday = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
export const weekday_FullNames = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];
