import classNames from 'classnames';
// interface ButtonProps {
//     value: string;
//     className: string;
//     onClick?: string;
// }

function Button(props: {
    value: string;
    name?: string;
    id?: string;
    className?: string | null;
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
    button?: boolean;
    disabled?:boolean;
}) {
    const styles = classNames(
        'text-white md:w-40 md:h-10 lg:w-38 lg:h-12 rounded-full bg-gradient-to-r from-zxblue-from to-zxblue-to hover:bg-gradient-to-t from-zxblue-from to-zxblue-to',
        props.className,
    );

    if (!props.button)
        return (
            <button
                name={props.name}
                id={props.id}
                type={props.button ? 'button' : 'submit'}
                className={styles}
                onClick={props.onClick}
                disabled={props.disabled}
            >
                {props.value}
            </button>
        );

    return (
        <button
            name={props.name}
            id={props.id}
            type={props.button ? 'button' : 'submit'}
            className="text-zxtext-1 md:w-40 md:h-10 lg:w-48 lg:h-12 rounded-full bg-white border border-zxbullet-to"
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {props.value}
        </button>
    );
}

export default Button;
