export default function MsViewerSectionHeader(props: {
    name: string;
}) {
    return (
        <div className="flex flex-row">
            <h2 className="py-4 border-x-zxblue-from text-transparent font-semibold md:text-lg lg:text-xl">
                <span className="border-l-4 border-zxsub-to pl-2 py-1 bg-clip-text bg-gradient-to-r from-zxheader-from to-zxheader-to opacity-100">
                    {props.name}
                </span>
            </h2>
        </div>
    );
}