import Axios from 'axios';
import { useEffect, useState } from 'react';
import {
    useListMSViewers,
    useRetrieveMSUser
} from '../../api';

export default function ProfileUpdateHeader(props: { id: string }) {
    const { data: usersData } = useRetrieveMSUser(props.id);
    const [imageUrl, setImageUrl] = useState('');

    const { data: viewersData, isSuccess: userSuccess } = useListMSViewers();

    const userId = usersData?.data.user_id;
    const token = viewersData?.data[0].provider_token;

    useEffect(() => {
        if (userSuccess) {
            Axios.get(
                `https://graph.microsoft.com/v1.0/users/${userId}/photo/$value`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                    responseType: 'blob',
                }
            ).then((o) => {
                const url = window.URL || window.webkitURL;
                const blobUrl = url.createObjectURL(o.data);
                setImageUrl(blobUrl);
            })
            .catch(() => {
                setImageUrl('../img/zedexinfologo.svg');
            });
        }
    }, [token, userId, userSuccess]);


    return (
        <div className="flex flex-row w-full shadow-xl px-[7%] pt-[0.5%] pb-[1.5%]">
            <div className="rounded-full border-blue-700">
                <img
                    src={imageUrl}
                    alt=""
                    className="w-16 h-16 rounded-full ring ring-zxblue-to"
                />
            </div>
            <span className="px-[2%] py-[1%]">
                {usersData?.data.MSemployee_details?.full_name}
                <br />
                <span className="text-sm">
                    ID:
                    <span className="text-blue-700 pl-[4%]">
                        {usersData?.data.MSemployee_details?.employee_number}
                    </span>
                </span>
            </span>
        </div>
    );
}
