import { Formik } from 'formik';
import { Dispatch, useEffect } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import {
    Accessories,
    Accessory,
    Employee,
    useCreateAccessories,
    useListAccessorys,
    useListEmployees,
    useRetrieveAccessory,
    useUpdateAccessories
} from '../../api';
import ButtonField from '../FormFields/ButtonField';
import FormikField from '../FormFields/FormikField';
import FormikSelect from '../FormFields/FormikSelect';

export default function AccessoriesUpdateForm(props: {
    accessory: Accessories;
    setForm: Dispatch<React.SetStateAction<boolean>>;
}) {
    const { data: accessoryData } = useRetrieveAccessory(
        props.accessory.type.toString()
    );
    const { mutateAsync: createAccessories, isSuccess: isCreateSuccess } =
        useCreateAccessories();
    const { mutateAsync: updateAccessories, isSuccess: isUpdateSuccess } =
        useUpdateAccessories();
    const { data: employeeData } = useListEmployees();
    const { data: allAccessoryData } = useListAccessorys();

    const AccessoriesInformationInitialValues = {
        type: accessoryData?.data.id
            ? Number(accessoryData.data.id)
            : Number(''),
        serial_no: props.accessory.serial_no,
        assigned_to: props.accessory.assigned_to,
    };
    const AccessoriesInformationValidationSchema = Yup.object({
        type: Yup.number().typeError('Choose a Value').required('Required'),
        serial_no: Yup.string().max(50).required('Required'),
        assigned_to: Yup.number()
            .typeError('Choose a Value')
            .required('Required'),
    });

    const handleSubmit = async (values: Accessories) => {
        if (props.accessory !== undefined || props.accessory !== null) {
            const updatedVal = {
                ...values,
            };
            await updateAccessories({
                data: updatedVal,
                id: props.accessory.id ? props.accessory.id.toString() : '',
            });
        } else {
            const createVal = {
                ...values,
            };
            await createAccessories({ data: createVal });
        }
    };
    useEffect(() => {
        if (isUpdateSuccess) {
            toast.success('Accessory Information Updated');
            props.setForm(false);
        }
        if (isCreateSuccess) {
            toast.success('Accessory Information Created');
            props.setForm(false);
        }
    }, [isCreateSuccess, isUpdateSuccess, props]);

    return (
        <Formik
            initialValues={AccessoriesInformationInitialValues}
            validationSchema={AccessoriesInformationValidationSchema}
            onSubmit={handleSubmit}
        >
            {({ touched, submitForm }) => (
                <form className="grid grid-flow-row gap-x-4 md:grid-cols-3 lg:grid-cols-4 px-7 mb-5">
                    <div className="flex flex-col">
                        <FormikSelect
                            label="Type"
                            name="type"
                            touched={touched.type}
                        >
                            {allAccessoryData?.data.map(
                                (accName: Accessory) => (
                                    <option key={accName.id} value={accName.id}>
                                        {accName.name}
                                    </option>
                                )
                            )}
                        </FormikSelect>
                    </div>
                    <FormikField
                        label="Serial No"
                        name="serial_no"
                        type="text"
                        touched={touched.serial_no}
                    />
                    <FormikSelect
                        label="Assigned To"
                        name="assigned_to"
                        touched={touched.assigned_to}
                    >
                        {employeeData?.data.map((employee: Employee) => (
                            <option key={employee.id} value={employee.id}>
                                {employee.full_name}
                            </option>
                        ))}
                    </FormikSelect>
                    <div className="row-start-4 lg:col-start-4 md:col-start-3 lg:col-span-4 md:col-span-3 space-x-10">
                        <ButtonField
                            name="Submit"
                            onClick={() => {
                                submitForm();
                            }}
                        />
                    </div>
                </form>
            )}
        </Formik>
    );
}
