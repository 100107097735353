export const EMPLOYEE_FIELD = {
    DOB: 'dob',
    BLOOD_GROUP: 'blood_group',
    MARITAL_STATUS: 'marital_status',
    NATIONALITY: 'nationality',
    CONTACT_NO: 'contact_no',
    SPO_FATHER: 'spo_father_name',
    EMERGENCY: 'emergency_contact',
    RELIGION: 'religion',
    DIFFABLED: 'differently_abled',
    INTERNATIONAL: 'international_employee',
    DIFFABLEDTEXT: 'differently_abled_text',
    INTERNATIONTEXT: 'international_employee_text',
    FATHER: 'father',
    SPOUSE: 'spouse',
};

export const ADDRESS_FIELD = {
    ADDRESS_LINE_1: 'address_line_1',
    ADDRESS_LINE_2: 'address_line_2',
    CITY: 'city',
    STATE: 'state',
    PINCODE: 'pincode',
};

export const BANK_FIELD = {
    ACCOUNT_NO: 'account_no',
    BANK_NAME: 'bank_name',
    CUSTOMER_NAME: 'customer_name',
    BRANCH: 'branch',
    IFSC: 'IFSC',
    PAN: 'PAN',
    UPI_ID: 'upi_id',
    UPI_MOBILE: 'upi_mobile',
    UPI_APP: 'upi_app',
    AADHAR_NO: 'aadhar_no',
};

export const EDUCATION_FIELD = {
    CLASS_10_BOARD: 'class_10_board',
    CLASS_10_PERCENTAGE: 'class_10_percentage',
    CLASS_12_BOARD: 'class_12_board',
    CLASS_12_PERCENTAGE: 'class_12_percentage',
    CLASS_12_STREAM: 'class_12_stream',
    GRAD_COLLEGE_UNI: 'grad_college_uni',
    GRAD_DEGREE: 'grad_degree',
    GRAD_SPECIALIZATION: 'grad_specialization',
    GRAD_CGPA_PERCENTAGE: 'grad_cgpa_percentage',
    MASTER_COLLEGE_UNI: 'master_college_uni',
    MASTER_DEGREE: 'master_degree',
    MASTER_SPECIALIZATION: 'master_specialization',
    MASTER_CGPA_PERCENTAGE: 'master_cgpa_percentage',
};
