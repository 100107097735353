/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  Accessories
} from '../../schema'


/**
 * List all accesories
 */
export const listAccessories = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Accessories[]>> => {
    return axios.get(
      `/accessories/`,options
    );
  }


export const getListAccessoriesQueryKey = () => [`/accessories/`];

    
export type ListAccessoriesQueryResult = NonNullable<Awaited<ReturnType<typeof listAccessories>>>
export type ListAccessoriesQueryError = AxiosError<unknown>

export const useListAccessories = <TData = Awaited<ReturnType<typeof listAccessories>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listAccessories>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListAccessoriesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listAccessories>>> = ({ signal }) => listAccessories({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof listAccessories>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * Create accessory
 */
export const createAccessories = (
    accessories: Accessories, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Accessories>> => {
    return axios.post(
      `/accessories/`,
      accessories,options
    );
  }



    export type CreateAccessoriesMutationResult = NonNullable<Awaited<ReturnType<typeof createAccessories>>>
    export type CreateAccessoriesMutationBody = Accessories
    export type CreateAccessoriesMutationError = AxiosError<unknown>

    export const useCreateAccessories = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAccessories>>, TError,{data: Accessories}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAccessories>>, {data: Accessories}> = (props) => {
          const {data} = props ?? {};

          return  createAccessories(data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof createAccessories>>, TError, {data: Accessories}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * Get accesory details
 */
export const retrieveAccessories = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Accessories>> => {
    return axios.get(
      `/accessories/${id}`,options
    );
  }


export const getRetrieveAccessoriesQueryKey = (id: string,) => [`/accessories/${id}`];

    
export type RetrieveAccessoriesQueryResult = NonNullable<Awaited<ReturnType<typeof retrieveAccessories>>>
export type RetrieveAccessoriesQueryError = AxiosError<unknown>

export const useRetrieveAccessories = <TData = Awaited<ReturnType<typeof retrieveAccessories>>, TError = AxiosError<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof retrieveAccessories>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveAccessoriesQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveAccessories>>> = ({ signal }) => retrieveAccessories(id, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof retrieveAccessories>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * Update accessory details
 */
export const updateAccessories = (
    id: string,
    accessories: Accessories, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Accessories>> => {
    return axios.put(
      `/accessories/${id}`,
      accessories,options
    );
  }



    export type UpdateAccessoriesMutationResult = NonNullable<Awaited<ReturnType<typeof updateAccessories>>>
    export type UpdateAccessoriesMutationBody = Accessories
    export type UpdateAccessoriesMutationError = AxiosError<unknown>

    export const useUpdateAccessories = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAccessories>>, TError,{id: string;data: Accessories}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAccessories>>, {id: string;data: Accessories}> = (props) => {
          const {id,data} = props ?? {};

          return  updateAccessories(id,data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof updateAccessories>>, TError, {id: string;data: Accessories}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * Delete an accessory
 */
export const destroyAccessories = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    return axios.delete(
      `/accessories/${id}`,options
    );
  }



    export type DestroyAccessoriesMutationResult = NonNullable<Awaited<ReturnType<typeof destroyAccessories>>>
    
    export type DestroyAccessoriesMutationError = AxiosError<unknown>

    export const useDestroyAccessories = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof destroyAccessories>>, TError,{id: string}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof destroyAccessories>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  destroyAccessories(id,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof destroyAccessories>>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    