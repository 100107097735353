import moment from 'moment';
import { useEffect } from 'react';
import {
    Regularization,
    RegularizationStatus,
    TeamRegularization,
    useRetrieveAttendance,
    useRetrieveEmployee,
    useRetrieveShift,
    useUpdateRegularization
} from '../api';
import { weekday } from '../constants/functions';
import { Minuscircle, Pluscircle } from './icons';

interface Props {
    regData: Regularization | undefined;
    regsData: TeamRegularization[] | undefined;
    refetch: any;
}

function AttRegAppForm(props: Props) {
    const multiregData = props.regsData;
    const regs = props.regData;

    const reg_reason = regs?.reason;

    const att_id: number | null | undefined = regs?.attendance_id;
    const { data: attData } = useRetrieveAttendance(String(att_id));
    const emp_id: number | null | undefined = attData?.data.employee_id;

    const { data: employeeData } = useRetrieveEmployee(String(emp_id));
    const emp_name = employeeData?.data.full_name;

    const manager_id: number | null | undefined =
        employeeData?.data.reporting_manager;

    const {
        data: managerData,
        isSuccess: managerSuccess,
        isLoading: managerLoading,
    } = useRetrieveEmployee(String(manager_id));

    const manager_name = managerData?.data.full_name;
    const shift_id: number | null | undefined = employeeData?.data.shift_id;

    const { data: shiftDetails } = useRetrieveShift(String(shift_id));
    const shift_type = shiftDetails?.data.shift_name;
    const shift_start_time = shiftDetails?.data.start_time;
    const shift_end_time = shiftDetails?.data.end_time;

    const { mutateAsync, isSuccess } = useUpdateRegularization();

    const handleAcceptChange = (e: any) => {
        if (regs !== undefined) {
            const regData = {
                ...regs,
                status: RegularizationStatus.Approved,
            };

            mutateAsync({
                id: regs?.id ? regs.id.toString() : '',
                data: regData,
            });
            // if (isSuccess) {
            //     props.ref();
            // }
        }
    };
    useEffect(() => {
        if (isSuccess) props.refetch();
    }, [isSuccess, props]);

    const handleRejectChange = (e: any) => {
        if (regs !== undefined) {
            const regData = {
                ...regs,
                status: RegularizationStatus.Rejected,
            };

            mutateAsync({
                id: regs?.id ? regs.id.toString() : '',
                data: regData,
            });
            // if (isSuccess) {
            //     props.ref();
            // }
        }
    };

    return (
        <div>
            <div className="grid grid-cols-5 gap-y-3 border border-zxblue-to rounded-md focus:outline-none lg:ml-[9%] lg:w-[85%] lg:mt-5 md:ml-[2%] md:mt-5 mr-8 md:w-[95%] h-[40%]">
                <h1 className="mt-6 text-xs ml-2 lg:pl-5 pt-1 md:pl-0 col-span-2">
                    <span className="border border-zxblue-to text-xs py-1 px-1 ">
                        {regs?.date}
                    </span>
                </h1>
                <h1 className="mt-6 text-sm col-span-1 -ml-10">{emp_name}</h1>
                <h1 className="text-sm font-medium mt-6 col-span-2 ">
                    Manager :{' '}
                    <span className="font-normal text-xs ">{manager_name}</span>
                </h1>
                <h1 className="ml-7 mt-4 text-sm col-span-5 font-medium">
                    Shift Type :{' '}
                    <span className="font-normal text-xs">
                        {shift_type} Shift {shift_start_time}-{shift_end_time}
                    </span>
                </h1>

                <h1 className="text-center text-sm font-medium mt-2">
                    {weekday[moment(regs?.date.toString()).day()]}
                </h1>
                <h1 className="text-sm font-medium mt-2 col-span-2">
                    Sign In :{' '}
                    <span className="font-normal text-xs bg-clip-text text-transparent bg-gradient-to-l from-zxorange-from to-zxorange-to">
                        {new Date(
                            regs?.actual_sign_in ? regs.actual_sign_in : ''
                        ).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                        })}
                    </span>
                </h1>
                <h1 className="text-sm font-medium mt-2  col-span-2">
                    Sign Out :{' '}
                    <span className="font-normal text-xs bg-clip-text text-transparent bg-gradient-to-l from-zxorange-from to-zxorange-to">
                        {new Date(
                            regs?.actual_sign_in ? regs.actual_sign_in : ''
                        ).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                        })}
                    </span>
                </h1>

                {/* <h1 className="text-sm font-medium mt-2">CC :</h1> */}

                {regs?.status === 'Pending' ? (
                    <div className="grid grid-rows-2 ">
                        <button
                            type="button"
                            onClick={handleAcceptChange}
                            className=" ml-[35%]"
                        >
                            <Pluscircle />
                        </button>
                        <button
                            type="button"
                            onClick={handleRejectChange}
                            className="ml-[35%] "
                        >
                            <Minuscircle />
                        </button>
                    </div>
                ) : (
                    <div />
                )}
                <h1 className="col-span-4 font-medium text-sm pt-6">
                    Reason :
                    <span className="font-normal text-xs"> {reg_reason}</span>
                </h1>
            </div>
        </div>
    );
}

export default AttRegAppForm;
