import { AxiosResponse } from 'axios';
import { Shift } from '../../api';

interface ShiftRowProps<A, R> {
    shiftsData: AxiosResponse<Shift[], any> | undefined;
    // refetch: (options?: A) => R;
}

export default function ShiftRows<A, R>(props: ShiftRowProps<A, R>) {
    const shifts = props.shiftsData?.data ? props.shiftsData.data : [];
    let counter = 0;
    return (
        <>
            {shifts.map((shift: Shift) => (
                <tr key={shift.id} className="h-14 hover:shadow-lg">
                    <td className="text-center">{++counter}</td>
                    <td className="text-center">{shift.shift_name}</td>
                    <td className="text-center">{shift.start_time}</td>
                    <td className="text-center">{shift.end_time}</td>
                    <td className="text-center">{shift.buffer_time}</td>
                </tr>
            ))}
        </>
    );
}
