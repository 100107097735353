import { AxiosResponse } from 'axios';
import { Formik } from 'formik';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Accessories, useDestroyAccessories } from '../../api';
import ButtonField from '../FormFields/ButtonField';
import GetAccessoryName from './GetAccessoryName';

export default function DeleteAccessory(props: {
    accessoriesData: AxiosResponse<Accessories[], any> | undefined;
    refetchAccessories: any;
}) {
    const { mutateAsync, isSuccess, isLoading } = useDestroyAccessories();

    interface DeleteAccessoryProps {
        id: string;
    }

    const DeleteAccessoryInitialValue = {
        id: '',
    };

    const handleSubmit = async (values: DeleteAccessoryProps) => {
        await mutateAsync({ id: values.id.toString() });
        toast.success(`Accessory deleted`);
    };

    useEffect(() => {
        if (isSuccess) {
            props.refetchAccessories();
        }
    }, [isSuccess, props]);

    return (
        <div className="flex flex-col px-10 py-2">
            <h2 className="py-4 border-x-zxblue-from text-transparent font-semibold md:text-xl lg:text-2xl bg-clip-text bg-gradient-to-t from-zxheader-from to-zxheader-to">
                <span className="border-l-2 border-zxsub-to pl-1">
                    Delete Accessory
                </span>
            </h2>
            <Formik
                initialValues={DeleteAccessoryInitialValue}
                onSubmit={handleSubmit}
            >
                {({ submitForm, handleChange, resetForm }) => (
                    <form className="flex space-x-5">
                        <div className="flex flex-col">
                            <label className="pl-0.5 border-zxblue-to">
                                Delete Accessory
                            </label>
                            <select
                                name="id"
                                id="id"
                                className="w-48 h-8 mb-4 border px-1 py-1 rounded-md focus:outline-none border-zxblue-to text-sm"
                                onChange={handleChange}
                            >
                                <option defaultValue={undefined}>Select</option>
                                {props.accessoriesData?.data.map(
                                    (accessory: Accessories) => (
                                        <option
                                            key={accessory.id}
                                            value={accessory.id}
                                        >
                                            <GetAccessoryName
                                                id={accessory.type}
                                            />
                                            {` - ${accessory.serial_no}`}
                                        </option>
                                    )
                                )}
                            </select>
                        </div>
                        <ButtonField
                            name="Delete"
                            onClick={() => {
                                submitForm().then(() => {
                                    resetForm();
                                });
                            }}
                            disabled={!!isLoading}
                        />
                    </form>
                )}
            </Formik>
        </div>
    );
}
