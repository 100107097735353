import { Leave, useListMSUsers, useRetrieveEmployee } from '../api';

function PendAppForm(props: { leavesData: Leave | undefined; ref: any }) {
    const leaves = props.leavesData;

    const {
        data: usersData,
        isSuccess: userSuccess,
        isLoading: userLoading,
    } = useListMSUsers();

    const managerId: number | null | undefined =
        usersData?.data[0].MSemployee_details?.reporting_manager;
    const {
        data: managerData,
        isSuccess: managerSuccess,
        isLoading: managerLoading,
    } = useRetrieveEmployee(String(managerId));
    const managerName = managerData?.data.full_name;

    const date1 = leaves?.applying_from;
    const date2 = leaves?.applying_to;
    const Difference_In_Time =
        new Date(date2 as string).getTime() -
        new Date(date1 as string).getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    return (
        <div className="lg:ml-14 border border-zxblue-to rounded-md focus:outline-none lg:h-auto lg:w-[85%] lg:mt-5 md:ml-14 md:mt-5 mr-8 md:w-[80%] ">
            <div className="grid grid-rows-1 grid-cols-2">
                <h1 className="mt-6 ml-8 text-black">
                    Leave Type: {leaves?.leave_type}{' '}
                </h1>
                <h1 className="mt-6 ml-8 text-black">
                    No Of Days : {Difference_In_Days+1}
                </h1>
            </div>
            <br />
            <div className="grid xl:grid-cols-4 sm:grid-cols-2 sm:gap-y-6">
                <h1 className="ml-8 text-black">
                    Applied From: {leaves?.applying_from}
                </h1>
                <h1 className="ml-8 text-black">
                    Applied To: {leaves?.applying_to}
                </h1>
                <h1 className="ml-8 text-black">Manager: {managerName}</h1>
                <h1 className="ml-8 text-black">CC: {leaves?.cc}</h1>
                
            </div>
            <h1 className="mt-6 ml-8 mb-5 text-black">
                    Reason: {leaves?.reason}
                </h1>
        </div>
    );
}

export default PendAppForm;
