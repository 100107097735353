/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  Holiday
} from '../../schema'


/**
 * Get holiday details
 */
export const retrieveHoliday = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Holiday>> => {
    return axios.get(
      `/holiday/${id}/`,options
    );
  }


export const getRetrieveHolidayQueryKey = (id: string,) => [`/holiday/${id}/`];

    
export type RetrieveHolidayQueryResult = NonNullable<Awaited<ReturnType<typeof retrieveHoliday>>>
export type RetrieveHolidayQueryError = AxiosError<unknown>

export const useRetrieveHoliday = <TData = Awaited<ReturnType<typeof retrieveHoliday>>, TError = AxiosError<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof retrieveHoliday>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveHolidayQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveHoliday>>> = ({ signal }) => retrieveHoliday(id, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof retrieveHoliday>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * Update holiday details
 */
export const updateHoliday = (
    id: string,
    holiday: Holiday, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Holiday>> => {
    return axios.put(
      `/holiday/${id}/`,
      holiday,options
    );
  }



    export type UpdateHolidayMutationResult = NonNullable<Awaited<ReturnType<typeof updateHoliday>>>
    export type UpdateHolidayMutationBody = Holiday
    export type UpdateHolidayMutationError = AxiosError<unknown>

    export const useUpdateHoliday = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateHoliday>>, TError,{id: string;data: Holiday}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateHoliday>>, {id: string;data: Holiday}> = (props) => {
          const {id,data} = props ?? {};

          return  updateHoliday(id,data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof updateHoliday>>, TError, {id: string;data: Holiday}, TContext>(mutationFn, mutationOptions)
    }
    /**
 * Delete a holiday
 */
export const destroyHoliday = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<void>> => {
    return axios.delete(
      `/holiday/${id}/`,options
    );
  }



    export type DestroyHolidayMutationResult = NonNullable<Awaited<ReturnType<typeof destroyHoliday>>>
    
    export type DestroyHolidayMutationError = AxiosError<unknown>

    export const useDestroyHoliday = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof destroyHoliday>>, TError,{id: string}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof destroyHoliday>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  destroyHoliday(id,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof destroyHoliday>>, TError, {id: string}, TContext>(mutationFn, mutationOptions)
    }
    