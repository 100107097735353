import classnames from "classnames";

export default function ProfileUpdateField(props: {
    label: string;
    value: number | string | boolean | undefined | null;
    className?: string;
}) {
    return (
        <div className={classnames(props.className, "text-[#4A5660]")}>
            <label className="text-base font-medium">{props.label}</label>
            <div className="font-normal text-xs">{props.value}</div>
        </div>
    );
}
