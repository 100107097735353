import { Tab } from '@headlessui/react';
import { AxiosResponse } from 'axios';
import classnames from 'classnames';
import { Dispatch, useEffect, useState } from 'react';
import { DateObject } from 'react-multi-date-picker';
import { toast } from 'react-toastify';
import {
    EmployeeRegularization,
    MSViewerMSemployeeDetails,
    MultipleRegularizations,
    Regularization,
    useCreateMultipleRegularizations,
    useRetrieveEmployee,
    useRetrieveShift
} from '../../api';
import Button from '../FormFields/Button';
import PendingRegularization from './PendingRegularization';
import RegularizationApplication from './RegularizationApplication';
import RegularizationHistory from './RegularizationHistory';

export default function RegularizationStatusBar(props: {
    clickedDates: Array<DateObject>;
    setValue: Dispatch<React.SetStateAction<Array<DateObject>>>;
    viewerDetails: MSViewerMSemployeeDetails;
    regData: AxiosResponse<EmployeeRegularization[]> | undefined;
    refetchEmpRegularization: any;
}) {
    const { mutateAsync, isSuccess: isCreateMultipleRegSuccess } =
        useCreateMultipleRegularizations();
    const empShiftID = props.viewerDetails.shift_id;
    const empReptMngrID = props.viewerDetails.reporting_manager;
    const { data: shiftData, isSuccess: isRetrieveShiftSuccess } =
        useRetrieveShift(empShiftID ? empShiftID.toString() : '');
    const { data: managerData } = useRetrieveEmployee(
        empReptMngrID ? empReptMngrID.toString() : ''
    );
    const [multiRegData, setMultiRegData] = useState<
        Array<MultipleRegularizations>
    >([]);
    let counter = 0;
    const tabStyle = 'w-full rounded-lg py-1.5 text-base';
    const selectedTabStyle =
        'bg-clip-text text-transparent bg-gradient-to-bl from-tabtext-from to-tabtext-to font-semibold';

    const multipleRegData: any = multiRegData;
    const handleMultipleSubmit = async () => {
        await mutateAsync({ data: multipleRegData });
        toast.success('Regularizations Applied');
    };

    useEffect(() => {
        if (isCreateMultipleRegSuccess) {
            setMultiRegData([]);
            props.setValue([]);
            props.refetchEmpRegularization();
        }
    }, [
        isCreateMultipleRegSuccess,
        props.setValue,
        props.refetchEmpRegularization,
        setMultiRegData,
    ]);

    return (
        <Tab.Group>
            <div className="box-border border border-zxblue-to rounded-md outline-none mt-[3%] mx-auto text-center w-full h-[13%]">
                <Tab.List className="grid grid-cols-5">
                    <Tab
                        className={({ selected }) =>
                            classnames(
                                tabStyle,
                                selected
                                    ? `${selectedTabStyle} pl-10 outline-none`
                                    : 'pl-10 text-zxtext-1'
                            )
                        }
                    >
                        Apply
                    </Tab>
                    <span className="py-1.5">|</span>
                    <Tab
                        className={({ selected }) =>
                            classnames(
                                tabStyle,
                                selected
                                    ? `${selectedTabStyle} outline-none`
                                    : 'text-zxtext-1'
                            )
                        }
                    >
                        Pending
                    </Tab>
                    <span className="py-1.5">|</span>
                    <Tab
                        className={({ selected }) =>
                            classnames(
                                tabStyle,
                                selected
                                    ? `${selectedTabStyle} pr-10 outline-none`
                                    : 'pr-10 text-zxtext-1'
                            )
                        }
                    >
                        History
                    </Tab>
                </Tab.List>
            </div>
            <Tab.Panels className="pt-4">
                <Tab.Panel>
                    {props.clickedDates.length !== 0 ? (
                        props.clickedDates.map((date: DateObject) => (
                            <RegularizationApplication
                                key={counter++}
                                date={date}
                                viewerDetails={props.viewerDetails}
                                clickedDates={props.clickedDates}
                                refetchEmpRegularization={
                                    props.refetchEmpRegularization
                                }
                                multiRegData={multiRegData}
                                setMultiRegData={setMultiRegData}
                                shift_name={shiftData?.data.shift_name}
                                start_time={shiftData?.data.start_time}
                                end_time={shiftData?.data.end_time}
                                manager_name={managerData?.data.full_name}
                                isRetrieveShiftSuccess={isRetrieveShiftSuccess}
                            />
                        ))
                    ) : (
                        <div className="text-center text-zxtext-1 text-sm">
                            Tap on the date/s to apply regularization
                        </div>
                    )}
                    {props.clickedDates.length > 1 ? (
                        <Button
                            value="Submit All"
                            className="float-right mr-5"
                            onClick={handleMultipleSubmit}
                        />
                    ) : null}
                </Tab.Panel>
                <Tab.Panel>
                    {props.regData?.data.length !== 0 && props.regData?.data ? (
                        props.regData.data
                            .sort((a, b) => {
                                return (
                                    new Date(a.date).getDate() -
                                    new Date(b.date).getDate()
                                );
                            })
                            .map((regDetails: Regularization) =>
                                regDetails.status === 'Pending' ? (
                                    <PendingRegularization
                                        key={regDetails.id}
                                        regDetails={regDetails}
                                    />
                                ) : (
                                    <div
                                        key={regDetails.id}
                                        className="text-center text-sm text-zxtext-1"
                                    >
                                        No Regularization/s Applied
                                    </div>
                                )
                            )
                    ) : (
                        <div className="text-center text-sm text-zxtext-1">
                            No Regularization/s Applied
                        </div>
                    )}
                </Tab.Panel>
                <Tab.Panel>
                    {props.regData?.data.length !== 0 && props.regData?.data ? (
                        props.regData.data
                            .sort((a, b) => {
                                return (
                                    new Date(a.date).getDate() -
                                    new Date(b.date).getDate()
                                );
                            })
                            .map((regDetails: Regularization) => (
                                <RegularizationHistory
                                    key={regDetails.id}
                                    regDetails={regDetails}
                                />
                            ))
                    ) : (
                        <div className="text-center text-sm text-zxtext-1">
                            No History
                        </div>
                    )}
                </Tab.Panel>
            </Tab.Panels>
        </Tab.Group>
    );
}
