import { ArrowPathRoundedSquareIcon } from '@heroicons/react/24/solid';
import {
    Accessory, useListAccessorys,
    useListEmployees
} from '../../api';

interface Props {
    setFilters: React.Dispatch<
        React.SetStateAction<{
            type: string;
            assigned_to: string;
        }>
    >;
}

export default function AccessoryFilter(props: Props) {
    const { data: accessoryData } = useListAccessorys();
    const {data: employeeData} = useListEmployees();
    
    const empData = employeeData?.data.map((emp) => (
        <option key={emp.id} value={emp.id}>
            {emp.full_name}
        </option>
    ));

    const accTypeOptions = accessoryData?.data.map((acc: Accessory) => (
        <option key={acc.id} value={acc.id}>
            {acc.name}
        </option>
    ));

    // const accAssignedToOptions = accessorysData?.data.map(
    //     (acc: Accessories) => (
    //         <option key={acc.id} value={acc.id}>
    //             <GetEmployeeName id={acc.assigned_to} />
    //         </option>
    //     )
    // );

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        props.setFilters((values) => ({
            ...values,
            [e.target.name]: e.target.value,
        }));
    };

    return (
        <div className="flex lg:text-sm md: text-xs text-zxtext-1 pb-5 space-x-5">
            <button
                type="button"
                onClick={() => props.setFilters({ type: '', assigned_to: '' })}
            >
                <ArrowPathRoundedSquareIcon className="h-6 w-6" />
            </button>
            <label className="flex flex-col">
                Type:
                <select
                    name="type"
                    id="type"
                    className="focus:outline-none lg:text-sm md: text-xs"
                    onChange={handleChange}
                >
                    <option defaultValue='' value=''>Select</option>
                    {accTypeOptions}
                </select>
            </label>
            <label className="flex flex-col">
                Assigned To:
                <select
                    name="assigned_to"
                    id="assigned_to"
                    className="focus:outline-none lg:text-sm md: text-xs"
                    onChange={handleChange}
                >
                    <option defaultValue='' value=''>Select</option>
                    {empData}
                </select>
            </label>
        </div>
    );
}
