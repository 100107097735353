/* eslint-disable react/button-has-type */

import {
    Leave,
    useListMSViewers,
    useRetrieveEmployee,
    useUpdateLeave
} from '../api';

import { useEffect } from 'react';
import { toast } from 'react-toastify';

function LeaveWithForm(props: { leavesData: Leave | undefined; refetch: any }) {
    const leaves = props.leavesData;

    const {
        data: usersData,
        isSuccess: userSuccess,
        isLoading: userLoading,
    } = useListMSViewers();

    const managerId: number | null | undefined =
        usersData?.data[0].MSemployee_details?.reporting_manager;
    const {
        data: managerData,
        isSuccess: managerSuccess,
        isLoading: managerLoading,
    } = useRetrieveEmployee(String(managerId));

    const managerName = managerData?.data.full_name;
    const { mutateAsync, isSuccess } = useUpdateLeave();
    const handleWithdrawChange = (e: any) => {
        if (leaves !== undefined) {
            const leavesData = {
                ...leaves,
                leave_status: 'WITH',
            };

            const resp = mutateAsync({
                id: leaves?.id ? leaves.id.toString() : '',
                data: {
                    session_1: leaves.session_1,
                    leave_type: leaves.leave_type,
                    applying_from: leaves.applying_from,
                    applying_to: leaves.applying_to,
                    leave_status: 'WITH',
                },
            });
        }
        toast.success('Leave Withdrawn')
    };
    useEffect(() => {
        if (isSuccess) props.refetch();
    }, [isSuccess, props, props.refetch]);

   
    const date1 = leaves?.applying_from;
    const date2 = leaves?.applying_to;
    const Difference_In_Time =
        new Date(date2 as string).getTime() -
        new Date(date1 as string).getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    return (
        <div className="lg:ml-14 border border-zxblue-to rounded-md focus:outline-none lg:h-auto lg:w-[85%] lg:mt-5 md:ml-14 md:mt-5 mr-8 md:w-[80%] ">
            <div className="grid grid-rows-1 grid-cols-2">
                <h1 className="mt-6 ml-8 text-black">
                    Leave Type: {leaves?.leave_type}{' '}
                </h1>
                <h1 className="mt-6 ml-8 text-black">
                    No Of Days : {Difference_In_Days+1}
                </h1>
            </div>
            <br />
            <div className="grid grid-rows-1 xl:grid-cols-4 sm:grid-cols-2 sm:gap-y-6">
                <h1 className="ml-8 text-black ">
                    Applied From: {leaves?.applying_from}
                </h1>
                <h1 className="ml-8 text-black ">
                    Applied To: {leaves?.applying_to}
                </h1>
                <h1 className="ml-8 text-black ">Manager: {managerName}</h1>
                <h1 className="ml-8 text-black ">CC: {leaves?.cc}</h1>
            </div>
            <h1 className="mt-6 ml-8 text-black mb-5">Reason: {leaves?.reason}</h1>
            <div className="grid grid-cols-2 ">
                {/* <button className="border border-orange-400 lg:w-[65%] md:mb-[5%] lg:h-[70%] md:w-[55%]  md:h-[73%] rounded-full  place-self-center md:ml-[50%] lg:ml-[40%] lg:mb-[5%]"
                type="button"
                // onClick={handleEditChange}
                value="Edit">
                    Edit{' '}
                </button> */}

                <button
                    value="Withdraw"
                    className="text-white lg:ml-[10%] lg:mb-[10%] md:mb-[10%] lg:w-[65%] lg:h-[70%] md:w-[55%] md:ml-[5%] md:h-[73%] rounded-full bg-gradient-to-r from-zxblue-from to-zxblue-to hover:bg-gradient-to-t col-start-2"
                    type="button"
                    aria-label="Withdraw"
                    onClick={handleWithdrawChange}
                >
                    Withdraw
                </button>
            </div>
        </div>
    );
}

export default LeaveWithForm;
