import {
    EducationClass10Board,
    EducationClass12Board,
    EducationClass12Stream,
    // EmployeeAttendanceType,
    EmployeeEmployeeNumberSeries,
    EmployeeEmployeeStatus,
    EmployeeGender,
    EmployeeGrade,
    EmployeeProbationPeriod,
    ProfileBloodGroup,
    ProfileMaritalStatus,
    ProfileNationality,
    ProfileReligion,
    ShiftBufferTime,
    TeamRegularizationStatus
} from '../api';

export const EmployeeNumberSeriesOptions = [
    EmployeeEmployeeNumberSeries.ZX,
    EmployeeEmployeeNumberSeries.IN,
    EmployeeEmployeeNumberSeries.PA,
    EmployeeEmployeeNumberSeries.T,
];
export const EmpGenderOptions = [
    EmployeeGender.Male,
    EmployeeGender.Female,
    EmployeeGender.Other,
];
export const EmployeeStatusOptions = [
    EmployeeEmployeeStatus.Pending,
    EmployeeEmployeeStatus.Verified,
    EmployeeEmployeeStatus.Active,
    EmployeeEmployeeStatus.Deactive,
    EmployeeEmployeeStatus.Retired,
    EmployeeEmployeeStatus.Absconded,
];
export const EmpProbationPeriodOptions = [
    EmployeeProbationPeriod[3],
    EmployeeProbationPeriod[6],
];
export const EmpGradeOptions = [
    EmployeeGrade.Junior,
    EmployeeGrade.Senior,
    EmployeeGrade.Team_Leader,
    EmployeeGrade.Manager,
    EmployeeGrade.Director,
];
// export const EmpAttendanceTypeOptions = [
//     EmployeeAttendanceType.General_Shift,
//     EmployeeAttendanceType.Afternoon_Shift,
//     EmployeeAttendanceType.Night_Shift,
//     EmployeeAttendanceType.Part_Time_Shift,
// ];

export const BloodGroup = [
    ProfileBloodGroup.A,
    ProfileBloodGroup.B,
    ProfileBloodGroup.AB,
    ProfileBloodGroup.O,
    ProfileBloodGroup['A-'],
    ProfileBloodGroup['B-'],
    ProfileBloodGroup['AB-'],
    ProfileBloodGroup['O-'],
];
export const MaritalStatus = [
    ProfileMaritalStatus.Single,
    ProfileMaritalStatus.Married,
];
export const Nationality = [
    ProfileNationality.Indian,
    ProfileNationality.USA,
    ProfileNationality.Other,
];
export const Religion = [
    ProfileReligion.Christian,
    ProfileReligion.Hindu,
    ProfileReligion.Muslim,
    ProfileReligion.Sikh,
    ProfileReligion.Jain,
    ProfileReligion.Other,
];

export const _10thBoardOptions = [
    EducationClass10Board.CBSE,
    EducationClass10Board.ICSE,
    EducationClass10Board.STATE,
];
export const _12thBoardOptions = [
    EducationClass12Board.CBSE,
    EducationClass12Board.ICSE,
    EducationClass12Board.STATE,
];

export const _12thStreamOptions = [
    EducationClass12Stream.Arts,
    EducationClass12Stream.Commerce,
    EducationClass12Stream.Science,
];

export const RegularizationStatusOptions = [
    TeamRegularizationStatus.Pending,
    TeamRegularizationStatus.Approved,
    TeamRegularizationStatus.Rejected,
];

export const ShiftBufferTimeOptions = [
    ShiftBufferTime[10],
    ShiftBufferTime[15],
    ShiftBufferTime[20],
    ShiftBufferTime[25],
    ShiftBufferTime[30],
    ShiftBufferTime[35],
    ShiftBufferTime[40],
    ShiftBufferTime[45],
    ShiftBufferTime[50],
    ShiftBufferTime[55],
    ShiftBufferTime[60],
];
