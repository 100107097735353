import { Tab } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { Leave, useListMSViewers, useListTLLeaves } from '../api';
import useAdmin from '../hooks/useAdmin';
import TeamLeaveAppForm from './TeamLeaveAppForm';

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

function LeaveStatus() {
    const isViewerAdmin = useAdmin();
    const {
        data: usersData,
        isSuccess: userSuccess,
        isLoading: userLoading,
    } = useListMSViewers();

    const {
        data: leavesData,
        isSuccess,
        refetch,
    } =  useListTLLeaves({
        axios: {
            params: {
                reporting_to:isViewerAdmin?null:usersData?.data[0].MSemployee_details?.id,
            },
        },
    });
    useEffect(()=>{
        refetch()
        
    },[isViewerAdmin])
    const leaves = leavesData?.data;
    
    const [currentPage, setCurrentPage] = useState(1);
    const pendL:any = leaves?.filter((leave: Leave) => leave.leave_status === 'PEND');
    const postsPerPage = 5;
     const indexOfLastPost = currentPage * postsPerPage;
     const indexOfFirstPost = indexOfLastPost - postsPerPage;
     const leavespend = pendL?.slice(indexOfFirstPost, indexOfLastPost);const pageNumbers = [];
 
     // eslint-disable-next-line no-unsafe-optional-chaining
     for (let i = 1; i <= Math.ceil(pendL?.length / postsPerPage); i++) {
        pageNumbers.push(i);
     }

     const [currentPageAcc, setCurrentPageAcc] = useState(1);
    const accL:any = leaves?.filter((leave: Leave) => leave.leave_status === 'ACC');
    const postsPerPageAcc = 5;
     const indexOfLastPostAcc = currentPageAcc * postsPerPageAcc;
     const indexOfFirstPostAcc = indexOfLastPostAcc - postsPerPageAcc;
     const leavesacc = accL?.slice(indexOfFirstPostAcc, indexOfLastPostAcc);
     const pageNumbersAcc = [];
 
     // eslint-disable-next-line no-unsafe-optional-chaining
     for (let i = 1; i <= Math.ceil(accL?.length / postsPerPageAcc); i++) {
        pageNumbersAcc.push(i);
     }

     const [currentPageRej, setCurrentPageRej] = useState(1);
    const rejL:any = leaves?.filter((leave: Leave) => leave.leave_status === 'REJ');
    const postsPerPageRej = 5;
     const indexOfLastPostRej = currentPageRej * postsPerPageRej;
     const indexOfFirstPostRej = indexOfLastPostRej - postsPerPageRej;
     const leavesRej = rejL?.slice(indexOfFirstPostRej, indexOfLastPostRej);
     const pageNumbersRej = [];
 
     // eslint-disable-next-line no-unsafe-optional-chaining
     for (let i = 1; i <= Math.ceil(rejL?.length / postsPerPageRej); i++) {
        pageNumbersRej.push(i);
     }


    useEffect(()=>{
        if(usersData?.data[0].MSemployee_details?.id)
        refetch();
        
      },[refetch, usersData?.data])
    
    const empDetails = usersData?.data[0].MSemployee_details;
  
    return (
        <Tab.Group>
            <div className=" mx-auto text-center divide-x shadow-md w-[84%] h-[5%] border border-zxblue-to rounded-md focus:outline-none ml-[6%] mt-[2%] md:w-[85%]">
                
                <Tab.List className="grid grid-cols-5">
                    <Tab className={({ selected }) =>
                classNames(
                  'w-full rounded-lg py-2.5 text-sm  leading-5 pl-18 ml-5',
                  '',
                  selected
                    ? 'lg:text-base font-bold bg-clip-text text-transparent bg-gradient-to-r from-tabtext-from to-tabtext-to outline-none'
                    : 'text-gray-500 font-semibold hover:bg-white/[0.12] hover:text-violet-900'
                )
              }>
                      <span className="">PENDING</span>
                    </Tab>
                    <span className="pt-3">|</span>
                    <Tab className={({ selected }) =>
                classNames(
                    'w-full rounded-lg py-2.5 text-sm  leading-5 ',
                    '',
                    selected
                      ? 'lg:text-base font-bold bg-clip-text text-transparent bg-gradient-to-r from-tabtext-from to-tabtext-to outline-none'
                      : 'text-gray-500 font-semibold hover:bg-white/[0.12] hover:text-violet-900'
                  )
                }>
                        ACCEPTED
                    </Tab>
                    <span className="pt-3">|</span>
                    <Tab className={({ selected }) =>
                classNames(
                    'w-full rounded-lg py-2.5 text-sm  leading-5 pr-20',
                    '',
                    selected
                      ? 'lg:text-base font-bold bg-clip-text text-transparent bg-gradient-to-r from-tabtext-from to-tabtext-to outline-none'
                      : 'text-gray-500 font-semibold hover:bg-white/[0.12] hover:text-violet-900'
                  )
                }>
                        REJECTED
                    </Tab>
                </Tab.List>
            </div>
            <Tab.Panels>
                <Tab.Panel>
                <div className="pagination-container">
                    <ul className="flex flex-row items-center justify-center mt-4">
                        <li>
                        <button type='button' onClick={()=>setCurrentPage(currentPage-1)} className="page-number px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                            Prev
                        </button>
                        </li>
                        {pageNumbers.map((number) => (
                        <li
                            key={number}
                            className="page-number px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        >
                            <button type='button' 
                            onClick={() => setCurrentPage(number)}>
                                {number}
                            </button>
                        </li>
                        ))}
                        <li className="page-number">
                        <button type='button' onClick={() => setCurrentPage(currentPage+1)} className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                            Next
                        </button>
                        </li>
                    </ul>
                </div>
                <div className="overflow-y-scroll h-[550px]">
                    {leavespend && isSuccess && empDetails
                        ? leavespend.map((leave: Leave) =>
                              leave.leave_status === 'PEND' ? (
                                  <TeamLeaveAppForm
                                      key={leave.id}
                                      leavesData={leave}
                                      refetch={refetch}
                                      empDetails={empDetails}
                                  />
                              ) : null
                          )
                        : null}{' '}
                        </div>
                </Tab.Panel>
                <Tab.Panel>
                <div className="pagination-container">
                    <ul className="flex flex-row items-center justify-center mt-4">
                        <li>
                        <button type='button' onClick={()=>setCurrentPageAcc(currentPageAcc-1)} className="page-number px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                            Prev
                        </button>
                        </li>
                        {pageNumbersAcc.map((number) => (
                        <li
                            key={number}
                            className="page-number px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        >
                            <button type='button' 
                            onClick={() => setCurrentPageAcc(number)}>
                                {number}
                            </button>
                        </li>
                        ))}
                        <li className="page-number">
                        <button type='button' onClick={() => setCurrentPageAcc(currentPageAcc+1)} className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                            Next
                        </button>
                        </li>
                    </ul>
                </div>
                <div className="overflow-y-scroll h-[550px]">
                    {leavesacc && isSuccess && empDetails
                        ? leavesacc.map((leave: Leave) =>
                              leave.leave_status === 'ACC' ? (
                                  <TeamLeaveAppForm
                                      key={leave.id}
                                      leavesData={leave}
                                      refetch={refetch}
                                  />
                              ) : null
                          )
                        : null}
                        </div>
                </Tab.Panel>
                <Tab.Panel>
                <div className="pagination-container">
                    <ul className="flex flex-row items-center justify-center mt-4">
                        <li>
                        <button type='button' onClick={()=>setCurrentPageRej(currentPageRej-1)} className="page-number px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                            Prev
                        </button>
                        </li>
                        {pageNumbersRej.map((number) => (
                        <li
                            key={number}
                            className="page-number px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                        >
                            <button type='button' 
                            onClick={() => setCurrentPageRej(number)}>
                                {number}
                            </button>
                        </li>
                        ))}
                        <li className="page-number">
                        <button type='button' onClick={() => setCurrentPageRej(currentPageRej+1)} className="px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                            Next
                        </button>
                        </li>
                    </ul>
                </div>
                <div className="overflow-y-scroll h-[550px]">
                    {leavesRej && isSuccess
                        ? leavesRej.map((leave: Leave) =>
                              leave.leave_status === 'REJ' ? (
                                  <TeamLeaveAppForm
                                      key={leave.id}
                                      leavesData={leave}
                                      refetch={refetch}
                                  />
                              ) : null
                          )
                        : null}{' '}
                        </div>
                </Tab.Panel>
            </Tab.Panels>
        </Tab.Group>
    );
}
export default LeaveStatus;
