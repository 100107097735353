import classnames from 'classnames';
import { HTMLAttributes } from 'react';
import {
    BASIC_INFORMATION_PAGE,
    EMPLOYEE_POSITION_PAGE
} from '../../constants/router';

interface ButtonFieldProps extends HTMLAttributes<HTMLButtonElement> {
    name: string;
    location?: string;
    className?: string;
    disabled?: boolean;
}

export default function ButtonField({
    name,
    location,
    className,
    disabled,
    ...props
}: ButtonFieldProps) {
    switch (name) {
        case 'Previous':
            return (
                <button
                    type="button"
                    disabled={location === BASIC_INFORMATION_PAGE}
                    className={
                        location === BASIC_INFORMATION_PAGE
                            ? 'md:w-40 md:h-12 lg:w-44 lg:h-10 mt-6 rounded-full text-sm text-gray cursor-not-allowed border border-gray-400'
                            : 'md:w-40 md:h-12 lg:w-44 lg:h-10  mt-6 rounded-full text-sm text-black border border-purple-600'
                    }
                    {...props}
                >
                    {name}
                </button>
            );

        case 'Cancel':
            return (
                <button
                    type="button"
                    className={classnames(
                        className,
                        'md:w-40 md:h-12 lg:w-44 lg:h-10 mt-6 rounded-full text-sm text-black border border-orange-500'
                    )}
                    {...props}
                    disabled={disabled}
                >
                    {name}
                </button>
            );
        case 'Next':
            return (
                <button
                    type="button"
                    className="md:w-40 md:h-12 lg:w-44 lg:h-10 rounded-full mt-6 text-sm text-white bg-gradient-to-r from-zxblue-from to-zxblue-to hover:bg-gradient-to-t from-zxblue-from to-zxblue-to"
                    {...props}
                >
                    {location === EMPLOYEE_POSITION_PAGE ? 'Finish' : name}
                </button>
            );
        case 'Submit':
            return (
                <button
                    type="button"
                    className={classnames(
                        className,
                        'md:w-40 md:h-12 lg:w-44 lg:h-10 rounded-full mt-6 ml-5 text-sm text-white bg-gradient-to-r from-zxblue-from to-zxblue-to hover:bg-gradient-to-t from-zxblue-from to-zxblue-to'
                    )}
                    disabled={disabled}
                    {...props}
                >
                    {name}
                </button>
            );
        case 'Delete':
            return (
                <button
                    type="button"
                    className={classnames(
                        className,
                        'md:w-40 md:h-12 lg:w-44 lg:h-10 rounded-full mt-6 ml-5 text-sm text-white bg-gradient-to-r from-zxblue-from to-zxblue-to hover:bg-gradient-to-t from-zxblue-from to-zxblue-to'
                    )}
                    disabled={disabled}
                    {...props}
                >
                    {name}
                </button>
            );
        default:
            return null;
    }
}
