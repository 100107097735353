/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  RolesAssign
} from '../../schema'


export const listRoleAssigns = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown[]>> => {
    return axios.get(
      `/role-assign/`,options
    );
  }


export const getListRoleAssignsQueryKey = () => [`/role-assign/`];

    
export type ListRoleAssignsQueryResult = NonNullable<Awaited<ReturnType<typeof listRoleAssigns>>>
export type ListRoleAssignsQueryError = AxiosError<unknown>

export const useListRoleAssigns = <TData = Awaited<ReturnType<typeof listRoleAssigns>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listRoleAssigns>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListRoleAssignsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listRoleAssigns>>> = ({ signal }) => listRoleAssigns({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof listRoleAssigns>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * Assign a role and get user
 */
export const retrieveRolesAssign = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<RolesAssign>> => {
    return axios.get(
      `/role-assign/${id}`,options
    );
  }


export const getRetrieveRolesAssignQueryKey = (id: string,) => [`/role-assign/${id}`];

    
export type RetrieveRolesAssignQueryResult = NonNullable<Awaited<ReturnType<typeof retrieveRolesAssign>>>
export type RetrieveRolesAssignQueryError = AxiosError<unknown>

export const useRetrieveRolesAssign = <TData = Awaited<ReturnType<typeof retrieveRolesAssign>>, TError = AxiosError<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof retrieveRolesAssign>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveRolesAssignQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveRolesAssign>>> = ({ signal }) => retrieveRolesAssign(id, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof retrieveRolesAssign>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

