import {
    ArrowDownTrayIcon,
    BellIcon,
    Cog8ToothIcon
} from '@heroicons/react/24/solid';

import { Link, useLocation } from 'react-router-dom';

interface HeaderProps {
    name: string;
    setNotif?: React.Dispatch<React.SetStateAction<boolean>>;
    notif?: boolean;
    emp_name?: string;
    hol_name?: string;
    set_name?: React.Dispatch<
        React.SetStateAction<{
            dept: string;
            grade: string;
            desg: string;
            status: string;
            past: string;
            name: string;
        }>
    >;
    set_hol?: React.Dispatch<
        React.SetStateAction<{
            name: string;
            month: number;
        }>
    >;
}
function Header(props: HeaderProps) {
    // const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;
    return (
        <div className="px-2 flex justify-between mb-8 w-full">
            <h2 className="pt-2 flex-none border-x-zxblue-from text-transparent font-semibold lg:text-2xl md:text-xl bg-clip-text bg-gradient-to-t from-zxheader-from to-zxheader-to">
                <span className="border-l-4 pt-1 border-zxsub-to pl-1">
                    {props.name}
                </span>
            </h2>
            {/* <BiArrowBack
                className="mt-1.5 -ml-9 cursor-pointer"
                size="3%"
                style={{ color: '#4A65EE' }}
                onClick={() => navigate(-1)}
            /> */}
            <form className="w-[50%]">
                <div className="relative flex justify-center ">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="absolute top-1 bottom-0 lg:w-6 lg:h-6 md:w-5 md:h-5 my-auto  text-zxtext-1 left-[85px]"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                    </svg>
                    { path === '/holidays' || path === '/leaves/ophols'?
                        <input
                        type="text"
                        placeholder="Search.."
                        id="search"
                        name="search"
                        value={props.hol_name}
                        onChange={(e) => {
                            if (props.set_hol)
                                props.set_hol((values) => ({
                                    ...values,
                                    name: e.target.value,
                                }));
                        }}
                        className="w-[75%] bg-transparent py-3 pl-12 pr-4 lg:h-10 md:h-8 mt-0.5 text-gray border rounded-full border-zxblue-to outline-none"
                        />:
                        <input
                        type="text"
                        placeholder="Search.."
                        id="search"
                        name="search"
                        value={props.emp_name}
                        onChange={(e) => {
                            if (props.set_name)
                                props.set_name((values) => ({
                                    ...values,
                                    name: e.target.value,
                                }));
                        }}
                        className="w-[75%] bg-transparent py-3 pl-12 pr-4 lg:h-10 md:h-8 mt-0.5 text-gray border rounded-full border-zxblue-to outline-none"
                    />
                    }
                </div>
            </form>
            <div className="flex flex-row pt-1 gap-x-12">
                {props.setNotif ? (
                    <BellIcon
                        onClick={() => {
                            if (props.setNotif) props.setNotif(!props.notif);
                        }}
                        className="relative z-0 lg:h-8 lg:w-8 md:h-6 md:w-6 text-zxicon hover:text-gray-800 cursor-pointer"
                    />
                ) : null}
                <Link to="1">
                    <Cog8ToothIcon className="lg:h-8 lg:w-8 md:h-6 md:w-6 text-zxicon hover:text-gray-800" />
                </Link>
                <Link to="3">
                    <ArrowDownTrayIcon className="lg:h-8 lg:w-6 md:h-6 md:w-4 text-zxicon hover:text-gray-800" />
                </Link>
            </div>
        </div>
    );
}

export default Header;
