import '../../App.css';

import { getAuthorisationURLWithQueryParamsAndSetState } from 'supertokens-web-js/recipe/thirdparty';
import { REACT_APP_URL } from '../../constant/environment';
import Button from '../FormFields/Button';

async function MSSignInClicked() {
    try {
        const authUrl = await getAuthorisationURLWithQueryParamsAndSetState({
            providerId: 'MS',

            // This is where Google should redirect the user back after login or error.
            // This URL goes on the Google's dashboard as well.
            authorisationURL: `${REACT_APP_URL}auth/callback/MS`,
        });
        /*
        Example value of authUrl: https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&access_type=offline&include_granted_scopes=true&response_type=code&client_id=1060725074195-kmeum4crr01uirfl2op9kd5acmi9jutn.apps.googleusercontent.com&state=5a489996a28cafc83ddff&redirect_uri=https%3A%2F%2Fsupertokens.io%2Fdev%2Foauth%2Fredirect-to-app&flowName=GeneralOAuthFlow
        */

        // we redirect the user to google for auth.
        window.location.assign(`${authUrl}`);
    } catch (err) {
        console.error(err);
        // if (err.isSuperTokensGeneralError === true) {
        //     // this may be a custom error message sent from the API by you.
        //     window.alert(err.message);
        // } else {
        //     window.alert('Oops! Something went wrong.');
        // }
    }
}

function LoginForm() {
    // const { mutateAsync } = useCreateTokenObtainPair();
    // const navigate = useNavigate();
    // const formik = useFormik({
    //     initialValues: {
    //         user_name: '',
    //         password: '',
    //     },
    //     onSubmit: async (values: TokenObtainPair) => {
    //         const res = await mutateAsync({ data: values });
    //         if (res.status !== 200) {
    //             alert('Not Authorized');
    //         } else {
    //             const token: any = res.data;
    //             localStorage.setItem('access_token', token.access);
    //             axios.defaults.headers.common.Authorization = `Bearer ${token.access}`;
    //             // console.log(localStorage.getItem('access_token'));
    //             alert('Submitted');
    //             navigate('/'); //   redirect to employee registration page
    //         }
    //     },
    // });

    const handleSubmit = async (event: any) => {
        event.preventDefault();
       await MSSignInClicked();
        // handleGoogleCallback();
        // console.log("Print")
    };
    return (
        <form className="lg:text-base md:text-sm" onSubmit={handleSubmit}>
            {/* <div className="flex flex-col lg:mt-2 md:mt-4">
                <label className="ml-14 -mb-4">User Id</label>
                <br />
                <input
                    name="user_name"
                    id="user_name"
                    onChange={formik.handleChange}
                    className="w-9/12 place-self-center border border-zxblue-to rounded-md 
                    focus:outline-none"
                    value={formik.values.user_name}
                    type="text"
                />
            </div>
            <div className="flex flex-col lg:mt-2 md:mt-4">
                <label className="ml-14 -mb-4">Password</label>
                <br />
                <input
                    name="password"
                    id="password"
                    onChange={formik.handleChange}
                    className="w-9/12 place-self-center mb-4 border border-zxblue-to
                     rounded-md focus:outline-none"
                    value={formik.values.password}
                    type="password"
                />
            </div> */}
            <div className="px-24 mt-20 flex flex-col">
                <Button
                    value="Log In"
                    className="lg:text-base place-self-center md:text-sm  "
                />
            </div>
            {/* <div className="text-center  ">
                <Link
                    to="/reset-password"
                    className="text-xs md:place-self-center lg:place-self-center"
                >
                    Forget Password
                </Link>
            </div> */}
        </form>
    );
}
export default LoginForm;
