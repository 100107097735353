import { Calendar } from 'react-multi-date-picker';
import AttStatusBar from './AttStatusBar';

function Tlattregform() {
    return (
        <div className="flex flex-row space-x-8">
            <Calendar className="ml-20 mt-5 w-[73%] h-min text-sm font-medium" />
            <AttStatusBar />
        </div>
    );
}

export default Tlattregform;