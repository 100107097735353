import { ArrowPathRoundedSquareIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { useListDepartments, useListDesignations } from '../api';
import { Department } from '../api/schema';
import { Designation } from '../api/schema/designation';
import { empGrade, empStatus } from '../constants/options';
import { BASIC_INFORMATION_PAGE } from '../constants/router';
import Button from './FormFields/Button';

interface Props {
    setFilters: React.Dispatch<
        React.SetStateAction<{
            dept: string;
            grade: string;
            desg: string;
            status: string;
            past: string;
            name: string;
        }>
    >;
}

function SubHeader(props: Props) {
    const { data: departmentsData, isSuccess: departmentsSuccess } =
        useListDepartments();
    const { data: designationsData, isSuccess: designationsSuccess } =
        useListDesignations();
    const deptName =
        departmentsSuccess && departmentsData.data
            ? departmentsData?.data.map((dept: Department) => (
                  <option key={dept.id} value={dept.id}>
                      {dept.name}
                  </option>
              ))
            : null;
    const navigate = useNavigate();
    const desgName =
        designationsSuccess && designationsData.data
            ? designationsData?.data.map((desg: Designation) => (
                  <option key={desg.id} value={desg.id}>
                      {desg.name}
                  </option>
              ))
            : null;

    const gradeName = empGrade.map((grade) => (
        <option key={grade.value} value={grade.value}>
            {grade.name}
        </option>
    ));

    const statusOptions = empStatus.map((status) => (
        <option key={status.value} value={status.value}>
            {status.name}
        </option>
    ));

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        props.setFilters((values) => ({
            ...values,
            [e.target.name]: e.target.value,
        }));
    };

    return (
        <div className="text-zxtext-1 space-x-5 flex flex-row">
            <div className="flex lg:text-sm md:text-xs flex-row gap-x-4 pt-2">
                <button
                    type="button"
                    onClick={() =>
                        props.setFilters({
                            dept: '',
                            grade: '',
                            desg: '',
                            status: '',
                            past: '',
                            name: '',
                        })
                    }
                >
                    <ArrowPathRoundedSquareIcon className="h-6 w-6" />
                </button>
                <label>
                    Department:
                    <select
                        className="focus:outline-none lg:text-sm md:text-xs"
                        name="dept"
                        id="dept"
                        onChange={handleChange}
                    >
                        <option defaultValue="0" value="">
                            Choose a Dept
                        </option>
                        {departmentsSuccess && departmentsData.data
                            ? deptName
                            : null}
                    </select>
                </label>
                <label>
                    Grade:
                    <select
                        className="focus:outline-none"
                        name="grade"
                        id="grade"
                        onChange={handleChange}
                    >
                        <option defaultValue="" value="">
                            Select a Grade
                        </option>
                        {gradeName}
                    </select>
                </label>
                <label>
                    Designation:
                    <select
                        className="focus:outline-none"
                        name="desg"
                        id="desg"
                        onChange={handleChange}
                    >
                        <option defaultValue="" value="">
                            Select a Designation
                        </option>
                        {designationsSuccess && designationsData.data
                            ? desgName
                            : null}
                    </select>
                </label>
                <label>
                    Status:
                    <select
                        className="focus:outline-none"
                        name="status"
                        id="status"
                        onChange={handleChange}
                    >
                        <option defaultValue="" value="">
                            Select a Status
                        </option>
                        {statusOptions}
                    </select>
                </label>
                <label>
                    Employee:
                    <select
                        className="focus:outline-none"
                        name="past"
                        id="past"
                        onChange={handleChange}
                    >
                        <option defaultValue={undefined} value="">
                            Select
                        </option>
                        <option value="true">Past Employees</option>
                        {/* <option value="false">Current Employees</option> */}
                    </select>
                </label>
            </div>
            <Button
                value="Add Employee"
                className="lg:text-base md:text-sm"
                onClick={() => navigate(BASIC_INFORMATION_PAGE)}
            />
        </div>
    );
}

export default SubHeader;
