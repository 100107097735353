import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useListAttendances, useRetrieveEmployee } from '../api';

import EmpAttTable from '../components/Attendance/EmpAttTable';
import EmpAvgHours from '../components/Attendance/EmpAvgHours';
import EmployeeInfo from '../components/Attendance/EmployeeInfo';
import Header from '../components/Header';
import { ATTENDANCEMUSTER } from '../constants/router';

function EmpAttendanceList() {
    const { id } = useParams();
    const { data: employeeData, isSuccess: isEmpSuccess } = useRetrieveEmployee(
        id || ''
    );
    const { state } = useLocation();
    const cal = state;
    const { month, year } = cal;
    const navigate = useNavigate();

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    const { data: empAttData, isSuccess: isAttSuccess } = useListAttendances({
        axios: {
            params: {
                emp_id: id,
                month,
                year,
            },
        },
    });
    return (
        <div className="lg:pl-12 md:pl-6 py-6 w-screen text-zxtext-1">
            <Header name="Attendance" />
            <div className="shadow-lg lg:mr-10 md:mr-8 ml-2 flex flex-col items-center">
                {employeeData && isEmpSuccess && state ? (
                    <EmployeeInfo
                        empData={employeeData.data}
                        dateState={state}
                    />
                ) : null}
                <div className="py-8 lg:text-2xl md:text-xl font-medium">
                    Attendance For The Month Of {months[month - 1]} {year}
                </div>
                {empAttData && isAttSuccess && employeeData ? (
                    <EmpAttTable
                        attData={empAttData.data}
                        empData={employeeData.data}
                    />
                ) : null}
                {empAttData && isAttSuccess ? (
                    <EmpAvgHours attData={empAttData?.data} />
                ) : null}
                <div className="flex justify-center gap-x-8 mb-12">
                    <button
                        type="button"
                        className="btn-yellow"
                        onClick={() => navigate(ATTENDANCEMUSTER)}
                    >
                        Back
                    </button>
                    <button type="button" className="btn-blue">
                        Download
                    </button>
                </div>
            </div>
        </div>
    );
}

export default EmpAttendanceList;
