import {
    useRetrieveDepartment,
    useRetrieveDesignation,
    useRetrieveEmployee,
    useRetrieveMSUser
} from '../../../api';
import ProfileUpdateField from '../ProfileUpdateField';

export default function EmployeeInfoSection(props: { id: string }) {
    const { data: employeeData } = useRetrieveMSUser(props.id);
    const empDetails = employeeData?.data.MSemployee_details;
    const desginationID = empDetails?.designation_id;
    const { data: designationName } = useRetrieveDesignation(
        desginationID ? desginationID.toString() : ''
    );
    const departmentID = empDetails?.department_id;
    const { data: departmentName } = useRetrieveDepartment(
        departmentID ? departmentID.toString() : ''
    );
    const { data: reportMgrData } = useRetrieveEmployee(
        empDetails?.reporting_manager
            ? empDetails.reporting_manager.toString()
            : ''
    );
    return (
        <div className="grid grid-flow-row gap-y-4 gap-x-2 md:grid-cols-3 lg:grid-cols-4 px-7">
            <ProfileUpdateField
                label="First Name"
                value={empDetails?.first_name ? empDetails.first_name : '-'}
            />
            <ProfileUpdateField
                label="Last Name"
                value={empDetails?.last_name ? empDetails.last_name : '-'}
            />
            <ProfileUpdateField
                label="Email ID"
                value={employeeData?.data.email ? employeeData.data.email : '-'}
            />
            <ProfileUpdateField
                label="Position"
                value={
                    designationName?.data.name
                        ? designationName?.data.name
                        : '-'
                }
            />
            <ProfileUpdateField
                label="Joining Date"
                value={empDetails?.joining_date ? empDetails.joining_date : '-'}
            />
            <ProfileUpdateField
                label="Confirmation Date"
                value={
                    empDetails?.confirmation_date
                        ? empDetails.confirmation_date
                        : '-'
                }
            />
            <ProfileUpdateField
                label="Status"
                value={
                    empDetails?.employee_status
                        ? empDetails.employee_status
                        : '-'
                }
            />
            <ProfileUpdateField
                label="Reporting To"
                value={
                    reportMgrData?.data.full_name
                        ? reportMgrData.data.full_name
                        : '-'
                }
            />
            <ProfileUpdateField
                label="Department ID"
                value={
                    departmentName?.data.name ? departmentName.data.name : '-'
                }
            />
            {empDetails?.employee_status === 'Absconded' ||
            empDetails?.employee_status === 'Retired' ? (
                <ProfileUpdateField
                    label="Last Working Date"
                    value={
                        empDetails.last_working_date
                            ? empDetails.last_working_date
                            : '-'
                    }
                />
            ) : null}
        </div>
    );
}
