import classnames from 'classnames';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useEffect } from 'react';
import { GiCancel } from 'react-icons/gi';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import {
    Department,
    MSUser,
    useCreateDepartment,
    useListDepartments,
    useListMSUsers
} from '../../api';
import { getHtmlFor } from '../../utils/formUtils';
import ButtonField from '../FormFields/ButtonField';

const initialDepartmentData = {
    name: '',
    manager_id: 0,
};
const departmentValidationSchema = Yup.object({
    name: Yup.string().max(50).required('Required'),
    manager_id: Yup.number().typeError('Choose a value').required('Required'),
});

export default function AddNewDepartment(props: {
    addNewDept: boolean;
    setAddNewDept: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const { data: deptData, refetch } = useListDepartments();
    const deptNames = deptData?.data;
    const { mutateAsync, isSuccess } = useCreateDepartment();
    const { data: usersData } = useListMSUsers();
    const usersId = usersData?.data;
    const children = usersId?.map((userID: MSUser) => (
        <option
            key={userID.MSemployee_details?.id}
            value={userID.MSemployee_details?.id}
        >
            {userID.MSemployee_details?.full_name}
        </option>
    ));

    const handleDeptSubmit = async (values: Department) => {
        if (deptNames?.some((depName) => depName.name === values.name)) {
            toast.warn(`${values.name} is present in Database`);
        } else {
            const createDept: Department = {
                ...values,
            };
            await mutateAsync({ data: createDept });
            toast.success(`Department Created`);
            props.setAddNewDept(false);
        }
    };

    useEffect(() => {
        if (isSuccess) {
            refetch();
        }
    }, [isSuccess, refetch]);

    if (props.addNewDept) {
        return (
            <div
                className="mx-auto w-[25%] z-0 bg-white text-zxtext-1 absolute shadow-lg shadow-slate-400 rounded-lg border-t-2 border-gray-300 box-border"
                role="alert"
            >
                <h2 className="py-6 pl-7 border-x-zxblue-from text-transparent font-semibold md:text-lg lg:text-xl inline-block">
                    <span className="pl-1 border-l-4 border-zxsub-to bg-clip-text bg-gradient-to-r from-zxheader-from to-zxheader-to opacity-100">
                        Add Department
                    </span>
                </h2>
                <GiCancel
                    className="float-right mr-7 mt-7"
                    size="6%"
                    onClick={() => {
                        props.setAddNewDept(false);
                    }}
                />
                <Formik
                    initialValues={initialDepartmentData}
                    validationSchema={departmentValidationSchema}
                    onSubmit={handleDeptSubmit}
                >
                    {({ touched, errors, submitForm, isSubmitting }) => (
                        <Form className="flex flex-col mx-auto w-[75%]">
                            <div className="flex flex-col">
                                <label
                                    htmlFor={getHtmlFor('manager_id')}
                                    className={classnames(
                                        'pl-0.5',
                                        touched.name && errors.name
                                            ? 'text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                                            : 'border-zxblue-to'
                                    )}
                                >
                                    Department Name
                                </label>
                                <Field
                                    name="name"
                                    type="text"
                                    placeholder="Enter new department name"
                                    className={classnames(
                                        'w-full h-8 border rounded-md focus:outline-none placeholder: pl-2 text-sm',
                                        touched.name && errors.name
                                            ? 'border-zxerror-to'
                                            : 'border-zxblue-to'
                                    )}
                                />
                                <ErrorMessage
                                    name="name"
                                    component="span"
                                    className="text-xs text-zxerror-from top-0"
                                />
                            </div>
                            <div className="flex flex-col">
                                <label
                                    htmlFor={getHtmlFor('manager_id')}
                                    className={classnames(
                                        'pl-0.5 pt-3',
                                        touched.manager_id && errors.manager_id
                                            ? 'text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                                            : 'border-zxblue-to'
                                    )}
                                >
                                    Select Manager
                                </label>
                                <Field
                                    as="select"
                                    name="manager_id"
                                    className={classnames(
                                        'w-full h-8 border py-1 rounded-md focus:outline-none placeholder: pl-2 text-sm',
                                        touched.manager_id && errors.manager_id
                                            ? 'border-zxerror-to '
                                            : 'border-zxblue-to'
                                    )}
                                    // {...props}
                                >
                                    <option defaultValue={undefined}>
                                        Select
                                    </option>
                                    {children}
                                </Field>
                                <ErrorMessage
                                    name="manager_id"
                                    component="span"
                                    className="text-xs text-zxerror-from top-0"
                                />
                            </div>
                            <ButtonField
                                name="Submit"
                                className="self-center mb-3"
                                onClick={() => submitForm()}
                                disabled={isSubmitting}
                            />
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
    return null;
}
