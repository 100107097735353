import { Holiday, HolidayStatus, useUpdateHoliday } from '../api';

import classnames from 'classnames';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

interface HolidayRowProps {
    holiday: Holiday;
    refetch: any;
}

function HolidayRow({ holiday, refetch }: HolidayRowProps) {
    // const navigate = useNavigate();
    const { mutateAsync, isSuccess } = useUpdateHoliday();

    const updateStatus = async (stats: HolidayStatus) => {
        const updatedVal = {
            name: holiday.name,
            date: holiday.date,
            status: stats,
        };
        await mutateAsync({
            id: holiday.id ? holiday.id?.toString() : '',
            data: updatedVal,
        });
    };

    useEffect(() => {
        if (isSuccess) {
            refetch();
            toast.success('Holiday Status Changed');
        }
    }, [isSuccess, refetch]);
    const buttonStyle = 'lg:w-20 lg:h-9 md:w-16 md:h-8 mt-2 rounded-lg';
    const activeOn = classnames(
        buttonStyle,
        'mr-2 text-white bg-gradient-to-r from-zxblue-from to-zxblue-to hover:bg-gradient-to-t from-zxblue-from to-zxblue-to'
    );

    const activeOff = classnames(
        buttonStyle,
        'mr-2',
        'text-black bg-gradient-to-l from-white to-white border border-zxblue-to hover:bg-gradient-to-r from-white to-slate-50'
    );

    const deactiveOn = classnames(
        buttonStyle,
        'text-white bg-gradient-to-tl from-zxbutton2-to to-zxbutton2-from border'
    );

    const deactiveOff = classnames(
        buttonStyle,
        'text-black bg-gradient-to-l from-white to-white border border-zxbutton2-to hover:bg-gradient-to-r from-white to-slate-50'
    );

    return (
        <tr key={holiday.id} className="text-center h-14">
            <td>{holiday.id}</td>
            <td>{holiday.date}</td>
            <td>{holiday.day}</td>
            <td>{holiday.name}</td>
            <td>
                {holiday.type === 'COMP' ? (
                    <div className="w-4 h-4 rounded-full flex  bg-gradient-to-r from-zxbullet-from to-zxbullet-to mx-auto" />
                ) : (
                    <div />
                )}
            </td>
            <td>
                {holiday.type === 'OP' ? (
                    <div className="w-4 h-4  rounded-full flex  bg-gradient-to-r from-zxbullet2-from to-zxbullet2-to mx-auto" />
                ) : (
                    <div />
                )}
            </td>
            <td className="flex flex-row gap-3 object-center items-center ">
                {/* <Button
                    value="Active"
                    className={holiday.status === 'ACT' ? activeOn : activeOff}
                    onClick={() => updateStatus('ACT')}
                /> */}
                <button
                    type="button"
                    className={holiday.status === 'ACT' ? activeOn : activeOff}
                    onClick={() => updateStatus('ACT')}
                >
                    Active
                </button>
                {/* <Button
                    value="Deactive"
                    className={
                        holiday.status !== 'ACT' ? deactiveOn : deactiveOff
                    }
                    onClick={() => updateStatus('INACT')}
                /> */}
                <button
                    type="button"
                    className={
                        holiday.status !== 'ACT' ? deactiveOn : deactiveOff
                    }
                    onClick={() => updateStatus('INACT')}
                >
                    Deactive
                </button>
            </td>
        </tr>
    );
}

export default HolidayRow;
