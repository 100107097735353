import { useListEmployees } from '../../api';
import MemberAttRows from './MemberAttRows';

function MembersAttendanceList(props: { tlId: number }) {
    const { data: employeesData, isSuccess } = useListEmployees({
        axios: {
            params: {
                reporting_manager: props.tlId,
            },
        },
    });

    return (
        <table className="table-fixed p-2 min-w-full text-zxtext-1 border-spacing-4">
            <thead>
                <tr>
                    <th>No</th>
                    <th>Name</th>
                    <th>Sign In</th>
                    <th>Sign Out</th>
                    <th>Total Hrs</th>
                    <th>Avg Working Hrs</th>
                    <th>Regularize</th>
                    <th>Leave Applied</th>
                </tr>
            </thead>
            <tbody className="text-center text-sm">
                {isSuccess && employeesData.data ? (
                    <MemberAttRows
                        employeesData={employeesData.data}
                        tlId={props.tlId}
                    />
                ) : null}
            </tbody>
        </table>
    );
}

export default MembersAttendanceList;
