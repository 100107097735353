import { useEffect, useState } from 'react';

import Session from 'supertokens-web-js/recipe/session';
import { UserRoleClaim } from 'supertokens-web-js/recipe/userroles';

const useAdmin = (): boolean => {
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const checkAdmin = async () => {
            if (await Session.doesSessionExist()) {

                const validationErrors = await Session.validateClaims({
                    overrideGlobalClaimValidators: (globalValidators) => [
                        ...globalValidators,
                        UserRoleClaim.validators.includes('admin'),
                    ],
                });

                if (validationErrors.length === 0) {
                    setIsAdmin(true);
                }

                for (const err of validationErrors) {
                    if (err.validatorId === UserRoleClaim.id) {
                        setIsAdmin(false);
                    }
                }
            }
        };

        checkAdmin();
    }, []);

    // console.log({isAdmin});
    

    return isAdmin;
};

export default useAdmin;
