/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  TLLeave
} from '../../schema'


/**
 * List all Leaves of an employee
 */
export const listTLLeaves = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TLLeave[]>> => {
    return axios.get(
      `/tlleaves/`,options
    );
  }


export const getListTLLeavesQueryKey = () => [`/tlleaves/`];

    
export type ListTLLeavesQueryResult = NonNullable<Awaited<ReturnType<typeof listTLLeaves>>>
export type ListTLLeavesQueryError = AxiosError<unknown>

export const useListTLLeaves = <TData = Awaited<ReturnType<typeof listTLLeaves>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTLLeaves>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListTLLeavesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listTLLeaves>>> = ({ signal }) => listTLLeaves({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof listTLLeaves>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

