import { Employee, TLAttendance, useListTLAttendances } from '../../api';

import AttInfo from './AttInfo';

interface MemberAttRowsProps {
    employeesData: Employee[];
    tlId: number;
}

function MemberAttRows(props: MemberAttRowsProps) {
    let counter = 1;
    const { data: attendanceData, isSuccess } = useListTLAttendances({
        axios: {
            params: {
                reporting_to: props.tlId,
                date: new Date().toISOString().split('T')[0],
            },
        },
    });
    return (
        <>
            {props.employeesData.map((employee: Employee) => (
                <tr key={employee.id}>
                    <td className="border border-zxblue-to ">{counter++}</td>
                    <td className="text-left pl-12">{employee.full_name}</td>
                    {isSuccess ? (
                        <AttInfo
                            attData={attendanceData?.data.filter(
                                (att: TLAttendance) =>
                                    att.employee_id === employee.id
                            )}
                            empId={employee.id}
                        />
                    ) : null}
                </tr>
            ))}
        </>
    );
}

export default MemberAttRows;
