import { useEffect, useState } from 'react';

import Session from 'supertokens-web-js/recipe/session';
import { UserRoleClaim } from 'supertokens-web-js/recipe/userroles';

const useTL = (): boolean => {
    const [isTL, setIsTL] = useState(false);

    useEffect(() => {
        const checkTL = async () => {
            if (await Session.doesSessionExist()) {
                const validationErrors = await Session.validateClaims({
                    overrideGlobalClaimValidators: (globalValidators) => [
                        ...globalValidators,
                        UserRoleClaim.validators.includes('team_lead'),
                    ],
                });

                if (validationErrors.length === 0) {
                    setIsTL(true);
                }

                for (const err of validationErrors) {
                    if (err.validatorId === UserRoleClaim.id) {
                        setIsTL(false);
                    }
                }
            }
        };

        checkTL();
    }, []);

    return isTL;
};

export default useTL;
