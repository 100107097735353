import { useRetrieveEmployee } from '../../api';

export default function GetManagerID(props: {
    manager_id: number | null | undefined;
}) {
    const { data: managerData } = useRetrieveEmployee(
        props.manager_id ? props.manager_id.toString() : ''
    );
    return <span>{managerData?.data.employee_number?.toString()}</span>;
}
