import './index.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import axios from 'axios';
import React from 'react';
// import 'react-calendar/dist/Calendar.css';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { REACT_APP_BASE_URL } from './constant/environment';

const queryClient = new QueryClient();

// axios.defaults.headers.common.Authorization = `Bearer 1234567890`;

axios.interceptors.request.use((config) => {
    return {
        ...config,
        baseURL: REACT_APP_BASE_URL,
    };
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </QueryClientProvider>
    </React.StrictMode>
);
