import { useListMSViewers } from '../api';
import TLList from '../components/Attendance/TLList';
import AttendanceFrame2 from '../components/AttendanceFrame2';
import ZXHeader from '../components/ZXHeader';

function Attendence() {
    const { data: msViewersData, isSuccess } = useListMSViewers();
    const viewerData = msViewersData?.data[0];
    return (
        <div className="h-screen box-border bg-zxattendancebg p-6 w-screen overflow-auto">
            <div className="pl-12">
                <ZXHeader />
            </div>

            <div className="m-10 box-border shadow-xl bg-white rounded-lg">
                {isSuccess && viewerData ? (
                    <TLList viewerData={viewerData} />
                ) : null}
                <AttendanceFrame2 />
            </div>
        </div>
    );
}

export default Attendence;
