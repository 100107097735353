import AvgHrs from './AvgHrs';
import { TLAttendance } from '../../api';
import moment from 'moment';

interface AttInfoProps {
    attData: TLAttendance[] | undefined;
    empId: number | undefined;
}

function AttInfo(props: AttInfoProps) {
    const attData = props.attData?.[0];
    const startTime = moment(attData?.sign_in, 'HH:mm:ss a');
    const endTime = moment(attData?.sign_out, 'HH:mm:ss a');
    const duration = moment.duration(endTime.diff(startTime));

    return (
        <>
            {attData && attData.sign_in ? (
                <td>{attData.sign_in}</td>
            ) : (
                <td>--:-- AM</td>
            )}
            {attData && attData.sign_out ? (
                <td>{attData.sign_out}</td>
            ) : (
                <td>--:-- PM</td>
            )}
            {attData && attData.sign_in && attData.sign_out ? (
                <td>
                    {duration.hours()} Hrs {duration.minutes()} min
                </td>
            ) : (
                <td>--:--</td>
            )}
            {props.empId ? <AvgHrs empId={props.empId} /> : <td>--:--</td>}
        </>
    );
}

export default AttInfo;
