import moment from 'moment';
import { Regularization } from '../../api';
import { weekday } from '../../constants/functions';

export default function RegularizationHistory(props: {
    regDetails: Regularization;
}) {
    return (
        <div className="box-border border border-zxblue-to rounded-md focus:outline-none w-full pl-4 py-4 mb-3">
            <div className="flex flex-col space-y-3 text-zxtext-1 text-sm">
                <div className="flex flex-row space-x-4">
                    <div className="flex flex-col leading-3 font-medium">
                        <span className="border border-zxblue-from px-2 py-1">
                            {moment(props.regDetails.date).date()}
                        </span>
                        <span className="pt-1.5 text-zxtext-3 text-xs text-center">
                            {weekday[moment(props.regDetails.date).day()]}
                        </span>
                    </div>
                    <div className="flex flex-col font-meduim space-y-2">
                        <span>
                            Actual Sign In:{' '}
                            <span className="text-xs text-zxerror-from">
                                {moment(props.regDetails.actual_sign_in).format(
                                    'LT'
                                )}
                            </span>
                        </span>
                        <span>
                            Actual Sign Out:{' '}
                            <span className="text-xs text-zxerror-from">
                                {moment(
                                    props.regDetails.actual_sign_out
                                ).format('LT')}
                            </span>
                        </span>
                    </div>
                    <div className="ml-5 flex flex-col font-meduim space-y-2">
                        <span>
                            Regularized Sign In:{' '}
                            <span className="text-xs">
                                {moment(
                                    props.regDetails.regularized_sign_in
                                ).format('LT')}
                            </span>
                        </span>
                        <span>
                            Regularized Sign Out:{' '}
                            <span className="text-xs">
                                {moment(
                                    props.regDetails.regularized_sign_out
                                ).format('LT')}
                            </span>
                        </span>
                    </div>
                </div>
                <div className="">
                    Reason:{' '}
                    <span className="text-xs">{props.regDetails.reason}</span>
                </div>
                <div className="place-self-end pr-10">
                    Status:{' '}
                    <span className="text-xs">{props.regDetails.status}</span>
                </div>
            </div>
        </div>
    );
}
