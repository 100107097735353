
function Helpdesk() {
    return (
        <div className="h-screen box-border bg-zxbg lg:pl-12 md:pl-6 py-6 lg:w-screen">
            <h1>Helpdesk Page</h1>
        </div>
    );
}

export default Helpdesk;
