import {
    BankUpiApp,
    EducationClass10Board,
    EducationClass12Board,
    EducationClass12Stream,
    EmployeeGrade,
    ProfileMaritalStatus
} from '../api/schema';

export const UPIAPP = [
    BankUpiApp.Amazon_Pay,
    BankUpiApp.BHIM,
    BankUpiApp.Gpay,
    BankUpiApp.Phonepe,
    BankUpiApp.WhatsApp,
    BankUpiApp.Other,
];

export const BOARD10 = [
    EducationClass10Board.STATE,
    EducationClass10Board.CBSE,
    EducationClass10Board.ICSE,
];

export const BOARD12 = [
    EducationClass12Board.STATE,
    EducationClass12Board.CBSE,
    EducationClass12Board.ICSE,
];

export const STREAM = [
    EducationClass12Stream.Arts,
    EducationClass12Stream.Science,
    EducationClass12Stream.Commerce,
];

export const empGrade = [
    {
        name: 'Junior',
        value: EmployeeGrade.Junior,
    },
    {
        name: 'Senior',
        value: EmployeeGrade.Senior,
    },
    {
        name: 'Manager',
        value: EmployeeGrade.Manager,
    },
    {
        name: 'Director',
        value: EmployeeGrade.Director,
    },
    {
        name: 'Team Leader',
        value: EmployeeGrade.Team_Leader,
    },
];

export const maritalStatus = [
    {
        name: 'Single',
        value: ProfileMaritalStatus.Single,
    },
    {
        name: 'Married',
        value: ProfileMaritalStatus.Married,
    },
];

export const empStatus = [
    {
        name: 'Permanent',
        value: 'ZX',
    },
    {
        name: 'Internship',
        value: 'IN',
    },
    {
        name: 'Part-Time',
        value: 'PA',
    },
    {
        name: 'Temporary',
        value: 'T',
    },
];
