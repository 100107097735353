import { AxiosResponse } from 'axios';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { MSViewer } from '../../api';
import { LEAVE } from '../../constants/router';
import Button from '../FormFields/Button';

export default function LeaveBalanceDashboard(props: {
    viewerData: AxiosResponse<MSViewer[]> | undefined;
    isLoading: boolean;
    isViewerAdmin: boolean;
}) {
    const navigate = useNavigate();
    return (
        <div className="py-6 px-7 flex flex-col box-border gap-2">
            <div className="lg:text-lg md:text-base font-semibold text-transparent bg-gradient-to-r from-zxheader-from to-zxheader-to opacity-700 bg-clip-text">
                Leave Balance
            </div>
            {props.isLoading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <ul
                        className={classNames(
                            'list-disc pl-4 font-normal text-zxtext-1 leading-5',
                            props.isViewerAdmin
                                ? 'space-y-2 lg:text-sm md:text-xs'
                                : 'text-xs'
                        )}
                    >
                        <ListLeaveBalance
                            leaveType="Earned leave"
                            balance={
                                props.viewerData?.data[0].MSemployee_details
                                    ?.earned_leave_balance
                                    ? props.viewerData.data[0].MSemployee_details.earned_leave_balance.toString()
                                    : '0'
                            }
                        />
                        <ListLeaveBalance
                            leaveType="Casual leave"
                            balance={
                                props.viewerData?.data[0].MSemployee_details
                                    ?.casual_leave_balance
                                    ? props.viewerData.data[0].MSemployee_details.casual_leave_balance.toString()
                                    : '0'
                            }
                        />
                        <ListLeaveBalance
                            leaveType="Sick leave"
                            balance={
                                props.viewerData?.data[0].MSemployee_details
                                    ?.sick_leave_balance
                                    ? props.viewerData.data[0]
                                          .MSemployee_details
                                          ?.sick_leave_balance
                                    : '0'
                            }
                        />
                        <ListLeaveBalance
                            leaveType="Optional Holiday"
                            balance={
                                props.viewerData?.data[0].MSemployee_details
                                    ?.optional_hol_balance
                                    ? props.viewerData.data[0].MSemployee_details.optional_hol_balance.toString()
                                    : '0'
                            }
                        />
                        <ListLeaveBalance
                            leaveType="Comp off"
                            balance={
                                props.viewerData?.data[0].MSemployee_details
                                    ?.comp_off_bal
                                    ? props.viewerData.data[0]
                                          .MSemployee_details.comp_off_bal
                                    : '0'
                            }
                        />
                    </ul>
                    {!props.isViewerAdmin ? (
                        <Button
                            onClick={() => navigate(LEAVE)}
                            className="w-fit mx-auto"
                            value="Apply"
                        />
                    ) : null}
                </>
            )}
        </div>
    );
}

function ListLeaveBalance(props: { leaveType: string; balance: string }) {
    return (
        <li>
            <div className="flex">
                <span className="w-full">{props.leaveType}</span>
                <span className="place-self-end">{props.balance}</span>
            </div>
        </li>
    );
}
