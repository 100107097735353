/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */

export type EducationClass12Stream = typeof EducationClass12Stream[keyof typeof EducationClass12Stream];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EducationClass12Stream = {
  Science: 'Science',
  Commerce: 'Commerce',
  Arts: 'Arts',
} as const;
