import { useEffect } from 'react';
import { useListDepartments } from '../../api';
import { DepartmentRow } from './DepartmentRow';

export default function DepartmentTable() {
    const {
        data: deptData,
        isSuccess,
        isLoading,
        isFetchedAfterMount,
        refetch,
    } = useListDepartments();

    useEffect(() => {
        if (isSuccess) {
            refetch();
        }
    }, [isSuccess, refetch]);

    return (
        <div className="flex flex-col px-10">
            <h2 className="py-4 border-x-zxblue-from text-transparent font-semibold md:text-xl lg:text-2xl bg-clip-text bg-gradient-to-t from-zxheader-from to-zxheader-to">
                <span className="border-l-2 border-zxsub-to pl-1">
                    Department Directory
                </span>
            </h2>
            <table className="text-zxtext-1 md:text-sm lg:text-lg px-2 w-full table-auto">
                <thead className="lg:h-11 md:h-10">
                    <tr id="bord-row">
                        <th>Code</th>
                        <th>Name</th>
                        <th>Manager ID</th>
                        <th>Manager Name</th>
                    </tr>
                </thead>
                <tbody className="lg:text-sm md:text-xs text-left">
                    {isLoading ? (
                        <tr>
                            <td>Loading.......</td>
                        </tr>
                    ) : isFetchedAfterMount && isSuccess ? (
                        <DepartmentRow<
                            Parameters<typeof refetch>[0],
                            ReturnType<typeof refetch>
                        >
                            deptData={deptData}
                            // refetch={refetch}
                        />
                    ) : (
                        <tr>
                            <td>Not Success</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
}
