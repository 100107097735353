import { ProfileNotifications, useRetrieveEmployee } from '../api';

import Notif from './Notif';
import ProfileImage from './ProfileImage';

function ProfileNotifInfo(props: {
    notification: ProfileNotifications;
    empId: number;
}) {
    const { data: employeeData, isSuccess: employeeSuccess } =
        useRetrieveEmployee(props.empId.toString());

    return employeeSuccess && employeeData.data ? (
        <>
            <ProfileImage className="px-3 py-1" empData={employeeData.data} />
            <Notif
                className="border-t border-t-zxline pl-1 py-2 w-[80%]"
                notification={props.notification}
                empName={employeeData.data.full_name}
            />
        </>
    ) : null;
}

export default ProfileNotifInfo;
