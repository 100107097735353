import { useFormik } from 'formik';
import Button from '../FormFields/Button';

function PwdForm() {
    const formik = useFormik({
        initialValues: {
            email_id: '',
        },

        onSubmit: (values) => {
            alert('Submitted');
        },
    });
    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="md:ml-20 md:place-self-center lg:place-self-center">
                <label className="">Email Id</label>
                <br />
                <input
                    name="email"
                    className="w-9/12 mb-4 border border-zxblue-to rounded-md focus:outline-none "
                    type="email"
                />
            </div>
            <div className="flex flex-col md:px-24 ">
                <Button
                    value="Reset Password"
                    className="lg:text-base md:place-self-center lg:place-self-center md:text-sm"
                />
            </div>
        </form>
    );
}
export default PwdForm;
