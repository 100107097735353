export function Pluscircle()
{
    return(
        
        
        <svg xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 24 24" strokeWidth="1" stroke="black" className="w-9 h-w-9 ">
  <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
</svg>


    )
}

export function Minuscircle()
{
    return(
        
        <svg xmlns="http://www.w3.org/2000/svg" fill="#4A65EE" viewBox="0 0 24 24" strokeWidth="1.5" stroke="white" className="w-9 h-w-9">
        <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
      
      

    )
}