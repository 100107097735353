import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import {
    MSViewer,
    Profile,
    ProfileBloodGroup,
    ProfileMaritalStatus,
    ProfileNationality,
    ProfileReligion,
    useCreateProfile,
    useUpdateProfile
} from '../api';

import { useFormik } from 'formik';
import { useEffect } from 'react';
import { EMPLOYEE_FIELD } from '../constants/FieldNames';
import {
    BloodGroup,
    MaritalStatus,
    Nationality,
    Religion
} from '../constants/formSelect';
import Button from './FormFields/Button';
import Field from './FormFields/Field';
import Select from './FormFields/Select';

function DateField(props: {
    label: string;
    placeholder: string;
    id: string;
    value: string;
    name: string;
    formikChange: any;
    touched: boolean | undefined;
    formik: string | undefined;
}) {
    return (
        <div className="flex flex-col">
            <label
                className={
                    props.touched && props.formik
                        ? 'pl-0.5 text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                        : 'pl-0.5'
                }
            >
                {props.label}
            </label>
            <input
                id={props.id}
                name={props.name}
                className={
                    props.touched && props.formik
                        ? ' w-9/12 mb-4 border border-zxerror-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                        : 'w-9/12 mb-4 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                }
                type="date"
                placeholder={props.placeholder}
                onChange={props.formikChange}
                value={props.value}
            />
            {props.formik && props.touched ? (
                <span className="text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to text-xs -mt-4">
                    {props.formik}
                </span>
            ) : null}
        </div>
    );
}

function ProfileForm(props: { userData: MSViewer, refetch:any }) {
    const { mutateAsync, isSuccess: createSuccess } = useCreateProfile();
    const { mutateAsync: profileMutate, isSuccess: updateSuccess } =
        useUpdateProfile();

    const profileDetails = props.userData.MSemployee_details?.profile_details;
    const empDetails = props.userData.MSemployee_details;

    const formik = useFormik({
        initialValues: {
            dob: '',
            contact_no: '',
            emergency_contact: '',
            blood_group: ProfileBloodGroup.AB,
            marital_status: ProfileMaritalStatus.Single,
            nationality: ProfileNationality.Indian,
            spo_father_name: '',
            religion: ProfileReligion.Hindu,
            differently_abled: false,
            international_employee: false,
            spouse: false,
            father: false,
            differently_abled_text: undefined,
            international_employee_text: undefined,
        },
        validationSchema: Yup.object({
            dob: Yup.string().required(),
            contact_no: Yup.string()
                .min(10)
                .max(10)
                .required('Required')
                .matches(/^\d*$/),
            emergency_contact: Yup.string()
                .min(10)
                .max(10)
                .required('Required')
                .matches(/^\d*$/),
            blood_group: Yup.string().oneOf(BloodGroup).required('Required'),
            marital_status: Yup.string()
                .oneOf(MaritalStatus)
                .required('Required'),
            nationality: Yup.string().oneOf(Nationality).required('Required'),
            spo_father_name: Yup.string().max(20).required('Required'),
            religion: Yup.string().oneOf(Religion).required('Required'),
            differently_abled_text: Yup.string(),
            international_employee_text: Yup.string(),
        }),
        onSubmit: async (values: Profile) => {
            if (empDetails === undefined || empDetails === null) {
                toast('Create a new employee');
            } else {
                const updatedVal: Profile = {
                    ...values,
                    emp_id: empDetails.id,
                };

                if (profileDetails === null || profileDetails === undefined) {
                    const res =await mutateAsync({ data: updatedVal });
                    if(res.status === 201) props.refetch();
                } else {
                    const profileId = profileDetails.id;
                    const res = await profileMutate({
                        id: profileId ? profileId.toString() : '',
                        data: updatedVal,
                    });
                    if(res.status === 200) props.refetch();
                }
            }
        },
    });

    useEffect(() => {
        if (createSuccess) toast.success('Profile Created');
        else if (updateSuccess) toast.success('Profile Updated');
        // if(createSuccess || updateSuccess) {props.refetch();formik.resetForm()};
    }, [createSuccess, updateSuccess]);


    return (
        <div>
            <h2 className="py-4 border-x-zxblue-from text-transparent font-semibold md:text-xl lg:text-2xl bg-clip-text bg-gradient-to-t from-zxheader-from to-zxheader-to">
                <span className="border-l-2 border-zxsub-to pl-1">
                    Profile Information
                </span>
            </h2>
            <form
                className="grid md:grid-cols-3 lg:grid-cols-4 pl-3"
                onSubmit={formik.handleSubmit}
            >
                <DateField
                    label="Date Of Birth *"
                    name="dob"
                    id="dob"
                    formikChange={formik.handleChange}
                    value={formik.values.dob}
                    touched={formik.touched.dob}
                    formik={formik.errors.dob}
                    placeholder="DD MM YYYY"
                />
                <Select
                    label="Blood Group *"
                    options={BloodGroup}
                    id={EMPLOYEE_FIELD.BLOOD_GROUP}
                    name={EMPLOYEE_FIELD.BLOOD_GROUP}
                    onChange={formik.handleChange}
                    value={formik.values.blood_group}
                    touched={formik.touched.blood_group}
                    formik={formik.errors.blood_group}
                />

                <Select
                    label="Marital Status *"
                    options={MaritalStatus}
                    id={EMPLOYEE_FIELD.MARITAL_STATUS}
                    name={EMPLOYEE_FIELD.MARITAL_STATUS}
                    onChange={formik.handleChange}
                    value={formik.values.marital_status}
                    touched={formik.touched.marital_status}
                    formik={formik.errors.marital_status}
                />

                <Select
                    label="Nationality *"
                    options={Nationality}
                    id={EMPLOYEE_FIELD.NATIONALITY}
                    name={EMPLOYEE_FIELD.NATIONALITY}
                    onChange={formik.handleChange}
                    value={formik.values.nationality}
                    touched={formik.touched.nationality}
                    formik={formik.errors.nationality}
                />

                <Field
                    label="Contact No *"
                    type="text"
                    placeholder="+91xxx"
                    colspan={undefined}
                    id={EMPLOYEE_FIELD.CONTACT_NO}
                    name={EMPLOYEE_FIELD.CONTACT_NO}
                    onChange={formik.handleChange}
                    value={formik.values.contact_no}
                    touched={formik.touched.contact_no}
                    formik={formik.errors.contact_no}
                />

                <Field
                    label="Spouse/Father's *"
                    type="text"
                    id={EMPLOYEE_FIELD.SPO_FATHER}
                    name={EMPLOYEE_FIELD.SPO_FATHER}
                    placeholder="-"
                    colspan={undefined}
                    onChange={formik.handleChange}
                    value={formik.values.spo_father_name}
                    touched={formik.touched.spo_father_name}
                    formik={formik.errors.spo_father_name}
                    svalue={formik.values.spouse}
                    fvalue={formik.values.father}
                />

                <Field
                    label="Emergency Contact No *"
                    type="text"
                    placeholder="+91xxx"
                    colspan={undefined}
                    id={EMPLOYEE_FIELD.EMERGENCY}
                    name={EMPLOYEE_FIELD.EMERGENCY}
                    onChange={formik.handleChange}
                    value={formik.values.emergency_contact}
                    touched={formik.touched.emergency_contact}
                    formik={formik.errors.emergency_contact}
                />

                <Select
                    label="Religion *"
                    options={Religion}
                    id={EMPLOYEE_FIELD.RELIGION}
                    name={EMPLOYEE_FIELD.RELIGION}
                    onChange={formik.handleChange}
                    value={formik.values.religion}
                    touched={formik.touched.religion}
                    formik={formik.errors.religion}
                />

                <Field
                    label="Differently Abled"
                    type="checkbox"
                    placeholder="+91xxx"
                    colspan={undefined}
                    id={EMPLOYEE_FIELD.DIFFABLED}
                    name={EMPLOYEE_FIELD.DIFFABLED}
                    onChange={formik.handleChange}
                    value={formik.values.differently_abled}
                    touched=""
                    formik=""
                    tid={EMPLOYEE_FIELD.DIFFABLEDTEXT}
                    tname={EMPLOYEE_FIELD.DIFFABLEDTEXT}
                    tvalue={formik.values.differently_abled_text}
                />

                <Field
                    label="International Employee"
                    type="checkbox"
                    placeholder="+91xxx"
                    colspan={undefined}
                    id={EMPLOYEE_FIELD.INTERNATIONAL}
                    name={EMPLOYEE_FIELD.INTERNATIONAL}
                    onChange={formik.handleChange}
                    value={formik.values.international_employee}
                    touched=""
                    formik=""
                    tid={EMPLOYEE_FIELD.INTERNATIONTEXT}
                    tname={EMPLOYEE_FIELD.INTERNATIONTEXT}
                    tvalue={formik.values.international_employee_text}
                />

                <Button value="Submit" className="mt-3" />
            </form>
            <div className="relative mt-3 mx-12 right-7 border-b border-3 border-zxheader-from" />
        </div>
    );
}

export default ProfileForm;
