import classnames from 'classnames';
import {
    ErrorMessage,
    Field,
    Form,
    Formik,
    FormikErrors,
    FormikTouched
} from 'formik';
import { useEffect } from 'react';

import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import {
    Employee,
    EmployeeEmployeeNumberSeries,
    EmployeeEmployeeStatus,
    EmployeeGender,
    EmployeeProbationPeriod,
    useListEmployees
} from '../../../api';

import {
    EmpGenderOptions,
    EmployeeNumberSeriesOptions,
    EmployeeStatusOptions,
    EmpProbationPeriodOptions
} from '../../../constants/formSelect';
import { EMPLOYEES, EMPLOYEE_POSITION_PAGE } from '../../../constants/router';
import { EmployeeCreationOutletContext } from '../../../pages/EmployeeCreation';
import ButtonField from '../../FormFields/ButtonField';
import FormikField from '../../FormFields/FormikField';
import FormikSelect from '../../FormFields/FormikSelect';

export interface BasicInformation {
    email_id: string;
    employee_number_series: EmployeeEmployeeNumberSeries;
    employee_number: string;
    first_name: string;
    last_name: string;
    gender: EmployeeGender;
    employee_status: EmployeeEmployeeStatus;
    joining_date: Date;
    probation_period: EmployeeProbationPeriod;
    confirmation_date: Date;
}

const BasicInformationField = FormikField<BasicInformation>;

const BasicInformationSelect = FormikSelect<BasicInformation>;

export default function Step1Form() {
    const { formData, setFormData } =
        useOutletContext<EmployeeCreationOutletContext>();

    const navigate = useNavigate();
    const location = useLocation();

    const { data: employeeData } = useListEmployees();

    const empNumbers = employeeData?.data.map(
        (emp: Employee) => emp.employee_number
    );

    const BasicInformationValidationSchema = Yup.object().shape({
        email_id: Yup.string().email('Invalid Email').required('Required'),
        employee_number_series: Yup.string()
            .oneOf(EmployeeNumberSeriesOptions, 'Choose a value')
            .required('Required'),
        employee_number: Yup.string()
            .max(4, 'Maximum 4 digits allowed')
            .required('Required'),
        first_name: Yup.string().max(20).required('Required'),
        last_name: Yup.string().max(20).required('Required'),
        gender: Yup.string()
            .oneOf(EmpGenderOptions, 'Choose a value')
            .required('Required'),
        employee_status: Yup.string()
            .oneOf(EmployeeStatusOptions, 'Choose a value')
            .required('Required'),
        joining_date: Yup.date().required('Required'),
        probation_period: Yup.string()
            .oneOf(EmpProbationPeriodOptions, 'Choose a value')
            .required('Required'),
        confirmation_date: Yup.date().required('Required'),
    });

    const handleSubmit = async (values: BasicInformation) => {
        if (
            empNumbers?.some(
                (empNum: string | undefined) =>
                    empNum === values.employee_number
            )
        ) {
            toast.warn('Employee Number already exists');
        } else {
            setFormData({ ...formData, step1: values });
            navigate(EMPLOYEE_POSITION_PAGE);
        }
    };

    return (
        <Formik
            initialValues={formData.step1}
            onSubmit={handleSubmit}
            validationSchema={BasicInformationValidationSchema}
        >
            {({ touched, errors, submitForm, values, setFieldValue, isSubmitting }) => (
                <Form className="grid grid-flow-row gap-5 md:grid-cols-3 lg:grid-cols-4 px-[1%]">
                    <BasicInformationField
                        label="Email ID"
                        name="email_id"
                        placeholder="xxxx@zedexinfo.com"
                        type="text"
                        touched={touched.employee_number}
                    />
                    <BasicInformationSelect
                        label="Employee Number Series"
                        name="employee_number_series"
                        touched={touched.employee_number_series}
                    >
                        {EmployeeNumberSeriesOptions.map((item) => (
                            <option key={item} value={item}>
                                {item}
                            </option>
                        ))}
                    </BasicInformationSelect>
                    <BasicInformationField
                        label="Employee Number"
                        name="employee_number"
                        placeholder="1010"
                        type="text"
                        touched={touched.employee_number}
                    />
                    <BasicInformationField
                        label="First Name"
                        name="first_name"
                        placeholder="-"
                        type="text"
                        touched={touched.first_name}
                    />
                    <BasicInformationField
                        label="Last Name"
                        name="last_name"
                        placeholder="-"
                        type="text"
                        touched={touched.last_name}
                    />
                    <BasicInformationSelect
                        label="Gender"
                        name="gender"
                        touched={touched.gender}
                    >
                        {EmpGenderOptions.map((item) => (
                            <option key={item} value={item}>
                                {item}
                            </option>
                        ))}
                    </BasicInformationSelect>
                    <BasicInformationSelect
                        label="Status"
                        name="employee_status"
                        touched={touched.employee_status}
                    >
                        {EmployeeStatusOptions.map((item) => (
                            <option key={item} value={item}>
                                {item}
                            </option>
                        ))}
                    </BasicInformationSelect>
                    <BasicInformationField
                        label="Joining Date"
                        name="joining_date"
                        placeholder="-"
                        type="date"
                        touched={touched.joining_date}
                    />
                    <BasicInformationSelect
                        label="Probation Period"
                        name="probation_period"
                        touched={touched.probation_period}
                    >
                        {EmpProbationPeriodOptions.map((item) => (
                            <option key={item} value={item}>
                                {item}
                            </option>
                        ))}
                    </BasicInformationSelect>
                    <MyField
                        name="confirmation_date"
                        values={values}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        errors={errors}
                    />
                    <div className="lg:pl-[30%] row-start-5 space-x-[5%] col-span-4 md:col-start-1">
                        <ButtonField
                            name="Previous"
                            location={location.pathname}
                        />
                        <ButtonField
                            name="Cancel"
                            onClick={() => navigate(EMPLOYEES)}
                        />
                        <ButtonField
                            name="Next"
                            location={location.pathname}
                            onClick={() => submitForm()}
                            disabled={isSubmitting}
                        />
                    </div>
                </Form>
            )}
        </Formik>
    );
}

function MyField(props: {
    name: string;
    values: BasicInformation;
    setFieldValue: any;
    touched: FormikTouched<BasicInformation>;
    errors: FormikErrors<BasicInformation>;
}) {
    const {
        values: { joining_date, probation_period, confirmation_date },
        touched,
        errors,
        name,
        setFieldValue,
    } = props;

    const HandleField = () => {
        useEffect(() => {
            if (
                joining_date.toString() !== '' &&
                probation_period.toString() !== '' &&
                touched.joining_date &&
                touched.probation_period
            ) {
                const d = new Date(joining_date);
                d.setMonth(d.getMonth() + Number(probation_period));
                const dtm = new Date(d);
                setFieldValue(
                    name,
                    `${dtm.toLocaleDateString('en-IN', {
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit',
                    })}`
                );
            }
        }, [probation_period, joining_date, touched]);
    };

    return (
        <div className="flex flex-col">
            <label
                className={classnames(
                    'pl-[1%]',
                    touched.confirmation_date && errors.confirmation_date
                        ? 'text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                        : 'border-zxblue-to'
                )}
            >
                Confirmation Date
            </label>
            <Field
                className={classnames(
                    'w-full h-8 mb-[2%] rounded-md focus:outline-none placeholder: pl-[4%] py-[1%] border',
                    touched.confirmation_date && errors.confirmation_date
                        ? 'border-zxerror-to'
                        : 'border-zxblue-to'
                )}
                name={name}
                value={confirmation_date}
                onClick={HandleField()}
                placeholder="-"
                disabled
            />
            <ErrorMessage
                name={name.toString()}
                component="span"
                className="text-[77%] text-zxerror-from"
            />
        </div>
    );
}
