/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  Designation
} from '../../schema'


/**
 * List all designation
 */
export const listDesignations = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Designation[]>> => {
    return axios.get(
      `/designations/`,options
    );
  }


export const getListDesignationsQueryKey = () => [`/designations/`];

    
export type ListDesignationsQueryResult = NonNullable<Awaited<ReturnType<typeof listDesignations>>>
export type ListDesignationsQueryError = AxiosError<unknown>

export const useListDesignations = <TData = Awaited<ReturnType<typeof listDesignations>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listDesignations>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListDesignationsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listDesignations>>> = ({ signal }) => listDesignations({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof listDesignations>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * Create a designation
 */
export const createDesignation = (
    designation: Designation, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Designation>> => {
    return axios.post(
      `/designations/`,
      designation,options
    );
  }



    export type CreateDesignationMutationResult = NonNullable<Awaited<ReturnType<typeof createDesignation>>>
    export type CreateDesignationMutationBody = Designation
    export type CreateDesignationMutationError = AxiosError<unknown>

    export const useCreateDesignation = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createDesignation>>, TError,{data: Designation}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createDesignation>>, {data: Designation}> = (props) => {
          const {data} = props ?? {};

          return  createDesignation(data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof createDesignation>>, TError, {data: Designation}, TContext>(mutationFn, mutationOptions)
    }
    