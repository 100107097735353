import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useRetrieveMSUser, useUpdateEmployee } from '../../api';
import ButtonField from '../FormFields/ButtonField';
import FormikField from '../FormFields/FormikField';
import SectionHeader from './SectionHeader';
import LeaveInfoSection from './Sections/LeaveInfoSection';

export default function LeaveInformationUpdate(props: { id: string }) {
    const { mutateAsync, isSuccess } = useUpdateEmployee();
    const [form, setForm] = useState(false);
    const { data: usersData, refetch } = useRetrieveMSUser(props.id);
    const empDetails = usersData?.data.MSemployee_details;

    const LeaveInformationInitialValues = {
        earned_leave_balance: empDetails?.earned_leave_balance
            ? empDetails.earned_leave_balance
            : Number(''),
        casual_leave_balance: empDetails?.casual_leave_balance
            ? empDetails.casual_leave_balance
            : Number(''),
        sick_leave_balance: empDetails?.sick_leave_balance
            ? empDetails.sick_leave_balance
            : Number(''),
        optional_hol_balance: empDetails?.optional_hol_balance
            ? empDetails.optional_hol_balance
            : Number(''),
        comp_off_balance: empDetails?.comp_off_bal
            ? empDetails.comp_off_bal
            : Number(''),
    };
    const LeaveInformationValidationSchema = Yup.object({
        earned_leave_balance: Yup.number()
            .typeError('Value must be a number')
            .required('Required'),
        casual_leave_balance: Yup.number()
            .typeError('Value must be a number')
            .required('Required'),
        sick_leave_balance: Yup.number()
            .typeError('Value must be a number')
            .required('Required'),
        optional_hol_balance: Yup.number()
            .typeError('Value must be a number')
            .required('Required'),
        comp_off_balance: Yup.number()
            .typeError('Value must be a number')
            .required('Required'),
    });

    const handleSubmit = async (values: any) => {
        if (empDetails !== undefined || empDetails !== null) {
            const updatedVal = {
                ...values,
                first_name: empDetails?.first_name,
                last_name: empDetails?.last_name,
                confirmation_date: empDetails?.confirmation_date,
                joining_date: empDetails?.joining_date,
            };
            await mutateAsync({
                data: updatedVal,
                id: empDetails?.id ? empDetails.id.toString() : '',
            });
        }
    };

    useEffect(() => {
        if (isSuccess) {
            refetch();
            toast.success('Leave Information Updated');
            setForm(false);
        }
    }, [isSuccess, refetch, setForm]);

    return form ? (
        <>
            <SectionHeader
                name="Leave Information"
                form={form}
                setForm={setForm}
                employeeDetails={empDetails}
            />
            <Formik
                initialValues={LeaveInformationInitialValues}
                validationSchema={LeaveInformationValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ touched, submitForm }) => (
                    <form className="grid grid-flow-row gap-x-4 gap-y-2 md:grid-cols-3 lg:grid-cols-4 px-7">
                        <FormikField
                            label="Earned Leave Balance"
                            name="earned_leave_balance"
                            type="number"
                            touched={touched.earned_leave_balance}
                        />
                        <FormikField
                            label="Casual Leave Balance"
                            name="casual_leave_balance"
                            type="number"
                            touched={touched.casual_leave_balance}
                        />
                        <FormikField
                            label="Sick Leave Balance"
                            name="sick_leave_balance"
                            type="number"
                            touched={touched.sick_leave_balance}
                        />
                        <FormikField
                            label="Optional Holiday Balance"
                            name="optional_hol_balance"
                            type="number"
                            touched={touched.optional_hol_balance}
                        />
                        <FormikField
                            label="Comp Off Grant Balance"
                            name="comp_off_balance"
                            type="number"
                            touched={touched.comp_off_balance}
                        />
                        <div className="row-start-4 lg:col-start-3 md:col-start-2 col-span-2 mx-auto mr-2">
                            <ButtonField
                                name="Cancel"
                                onClick={() => setForm(false)}
                            />
                            <ButtonField
                                name="Submit"
                                onClick={() => {
                                    submitForm();
                                }}
                            />
                        </div>
                    </form>
                )}
            </Formik>
        </>
    ) : (
        <>
            <SectionHeader
                name="Leave Information"
                form={form}
                setForm={setForm}
                employeeDetails={empDetails}
            />
            <LeaveInfoSection id={props.id} />
        </>
    );
}
