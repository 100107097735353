import { FormikTouched } from 'formik';
import { useEffect } from 'react';
import { Leave, useRetrieveEmployee } from '../api';

export default function SetManagerNameField(props: {
    name: string;
    employee_id: number;
    setFieldValue: any;
    touched: FormikTouched<Leave>;
}) {
    const { employee_id, touched, name, setFieldValue } = props;

    const { data: empData } = useRetrieveEmployee(
        employee_id ? employee_id.toString() : ''
    );

    const { data: managerData } = useRetrieveEmployee(
        String(empData ? empData.data.reporting_manager : '')
    );

    const mngName = managerData ? managerData.data.id : Number('');

    useEffect(() => {
        if (
            (employee_id !== null || employee_id !== undefined) &&
            touched.employee_id
        ) {
            setFieldValue(name, mngName);
        }
    }, [touched, employee_id]);

    return (
        <div>
            <label>Manager</label>
            <input
                value={managerData?.data.full_name}
                className="w-[85%] mb-4 mt-2 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5"
                name={name}
                id="approved_by"
                disabled
                placeholder="-"
            />
        </div>
    );
}
