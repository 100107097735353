function LoginCircle() {
    return (
        <div className="flex justify-between gap-x-10">
            <div className="absolute lg:inset-x-[32%] lg:inset-y-32 md:inset-y-[20%] md: inset-x-[20%] md:w-40 md:h-40 lg:w-48 lg:h-48 bg-gradient-to-r from-zxblue-from to-zxblue-to rounded-full" />
            <div className="absolute lg:inset-x-[59%] lg:inset-y-[52%] md:inset-x-[65%] md:inset-y-25 lg:h-28 lg:w-28 md:w-28 md:h-28 bg-gradient-to-r from-zxblue-from to-zxblue-to rounded-full" />
        </div>
    );
}

export default LoginCircle;
