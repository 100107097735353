import { AxiosResponse } from 'axios';
import { useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { useListMSUsers, useListRoles, useRetrieveGetUserRole } from '../api';
import Assign from './Assign';
import Button from './FormFields/Button';
import RemoveRole from './RemoveRole';

function AdminAssign() {
    const { data: msUsersData } = useListMSUsers();
    const { data: rolesData } = useListRoles<AxiosResponse<string[]>>();
    // const { data: assignedRoles } = useRetrieveRolesRemove();

    const navigate = useNavigate();

    const [val, setValue] = useState({
        id: '',
        role: '',
    });

    const [rmRole, setRmRole] = useState({
        id: '',
        role: '',
    });

    const [removeRoleEmpID, setRemoveRoleEmpID] = useState('');
    // const [removeRole, setRemoveRole] = useState('');
    const usersData = msUsersData?.data;
    const userOptions = usersData?.map((user) =>
        user.MSemployee_details.profile_details !== null ? (
            <option value={user.id} key={user.id}>
                {`${user.MSemployee_details.employee_number} - ${user.MSemployee_details.full_name}`}
            </option>
        ) : null
    );

    const roles = rolesData?.data;
    const roleOptions = roles?.map((role) => (
        <option value={role} key={role}>
            {role}
        </option>
    ));

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const user_id = e.target.user.value;
        const role_id = e.target.role.value;
        setValue({ id: user_id, role: role_id });
        // navigate(ADMIN_REDIRECT, {state: {to:id}});s
    };
    const handleRmRemoveSubmit = (e: any) => {
        e.preventDefault();
        const user_id = e.target.rmRole_user.value;
        const role_id = e.target.remove_role.value;
        setRmRole({ id: user_id, role: role_id });
    };

    const circleStyle =
        'absolute bg-gradient-to-r from-zxblue-from to-zxblue-to rounded-full';

    return (
        <div className="w-screen box-border overflow-hidden bg-zxbg">
            <div className="flex justify-between">
                <div
                    className={`${circleStyle} inset-x-[16.5%] inset-y-[5.5%] w-48 h-48`}
                />
                <div
                    className={`${circleStyle} inset-x-[71.5%] inset-y-[65.5%] h-28 w-28`}
                />
            </div>
            <div className="p-[10%]">
                <div className="shadow-2xl rounded-2xl w-fit m-auto relative z-0 backdrop-blur-xl">
                    <div className="flex flex-col px-10">
                        <div className="flex items-center pt-3 w-full">
                            <BiArrowBack
                                className="mt-4 cursor-pointer"
                                size="7%"
                                style={{ color: '#4A65EE' }}
                                onClick={() => navigate(-1)}
                            />
                            {/* <div className="w-full"> */}
                            <img
                                className="pt-4 mx-auto"
                                src="./img/logo.png"
                                alt=""
                            />
                            {/* </div> */}
                        </div>
                        <h2 className="py-4 border-x-zxblue-from text-transparent font-semibold md:text-xl lg:text-2xl bg-clip-text bg-gradient-to-t from-zxheader-from to-zxheader-to">
                            <span className="border-l-2 border-zxsub-to pl-1">
                                Assign Role
                            </span>
                        </h2>
                        <form
                            className="flex flex-row space-x-5"
                            onSubmit={handleSubmit}
                        >
                            <div className="flex flex-col">
                                <label className="pl-0.5 border-zxblue-to">
                                    Employee Name
                                </label>
                                <select
                                    id="user"
                                    name="user"
                                    className="h-8 mb-4 border pl-2 py-1 rounded-md focus:outline-none border-zxblue-to"
                                >
                                    {userOptions}
                                </select>
                            </div>
                            <div className="flex flex-col">
                                <label className="pl-0.5 border-zxblue-to">
                                    Roles
                                </label>
                                <select
                                    id="role"
                                    name="role"
                                    className="h-8 mb-4 border pl-2 py-1 rounded-md focus:outline-none border-zxblue-to"
                                >
                                    {roleOptions}
                                </select>
                            </div>
                            <Button
                                value="Submit"
                                className="lg:text-base place-self-center md:text-sm  "
                            />
                        </form>
                    </div>
                    {val.role !== '' ? <Assign val={val} /> : null}

                    <div className="relative mt-3 mx-auto w-[75%] border-b border-3 border-zxheader-from" />

                    <div className="flex flex-col px-10">
                        <h2 className="py-4 border-x-zxblue-from text-transparent font-semibold md:text-xl lg:text-2xl bg-clip-text bg-gradient-to-t from-zxheader-from to-zxheader-to">
                            <span className="border-l-2 border-zxsub-to pl-1">
                                Remove Role
                            </span>
                        </h2>
                        <form
                            className="flex flex-row space-x-5 pb-3"
                            onSubmit={handleRmRemoveSubmit}
                        >
                            <div className="flex flex-col">
                                <label className="pl-0.5 border-zxblue-to">
                                    Employee Name
                                </label>
                                <select
                                    id="rmRole_user"
                                    name="rmRole_user"
                                    className="h-8 mb-4 border pl-2 py-1 rounded-md focus:outline-none border-zxblue-to"
                                    onChange={(e) =>
                                        setRemoveRoleEmpID(e.target.value)
                                    }
                                    value={removeRoleEmpID}
                                >
                                    {userOptions}
                                </select>
                            </div>
                            <div className="flex flex-col">
                                <label className="pl-0.5 border-zxblue-to">
                                    Role
                                </label>
                                <RoleField
                                    removeRoleEmpID={removeRoleEmpID}
                                    // removeRole={removeRole}
                                    // setRemoveRole={setRemoveRole}
                                />
                            </div>
                            <Button
                                value="Remove"
                                className="lg:text-base place-self-center md:text-sm"
                            />
                        </form>
                    </div>
                    {rmRole.role !== '' ? <RemoveRole rmRole={rmRole} /> : null}
                </div>
            </div>
        </div>
    );
}

export default AdminAssign;

function RoleField(props: {
    removeRoleEmpID: string;
    // removeRole: string;
    // setRemoveRole: Dispatch<React.SetStateAction<string>>;
}) {
    const { data: empRoleData } = useRetrieveGetUserRole(
        props.removeRoleEmpID.toString()
    );

    const assEmpRole: any = empRoleData?.data;
    const assignedRoleOptions = assEmpRole?.map((role: any) => (
        <option value={role} key={role}>
            {role}
        </option>
    ));

    return (
        <select
            id="remove_role"
            name="remove_role"
            className="h-8 mb-4 border pl-2 py-1 rounded-md focus:outline-none border-zxblue-to"
            // onChange={(e) => props.setRemoveRole(e.target.value)}
            onChange={(e) => e.target.value}
        >
            {assignedRoleOptions}
        </select>
    );
}
