/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/button-has-type */
import { useFormik } from 'formik';
import { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker, { Calendar, Value } from 'react-multi-date-picker';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import {
    Employee,
    Leave,
    LeaveLeaveType,
    LeaveSession1,
    LeaveSession2,
    useCreateLeave,
    useListEmployees,
    useListHolidays,
} from '../api';
import AttatchmentForm from './AttatchmentForm';
import Button from './FormFields/Button';
import SetContactField from './SetContactField';
import SetEmergencyContactField from './SetEmergencyContactField';
import SetManagerNameField from './SetManagerNameField';
// import './Calendar.css';

function LeaveForm() {
    const navigate = useNavigate();
    const {
        data: userData,
        // isSuccess,
        // isLoading,
        // isFetchedAfterMount,
    } = useListEmployees();
    const { mutateAsync } = useCreateLeave();

    const {
        data: holidaysData,
        // isSuccess: holidaySuccess,
        // isLoading: holidayLoading,
        // isFetchedAfterMount: holidayMount,
        // refetch,
    } = useListHolidays();

    const people = userData?.data
        ? userData.data.map((value) => value.full_name)
        : [''];

    const [query, setQuery] = useState('');

    const filteredOptions =
        query === ''
            ? people
            : people.filter((option) => {
                  return option?.toLowerCase().includes(query.toLowerCase());
              });

    const formik = useFormik({
        initialValues: {
            leave_type: LeaveLeaveType.CompOff,
            session_1: LeaveSession1.S1,
            session_2: LeaveSession2.S2,
            applying_from: '',
            applying_to: '',
            // manager: '',
            cc: '',
            // contact_no: '',
            // other_no: '',
            reason: '',
            employee_id: Number(''),
        },
        validationSchema: Yup.object({
            // contact_no: Yup.string()
            //     .min(10)
            //     .max(13)
            //     .required('Required')
            //     .matches(/^[+\d]\d*$/),
            // other_no: Yup.string()
            //     .min(10)
            //     .max(13)
            //     .required('Required')
            //     .matches(/^[+\d]\d*$/),

            reason: Yup.string().max(1000).required('Reason Required'),
            // manager: Yup.string().max(20).required('Required'),
            cc: Yup.string().max(25).required('CC Required'),
        }),
        //     onSubmit: async (values: Leave) => {
        //         const updatedVal: Leave = {
        //             ...values,
        //             employee_id: empDetails?.id,
        //         };
        //         await mutateAsync({ data: updatedVal });
        //     },
        // });

        onSubmit: async (values: Leave) => {
            // console.log(formik.errors);
            const updatedVal: Leave = {
                // ...formik.values,
                employee_id: values.employee_id,

                session_1: values?.session_1,
                session_2: values?.session_2,
                leave_type: values?.leave_type,
                leave_status: values?.leave_status,
                applying_from: values?.applying_from.split('/').join('-'),
                applying_to: values?.applying_to.split('/').join('-'),
                cc: values?.cc,
                reason: values?.reason,
            };
            await mutateAsync({ data: updatedVal });

            formik.resetForm();

            toast.success('Leave Applied');
        },
    });

    const [values, setValues] = useState<Value>(new Date());
    // formik.errors.map((mssg)=>toast.error(mssg))
    // useEffect(() => {
    //     if (!formik.values.employee_id) {
    //         formik.setFieldValue('employee_id', userData?.data[0].id);
    //     }
    // }, [userData?.data]);

    return (
        <div>
            <form
                className="grid grid-cols-2 sm:gap-4 xl:gap-2 mt-4  "
                onSubmit={(event) => {
                    event.preventDefault();
                    if (formik.errors.reason) {
                        toast.error(formik.errors.reason);
                    }
                    if (formik.errors.cc) {
                        toast.error(formik.errors.cc);
                    }
                    formik.handleSubmit();
                }}
            >
                <div className="sm:col-span-2 xl:col-span-1  grid grid-rows-5   ">
                    <div className="  grid grid-cols-2 row-span-1 mb-3">
                        <div className="col-span-1">
                            <label className=" ml-12">Leave Type</label>
                            <span className="text-red-600">*</span>
                            <br />
                            <select
                                className={
                                    formik.touched.leave_type &&
                                    formik.errors.leave_type
                                        ? ' w-fit h-[40%] mb-4 mt-2 ml-12 border border-zxerrordisplay-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                                        : 'w-fit h-[40%] mb-4 mt-2 ml-12 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                                }
                                name="leave_type"
                                id="leave_type"
                                placeholder="----"
                                onChange={formik.handleChange}
                                value={formik.values.leave_type}
                            >
                                {' '}
                                <option defaultValue="">CHOOSE A TYPE</option>
                                <option value="CompOff">Comp Off</option>
                                <option value="Medical">Medical Leave</option>
                                <option value="Earned">Earned Leave</option>
                                <option value="Unpaid">Unpaid Leave</option>
                                <option value="Casual">Casual</option>
                            </select>
                        </div>
                        <div className="col-start-2">
                            <label className="ml-12">Employee</label>
                            <span className="text-red-600">*</span>
                            <br />
                            <select
                                className={
                                    formik.touched.leave_type &&
                                    formik.errors.leave_type
                                        ? ' w-fit h-[40%] mb-4 mt-2 ml-12 border border-zxerrordisplay-to rounded-md focus:outline-none placeholder:py-0.5 pl-2'
                                        : 'w-fit h-[40%] mb-4 mt-2 ml-12 border border-zxblue-to rounded-md focus:outline-none placeholder:py-0.5 pl-2'
                                }
                                name="employee_id"
                                placeholder="----"
                                onChange={formik.handleChange}
                            >
                                <option defaultValue={undefined}>Select</option>
                                {userData?.data.map((employee: Employee) => (
                                    <option
                                        key={employee.id}
                                        value={employee.id}
                                    >
                                        {employee.full_name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="place-self-center  row-span-4">
                        <Calendar
                            className="pb-4 pt-4 scale-x-[95%] scale-y-[110%] ml-[6%] "
                            value={values}
                            onChange={(date) => {
                                formik.setFieldValue(
                                    'applying_from',
                                    date?.toString().split(',')[0]
                                );
                                formik.setFieldValue(
                                    'applying_to',
                                    date?.toString().split(',')[1]
                                );
                            }}
                            range
                            // eslint-disable-next-line consistent-return
                            mapDays={({ date, currentMonth }) => {
                                let color;

                                // console.log(Number(currentMonth.toString()))
                                const currentMonthString =
                                    currentMonth.toString().length === 1
                                        ? '0'.concat(currentMonth.toString())
                                        : currentMonth.toString();

                                const currentMonthHolidays =
                                    holidaysData?.data?.filter(
                                        (holiday) =>
                                            holiday?.date
                                                ?.substring(3, 5)
                                                .includes(currentMonthString) &&
                                            holiday.type === 'OP'
                                    );

                                const currentMonthHolidaysDate: number[] = [];

                                currentMonthHolidays?.forEach(
                                    (currentMonthHoliday) =>
                                        currentMonthHolidaysDate.push(
                                            Number(
                                                currentMonthHoliday?.date?.substring(
                                                    0,
                                                    2
                                                )
                                            )
                                        )
                                );

                                // if ([4, 5, 6, 7].includes(date.day)) color = "green"
                                if (currentMonthHolidaysDate.includes(date.day))
                                    color = 'red';

                                // eslint-disable-next-line prefer-template
                                if (color)
                                    return {
                                        className: `highlight highlight-${color}`,
                                    };
                            }}
                        />
                    </div>
                </div>

                <div className="sm:col-span-2 xl:col-span-1 pl-5 grid grid-cols-2  ">
                    <div className="">
                        <label>Applying From</label>
                        <span className="text-red-600">*</span>
                        <br />
                        <div className="border border-zxblue-from border-zxblue-to rounded-[8px] w-[85%] mt-2">
                            <DatePicker
                                value={formik.values?.applying_from}
                                disabled
                                inputClass="custom-input"
                                style={{
                                    width: '100%',
                                    backgroundColor: 'white',

                                    borderRadius: '8px',
                                    fontSize: '14px',
                                    // height: "10px",
                                    padding: '3px 10px',
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        <label className="">Session</label>
                        <span className="text-red-600">*</span>
                        <br />
                        <select
                            className={
                                formik.touched.session_1 &&
                                formik.errors.session_1
                                    ? ' w-[85%] h-[40%] mb-4 mt-2 border border-zxerrordisplay-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                                    : 'w-[85%] h-[40%] mb-4 mt-2 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                            }
                            name="session_1"
                            id="session_1"
                            placeholder="----"
                            onChange={formik.handleChange}
                            value={formik.values.session_1}
                            // type=""
                        >
                            {' '}
                            <option defaultValue="">CHOOSE A SESSION</option>
                            <option value="S1">1</option>
                            <option value="S2">2</option>
                        </select>
                    </div>

                    <div className="">
                        <label>Applying To</label>
                        <span className="text-red-600">*</span>
                        <br />
                        <div className="border border-zxblue-from border-zxblue-to rounded-[8px] w-[85%] mt-2">
                            <DatePicker
                                value={formik.values?.applying_to}
                                disabled
                                inputClass="custom-input"
                                style={{
                                    width: '100%',
                                    backgroundColor: 'white',
                                    // height: "30px",
                                    borderRadius: '8px',
                                    fontSize: '14px',
                                    // borderBlockColor:"#4A96EE",
                                    padding: '3px 10px',
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        <label className="">Session</label>
                        <span className="text-red-600">*</span>
                        <select
                            className={
                                formik.touched.session_2 &&
                                formik.errors.session_2
                                    ? ' w-[85%] h-[40%] mb-4 mt-2 border border-zxerrordisplay-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                                    : 'w-[85%] h-[40%] mb-4 mt-2 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                            }
                            name="session_2"
                            id="session_2"
                            placeholder="----"
                            onChange={formik.handleChange}
                            value={formik.values.session_2}
                            // type=""
                        >
                            {' '}
                            <option defaultValue="">CHOOSE A SESSION</option>
                            <option value="S1">1</option>
                            <option value="S2">2</option>
                        </select>
                    </div>
                    <SetManagerNameField
                        name="approved_by"
                        employee_id={
                            formik.values.employee_id !== null &&
                            formik.values.employee_id !== undefined
                                ? formik.values.employee_id
                                : Number('')
                        }
                        setFieldValue={formik.setFieldValue}
                        touched={formik.touched}
                    />

                    {/* <div >
                    <label>CC</label>
                        <br />
                                 <Combobox 
                                 value={selectedPeople} 
                                 onChange={(event)=>{setSelectedPeople(event)}}
                                  multiple>
                                    <div className="">
                                    <Combobox.Input
                                        className="w-[85%] mb-4 mt-2 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5"
                                        
                                        onChange={(event) => setQuery(event.target.value)}
                                        placeholder={
                                            selectedPeople.length === 0
                                                ? 'Search for Niches'
                                                : selectedPeople
                                                      .map((option) => option)
                                                      .join(', ')
                                        }
                                    /> */}

                    {/* <Combobox.Button>
                                    {selectedPeople.map((person) => person).join(', ')}
                                    </Combobox.Button> */}
                    {/* <Transition
                                        as={Fragment}
                                        leave="transition ease-in duration-100"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    > */}
                    {/* <Combobox.Options className="absolute mt-1 max-h-60  overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                        {isLoading?("Loading.."):isFetchedAfterMount && isSuccess ? (userData.data.map((employee: Employee) => (
                                            <Combobox.Option
                                            key={employee.id}
                                            className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                                            }`
                                            }
                                            value={employee.full_name}
                                        >
                                            {({ selected }) => (
                                            <>
                                                <span
                                                className={`block truncate ${
                                                    selected ? 'font-medium' : 'font-normal'
                                                }`}
                                                >
                                                {employee.full_name}
                                                </span>
                                                {selected ? (
                                                <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                                ) : null}
                                            </>
                                            )}
                                        </Combobox.Option>
                                        ))
                                        ):("Not Success")}
                                        </Combobox.Options> */}
                    {/* <Combobox.Options className="bg-blue-50 mt-1 rounded-lg z-10 absolute overflow-auto min-w-[15rem] py-2 px-5">
                    {filteredOptions.map((option) => (
                        <Combobox.Option
                            key={option}
                            value={option}
                            className="hover:bg-blue-100"
                        >
                            {selectedPeople.includes(option) ? (
                                <CheckIcon className="w-5 h-5 inline-block float-left " />
                            ) : (
                                <CheckIcon className="w-5 h-5 inline-block float-left fill-blue-100" />
                            )}
                            <span>{option}</span>
                        </Combobox.Option>
                    ))}
                </Combobox.Options>
                                        
                                    </Transition>
                                    </div>
                                </Combobox>
                            </div> */}

                    {/* <div>
                        <label>CC</label>
                        <br />
                        <select
                            className={
                                formik.touched.session_2 &&
                                formik.errors.session_2
                                    ? ' w-[85%] mb-4 border border-zxerrordisplay-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                                    : 'w-[85%] mb-4 mt-2 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                            }
                            name="session_2"
                            id="session_2"
                            placeholder="----"
                            onChange={formik.handleChange}
                            value={formik.values.session_2}
                            multiple
                            // type=""
                        >
                            {' '}
                            {isLoading ? (
                    <tr>
                        <td>Loading.......</td>
                    </tr>
                ) : isFetchedAfterMount && isSuccess ? (
                    userData.data.map((employee: Employee) => (
                        <option key={employee.id}>{employee.full_name}</option>
                    ))
                ) : (
                    <tr>
                        <td>Not Success</td>
                    </tr>
                )}
                            
                            
                        </select> */}
                    {/* <div>
                            <label>CC</label>
                            <br />
                        <select  multiple>
                            {isLoading? (
                                <option>Loading...</option>
                            ): 
                            isFetchedAfterMount && isSuccess?(
                                userData.data.map((employee:Employee)=>(
                                <option key ={employee.id}>{employee.full_name}</option>
                            ))
                            
                            ):(
                                <option>Not Success</option>
                            )}

                        </select>
                        </div> */}
                    {/* <div>
                            <label>CC</label>
                            <br />
                            <Combobox value={selectedPeople} onChange={setSelectedPeople} multiple>
                                {selectedPeople.length > 0 && (
                                    <ul>
                                    {selectedPeople.map((person) => (
                                        <li key={person.id}>{person.name}</li>
                                    ))}
                                    </ul>
                                )}
                        <Combobox.Input />
                        <Combobox.Options>
                            {people.map((person) => (
                            <Combobox.Option key={person.id} value={person}>
                                {person.name}
                            </Combobox.Option>
                            ))}
                        </Combobox.Options>
                        </Combobox>
                        </div> */}

                    <div>
                        <label>CC</label>
                        <span className="text-red-600">*</span>
                        <br />
                        <input
                            type="text"
                            placeholder="Sushmita"
                            className={
                                formik.touched.cc && formik.errors.cc
                                    ? 'w-[85%] mb-4 mt-2 border border-zxerrordisplay-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                                    : 'w-[85%] mb-4 mt-2 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                            }
                            name="cc"
                            id="cc"
                            onChange={formik.handleChange}
                            value={formik.values.cc}
                        />
                    </div>
                    {/* <div>
                        <label>Contact No</label>
                        <br />
                        <input
                            type="text"
                            // placeholder="91 88282xxxxx"
                            className={
                                // formik.touched.contact_no &&
                                // formik.errors.contact_no
                                // ? ' w-44 mb-4 border border-zxerrordisplay-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                                'w-[85%] mb-4 mt-2 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                            }
                            name="contact_no"
                            id="contact_no"
                            value={contact_no}
                            readOnly
                            // onChange={formik.handleChange}
                            // value={formik.values.contact_no}
                        />
                    </div> */}
                    <SetContactField
                        name="contact_no"
                        employee_id={
                            formik.values.employee_id !== null &&
                            formik.values.employee_id !== undefined
                                ? formik.values.employee_id
                                : Number('')
                        }
                        setFieldValue={formik.setFieldValue}
                        touched={formik.touched}
                    />
                    <SetEmergencyContactField
                        name="emergency_contact"
                        employee_id={
                            formik.values.employee_id !== null &&
                            formik.values.employee_id !== undefined
                                ? formik.values.employee_id
                                : Number('')
                        }
                        setFieldValue={formik.setFieldValue}
                        touched={formik.touched}
                    />
                    {/* <div>
                        <label>Other No</label>
                        <br />
                        <input
                            type="text"
                            // placeholder="91 98282xxxxx"
                            className={
                                // formik.touched.other_no &&
                                // formik.errors.other_no
                                // ? ' w-44 mb-4 border border-zxerrordisplay-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                                'w-[85%] mb-4 mt-2 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                            }
                            name="other_no"
                            id="other_no"
                            value={other_no}
                            readOnly
                            // onChange={formik.handleChange}
                            // value={formik.values.other_no}
                        />
                    </div> */}

                    <div className="col-span-2">
                        <label>Reason</label>
                        <span className="text-red-600">*</span>
                        <br />
                        <textarea
                            className={
                                formik.touched.reason && formik.errors.reason
                                    ? ' w-[95%] h-[70%] mb-4 border border-zxerrordisplay-to rounded-md focus:outline-none placeholder: pl-2 py-0.5 mt-2'
                                    : 'w-[95%] h-[70%] mb-4 mt-2 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                            }
                            name="reason"
                            id="reason"
                            onChange={formik.handleChange}
                            value={formik.values.reason}
                        />
                    </div>
                </div>
                <div className="col-span-2">
                    <AttatchmentForm />
                </div>
                <div className="sm:col-span-2 border place-self-center">
                    <button
                        className="border border-orange-400 rounded-full px-14 py-2 "
                        onClick={() => navigate(-1)}
                    >
                        Back
                    </button>
                </div>
                <div className="sm:col-span-2 place-self-center">
                    <Button
                        value="Submit"
                        className="lg:text-base mb-6 w-[40%]"
                    />
                </div>
            </form>
        </div>
    );
}

export default LeaveForm;
