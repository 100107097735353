/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  TLAttendance
} from '../../schema'


/**
 * List all attendances of employees reporting to a TL
 */
export const listTLAttendances = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<TLAttendance[]>> => {
    return axios.get(
      `/tlattendances/`,options
    );
  }


export const getListTLAttendancesQueryKey = () => [`/tlattendances/`];

    
export type ListTLAttendancesQueryResult = NonNullable<Awaited<ReturnType<typeof listTLAttendances>>>
export type ListTLAttendancesQueryError = AxiosError<unknown>

export const useListTLAttendances = <TData = Awaited<ReturnType<typeof listTLAttendances>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listTLAttendances>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListTLAttendancesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listTLAttendances>>> = ({ signal }) => listTLAttendances({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof listTLAttendances>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

