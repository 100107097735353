/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useMutation
} from '@tanstack/react-query'
import type {
  UseMutationOptions,
  MutationFunction
} from '@tanstack/react-query'
import type {
  MultipleRegularizations
} from '../../schema'


/**
 * Create multiple regularizations
 */
export const createMultipleRegularizations = (
    multipleRegularizations: MultipleRegularizations, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<MultipleRegularizations>> => {
    return axios.post(
      `/multiple-regularizations/`,
      multipleRegularizations,options
    );
  }



    export type CreateMultipleRegularizationsMutationResult = NonNullable<Awaited<ReturnType<typeof createMultipleRegularizations>>>
    export type CreateMultipleRegularizationsMutationBody = MultipleRegularizations
    export type CreateMultipleRegularizationsMutationError = AxiosError<unknown>

    export const useCreateMultipleRegularizations = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createMultipleRegularizations>>, TError,{data: MultipleRegularizations}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createMultipleRegularizations>>, {data: MultipleRegularizations}> = (props) => {
          const {data} = props ?? {};

          return  createMultipleRegularizations(data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof createMultipleRegularizations>>, TError, {data: MultipleRegularizations}, TContext>(mutationFn, mutationOptions)
    }
    