import { MSViewer, useListEmployees } from '../../api';
import { getDaysInMonth } from '../../constants/functions';

import moment from 'moment';
import { useState } from 'react';
import TeamMemberCol from './TeamMemberCol';

interface MusterProps {
    viewerData: MSViewer;
    isViewerAdmin: boolean;
}

function Muster(props: MusterProps) {
    // const [admin, setAdmin] = useState(false);
    
    // isAdmin().then((o) => setAdmin(o));
    const tlID = props.viewerData.MSemployee_details?.id;
    const { data: employeesData, isSuccess } = useListEmployees({
        axios: {
            params: {
                reporting_manager: props.isViewerAdmin ? null : tlID,
            },
        },
    });
    const [date, setDate] = useState({
        month: moment().month(),
        year: moment().year(),
    });
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const days = getDaysInMonth(date.month, date.year);

    const daysInMonth = days.map((day) => (
        <th
            key={day.getDate()}
            className={
                day.getDay() === 0
                    ? 'px-1 text-center font-extralight text-gray-400'
                    : 'px-1 text-center'
            }
        >
            {day.getDate()} {weekdays[day.getDay()]}
        </th>
    ));
    const handleClick = (e: any) => {
        const d = e.target.value.split('-');
        setDate({
            month: parseInt(d[1]) - 1,
            year: parseInt(d[0]),
        });
    };
    return (
        <div className="overflow-auto">
            <table className=" text-zxtext-1 text-center lg:text-sm md:text-xs">
                <thead>
                    <tr className="font-medium">
                        <th id="muster-col1">
                            <input
                                type="month"
                                id="date"
                                name="date"
                                min="2015-08"
                                defaultValue={moment().format('YYYY-MM')}
                                onChange={handleClick}
                                className="w-28 cursor-pointer"
                            />
                        </th>
                        <th
                            id="muster-col2"
                            className="bg-zxbg sticky left-0 top-0"
                        >
                            Name
                        </th>
                        <th> </th>
                        {daysInMonth}
                    </tr>
                </thead>
                <tbody>
                    {isSuccess && employeesData.data ? (
                        <TeamMemberCol
                            empData={employeesData.data}
                            month={date.month + 1}
                            year={date.year}
                        />
                    ) : null}
                </tbody>
            </table>
        </div>
    );
}

export default Muster;
