/* eslint-disable import/no-unresolved */
import Emponleavetable from './Emponleavetable';

function Emponleave() {
    return (
        <div className="pb-4 border border-blue-600 rounded-md w-[90%]  ml-[5%] mt-[4%]">
            <Emponleavetable />
            <div className="flex flex-col">
                <hr className="lg:w-[93%] mt-4 h-px border-0 bg-blue-700 ml-6" />
            </div>
            <div className="grid sm:grid-cols-3 xl:grid-cols-6 gap-x-1 gap-y-3 mt-4 pr-8">
                <div className="w-6 h-6 bg-gradient-to-r from-compbullet-from to-compbullet-to ml-6 ">
                    <h1 className="sm:text-xs ml-8 ">Comp Off</h1>
                </div>
                <div className="w-6 h-6 border bg-gradient-to-r from-earnedbullet-from to-earnedbullet-to ml-4">
                    <h1 className="text-sm sm:text-xs ml-8 ">Earned Leave</h1>
                </div>
                <div className="w-6 h-6 border bg-gradient-to-r from-medbullet-from to-medbullet-to ml-4">
                    <h1 className="text-sm sm:text-xs ml-8 ">Medical Leave</h1>
                </div>
                <div className="sm:ml-6 w-6 h-6 border bg-gradient-to-r from-unpaidbullet-from to-unpaidbullet-to ml-4">
                    <h1 className="text-sm sm:text-xs ml-8 ">Unpaid Leave</h1>
                </div>
                <div className="w-6 h-6 border bg-gradient-to-r from-casbullet-from to-casbullet-to ml-4">
                    <h1 className="text-sm sm:text-xs ml-8 ">Casual Leave</h1>
                </div>
                <div className="w-6 h-6 border bg-gradient-to-r from-opbullet-from to-opbullet-to ml-4">
                    <h1 className="text-sm sm:text-xs ml-8 ">Optional Leave</h1>
                </div>
            </div>
        </div>
    );
}

export default Emponleave;
