import { AxiosResponse } from 'axios';
import classnames from 'classnames';
import { ErrorMessage, Formik } from 'formik';
import moment from 'moment';
import { Dispatch, useEffect, useMemo, useState } from 'react';
import { IconContext } from 'react-icons';
import { BsArrowDownCircle } from 'react-icons/bs';
import { DateObject } from 'react-multi-date-picker';
import { toast } from 'react-toastify';
import {
    EmpAttendance,
    MSUserMSemployeeDetails,
    MultipleRegularizations,
    useCreateRegularization,
    useRetrieveEmpAttendance
} from '../../api';
import { weekday } from '../../constants/functions';
import ButtonField from '../FormFields/ButtonField';

export default function RegularizationApplication(props: {
    date: DateObject;
    viewerDetails: MSUserMSemployeeDetails;
    clickedDates: Array<DateObject>;
    refetchEmpRegularization: any;
    multiRegData: Array<MultipleRegularizations>;
    setMultiRegData: Dispatch<
        React.SetStateAction<Array<MultipleRegularizations>>
    >;
    shift_name: string | undefined;
    start_time: string | null | undefined;
    end_time: string | null | undefined;
    manager_name: string | undefined;
    isRetrieveShiftSuccess: boolean;
}) {
    const { data: absAttData, isSuccess: isRetrieveEmpAttendanceSuccess } =
        useRetrieveEmpAttendance<AxiosResponse<EmpAttendance[]>>(
            props.viewerDetails.id ? props.viewerDetails.id.toString() : '',
            {
                query: { queryKey: [props.date.format('YYYY-MM-DD')] },
                axios: {
                    params: {
                        date: props.date.format('YYYY-MM-DD'),
                    },
                },
            }
        );

    const absAtt = absAttData?.data[0];
    const { mutateAsync, isSuccess: isSingleRegPostSuccess } =
        useCreateRegularization();

    const [form, setForm] = useState(false);
    const value = useMemo(
        () => ({
            color: '738DFE',
        }),
        []
    );
    const timeInputStyle =
        'mb-1 w-20 focus:outline-none border border-zxblue-from rounded-lg pl-1 pt-0.5 ml-1';

    const SingleRegInitialValues = {
        reason: '',
    };

    interface RegApply {
        reason: string;
    }

    const actSignIn = moment(
        `${props.date.format('YYYY-MM-DD')} ${absAtt?.sign_in}`
    ).toISOString();
    const actSignOut = moment(
        `${props.date.format('YYYY-MM-DD')} ${absAtt?.sign_in}`
    ).toISOString();
    const regSignIn = moment(
        `${props.date.format('YYYY-MM-DD')} ${props.start_time}`
    ).toISOString();
    const regSignOut = moment(
        `${props.date.format('YYYY-MM-DD')} ${props.end_time}`
    ).toISOString();

    const createMultipleReg = useMemo(
        () => ({
            date: props.date.format('YYYY-MM-DD'),
            actual_sign_in: actSignIn,
            actual_sign_out: actSignOut,
            regularized_sign_in: regSignIn,
            regularized_sign_out: regSignOut,
            reason: '',
            attendance_id: absAtt?.id,
        }),
        [absAtt?.id, actSignIn, actSignOut, props.date, regSignIn, regSignOut]
    );

    if (isRetrieveEmpAttendanceSuccess && props.isRetrieveShiftSuccess) {
        if (
            !props.multiRegData.some(
                (reg) => reg.date === createMultipleReg.date
            ) &&
            props.clickedDates.includes(props.date) &&
            props.clickedDates.length > 1
        ) {
            props.setMultiRegData(() => [
                ...props.multiRegData,
                createMultipleReg,
            ]);
        }
        if (
            props.clickedDates.length !== 0 &&
            props.clickedDates.length < props.multiRegData.length
        ) {
            const mrdd = props.multiRegData.map((reg) => reg.date);
            if (
                !props.clickedDates.some(
                    (date) => !mrdd.includes(date.format('YYYY-MM-DD'))
                )
            ) {
                props.setMultiRegData(() => [
                    ...props.multiRegData.filter((reg) =>
                        props.clickedDates.includes(new DateObject(reg.date))
                    ),
                ]);
            }
        }
    }

    const handleSubmit = async (values: RegApply) => {
        const createReg = {
            ...values,
            date: props.date.format('YYYY-MM-DD'),
            actual_sign_in: actSignIn,
            actual_sign_out: actSignOut,
            regularized_sign_in: regSignIn,
            regularized_sign_out: regSignOut,
            attendance_id: absAtt?.id,
        };
        createReg.reason = values.reason;
        await mutateAsync({ data: createReg });
    };

    useEffect(() => {
        if (isSingleRegPostSuccess) {
            setForm(false);
            toast.success('Regularization Applied');
            props.clickedDates.splice(
                props.clickedDates.indexOf(props.date),
                1
            );
            props.refetchEmpRegularization();
        }
    }, [isSingleRegPostSuccess, props]);

    return (
        <div>
            <div className="box-border border border-zxblue-to rounded-md focus:outline-none w-full pl-4 py-4 mb-3">
                <div className="flex flex-col space-y-3">
                    <div className="flex flex-row space-x-4">
                        <div className="flex flex-col leading-3 font-medium">
                            <span className="border border-zxblue-from px-2 py-1 text-zxtext-1 text-sm">
                                {moment(props.date.toString()).date()}
                            </span>
                            <span className="pt-1.5 text-zxtext-3 text-xs text-center">
                                {weekday[moment(props.date.toString()).day()]}
                            </span>
                        </div>
                        <div className="flex flex-col space-y-2">
                            <div className="space-x-3 text-zxtext-1 font-medium text-sm">
                                <span>Shift Type:</span>
                                <span>
                                    {props.shift_name}{' '}
                                    <span className="text-black text-xs">{`${props.start_time}-${props.end_time}`}</span>
                                </span>
                            </div>

                            <div className="flex flex-row font-medium text-sm text-zxtext-1">
                                Sign In:{' '}
                                <span className="flex flex-col text-xs mr-4">
                                    <input
                                        className={timeInputStyle}
                                        type="text"
                                        name="sign_in"
                                        id="sign_in"
                                        value={props.start_time || undefined}
                                        onChange={(e) => e.target.value}
                                    />
                                    <span className="text-zxerror-from ml-2">
                                        {absAtt?.sign_in
                                            ? absAtt.sign_in
                                            : 'Not signed in'}
                                    </span>
                                </span>
                                Sign Out:{' '}
                                <span className="flex flex-col text-xs mr-4">
                                    <input
                                        className={timeInputStyle}
                                        type="text"
                                        name="sign_out"
                                        id="sign_out"
                                        value={props.end_time || undefined}
                                        onChange={(e) => e.target.value}
                                    />
                                    <span className="text-zxerror-from ml-2">
                                        {absAtt?.sign_out
                                            ? absAtt.sign_out
                                            : 'Not signed out'}
                                    </span>
                                </span>
                                <span className="mr-4">
                                    Manager:{' '}
                                    <span className="font-normal text-xs leading-4">
                                        {props.manager_name}
                                    </span>
                                </span>
                                <span className="">CC: </span>
                            </div>
                        </div>
                    </div>
                    {form ? (
                        <div className="flex flex-col mr-3.5 box-border">
                            <Formik
                                initialValues={SingleRegInitialValues}
                                onSubmit={handleSubmit}
                            >
                                {(prop) => (
                                    <form className="w-full box-border flex flex-col">
                                        <textarea
                                            name="reason"
                                            className={classnames(
                                                'rounded-lg focus:outline-none px-1.5 py-0.5 mb-[2%] w-full h-12 text-xs font-medium text-zxtext-1 border',
                                                prop.touched.reason &&
                                                    prop.errors.reason
                                                    ? 'border-zxerror-to'
                                                    : 'border-zxblue-to'
                                            )}
                                            value={prop.values.reason}
                                            onChange={prop.handleChange}
                                        />
                                        <ErrorMessage
                                            name="reason"
                                            component="span"
                                            className="text-[77%] text-zxerror-from"
                                        />
                                        <div className="flex flex-row pl-[37%]">
                                            <ButtonField
                                                name="Cancel"
                                                onClick={() => setForm(false)}
                                                className="mt-[3%]"
                                            />
                                            <ButtonField
                                                name="Submit"
                                                className="mt-[3%]"
                                                onClick={() => {
                                                    prop.submitForm();
                                                }}
                                            />
                                        </div>
                                    </form>
                                )}
                            </Formik>
                        </div>
                    ) : (
                        <>
                            <div className="font-normal text-xs leading-5 text-zxtext-1">
                                Give a reason:
                            </div>
                            <div className="w-full -mt-1">
                                <button
                                    type="button"
                                    className="float-right w-6 pr-7"
                                    onClick={() => setForm(true)}
                                >
                                    <IconContext.Provider value={value}>
                                        <BsArrowDownCircle color="#738DFE" />
                                    </IconContext.Provider>
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}
