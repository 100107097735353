import 'react-toastify/dist/ReactToastify.css';

import * as Yup from 'yup';

import { Field, Form, Formik } from 'formik';
import { Education, MSViewer } from '../api';
import { BOARD10, BOARD12, STREAM } from '../constants/options';

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUpdateEducation } from '../api/endpoints/education/education';
import { useCreateEducation } from '../api/endpoints/educations/educations';
import { EDUCATION_FIELD } from '../constants/FieldNames';
import { EDUCATIONINITIAL } from '../constants/initialValues';
import { HOME } from '../constants/router';
import Button from './FormFields/Button';

const EducationValidationSchema = Yup.object().shape({
    class_10_percentage: Yup.string()
        .min(1)
        .max(5)
        .required('Required')
        .matches(/^(\d{1,5}|\d{0,5}\.\d{1,2})$/, 'Must be only digits'),
    class_12_percentage: Yup.string()
        .min(1)
        .max(5)
        .required('Required')
        .matches(/^(\d{1,5}|\d{0,5}\.\d{1,2})$/, 'Must be only digits'),
    grad_college_uni: Yup.string().max(200).required('Required'),
    grad_degree: Yup.string().max(100).required('Required'),
    grad_specialization: Yup.string().max(50).required('Required'),
    grad_cgpa_percentage: Yup.string()
        .min(1)
        .max(4)
        .required('Required')
        .matches(/^(\d{1,3}|\d{0,3}\.\d{1,2})$/, 'Must be only digits'),
    master_college_uni: Yup.string().max(200),
    master_degree: Yup.string().max(100),
    master_specialization: Yup.string().max(50),
    master_cgpa_percentage: Yup.string()
        .min(1)
        .max(4)
        .matches(/^(\d{1,3}|\d{0,3}\.\d{1,2})$/, 'Must be only digits'),
});

interface EducationFormProps {
    userData: MSViewer;
    // form2: boolean;
    refetch: any;
    setForm2: React.Dispatch<React.SetStateAction<boolean>>;
}

function EducationForm(props: EducationFormProps) {
    const { mutateAsync: createMutate, isSuccess: createSuccess } =
        useCreateEducation();
    const { mutateAsync: updateMutate, isSuccess: updateSuccess } =
        useUpdateEducation();
    const empDetails = props.userData.MSemployee_details;

    const navigate = useNavigate();

    const board10Options = BOARD10.map((val) => (
        <option key={val} value={val}>
            {val}
        </option>
    ));

    const board12Options = BOARD12.map((val) => (
        <option key={val} value={val}>
            {val}
        </option>
    ));

    const board12Stream = STREAM.map((val) => (
        <option key={val} value={val}>
            {val}
        </option>
    ));

    const handleSubmit = async (values: Education) => {
        if (empDetails === undefined || empDetails === null) {
            // console.log('Create a new employee');
        } else {
            const updatedVal: Education = {
                ...values,
                employee_id: empDetails.id,
                master_college_uni:
                    values.master_college_uni === ''
                        ? undefined
                        : values.master_college_uni,
                master_degree:
                    values.master_degree === ''
                        ? undefined
                        : values.master_degree,
                master_specialization:
                    values.master_specialization === ''
                        ? undefined
                        : values.master_specialization,
            };

            if (
                empDetails.education_qualifications === null ||
                empDetails.education_qualifications === undefined
            ) {
                const res = await createMutate({ data: updatedVal });
                if (res.status === 201) props.refetch();
            } else if (empDetails.education_qualifications?.id !== undefined) {
                const res = await updateMutate({
                    id: empDetails.education_qualifications?.id.toString(),
                    data: updatedVal,
                });
                if (res.status === 200) props.refetch();
            }
        }
    };

    if (
        empDetails &&
        empDetails.education_qualifications &&
        empDetails.bank_details &&
        empDetails.profile_details && 
        empDetails.profile_details.address_details
    )
        navigate(HOME);
    else toast.error("Please fill all the details first!")

    useEffect(() => {
        if (createSuccess) toast('Education Details Created');
        else if (updateSuccess) toast('Education Details Updated');
    }, [createSuccess, updateSuccess]);

    return (
        <div>
            <h2 className="py-4 border-x-zxblue-from text-transparent font-semibold md:text-xl lg:text-2xl bg-clip-text bg-gradient-to-t from-zxheader-from to-zxheader-to">
                <span className="border-l-2 border-zxsub-to pl-1">
                    Education Qualifications
                </span>
            </h2>
            <Formik
                initialValues={EDUCATIONINITIAL}
                validationSchema={EducationValidationSchema}
                onSubmit={handleSubmit}
            >
                {({ touched, errors }) => (
                    <Form className="pl-3 text-sm">
                        <div className="grid md:grid-cols-3 lg:grid-cols-4 pl-3">
                            <div className="flex flex-col">
                                <label
                                    className={
                                        touched.class_10_board &&
                                        errors.class_10_board
                                            ? 'pl-0.5 text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                                            : 'pl-0.5'
                                    }
                                >
                                    Class X Board *
                                </label>
                                <Field
                                    as="select"
                                    id={EDUCATION_FIELD.CLASS_10_BOARD}
                                    name={EDUCATION_FIELD.CLASS_10_BOARD}
                                    className={
                                        touched.class_10_board &&
                                        errors.class_10_board
                                            ? 'w-9/12 mb-4 border border-zxerror-to rounded-md focus:outline-none placeholder: pl-2 py-1'
                                            : 'w-9/12 mb-4 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-1'
                                    }
                                >
                                    {board10Options}
                                </Field>
                                {errors.class_10_board &&
                                touched.class_10_board ? (
                                    <span className="text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to text-xs -mt-4">
                                        {errors.class_10_board}
                                    </span>
                                ) : null}
                            </div>

                            <div className="flex flex-col">
                                <label
                                    className={
                                        touched.class_10_percentage &&
                                        errors.class_10_percentage
                                            ? 'pl-0.5 text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                                            : 'pl-0.5'
                                    }
                                >
                                    Class X Percentage *
                                </label>
                                <Field
                                    id={EDUCATION_FIELD.CLASS_10_PERCENTAGE}
                                    name={EDUCATION_FIELD.CLASS_10_PERCENTAGE}
                                    placeholder="%"
                                    className={
                                        touched.class_10_percentage &&
                                        errors.class_10_percentage
                                            ? 'w-9/12 mb-4 border border-zxerror-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                                            : 'w-9/12 mb-4 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5 '
                                    }
                                />
                                {errors.class_10_percentage &&
                                touched.class_10_percentage ? (
                                    <span className="text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to text-xs -mt-4">
                                        {errors.class_10_percentage}
                                    </span>
                                ) : null}
                            </div>

                            <div className="flex flex-col">
                                <label
                                    className={
                                        touched.class_12_board &&
                                        errors.class_12_board
                                            ? 'pl-0.5 text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                                            : 'pl-0.5'
                                    }
                                >
                                    Class XII Board *
                                </label>
                                <Field
                                    as="select"
                                    id={EDUCATION_FIELD.CLASS_12_BOARD}
                                    name={EDUCATION_FIELD.CLASS_12_BOARD}
                                    className={
                                        touched.class_12_board &&
                                        errors.class_12_board
                                            ? 'w-9/12 mb-4 border border-zxerror-to rounded-md focus:outline-none placeholder: pl-2 py-1'
                                            : 'w-9/12 mb-4 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-1'
                                    }
                                >
                                    {board12Options}
                                </Field>
                                {errors.class_12_board &&
                                touched.class_12_board ? (
                                    <span className="text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to text-xs -mt-4">
                                        {errors.class_12_board}
                                    </span>
                                ) : null}
                            </div>

                            <div className="flex flex-col">
                                <label
                                    className={
                                        touched.class_12_stream &&
                                        errors.class_12_stream
                                            ? 'pl-0.5 text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                                            : 'pl-0.5'
                                    }
                                >
                                    Class XII Stream *
                                </label>
                                <Field
                                    as="select"
                                    id={EDUCATION_FIELD.CLASS_12_STREAM}
                                    name={EDUCATION_FIELD.CLASS_12_STREAM}
                                    className={
                                        touched.class_12_stream &&
                                        errors.class_12_stream
                                            ? 'w-9/12 mb-4 border border-zxerror-to rounded-md focus:outline-none placeholder: pl-2 py-1'
                                            : 'w-9/12 mb-4 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-1'
                                    }
                                >
                                    {board12Stream}
                                </Field>
                                {errors.class_12_stream &&
                                touched.class_12_stream ? (
                                    <span className="text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to text-xs -mt-4">
                                        {errors.class_12_stream}
                                    </span>
                                ) : null}
                            </div>

                            <div className="flex flex-col">
                                <label
                                    className={
                                        touched.class_12_percentage &&
                                        errors.class_12_percentage
                                            ? 'pl-0.5 text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                                            : 'pl-0.5'
                                    }
                                >
                                    Class XII Percentage *
                                </label>
                                <Field
                                    id={EDUCATION_FIELD.CLASS_12_PERCENTAGE}
                                    placeholder="%"
                                    name={EDUCATION_FIELD.CLASS_12_PERCENTAGE}
                                    className={
                                        touched.class_12_percentage &&
                                        errors.class_12_percentage
                                            ? 'w-9/12 mb-4 border border-zxerror-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                                            : 'w-9/12 mb-4 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5 '
                                    }
                                />
                                {errors.class_12_percentage &&
                                touched.class_12_percentage ? (
                                    <span className="text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to text-xs -mt-4">
                                        {errors.class_12_percentage}
                                    </span>
                                ) : null}
                            </div>

                            <div className="flex flex-col">
                                <label
                                    className={
                                        touched.grad_college_uni &&
                                        errors.grad_college_uni
                                            ? 'pl-0.5 text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                                            : 'pl-0.5'
                                    }
                                >
                                    Graduation College/University *
                                </label>
                                <Field
                                    id={EDUCATION_FIELD.GRAD_COLLEGE_UNI}
                                    name={EDUCATION_FIELD.GRAD_COLLEGE_UNI}
                                    className={
                                        touched.grad_college_uni &&
                                        errors.grad_college_uni
                                            ? 'w-9/12 mb-4 border border-zxerror-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                                            : 'w-9/12 mb-4 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5 '
                                    }
                                />
                                {errors.grad_college_uni &&
                                touched.grad_college_uni ? (
                                    <span className="text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to text-xs -mt-4">
                                        {errors.grad_college_uni}
                                    </span>
                                ) : null}
                            </div>

                            <div className="flex flex-col">
                                <label
                                    className={
                                        touched.grad_degree &&
                                        errors.grad_degree
                                            ? 'pl-0.5 text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                                            : 'pl-0.5'
                                    }
                                >
                                    Graduation Degree *
                                </label>
                                <Field
                                    id={EDUCATION_FIELD.GRAD_DEGREE}
                                    placeholder="B.Tech/B.E"
                                    name={EDUCATION_FIELD.GRAD_DEGREE}
                                    className={
                                        touched.grad_degree &&
                                        errors.grad_degree
                                            ? 'w-9/12 mb-4 border border-zxerror-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                                            : 'w-9/12 mb-4 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5 '
                                    }
                                />
                                {errors.grad_degree && touched.grad_degree ? (
                                    <span className="text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to text-xs -mt-4">
                                        {errors.grad_degree}
                                    </span>
                                ) : null}
                            </div>

                            <div className="flex flex-col">
                                <label
                                    className={
                                        touched.grad_specialization &&
                                        errors.grad_specialization
                                            ? 'pl-0.5 text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                                            : 'pl-0.5'
                                    }
                                >
                                    Graduation Specialization *
                                </label>
                                <Field
                                    id={EDUCATION_FIELD.GRAD_SPECIALIZATION}
                                    placeholder="Computer Science"
                                    name={EDUCATION_FIELD.GRAD_SPECIALIZATION}
                                    className={
                                        touched.grad_specialization &&
                                        errors.grad_specialization
                                            ? 'w-9/12 mb-4 border border-zxerror-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                                            : 'w-9/12 mb-4 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5 '
                                    }
                                />
                                {errors.grad_specialization &&
                                touched.grad_specialization ? (
                                    <span className="text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to text-xs -mt-4">
                                        {errors.grad_specialization}
                                    </span>
                                ) : null}
                            </div>

                            <div className="flex flex-col">
                                <label
                                    className={
                                        touched.grad_cgpa_percentage &&
                                        errors.grad_cgpa_percentage
                                            ? 'pl-0.5 text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                                            : 'pl-0.5'
                                    }
                                >
                                    CGPI/Percentage *
                                </label>
                                <Field
                                    id={EDUCATION_FIELD.GRAD_CGPA_PERCENTAGE}
                                    name={EDUCATION_FIELD.GRAD_CGPA_PERCENTAGE}
                                    className={
                                        touched.grad_cgpa_percentage &&
                                        errors.grad_cgpa_percentage
                                            ? 'w-9/12 mb-4 border border-zxerror-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                                            : 'w-9/12 mb-4 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5 '
                                    }
                                />
                                {errors.grad_cgpa_percentage &&
                                touched.grad_cgpa_percentage ? (
                                    <span className="text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to text-xs -mt-4">
                                        {errors.grad_cgpa_percentage}
                                    </span>
                                ) : null}
                            </div>

                            <div className="flex flex-col">
                                <label
                                    className={
                                        touched.master_college_uni &&
                                        errors.master_college_uni
                                            ? 'pl-0.5 text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                                            : 'pl-0.5'
                                    }
                                >
                                    Masters/PostGrad College/University
                                </label>
                                <Field
                                    id={EDUCATION_FIELD.MASTER_COLLEGE_UNI}
                                    name={EDUCATION_FIELD.MASTER_COLLEGE_UNI}
                                    className={
                                        touched.master_college_uni &&
                                        errors.master_college_uni
                                            ? 'w-9/12 mb-4 border border-zxerror-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                                            : 'w-9/12 mb-4 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5 '
                                    }
                                />
                                {errors.master_college_uni &&
                                touched.grad_college_uni ? (
                                    <span className="text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to text-xs -mt-4">
                                        {errors.master_college_uni}
                                    </span>
                                ) : null}
                            </div>

                            <div className="flex flex-col">
                                <label
                                    className={
                                        touched.master_degree &&
                                        errors.master_degree
                                            ? 'pl-0.5 text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                                            : 'pl-0.5'
                                    }
                                >
                                    Masters/PostGrad Degree
                                </label>
                                <Field
                                    id={EDUCATION_FIELD.MASTER_DEGREE}
                                    placeholder="M.Tech/M.E"
                                    name={EDUCATION_FIELD.MASTER_DEGREE}
                                    className={
                                        touched.master_degree &&
                                        errors.master_degree
                                            ? 'w-9/12 mb-4 border border-zxerror-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                                            : 'w-9/12 mb-4 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5 '
                                    }
                                />
                                {errors.master_degree &&
                                touched.master_degree ? (
                                    <span className="text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to text-xs -mt-4">
                                        {errors.master_degree}
                                    </span>
                                ) : null}
                            </div>

                            <div className="flex flex-col">
                                <label
                                    className={
                                        touched.master_specialization &&
                                        errors.master_specialization
                                            ? 'pl-0.5 text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                                            : 'pl-0.5'
                                    }
                                >
                                    Masters/PostGrad Specialization
                                </label>
                                <Field
                                    id={EDUCATION_FIELD.MASTER_SPECIALIZATION}
                                    placeholder="Electronics"
                                    name={EDUCATION_FIELD.MASTER_SPECIALIZATION}
                                    className={
                                        touched.master_specialization &&
                                        errors.master_specialization
                                            ? 'w-9/12 mb-4 border border-zxerror-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                                            : 'w-9/12 mb-4 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5 '
                                    }
                                />
                                {errors.master_specialization &&
                                touched.master_specialization ? (
                                    <span className="text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to text-xs -mt-4">
                                        {errors.master_specialization}
                                    </span>
                                ) : null}
                            </div>

                            <div className="flex flex-col">
                                <label
                                    className={
                                        touched.master_cgpa_percentage &&
                                        errors.master_cgpa_percentage
                                            ? 'pl-0.5 text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
                                            : 'pl-0.5'
                                    }
                                >
                                    Masters/PostGrad CGPI/Percentage
                                </label>
                                <Field
                                    id={EDUCATION_FIELD.MASTER_CGPA_PERCENTAGE}
                                    name={
                                        EDUCATION_FIELD.MASTER_CGPA_PERCENTAGE
                                    }
                                    className={
                                        touched.master_cgpa_percentage &&
                                        errors.master_cgpa_percentage
                                            ? 'w-9/12 mb-4 border border-zxerror-to rounded-md focus:outline-none placeholder: pl-2 py-0.5'
                                            : 'w-9/12 mb-4 border border-zxblue-to rounded-md focus:outline-none placeholder: pl-2 py-0.5 '
                                    }
                                />
                                {errors.master_cgpa_percentage &&
                                touched.master_cgpa_percentage ? (
                                    <span className="text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to text-xs -mt-4">
                                        {errors.master_cgpa_percentage}
                                    </span>
                                ) : null}
                            </div>
                        </div>
                        <div className="flex flex-row gap-x-20 justify-center">
                            <Button
                                value="Previous"
                                onClick={() => {
                                    props.setForm2(false);
                                }}
                                button
                                className="-ml-16 text-zxtext-1 border border-zxbutton2-to"
                            />
                            <Button value="Submit" className="-ml-14" />
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default EducationForm;
