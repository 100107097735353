import Header from '../components/Header';
import SalaryTable from '../components/Salary/SalaryTable';

function SalaryDashboard() {
    return (
        <div className="lg:ml-12 pr-4 md:ml-6 py-6 min-w-[75%]">
            <Header name="Salary Dashboard" />
            <SalaryTable />
        </div>
    );
}
export default SalaryDashboard;
