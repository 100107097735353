import 'react-toastify/dist/ReactToastify.css';

import * as Yup from 'yup';

import { Address, MSViewer, useCreateAddress, useUpdateAddress } from '../api';

import { useFormik } from 'formik';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { ADDRESS_FIELD } from '../constants/FieldNames';
import Button from './FormFields/Button';
import Field from './FormFields/Field';

interface AddressFormProps {
    userData: MSViewer;
    // form2: boolean;
    setForm2: React.Dispatch<React.SetStateAction<boolean>>;
}

function AddressForm(props: AddressFormProps) {
    const { mutateAsync: createMutate, isSuccess: createSuccess } =
        useCreateAddress();
    const { mutateAsync: updateMutate, isSuccess: updateSuccess } =
        useUpdateAddress();

    const profileDetails = props.userData.MSemployee_details?.profile_details;
    const addressDetails =
        props.userData.MSemployee_details?.profile_details?.address_details;

    const address =
        addressDetails === undefined ? undefined : addressDetails[0];

    const formik = useFormik({
        initialValues: {
            address_line_1: '',
            address_line_2: '',
            city: '',
            state: '',
            pincode: 0,
        },
        validationSchema: Yup.object({
            address_line_1: Yup.string()
                .max(23, 'Continue in line 2')
                .required('Required'),
            address_line_2: Yup.string()
                .max(23, 'Max characters of 23 allowed')
                .required('Required'),
            city: Yup.string()
                .max(20, 'Max 20 characters')
                .required('Required'),
            state: Yup.string()
                .max(20, 'Max 20 characters')
                .required('Required'),
            pincode: Yup.number()
                .typeError('Only numbers are allowed')
                .max(999999, '6 digits')
                .min(100000, '6 digits')
                .required('Required'),
        }),
        onSubmit: async (values: Address) => {
            if (profileDetails !== undefined && profileDetails !== null) {
                const updatedVal: Address = {
                    ...values,
                    profile_id: profileDetails.id,
                };
                if (address === undefined || address === null) {
                    await createMutate({ data: updatedVal });
                } else {
                    const addressId = address.id;
                    await updateMutate({
                        id: addressId ? addressId.toString() : '',
                        data: updatedVal,
                    });
                }
            }
        },
    });

    useEffect(() => {
        if (createSuccess) {props.setForm2(true);toast('Address Added');}
        if(updateSuccess) {props.setForm2(true);toast('Address Updated');}
    }, [createSuccess, props, updateSuccess]);
    
    return (
        <div>
            <h2 className="py-4 border-x-zxblue-from text-transparent font-semibold md:text-xl lg:text-2xl bg-clip-text bg-gradient-to-t from-zxheader-from to-zxheader-to">
                <span className="border-l-2 border-zxsub-to pl-1">
                    Address Information
                </span>
            </h2>
            <form className="pl-3" onSubmit={formik.handleSubmit}>
                <div className="place-self-auto	 grid grid-cols-4 ">
                    <Field
                        label="Line 1 *"
                        type="text"
                        placeholder="-"
                        colspan={2}
                        id={ADDRESS_FIELD.ADDRESS_LINE_1}
                        name={ADDRESS_FIELD.ADDRESS_LINE_1}
                        onChange={formik.handleChange}
                        value={formik.values.address_line_1}
                        touched={formik.touched.address_line_1}
                        formik={formik.errors.address_line_1}
                    />

                    <Field
                        label="Line 2 *"
                        type="text"
                        placeholder="-"
                        colspan={2}
                        id={ADDRESS_FIELD.ADDRESS_LINE_2}
                        name={ADDRESS_FIELD.ADDRESS_LINE_2}
                        onChange={formik.handleChange}
                        value={formik.values.address_line_2}
                        touched={formik.touched.address_line_2}
                        formik={formik.errors.address_line_2}
                    />

                    <Field
                        label="City *"
                        type="text"
                        placeholder="-"
                        colspan={undefined}
                        id={ADDRESS_FIELD.CITY}
                        name={ADDRESS_FIELD.CITY}
                        onChange={formik.handleChange}
                        value={formik.values.city}
                        touched={formik.touched.city}
                        formik={formik.errors.city}
                    />

                    <Field
                        label="State *"
                        type="text"
                        placeholder="-"
                        colspan={undefined}
                        id={ADDRESS_FIELD.STATE}
                        name={ADDRESS_FIELD.STATE}
                        onChange={formik.handleChange}
                        value={formik.values.state}
                        touched={formik.touched.state}
                        formik={formik.errors.state}
                    />

                    <Field
                        label="Pincode *"
                        type="number"
                        placeholder="-"
                        colspan={undefined}
                        id={ADDRESS_FIELD.PINCODE}
                        name={ADDRESS_FIELD.PINCODE}
                        onChange={formik.handleChange}
                        value={formik.values.pincode}
                        touched={formik.touched.pincode}
                        formik={formik.errors.pincode}
                    />
                </div>
                {/* <Button value="Submit" className="-ml-16 place-self-center"  /> */}
                <div className="flex flex-row gap-x-6 justify-center">
                    <Button
                        value="Submit"
                        className="-ml-16 place-self-center"
                    />
                    {/* <Button
                        value="Next"
                        className="text-zxtext-1 border border-zxbutton2-to place-self-center"
                    /> */}
                </div>
            </form>
        </div>
    );
}

export default AddressForm;
