function Holcrudcircle() {
    return (
        <div className="flex justify-between gap-x-10">
            <div className="absolute lg:inset-x-72 lg:inset-y-[65%] md:w-52 md:h-52 md:inset-x-52 md:inset-y-[60%] lg:w-60 lg:h-60 bg-gradient-to-r from-zxblue-from to-zxblue-to rounded-full" />
            <div className="absolute lg:inset-x-[90%] md:inset-x-[110%] md:inset-y-[15%] lg:inset-y-[10%] lg:h-44 lg:w-44 md:w-36 md:h-36 bg-gradient-to-r from-zxblue-from to-zxblue-to rounded-full" />
        </div>
    );
}

export default Holcrudcircle;
