/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'


/**
 * Return a list of all roles.
 */
export const listRoles = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown[]>> => {
    return axios.get(
      `/roles/`,options
    );
  }


export const getListRolesQueryKey = () => [`/roles/`];

    
export type ListRolesQueryResult = NonNullable<Awaited<ReturnType<typeof listRoles>>>
export type ListRolesQueryError = AxiosError<unknown>

export const useListRoles = <TData = Awaited<ReturnType<typeof listRoles>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listRoles>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListRolesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listRoles>>> = ({ signal }) => listRoles({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof listRoles>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * Get roles of the user
 */
export const retrieveGetUserRole = (
    id: string, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<unknown>> => {
    return axios.get(
      `/roles/${id}`,options
    );
  }


export const getRetrieveGetUserRoleQueryKey = (id: string,) => [`/roles/${id}`];

    
export type RetrieveGetUserRoleQueryResult = NonNullable<Awaited<ReturnType<typeof retrieveGetUserRole>>>
export type RetrieveGetUserRoleQueryError = AxiosError<unknown>

export const useRetrieveGetUserRole = <TData = Awaited<ReturnType<typeof retrieveGetUserRole>>, TError = AxiosError<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof retrieveGetUserRole>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getRetrieveGetUserRoleQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveGetUserRole>>> = ({ signal }) => retrieveGetUserRole(id, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof retrieveGetUserRole>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

