import {
    useRetrieveMSUser
} from '../../../api';
import ProfileUpdateField from '../ProfileUpdateField';

export default function AddressInfoSection(props: { id: string }) {
    const { data: usersData } = useRetrieveMSUser(props.id);
    const addressDetails =
        usersData?.data.MSemployee_details?.profile_details?.address_details;
    return (
        <div className="grid grid-flow-row gap-y-4 gap-x-2 md:grid-cols-3 lg:grid-cols-4 px-7">
                <ProfileUpdateField
                    label="Line 1"
                    value={
                        addressDetails ? addressDetails[0].address_line_1 : '-'
                    }
                    className='col-span-2'
                />
                <ProfileUpdateField
                    label="Line 2"
                    value={
                        addressDetails ? addressDetails[0].address_line_2 : '-'
                    }
                    className='col-span-2'
                />
                <ProfileUpdateField
                    label="City"
                    value={addressDetails ? addressDetails[0].city : '-'}
                />
                <ProfileUpdateField
                    label="State"
                    value={addressDetails ? addressDetails[0].state : '-'}
                />
                <ProfileUpdateField
                    label="Pincode"
                    value={addressDetails ? addressDetails[0].pincode : '-'}
                />
            </div>
    );
}
