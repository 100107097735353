/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */

export type MultipleRegularizationsStatus = typeof MultipleRegularizationsStatus[keyof typeof MultipleRegularizationsStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const MultipleRegularizationsStatus = {
  Pending: 'Pending',
  Approved: 'Approved',
  Rejected: 'Rejected',
} as const;
