import classnames from 'classnames';

function Select(props: {
    label: string;
    options: any;
    id: string;
    name: string;
    onChange: any;
    value: any;
    touched: boolean | undefined;
    formik: string | undefined;
}) {
    const { options } = props;
    const newoptions = options.map((val: any) => (
        <option value={val} key={val}>
            {val}
        </option>
    ));
    const styles = classnames(
        'w-9/12 mb-4 border pl-2 py-1 rounded-md focus:outline-none',
        props.touched && props.formik ? 'border-zxerror-to' : 'border-zxblue-to'
    );
    const labelStyle = classnames(
        'pl-0.5',
        props.touched && props.formik
            ? 'pl-0.5 text-transparent bg-clip-text bg-gradient-to-tr from-zxerror-from to-zxerror-to'
            : 'border-zxblue-to'
    );
    return (
        <div className="flex flex-col">
            <label className={labelStyle}>{props.label}</label>
            <select
                onChange={props.onChange}
                id={props.id}
                value={props.value}
                className={styles}
            >
                <option defaultValue={undefined}>Choose {props.label}</option>
                {newoptions}
            </select>
        </div>
    );
}

export default Select;
