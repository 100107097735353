import { useEffect } from 'react';
import { useListMSUsers } from '../api';
import EmployeeRow from './EmployeeRow';

interface EmployeeTableProps {
    filters: {
        dept: string;
        grade: string;
        desg: string;
        status: string;
        past: string;
        name: string;
    };
}

function EmployeeTable(props: EmployeeTableProps) {
    const {
        data: usersData,
        isSuccess,
        isLoading,
        isFetchedAfterMount,
        refetch,
    } = useListMSUsers({
        axios: {
            params: {
                department_id:
                    props.filters.dept === '' ? null : props.filters.dept,
                grade: props.filters.grade === '' ? null : props.filters.grade,
                designation_id:
                    props.filters.desg === '' ? null : props.filters.desg,
                employee_number_series:
                    props.filters.status === '' ? null : props.filters.status,
                name: props.filters.name === '' ? null : props.filters.name,
                retired:
                    props.filters.past === ''
                        ? null
                        : props.filters.past === 'true'
                        ? 'Retired'
                        : null,
                absconded:
                    props.filters.past === ''
                        ? null
                        : props.filters.past === 'true'
                        ? 'Absconded'
                        : null,
                r_current:
                    props.filters.past === ''
                        ? null
                        : props.filters.past === 'false'
                        ? 'Absconded'
                        : null,
                a_current:
                    props.filters.past === ''
                        ? null
                        : props.filters.past === 'false'
                        ? 'Retired'
                        : null,
            },
        },
    });

    useEffect(() => {
        refetch();
    }, [
        props.filters.dept,
        props.filters.grade,
        props.filters.desg,
        props.filters.status,
        props.filters.past,
        props.filters.name,
        refetch,
    ]);
    // Need to render fast

    return (
        <table className="pt-6 text-zxtext-1 md:text-sm lg:text-lg px-2 w-full table-auto">
            <thead className="h-12">
                <tr id="bord-row">
                    <th>No</th>
                    <th>Employee ID</th>
                    <th>Name</th>
                    <th>Phone No</th>
                    <th>Email ID</th>
                    <th>Joining Date</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody className="lg:text-sm md:text-xs text-left">
                {isLoading ? (
                    <tr>
                        <td>Loading.......</td>
                    </tr>
                ) : isFetchedAfterMount && isSuccess ? (
                    <EmployeeRow<
                        Parameters<typeof refetch>[0],
                        ReturnType<typeof refetch>
                    >
                        usersData={usersData}
                        refetch={refetch}
                    />
                ) : (
                    <tr>
                        <td>Not Success</td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}

export default EmployeeTable;
