import {
    useRetrieveMSUser
} from '../../../api';
import ProfileUpdateField from '../ProfileUpdateField';

export default function EducationInfoSection(props: { id: string }) {
    const { data: employeeData } = useRetrieveMSUser(props.id);
    const educationDetails =
        employeeData?.data.MSemployee_details?.education_qualifications;
    return (
        <div className="grid grid-flow-row gap-y-4 gap-x-2 md:grid-cols-3 lg:grid-cols-4 px-7">
            <ProfileUpdateField
                label="Class 10 Board"
                value={
                    educationDetails?.class_10_board
                        ? educationDetails.class_10_board
                        : '-'
                }
            />
            <ProfileUpdateField
                label="Class 10 Percentage"
                value={
                    educationDetails?.class_10_percentage
                        ? educationDetails.class_10_percentage
                        : '-'
                }
            />
            <ProfileUpdateField
                label="Class 12 Board"
                value={
                    educationDetails?.class_12_board
                        ? educationDetails.class_12_board
                        : '-'
                }
            />
            <ProfileUpdateField
                label="Class 12 Stream"
                value={
                    educationDetails?.class_12_stream
                        ? educationDetails.class_12_stream
                        : '-'
                }
            />
            <ProfileUpdateField
                label="Class 12 Percentage"
                value={
                    educationDetails?.class_12_percentage
                        ? educationDetails.class_12_percentage
                        : '-'
                }
            />
            <ProfileUpdateField
                label="Graduation College"
                value={
                    educationDetails?.grad_college_uni
                        ? educationDetails.grad_college_uni
                        : '-'
                }
            />
            <ProfileUpdateField
                label="Graduation Degree"
                value={
                    educationDetails?.grad_degree
                        ? educationDetails.grad_degree
                        : '-'
                }
            />
            <ProfileUpdateField
                label="Graduation Specialization"
                value={
                    educationDetails?.grad_specialization
                        ? educationDetails.grad_specialization
                        : '-'
                }
            />
            <ProfileUpdateField
                label="CGPI/Percentage"
                value={
                    educationDetails?.grad_cgpa_percentage
                        ? educationDetails.grad_cgpa_percentage
                        : '-'
                }
            />
            <ProfileUpdateField
                label="PostGraduation College"
                value={
                    educationDetails?.master_college_uni
                        ? educationDetails.master_college_uni
                        : '-'
                }
            />
            <ProfileUpdateField
                label="PostGraduation Degree"
                value={
                    educationDetails?.master_degree
                        ? educationDetails.master_degree
                        : '-'
                }
            />
            <ProfileUpdateField
                label="PostGraduation Specialization"
                value={
                    educationDetails?.master_specialization
                        ? educationDetails.master_specialization
                        : '-'
                }
            />
            <ProfileUpdateField
                label="CGPI/Percentage"
                value={
                    educationDetails?.master_cgpa_percentage
                        ? educationDetails.master_cgpa_percentage
                        : '-'
                }
            />
        </div>
    );
}
