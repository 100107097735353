import { AxiosResponse } from 'axios';
import { useEffect } from 'react';
import {
    EmpAttendance,
    useListMSViewers,
    useRetrieveEmpAttendance
} from '../api';
import AttAvgHrs from './AttAvgHrs';
import AttendanceTable from './AttendanceTable';

export default function AttendanceTableDiv(props: {
    month: number;
    year: number;
    createdAtt?: boolean;
    updatedAtt?: boolean;
}) {
    const { data: usersData } = useListMSViewers();
    const empID = usersData?.data[0].MSemployee_details?.id
        ? usersData.data[0].MSemployee_details.id.toString()
        : '';
    const {
        data: attendancesData,
        isSuccess: isMonthlyAttndSuccess,
        isLoading: isMonthlyAttndLoading,
        isFetchedAfterMount: isMonthlyAttndFetchedAfterMount,
        refetch: refetchMonthlyAttnd,
    } = useRetrieveEmpAttendance<AxiosResponse<EmpAttendance[]>>(empID, {
        axios: {
            params: {
                month: props.month,
                year: props.year,
            },
        },
    });
    const att_data = attendancesData?.data;
    useEffect(()=>{
        refetchMonthlyAttnd();
    },[props.month, props.year, refetchMonthlyAttnd])
    return (
        <div className="w-full">
            <AttendanceTable
                att_data={att_data}
                isMonthlyAttndSuccess={isMonthlyAttndSuccess}
                isMonthlyAttndLoading={isMonthlyAttndLoading}
                isMonthlyAttndFetchedAfterMount={
                    isMonthlyAttndFetchedAfterMount
                }
                isSignInSucces={props.createdAtt}
                isSignOutSucces={props.updatedAtt}
                refetchMonthlyAttnd={refetchMonthlyAttnd}
            />
            <div className="border border-zxblue-to rounded-md font-medium text-base flex flex-row h-14">
                <h1 className="py-4 pl-4">Average Hours</h1>
                <AttAvgHrs
                    att_data={att_data}
                    isMonthlyAttndSuccess={isMonthlyAttndSuccess}
                />
            </div>
        </div>
    );
}
