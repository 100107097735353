import {
    Bank,
    BankUpiApp,
    Education,
    EducationClass10Board,
    EducationClass12Board,
    EducationClass12Stream,
} from '../api/schema';

export const BANKINITIAL: Bank = {
    account_no: '',
    bank_name: '',
    customer_name: '',
    branch: '',
    IFSC: '',
    PAN: '',
    upi_id: '',
    upi_mobile: '',
    upi_app: BankUpiApp.Gpay,
    aadhar_no: '',
};

export const EDUCATIONINITIAL: Education = {
    class_10_board: EducationClass10Board.STATE,
    class_10_percentage: '',
    class_12_board: EducationClass12Board.STATE,
    class_12_percentage: '',
    class_12_stream: EducationClass12Stream.Science,
    grad_college_uni: '',
    grad_degree: '',
    grad_specialization: '',
    grad_cgpa_percentage: '',
    master_college_uni: undefined,
    master_degree: undefined,
    master_specialization: undefined,
    master_cgpa_percentage: undefined,
};
