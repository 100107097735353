/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios'
import type {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  Shift
} from '../../schema'


/**
 * List all shifts
 */
export const listShifts = (
     options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Shift[]>> => {
    return axios.get(
      `/shifts/`,options
    );
  }


export const getListShiftsQueryKey = () => [`/shifts/`];

    
export type ListShiftsQueryResult = NonNullable<Awaited<ReturnType<typeof listShifts>>>
export type ListShiftsQueryError = AxiosError<unknown>

export const useListShifts = <TData = Awaited<ReturnType<typeof listShifts>>, TError = AxiosError<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof listShifts>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getListShiftsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof listShifts>>> = ({ signal }) => listShifts({ signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof listShifts>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

/**
 * Create a shifts
 */
export const createShift = (
    shift: Shift, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<Shift>> => {
    return axios.post(
      `/shifts/`,
      shift,options
    );
  }



    export type CreateShiftMutationResult = NonNullable<Awaited<ReturnType<typeof createShift>>>
    export type CreateShiftMutationBody = Shift
    export type CreateShiftMutationError = AxiosError<unknown>

    export const useCreateShift = <TError = AxiosError<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createShift>>, TError,{data: Shift}, TContext>, axios?: AxiosRequestConfig}
) => {
      const {mutation: mutationOptions, axios: axiosOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createShift>>, {data: Shift}> = (props) => {
          const {data} = props ?? {};

          return  createShift(data,axiosOptions)
        }

      return useMutation<Awaited<ReturnType<typeof createShift>>, TError, {data: Shift}, TContext>(mutationFn, mutationOptions)
    }
    