import { EmpAttendance, useRetrieveEmpAttendance } from '../../api';

import { AxiosResponse } from 'axios';
import moment from 'moment';

interface EmpAttProps {
    empId: number;
}

function AvgHrs(props: EmpAttProps) {
    const { data: empAttData, isSuccess } = useRetrieveEmpAttendance<
        AxiosResponse<EmpAttendance[]>
    >(props.empId ? props.empId.toString() : '');

    if (isSuccess) {
        // assigned any due to incorrect interface from openapi
        const attData = empAttData?.data;
        const totalHrs = attData?.reduce((acc, curr) => {
            const startTime = moment(curr.sign_in, 'HH:mm:ss a');
            const endTime = moment(curr.sign_out, 'HH:mm:ss a');
            const duration = moment.duration(endTime.diff(startTime));
            return acc + duration.hours();
        }, 0);

        // could give an error due to attData.length
        const avgHrs = totalHrs / attData.length;
        const avgmin = ((totalHrs % attData.length) * 60) / attData.length;

        return (
            <td>
                {Math.floor(avgHrs).toString()} Hrs {avgmin.toString()} min
            </td>
        );
    }
    return null;
}

export default AvgHrs;
