import { useRetrieveMSUser } from '../../../api';
import ProfileUpdateField from '../ProfileUpdateField';

export default function LeaveInfoSection(props: { id: string }) {
    const { data: employeeData } = useRetrieveMSUser(props.id);
    const empDetails = employeeData?.data.MSemployee_details;
    return (
        <div className="grid grid-flow-row gap-y-4 gap-x-2 md:grid-cols-3 lg:grid-cols-4 px-7">
            <ProfileUpdateField
                label="Earned Leave Balance"
                value={
                    empDetails?.earned_leave_balance
                        ? empDetails.earned_leave_balance
                        : '-'
                }
            />
            <ProfileUpdateField
                label="Casual Leave Balance"
                value={
                    empDetails?.casual_leave_balance
                        ? empDetails.casual_leave_balance
                        : '-'
                }
            />
            <ProfileUpdateField
                label="Sick Leave Balance"
                value={
                    empDetails?.sick_leave_balance
                        ? empDetails.sick_leave_balance
                        : '-'
                }
            />
            <ProfileUpdateField
                label="Optional Holiday Balance"
                value={
                    empDetails?.optional_hol_balance
                        ? empDetails.optional_hol_balance
                        : '-'
                }
            />
            <ProfileUpdateField
                label="Comp Off Grant Balance"
                value={empDetails?.comp_off_bal ? empDetails.comp_off_bal : '-'}
            />
        </div>
    );
}
