/**
 * Generated by orval v6.10.3 🍺
 * Do not edit manually.
 * Your Project
 * API for all things …
 * OpenAPI spec version: 1.0.0
 */

export type EmployeeEmployeeStatus = typeof EmployeeEmployeeStatus[keyof typeof EmployeeEmployeeStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EmployeeEmployeeStatus = {
  Pending: 'Pending',
  Verified: 'Verified',
  Active: 'Active',
  Deactive: 'Deactive',
  Retired: 'Retired',
  Absconded: 'Absconded',
} as const;
