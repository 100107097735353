import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { HOLCRUD } from '../constants/router';
import Button from './FormFields/Button';

interface Props {
    setFilters?: React.Dispatch<
        React.SetStateAction<{
            name: string;
            month: number;
        }>
    >;
    isViewerAdmin: boolean;
}

function HolidaysSubHeader(props: Props) {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const d = e.target.value.split('-');
        const month = d[1];
        if (props.setFilters) {
            props.setFilters((values) => ({
                ...values,
                [e.target.name]: month,
            }));
        }
    };
    const navigate = useNavigate();

    return (
        <div className="ml-12 px-2 text-zxtext-1 space-x-8 flex flex-row">
            <div className="flex lg:text-sm md:text-xs flex-row gap-x-6 mt-4 ">
                {/* <label>
                    <select
                        className="focus:outline-none border-4 rounded-lg border-indigo-400"
                        name="filter"
                        id="filter"
                    >
                        <option selected>Filter</option>
                    </select>
                </label> */}
                {/* <label>General Holiday : 12</label>
                <label>Restricted Holiday : 2</label>
                <label>Optional Holiday : 79</label> */}
            </div>
            <input
                type="month"
                id="month"
                name="month"
                min="2015-08"
                defaultValue={moment().format('YYYY-MM')}
                onChange={handleChange}
                className="bg-zxbg cursor-pointer"
            />
            {props.isViewerAdmin ? (
                <Button
                    value="Add Holiday"
                    className="lg:text-base md:text-sm"
                    onClick={() => navigate(HOLCRUD)}
                />
            ) : null}
        </div>
    );
}

export default HolidaysSubHeader;
