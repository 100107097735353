import Circle from './Circle';
import Frame from './Frame';

function Background() {
    return (
        <div className="w-full h-screen flex justify-center items-center">
            <Circle />
            <Frame />
        </div>
    );
}

export default Background;
